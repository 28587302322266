import { Button, Form, Input, Typography, message, notification } from "antd";
import React, { useEffect,useState } from "react";
const { Text } = Typography;
import { useParams } from "react-router-dom";
import { userCreateConfirmCodeUrl, userValidateConfirmCodeContinueUrl } from "services";
import { emailRegex } from "constant";
import { post } from "helper";
import { checkIfUserExists } from "helper/CheckUserExist";
import { useUser } from "helper/Hook";

const Email = ({ setOpenMail,openMail }) => {
    const { id: userId } = useParams();
    const { data, mutateData } = useUser(userId);
    const { result: user } = data;
    const [form] = Form.useForm();

    const [email, setEmail] = useState("");
    const [stepMail,setStepMail] = useState("0");
    const [emailCode, setEmailCode] = useState();

    const handleSubmitMailConfirmCode = async () => {
        const emailAddress = (form.getFieldValue("email"));
        if (email.validateStatus === "error") return;
        if (emailAddress !== undefined) {
            const newValues = {
                email: emailAddress,
                type: 1
            };
            const { success, message: msg } = await post(
                userCreateConfirmCodeUrl,
                newValues
            );
            if (!success) {
                notification.error({
                    description: msg,
                    message: "Bilgilendirme",
                    placement: "top"
                });
                return;
            }
            message.success("Mail Adresinize Kod Gönderildi!");
            setStepMail("1");
        }
        else{
            message.error("Mail adresi boş girilmez");
        }
    };

    const emailHandleChange = async e => {
        const emailInput = e.target.value;
        if (!emailRegex.test(emailInput) ){
            const errorMessage = "Lütfen geçerli bir email giriniz";
            const errorValidateStatus = { errorMsg: errorMessage, validateStatus: "error" };
            setEmail((prevValue) => ({ ...prevValue, ...errorValidateStatus }));
            return ;
        } 
        else { 
            const isExist = await checkIfUserExists("email" , emailInput);
            const errorMessage = "Bu email kayıtlıdır!";
            const errorValidateStatus = { errorMsg: errorMessage, validateStatus: "error" };
            const successValidateStatus = { errorMsg: null, validateStatus: "success" };
            if (!isExist) {
                setEmail((prevValue) => ({ ...prevValue, ...successValidateStatus, value: emailInput  }));
            }
            else setEmail((prevValue) => ({ ...prevValue, ...errorValidateStatus }));
        }
    };

    const emailHandleCodeChange = e => {
        const emailCode = e.target.value.replace(/\D/g, "");
        setEmailCode(emailCode);
    };

    const handleSubmitMailValidateCode = async () => {
        const emailAddress = (form.getFieldValue("email"));
        const newValues = {
            code: emailCode,
            email: emailAddress,
            type: 1,
            user_id: user.id
        };
        const { success, message: msg } = await post(
            `${userValidateConfirmCodeContinueUrl}${user.id}`,
            newValues
        );
        if (!success) {
            notification.error({
                description: msg,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        message.success("Başarıyla Güncellendi!");
        setOpenMail(false);
        mutateData();
    };

    useEffect(() => {
        mutateData();
        setStepMail("0");
        form.resetFields();
        setEmail( { errorMsg: undefined, validateStatus: undefined }); 
        setEmailCode("");
    }, [openMail]);

    return (
        <div>
            { stepMail === "0" ? (
                <>
                    <Text>Yeni Mail Adresi Giriniz</Text>
                    <Form 
                        form={ form } 
                    >
                        <Form.Item
                            name="email"
                            validateStatus={ email?.validateStatus }
                            help={ email?.errorMsg }
                            hasFeedback
                            rules={ [
                                {
                                    message: "Lütfen email adresinizi girin!",
                                    required: true
                                },
                                {
                                    message: "Lütfen email adresinizi girin!",
                                    type:"email"
                                },
                                {
                                    whitespace:true
                                }
                            ] }
                        >
                            <Input 
                                placeholder={ user?.email }
                                className="phone-Input"
                                onChange={ emailHandleChange }
                            />
                        </Form.Item>
                        <Button
                            type="primary"
                            onClick={ handleSubmitMailConfirmCode }
                        >
                            Kod Gönder
                        </Button>
                    </Form> </>)
                : (
                    <>
                        <Text>Mail Adresinize Gelen Kodu Giriniz</Text>
                        <Form 
                            form={ form } 
                        >
                            <Form.Item 
                                hasFeedback
                            >
                                <Input 
                                    placeholder="Lütfen email adresinize gelen kodu giriniz" 
                                    className="phone-Input"
                                    onChange={ emailHandleCodeChange }
                                    maxLength={ 6 }
                                    value={ emailCode }
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                onClick={ handleSubmitMailValidateCode }
                            >
                                Kodu Onayla
                            </Button>
                        </Form></>) 
            }
        </div>
    );
};

export default Email;
