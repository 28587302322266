import React, { useState } from "react";
import Filter from "./Filter";
import SettingsAddPage from "../add/Page";
import { Breadcrumbs } from "../Constant";
import { useData, useTable } from "../Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const SettingsListPage = () => {
    const [type,setType] = useState("list");
    const [id,setId] = useState();

    const { queryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns } = useTable( setType, setId, mutate );

    return (
        <>
            { type === "list" ? <PageContent
                breadcrumbs={ Breadcrumbs } 
                tableData={ useData } 
                tableColumns={ columns } 
                dataFilter={ <Filter setType={ setType } /> }
            /> : <SettingsAddPage settingsId={ type === "edit" ? id : undefined } setType={ setType } /> }
        </>
    );
};

export default SettingsListPage;
