import { CreditCardOutlined } from "@ant-design/icons";
import { Form } from "antd";
import React from "react";
import { CustomNumberInput } from "components";

const CvvCode = () => (
    <Form.Item
        name="cvv2"
        rules={ [
            {
                required: true,
                message: "Lütfen güvenlik kodunu giriniz!"
            },
            {
                min: 3,
                max: 3,
                message: "Güvenlik kodu 3 karakter olmalıdır!"
            }
        ] }
    >
        <CustomNumberInput
            digitsOfNumber={ 3 }
            placeholder="789"
            prefix={ <CreditCardOutlined className="mr-7"/> }
        />
    </Form.Item>
);

export default CvvCode;
