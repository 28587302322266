import { Breadcrumb as AntdBreadcrumb, PageHeader } from "antd";
import { Link } from "react-router-dom";
import { usePageData } from "context";

const Breadcrumb = () => {
    
    const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();
    
    const Routes = () => (
        <AntdBreadcrumb style={ { marginTop:"64px" } }>
            <AntdBreadcrumb.Item key="dashboard">
                <Link to="/dashboard">Dashboard</Link>
            </AntdBreadcrumb.Item>
            { pageBreadcrumbs.map((item, index) => 
                <AntdBreadcrumb.Item key={ index.toString() }>
                    { index === pageBreadcrumbs.length -1 ? 
                        <span className="text-muted">{ item.breadcrumbName }</span> :
                        <Link to={ item.path }>{ item.breadcrumbName }</Link> 
                    }
                </AntdBreadcrumb.Item>
            ) }
        </AntdBreadcrumb>
    );

    return (
        <PageHeader
            className="site-page-header"
            title={ pageTitle }
            breadcrumb={ Routes() }
            subTitle={ pageDescription }
        />
        
        
    );
};

export default Breadcrumb;
