/* eslint-disable no-restricted-imports */
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Progress, Row, Space } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { syncOrderUrl } from "services";
import FilterMenu from "./filter/FilterMenu";
import UpdateSend from "./filter/UpdateSend";
import { getFilterQuery } from "../Helper";
import { ProtectedElement } from "components";
import { DrawerComp } from "components/common/Drawer";
import { FilterButton } from "components/common/FilterButton";
import { comparingInitialSalesFilter } from "constant";
import { useFilter } from "context";
import { useTableData } from "context/TableProvider";
import { notificationError, post, useSyncProgress } from "helper";

const Filter = ({ data, mutate }) => {
    const { queryParams, setQueryParams,  searchText, setSearchText } = useFilter();
    const [form] = Form.useForm();
    const { progressError, progressItem1, delayItem1, syncProgress }= useSyncProgress("order");
    const { selectedRows } = useTableData();

    const [open, setOpen] = useState(false);
    const [buttonStyle, setButtonStyle] = useState("no-filter");
    const [date,setDate] = useState([]);

    const syncOrderList = async () => {
        const { success, message } = await post(syncOrderUrl);
        if (!success) {
            notificationError(message);
            return;
        }
        //syncProgress();
    };

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleSubmit = (values) => {
        const { date, ...rest }=values;
        const started_at = values?.date?.at(0).format("YYYY-MM-DD 00:00");
        const finished_at = values?.date?.at(1).format("YYYY-MM-DD 23:59");

        const newValues = {
            ...rest,
            ordered_at: [started_at, finished_at]
        };

        if (searchText) newValues.name = searchText;
        if ( !values.date ) newValues.ordered_at = undefined;
        applyFilter({ ...queryParams.filter, ...newValues });
    };

    const handleInputChange = ({ target }) => {
        const name=target.value;
        setSearchText(name);
        applyFilter({ ...queryParams.filter, name });
    };

    useEffect(()=>{
        const { pageNumber, filter: { active,...restFilter }, ...newFilter } = queryParams;
        const newParam={ ...newFilter,filter:{ ...restFilter } };
        const equal = isEqual(newParam, comparingInitialSalesFilter);
        { equal ? setButtonStyle("no-filter-btn-style") : setButtonStyle("filter-btn-style"); }
    },[queryParams]);

    return (
        <>
            <Row gutter={ 24 }>
                <Col span={ 6 }>
                    <div className="mb-10">
                        <Input
                            onChange={ handleInputChange }
                            placeholder="Sipariş Kodu"
                            value={ searchText }
                        />
                    </div>
                </Col>
                <Col className="ml-auto">
                    <Space>
                        <UpdateSend data={ data } mutate={ mutate }/>
                        {
                            selectedRows<1 &&     
                                (<ProtectedElement permissionKey={ "sync.add" }>   
                                    <Button
                                        shape="round"
                                        type="dashed"
                                        onClick={ () => syncOrderList() }
                                        block
                                        icon={ <CloudDownloadOutlined /> }
                                        disabled={ progressItem1 !== 100 }
                                    >
                                        Güncelle
                                    </Button>
                                </ProtectedElement>)
                        }
                        <FilterButton
                            setOpen={ setOpen }
                            buttonStyle={ buttonStyle }
                        />
                        <DrawerComp
                            setOpen={ setOpen }
                            open={ open }
                            form={ form }
                            type={ "edit" }
                        >
                            <FilterMenu form={ form } 
                                handleSubmit={ handleSubmit } 
                                setDate={ setDate } 
                                date= { date } />
                        </DrawerComp>
                    </Space>
                </Col>
            </Row>
            { ((progressItem1 !== 100 || !delayItem1) && !progressError) && (
                <>
                    <Row>
                        <p>Siparişler Güncelleniyor</p>
                    </Row>
                    <Progress percent={ progressItem1 } status="active" />
                </>
            ) }
        </>

    );
};
export default Filter;
