import { DeleteOutlined } from "@ant-design/icons";

export const columnHelper = (deleteHandler) => {
    const columns = [
        {
            render: (_, record) => (       
                <div >{ record?.label }</div>
            ),      
            sorter: (a, b) => a.label.localeCompare(b.label),
            title: "Firma İsmi"
        },
        {
            key: "actions",
            render: (record) => (
                <DeleteOutlined
                    style={ { color: "red", marginLeft: 10 } }
                    onClick={ () => deleteHandler(record) }
                />
            ),
            title: "İşlem"
        }
    ];
    return columns;
};

export const formatResultForTable = (data) =>data?.map((item) => ({
    key: item?.id?.toString(),
    label: item?.name,
    value: item?.id
})).sort((a, b) => a.label.localeCompare(b.label));

export const formatResultForTable1 = (data) =>data?.map((item) => ({
    key: item?.company_id?.toString(),
    label: item?.company_name,
    value: item?.company_id
}))?.sort((a, b) => a.label.localeCompare(b.label));
