import axios from "axios";
import { getStorage, removeStorage, setStorage } from "../helper"; // Düzeltme: setStorage1 yerine setStorage olarak değiştirildi

const ROOT_STATE_STORAGE_KEY = "root";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
});

let isRefreshing = false;
const refreshSubscribers = [];
const subscribeTokenRefresh = (cb) => {
    refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
    refreshSubscribers.forEach(cb => cb(token)); // Düzeltme: map yerine forEach kullanıldı
};

const requestInterceptor = async (config) => {
    const storeData = await getStorage(ROOT_STATE_STORAGE_KEY);
    if (!storeData) return config;
    const data = JSON.parse(storeData);
    const isRefreshTokenRequest = config.url.includes("/authenticate/refresh");
    const accessToken = data?.tokens?.access_token;
    const refreshToken = data?.tokens?.refresh_token;

    if (!isRefreshTokenRequest && accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    } else if (isRefreshTokenRequest && refreshToken) {
        config.headers.Authorization = `Bearer ${refreshToken}`;
    }
    return config;
};

const responseInterceptor = (response) => {
    return response?.data;
};

const responseErrorInterceptor = async (error) => {
    const { config, response: { status } } = error;
    const originalRequest = config;
    const isRefreshTokenRequest = config.url.includes("/authenticate/refresh");

    if (status === 401) {
        if (isRefreshTokenRequest) {
            removeStorage(ROOT_STATE_STORAGE_KEY);
            window.location.reload(); // Not: Server-side rendering durumunda bu kod çalışmayabilir
            return Promise.reject(error);
        } else {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const storeData = await getStorage(ROOT_STATE_STORAGE_KEY);
                    const data = JSON.parse(storeData);
                    const refreshToken = data?.tokens?.refresh_token;

                    if (!refreshToken) {
                        throw new Error("No refresh token available");
                    }

                    const response = await instance.get("/authenticate/refresh");
                    await setStorage(ROOT_STATE_STORAGE_KEY, JSON.stringify({ ...data, tokens: response }));
                    onRefreshed(response?.access_token);
                    isRefreshing = false;
                } catch (refreshError) {
                    isRefreshing = false;
                    return Promise.reject(refreshError);
                }
            }

            return new Promise((resolve) => {
                subscribeTokenRefresh(token => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    resolve(instance(originalRequest));
                });
            });
        }
    }

    return Promise.reject(error);
};

instance.interceptors.request.use(requestInterceptor, error => Promise.reject(error));
instance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export default instance;
