import { Form, Input } from "antd";
import React from "react";

const Description = () => 
    ( <Form.Item
        label="Açıklama / Ödeme Notu"
        name="description"
        initialValue={ "" }
    >
        <Input placeholder="Lütfen açıklama giriniz" />
    </Form.Item>);

export default Description;
