import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { cartesianHelper, columnAdd } from "./Helper";

const ProductVariants = ( { variants, setProductDetails, productDetails } ) => {
    const [tableData, setTableData] = useState(undefined);
    const [selectedRows, setSelectedRows] = useState([]);
    const [newColumns, setNewColumns] = useState( undefined);
 
    const onTableFormChangeHandler = ( inputType, record ) => ( e ) => {
        setProductDetails( ( prev ) => ( {
            ...prev,
            [ record?.key ]: { ...record, ...prev?.[record?.key], [ inputType ]:  e?.target?.value }
        } ) );
    };

    const createVariantsTable = () => {      
        const result = cartesianHelper( Object.values( variants ) );       
        const tableFormattedData = result.map((item1, i) => {
            let newObj = {};
            item1.map((item) => {
                item = {
                    ...item,
                    key: `${i + 10}`
                };
                newObj = Object.assign(newObj, item);
            });
            return newObj;
        });
        setTableData(tableFormattedData);
    };

    const deleteHandler = (record) => {
        Modal.confirm({
            cancelText: "Hayır",
            okText: "Evet",
            okType: "danger",
            onOk: () =>
                setTableData((prev) => prev.filter((item) => item.key !== record.key)),
            title: "Emin misiniz?"
        });
    };

    useEffect( () => {       
        const columns= columnAdd( productDetails, onTableFormChangeHandler, deleteHandler );
        const keyNamesVariant = Object.keys( variants );       
        keyNamesVariant.map((name) => {
            let tempObj = {
                dataIndex: name,
                key: name,
                title: name?.charAt(0)?.toUpperCase() + name?.slice(1),
                width: "5%"
            };
            if (!columns.find((item) => item.title === name)) {
                columns.unshift({ ...tempObj });
            }
            setNewColumns(columns);
        });
    }, []);

    useEffect(() => {
        createVariantsTable();
    }, [variants]);

    return (
        <Table 
            className="product_add_table"
            bordered
            dataSource={ tableData }
            columns={ newColumns }
            rowSelection={ {
                columnWidth: "2%",
                onChange: (keys) => setSelectedRows(keys),
                selectedRowKeys: selectedRows,
                type: "checkbox"
            } }
        />
    );
};

export default ProductVariants;
