import { Button, Form, Input, Typography, message, notification } from "antd";
import React, { useEffect, useState } from "react";
const { Text } = Typography;
import { useParams } from "react-router-dom";
import { userCreateConfirmCodeUrl, userValidateConfirmCodeContinueUrl } from "services";
import { post } from "helper";
import { checkIfUserExists } from "helper/CheckUserExist";
import { useUser } from "helper/Hook";

const Gsm = ({ setOpenGsm,openGsm }) => {
    const { id: userId } = useParams();
    const { data, mutateData } = useUser(userId);
    const { result: user } = data;  
    const [form] = Form.useForm();

    const [stepGsm,setStepGsm] = useState("0");
    const [fullGsm,setFullGsm] = useState();
    const [gsmCode,setGsmCode] = useState();
    const [gsm, setGsm] = useState({ value:"" });

    const handleSubmitGsmConfirmCode = async () => {    
        if (gsm.validateStatus === "error") return;
        if ( fullGsm !== "+905") {
            const newValues = {
                phone: fullGsm,
                type: 0
            };
            const { success, message: msg } = await post(
                userCreateConfirmCodeUrl,
                newValues
            );
            if (!success) {
                notification.error({
                    description: msg,
                    message: "Bilgilendirme",
                    placement: "top"
                });
                return;
            }
            message.success("Telefonunuza Kod Gönderildi!");
            setStepGsm("1");
        }
        else {
            message.error("Boş girmeyiniz");
        }
    };

    const handleSubmitGsmValidateCode = async () => {
        const newValues = {
            code: gsmCode,
            phone: fullGsm,
            type: 0,
            user_id: user.id
        };
        const { success, message: msg } = await post(
            `${userValidateConfirmCodeContinueUrl}${user.id}`,
            newValues
        );
        if (!success) {
            notification.error({
                description: msg,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        message.success("Numaranız Başarıyla Güncellenmiştir!");
        setOpenGsm(false);
        mutateData();
    };

    const gsmHandleChange = async e => {
        const gsmInput = e.target.value.replace(/\D/g, "").slice(0, 9);
        const fullGsm = "+905"+ gsmInput;
        setFullGsm(fullGsm);
        setGsm(prev=> ({ ...prev,fullGsm,value:gsmInput }));
        if ( !/^\d{9}$/.test( gsmInput) ) {
            setGsm((prevValue) =>
                ({ ...prevValue, errorMsg:"Lütfen geçerli bir telefon numarası yazınız!", validateStatus: "error" }));
            return;         
        } 
        else {
            const isExist = await checkIfUserExists( "gsm", fullGsm );
            const errorMessage = "Bu telefon numarası kayıtlıdır!";
            const errorValidateStatus = { errorMsg: errorMessage, validateStatus: "error" };
            const successValidateStatus = { errorMsg: null, validateStatus: "success" };
            if (!isExist) {
                setGsm((prevValue) => ({ ...prevValue, ...successValidateStatus, value: gsmInput  }));
            }
            else setGsm((prevValue) => ({ ...prevValue, ...errorValidateStatus }));
        }
    };

    const gsmHandleCodeChange = e => {
        const gsmCode = e.target.value.replace(/\D/g, "");
        setGsmCode(gsmCode);
    };

    useEffect(() => {
        mutateData();
        setStepGsm("0");
        form.resetFields();
        setGsm( { errorMsg: undefined, validateStatus: undefined }); 
        setGsmCode("");
    }, [openGsm]);

    return (
        <div>{ stepGsm === "0" ? (
            <>
                <Text>Yeni Telefon Numarası Giriniz</Text>
                <Form 
                    form={ form }
                >
                    <Form.Item
                        hasFeedback
                        validateStatus={ gsm.validateStatus }
                        help={ gsm.errorMsg }
                        className="phone-Input"
                        rules={ [
                            {
                                message: "Lütfen telefon numaranızı yazınız!",
                                required: true
                            },
                            {
                                max: 9,
                                message:"Lütfen geçerli bir telefon numarası yazınız!",
                                min: 9                                    
                            }                 
                        ] }
                    >
                        <Input 
                            prefix="+90 5" 
                            type="text"
                            placeholder={ user?.gsm.slice(4,13) } 
                            onChange={ gsmHandleChange } 
                            maxLength={ 9 }
                            validateStatus={ gsm?.validateStatus }
                            value={ gsm.value }
                            className="phone-Input"
                        />
                    </Form.Item>
                    <Button
                        type="primary"
                        onClick={ handleSubmitGsmConfirmCode }
                    >
                        Kod Gönder
                    </Button>
                </Form>
            </>
        ) : (<>
            <Text>Telefonunuza Gelen Kodu Giriniz</Text>
            <Form 
                form={ form } 
            >
                <Form.Item 
                    hasFeedback
                >
                    <Input  
                        placeholder="Lütfen telefonunuza gelen kodu giriniz" 
                        className="phone-Input"
                        onChange={ gsmHandleCodeChange }
                        maxLength={ 6 }
                        value={ gsmCode }
                    />
                </Form.Item>
                <Button
                    type="primary"
                    onClick={ handleSubmitGsmValidateCode }
                >
                    Kodu Onayla
                </Button>
            </Form></>) }</div>
    );
};

export default Gsm;
