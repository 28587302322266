export const Breadcrumbs = [
    {
        path: "/account",
        breadcrumbName: "Cari İşlemler"
    },
    {
        path: "/account/company",
        breadcrumbName: "Firmalar"
    }
];

export const getUserFields =  ( user ) => {
    const fields = [
        {
            title: "Firma Adı",
            value: user?.name
        },
        {
            title: "Firma ID",
            value: user?.id
        },
        {
            title: "Firma Kodu",
            value: user?.code
        },
        {
            title: "Kaynak Kodu",
            value: user?.out_ref
        }
    ];

    return fields;
};
