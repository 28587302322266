import { Card, Col, Form, Input, Row, Skeleton } from "antd";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BankAccounts from "./BankAccounts";
import { wrapperCol } from "./Constant";
import { addPostHandler, editPutHandler } from "./Helper";
import TopSection from "./TopSection";
import WarningResult from "./WarningResult";

const AddView = () => {
    const { id: posId } = useParams();
    const { state:posName }= useLocation();
    const [ form ] = Form.useForm();
    
    const [ renderedScheme, setRenderedScheme ] = useState(undefined);
    const [error, setError ] = useState( false );
    const [ loading, setLoading ] = useState( false );
    const [errorMessage, setErrorMessage ] = useState(undefined);

    const handleSubmit = async ( values ) => {  
        if ( posId ) {
            editPutHandler(posId,values);
        } else if ( !posId ) {
            addPostHandler(values);
        }   
    };

    if ( !loading && error ) return ( <WarningResult message={ errorMessage } />);
    
    return (
        <>
            <Card>
                <TopSection
                    form={ form }
                    posId={ posId }
                />
                <Form
                    onFinish={ handleSubmit }
                    form={ form }
                    requiredMark={ "Required" }
                    labelCol={ { span: 8 } }
                    autoComplete="off"
                    { ...wrapperCol }
                >
                    {  <BankAccounts
                        posId={ posId }
                        form={ form }
                        setLoading={ ( state ) => setLoading( state ) }
                        setError={ ( state ) => setError( state ) }
                        posName={ posName }
                        setErrorMessage={ (message)=>setErrorMessage(message) }
                        setRenderedScheme={ (scheme)=>setRenderedScheme(scheme) }
                        loading={ loading }
                    /> }
                    <Form.Item
                        label="Pos Adı"
                        name="pos_name"
                    >
                        <Input/>
                    </Form.Item>
                    { ( loading && !error ) ? (
                        <Row justify="center">
                            <Col span={ 12 }>
                                <Skeleton
                                    loading={ loading }
                                    active
                                    paragraph={ {
                                        rows: 6
                                    } }
                                /></Col>
                        </Row> ) : !loading && !error ? renderedScheme :null }
                </Form>
            </Card>
        </>
    );
};

export default AddView;
