import { Result } from "antd";

const Error = ({ 
    status="404", 
    title="404", 
    subTitle="Üzgünüz, erişmeye çalıştığınız sayfa sunucuda mevcut değil!" 
}) => (
    <Result status={ status } title={ title } subTitle={ subTitle }/>
);

export default Error;
