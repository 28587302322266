import { DatePicker, Form, Select, notification } from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import { useEffect, useState } from "react";
import { companyListUrl, userListUrl, virtualPosBankListUrl } from "services";
import { statusOptions } from "constant";
import { formatResult, post } from "helper";

const { RangePicker } = DatePicker;
import "moment/locale/tr";

export const FilterMenu = ({ form, handleSubmit, setDate }) => {
    const [companies, setCompanies] = useState(undefined);
    const [users, setUsers] = useState(undefined);
    const [virtual, setVirtual] = useState(undefined);

    const onChange = (value, dateString) => {
        setDate(dateString);
    };

    const getCompanies = async () => {
        const { result, success, message } = await post(companyListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setCompanies(formatResult(result));
    };

    const getUsers = async () => {
        const { result, success, message } = await post(userListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setUsers(formatResult(result, "full_name"));
    };

    const getVirtualPos = async () => {
        const { result, success, message } = await post(virtualPosBankListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setVirtual(formatResult(result));
    };

    useEffect(() => {
        getCompanies();
        getVirtualPos();
        // Promise.all([getCompanies(), getUsers(), getVirtualPos()]);
    }, []);

    return (
        <Form
            form={ form }
            layout="vertical"
            requiredMark={ "Required" }
            initialValues={ { active: statusOptions[1].value } }
            onFinish={ handleSubmit }
        >
            <Form.Item
                label="Firma"
                name="company_id"
            >
                <Select
                    placeholder="Lütfen firma seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                > { companies?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }</Select>
            </Form.Item>
            { /*<Form.Item
                label="Kullanıcı"
                name="user_id"
            >
                <Select
                    placeholder="Lütfen kullanıcı seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                > { users?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }</Select>
            </Form.Item>*/ }
            <Form.Item
                label="Banka(Pos Hesabı)"
                name="bank_name"
            >
                <Select
                    placeholder="Lütfen pos hesabı seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                > { virtual?.map((option) => (<Select.Option key={ option.label }>{ option.label }</Select.Option>
                    )) }</Select>
            </Form.Item>
            <Form.Item
                label="Başlangıç - Bitiş Tarihi"
                name="date"
            >
                <RangePicker locale={ locale }
                    onChange={ onChange }
                />
            </Form.Item>
        </Form>
    );
};

