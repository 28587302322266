import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Filter from "./Filter";
import { useData, useTable } from "./Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const Page = () => {
    const { id: userId } = useParams();
    const { queryParams, setQueryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns } = useTable(mutate);

    useEffect(() => {
        setQueryParams({
            filter: { user_id : parseInt(userId)  },
            pageNumber: 1,
            pageSize: 10,
            sortField: "id",
            sortOrder: "desc"
        });
    }, []);
    
    return (
        <PageContent 
            tableData={ useData } 
            tableColumns={ columns } 
            dataFilter={ <Filter /> }
        />
    );
};

export default Page;

