import { Card, Checkbox, Col, Form, Modal, Row, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { bin_checkUrl, companyListUrl, newPaymentUrl } from "services";
import {
    CardNumberInput,
    Company,
    CvvCode,
    DatePick,
    Description,
    Installments,
    Name,
    TopSection
} from "./";
import { BreadcrumbsAdd } from "../Constant";
import { PageTitle } from "context";
import { notificationError } from "helper";
import { formatResult, post } from "helper";

const AddView = () => {
    const [form] = Form.useForm();
    const enteredAmount = Form.useWatch("entered_amount", form);
    const newEnteredAmount = enteredAmount?.replace(/,/g, "");
    const enteredCardNumber = Form.useWatch("number", form);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [htmlContent, setHtmlContent] = useState(undefined);
    const [cardExpiryDate, setCardExpiryDate] = useState(undefined);
    const [installments, setInstallments] = useState(undefined);
    const [selectedInstallmentData, setSelectedInstallmentData] =useState(undefined);
    const [companies, setCompanies] = useState(undefined);
    const [pos_id, setPos_id] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const [isInstallmentsFetched, setIsInstallmentsFetched] = useState(undefined);

    const handleSave = async (values) => {
        if (!pos_id || !installments?.length || +newEnteredAmount === 0) return;
        const { date, entered_amount, ...rest } = values;
        const customerPaymentInfo = {
            ...rest,
            ...cardExpiryDate,
            ...selectedInstallmentData
        };
        const result = await post(`${newPaymentUrl}${pos_id}`, customerPaymentInfo);
        if (result) {
            setHtmlContent(result);
            setIsModalOpen(true);
        }
    };

    const onCardExpiryDateChange = (date) => setCardExpiryDate(date);

    const onSelectedInstallmentHandler = (installmentData) =>
        setSelectedInstallmentData(installmentData);

    const getCompanies = async () => {
        setLoading(true);
        const { result, success, message } = await post(`${companyListUrl}`, {
            pageSize: 1000,
            filter: { active: 1 }
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCompanies(formatResult(result));
        setLoading(false);
    };

    const getBankInformation = useCallback(
        async (first16Digit) => {
            const firstSixDigit = first16Digit.slice(0, 6);
            const { result, success } = await post(
                `${bin_checkUrl}${firstSixDigit}?amount=${newEnteredAmount || 0}`
            );
            if (!success) {
                setInstallments(false);
                setPos_id(false);
                return {
                    status: false,
                    message: "Lütfen geçerli bir kart numarası giriniz!"
                };
            }
            const { pos_id, installments } = result;
            const newInstallments = installments?.map((item, i) => ({
                key: i + 1,
                ...item
            }));

            setIsInstallmentsFetched(true);
            setInstallments(newInstallments);
            setPos_id(pos_id);
            return { status: true };
        },
        [newEnteredAmount, enteredCardNumber]
    );

    useEffect(() => {
        getCompanies();
        const handler = (event) => {
            if (event.origin === "https://api-dev.buficore.com") {
                setIsModalOpen(false);
                if (event?.data) {
                    if (event.data.status === "success") {
                        navigate("/bufipay/payments/success", { state: { paid: true } });
                    } else {
                        setIsModalOpen(false);
                        notificationError(event.data?.message);
                    }
                } else {
                    setIsModalOpen(false);
                }
            }
        };
        window.addEventListener("message", handler);
        return () => window.removeEventListener("message", handler);
    }, []);

    useEffect(() => {
        if (enteredCardNumber?.length > 15)
            getBankInformation(enteredCardNumber).catch(console.error);
    }, [getBankInformation]);

    return (
        <Card>
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <TopSection
                    isDisabled={
                        !pos_id || !installments?.length || +newEnteredAmount === 0
                    }
                />
                <Row gutter={ 24 } align="middle" justify="center">
                    <PageTitle breadcrumbs={ BreadcrumbsAdd }>
                        { BreadcrumbsAdd[BreadcrumbsAdd.length - 1].breadcrumbName }
                    </PageTitle>
                    <Col span={ 13 }>
                        <Company companies={ companies } loading={ loading } />
                        <Form.Item
                            label="Tutar"
                            name="entered_amount"
                            initialValue={ "0" }
                            rules={ [
                                {
                                    validator: (_, value) => {
                                        if (+value === 0 || value === "")
                                            return Promise.reject(
                                                new Error("Lütfen sıfırdan farklı bir tutar giriniz!")
                                            );
                                        if (+value.replace(/,/g, "") > 9999999)
                                            return Promise.reject(
                                                new Error("Ödeme miktarı belirlenen sınırdan fazla!")
                                            );
                                        return Promise.resolve();
                                    }
                                }
                            ] }
                        >
                            <NumericFormat
                                allowedDecimalSeparators={ ["."] }
                                fixedDecimalScale
                                allowLeadingZeros={ false }
                                allowNegative={ false }
                                decimalSeparator="."
                                thousandSeparator=","
                                decimalScale={ 2 }
                                className="ant-input"
                            />
                        </Form.Item>
                        <Description />
                        <Typography.Title strong level={ 5 }>
                            Banka Kart Bilgileri
                        </Typography.Title>
                        <Row gutter={ 16 }>
                            <Col span={ 12 }>
                                <Name />
                            </Col>
                            <Col span={ 12 }>
                                <Form.Item
                                    name="number"
                                    rules={ [
                                        {
                                            validator: async (a, value) => {
                                                if (value.length < 16) {
                                                    setInstallments(false);
                                                    setPos_id(false);
                                                    setIsInstallmentsFetched(false);
                                                    return Promise.reject(
                                                        "Lütfen kart numarasını tam olarak giriniz!"
                                                    );
                                                }
                                                if (value.length === 16) {
                                                    const response = await getBankInformation(value);
                                                    if (!response) {
                                                        return Promise.reject(
                                                            "Kart bilgileri doğrulanamadı!"
                                                        );
                                                    }
                                                    if (!response.status) {
                                                        return Promise.reject(response.message);
                                                    }
                                                } else return Promise.resolve();
                                            }
                                        }
                                    ] }
                                >
                                    <CardNumberInput />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ 16 }>
                            <Col span={ 12 } align="right">
                                <DatePick onCardExpiryDateChange={ onCardExpiryDateChange } />
                            </Col>
                            <Col span={ 12 }>
                                <CvvCode />
                            </Col>
                        </Row>
                        <Form.Item
                            name="threed_secure"
                            valuePropName="checked"
                            initialValue={ true }
                        >
                            <Checkbox>3D Secure İle Ödemek İstiyorum</Checkbox>
                        </Form.Item>
                        { !installments?.length > 0 &&
              isInstallmentsFetched &&
              enteredAmount > 0 && <p>Malesef Taksit Bilgileri Alınamadı!</p> }
                        { installments?.length > 0 && newEnteredAmount > 0 && (
                            <Installments
                                installments={ installments }
                                onSelectedInstallmentHandler={ onSelectedInstallmentHandler }
                            />
                        ) }
                    </Col>
                </Row>
            </Form>
            <Modal
                open={ isModalOpen }
                centered={ true }
                okText=""
                cancelText=""
                footer={ null }
                width={ 450 }
                height={ 670 }
                onCancel={ () => setIsModalOpen(false) }
                title="3D Ödeme"
            >
                <iframe
                    srcDoc={ htmlContent }
                    width={ 400 }
                    height={ 650 }
                    title="Bufipay 3DS"
                />
            </Modal>
        </Card>
    );
};
export default AddView;
