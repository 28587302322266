import { Button, Popconfirm } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate, useParams } from "react-router-dom";
import { Columns, ListUrl, Path, Url } from "./Constant";
import { delete_, getColumns } from "helper";
import { usePost } from "helper/Hook";
import { notificationError } from "helper/NotificationHelpers";

export const useTable = (mutateData) => {
    const params = useParams();
    const id = params?.id;
    const navigate = useNavigate();
    const onHide = async () => {
        await mutateData();
        navigate(`${Path}/${id}`);
    };

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${Url}/${primaryKey}`);
        if (!success) {
            notificationError(message);
            return;
        }
        onHide();
    };

    const renderAction = (_, record) => (
        <>
            <Button onClick={ () => navigate(`${record.id}/edit`) } type="link" size="small">
                <i className="flaticon2-edit text-muted" />
            </Button>
            <Popconfirm okText="Evet" cancelText="Hayır" 
                title="Eminmisiniz?" onConfirm={ () => deleteAction(record.id) }>
                <Button type="link" size="small" danger>
                    <i className="flaticon2-trash" />
                </Button>
            </Popconfirm>
        </>
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns, onHide };
};

export const useData = (queryParams) => {
    const params = useParams();
    const id = params?.id;
    const newParams = cloneDeep(queryParams);

    if (id) newParams.filter.attribute_id = +id;

    return usePost(ListUrl, newParams);
};
