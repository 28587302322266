import { Badge, Form, Space } from "antd";

export const columns = [
    {
        title: "Taksit Sayısı",
        key: "install",
        render: (_, record) => (
            <Form.Item className="table_form_item">
                { record?.install === 1 ? <p className="table_form_item">  Peşin </p> 
                    :<Space > { record?.install }
                        <span>Taksit</span>
                        { record?.extra_installment > 0 ?
                            <>
                                <Badge
                                    count={ "+"+record?.extra_installment }
                                    style={ {
                                        backgroundColor: "#52c41a",
                                        marginLeft:"5px" 
                                    } }
                                />
                            </>
                            :null
                        } 
                    </Space> }
            </Form.Item>
        )
    },
    {
        title: "Taksit Miktarı",
        key: "install_price",
        render: ( _, record ) => (
            <Form.Item className="table_form_item">
                <div className="table_form_item">
                    { record?.install } x { `${record?.install_price} TL`  }
                </div>    
            </Form.Item>
        )
    },
    {
        title: "Toplam",
        key: "total_amount",
        render: ( _, record ) =>(
            <Form.Item className="table_form_item">
                <div className="table_form_item">
                    { `${record?.total_amount} TL` }
                </div>     
            </Form.Item>
        )
    }
];
