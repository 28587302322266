import { Route, Routes } from "react-router-dom";
import { listUrl, productAttributeValueUrl } from "services";
import { AddView } from "./add";

const Breadcrumbs = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog"
    },
    {
        breadcrumbName: "Ürün Özellik Değerleri",
        path: "/product-type"
    }
];

const Columns = [
    
    {
        render: (_, record) => {
            const { name, out_ref, update_date, create_date } = record;
            return(
                <>
                    <div className="mb-5">  { name }</div>
                    <div className="font-weight-light font-size-xs">
                        Kaynak Kodu { out_ref }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Kayıt Tarihi: { create_date }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Güncelleme Tarihi: { update_date }
                    </div>
                </>
            );},
        title: "Özellik Değeri"
    },
    {
        dataIndex: "attribute",
        title: "Özellik"
    }
];
const Path = "/catalog/product-attribute-value";
const Url = productAttributeValueUrl;
const ListUrl = `${Url}${listUrl}`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddView onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddView onHide={ onHide }/> } />
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

