import { Form, Select, Typography } from "antd";
import React, {  useState } from "react";
const { Paragraph, Text }=Typography ;

const Companies = ({
    companyList,
    setCompanyList,
    tableData,
    setTableData,
    loading,
    totalCompanyCount
}) => {
    const [value, setValue] = useState([]);
    const [searchKey, setSearchKey] = useState(undefined);
    const [open, setOpen] = useState(true);

    const handleSearch = (value) => {
        setSearchKey(value);
    };

    const onCompaniesChange = (event, data) => {
        const priceHasSelectedCompany = tableData?.find(
            (product) => product?.value === event
        );
        if (!priceHasSelectedCompany)
            setTableData((prev) =>
                [...prev, data]?.sort((a, b) => a.label.localeCompare(b.label))
            );
        setCompanyList((prev) =>
            prev.filter((product) => product.value !== +event)
        );
        setValue([]);
    };

    return (
        <>
            <Form.Item label="Firma Seç">
                <Select
                    onFocus={ () => setOpen(false) }
                    showSearch
                    style={ { marginBottom: "30px", width: "100%" } }
                    defaultActiveFirstOption={ false }
                    notFoundContent={ null }
                    clearIcon={ false }
                    placeholder="Lütfen firma seçiniz"
                    searchValue={ searchKey }
                    onSearch={ handleSearch }
                    value={ value }
                    mode="multiple"
                    options={ companyList }
                    onSelect={ onCompaniesChange }
                    filterOption={ (inputValue, option) =>
                        option.label?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    loading={ loading }
                />
            </Form.Item>
            <div>
                <div style={ { color: "green", float: "right", marginTop: "-50px" } }>
                    { totalCompanyCount ===tableData?.length
                        ? "Listedeki tüm firmalar seçili"
                        : companyList?.length+ " Firma"  }         
                </div>
            </div>
            { open && (
                <Paragraph>
                    <Text type="danger">*</Text>
                    Firma listesinden seçtiğiniz her firma aşağıdaki tabloya
                    eklenecektir
                </Paragraph>
            ) }
        </>
    );
};

export default Companies;
