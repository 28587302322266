import { Form, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { productSkuListUrl } from "services";
import { formatDataHelper } from "./Helper";
import { notificationError, post } from "helper";

const { Paragraph, Text }=Typography;

const ProductList = ({
    filteredOptions,
    onFilteredOptionsHandler,
    onCampaignDataHandler,
    campaignData
}) => {
    const [searchKey, setSearchKey] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const [ value, setValue ] = useState();
    const [isMessageVisible, setIsMessageVisible] = useState(true);

    const getProductList = async () => {
        setLoading(true);
        const { result, success, message } = await post(productSkuListUrl, {
            filter: { active: 1, on_sale: true },
            keyword: searchKey,
            pageNumber: 1,
            pageSize: 20
        });
        if (!success) {
            setLoading(false);
            notificationError(message);
            return;
        }
        const formattedResult = formatDataHelper(result);
        onFilteredOptionsHandler(formattedResult);
        setLoading( false );
    };

    const onProductsChange = ( event ) => {             
        const selectedProduct = filteredOptions?.find(
            (product) => product?.id === +event?.at(0)
        );     
        onFilteredOptionsHandler( prev => prev.filter(product=>product.id!==+event?.at(0)) );  
        const campaignHasSelectedProduct = campaignData?.find(
            (product) => product?.id === +event?.at(0)
        );        
        if ( !campaignHasSelectedProduct ) onCampaignDataHandler( ( prev ) => ([ ...prev, selectedProduct ]) );       
        setValue( [] );
        setSearchKey("");
    };

    const handleSearch = (newValue) => {
        setSearchKey(newValue);
    };

    useEffect(() => {
        if (searchKey?.length > 2) getProductList();
    }, [searchKey]);
    
    return (
        <>
            <Form.Item
                label="Ürün arama ve ürün ekleme"
            >
                <Select
                    onFocus={ ()=>setIsMessageVisible(false) }
                    searchValue={ searchKey }
                    getPopupContainer={ trigger => trigger.parentNode }
                    clearIcon={ false }
                    mode="multiple"
                    showSearch
                    value={ value }
                    placeholder="Lütfen ürün seçiniz"
                    defaultActiveFirstOption={ false }
                    showArrow={ false }
                    filterOption={ false }
                    onSearch={ handleSearch }
                    onChange={ onProductsChange }
                    notFoundContent={ null }
                    options={ filteredOptions?.map((option) => ({
                        label:option.name,
                        value: option.id
                    } ) ) }
                    loading={ loading }
                />
            </Form.Item>
            { isMessageVisible && <Paragraph>
                <Text type="danger">*</Text>
                Ürün listesinden seçtiğiniz her ürün aşağıdaki tabloya eklenecektir</Paragraph> }
        </>
    );
};

export default ProductList;
