import { Typography } from "antd";
import { Link } from "react-router-dom";
import { cartUrl, listUrl } from "services";

const Columns = [
    {
        render:(_,record)=>(
            <Link
                to={ `/catalog/product-stock/${record?.id}/edit` }
                className="text-dark-75 text-hover-info mb-1"
            >
                { record?.name }
            </Link>),
        title:"Ürün İsmi"
    },
    {
        render: ( _, record ) =>{ 
            const {  features, properties, on_sale } = record || {};
            console.log(record);
            return (
                <>
                    { features ? (
                        <>
                            { features?.width && (
                                <div className={ "font-weight-light font-size-xs" }>
                                    <span>
                                        <span>En : </span>
                                        <span>{ features?.width } </span>
                                    </span>
                                </div>
                            ) }
                            { features?.height && (
                                <div className={ "font-weight-light font-size-xs" }>
                                    <span>
                                        <span>Boy : </span>
                                        <span>{ features?.height } </span>
                                    </span>
                                </div>
                            ) }
                            { features?.shape && (
                                <div className={ "font-weight-light font-size-xs" }>
                                    <span>
                                        <span>Şekil : </span>
                                        <span>{ features?.shape } </span>
                                    </span>
                                </div>
                            ) }
                            { features?.side && (
                                <div className={ "font-weight-light font-size-xs" }>
                                    <span>
                                        <span>Kenar Dokuma : </span>
                                        <span>{ features?.side } </span>
                                    </span>
                                </div>
                            ) }
                            { features?.side === "Saçak" && (
                                <div className={ "font-weight-light font-size-xs" }>
                                    <span>
                                        <span>Saçak Tipi : </span>
                                        <span>{ features?.webType } </span>
                                    </span>
                                </div>) }
                        </>
                    ) : (
                        <div className={ "font-weight-light text-muted" }>
                            { Object.keys(properties || {}).map((key, i) => (
                                <span key={ i } >
                                    <span><Typography.Text strong> { key } </Typography.Text> : </span>
                                    <span>{ properties[key] } </span>
                                    <br/>
                                </span>
                            )) }
                        </div>
                    ) }
                    <div className="font-weight-light font-size-xs mt-3">
                        <span className={ on_sale === true ? "on-sale" : "not-on-sale" }>
                            { on_sale ? "Satışta" : "Satışta Değil" }
                        </span>
                    </div>
                </>
            );},
        title: "Ürün Bilgileri"
    },
    {
        render: ( _, record ) => (
            <div className="fw-600">
                { record?.quantity }
            </div>
        ),
        title: "Adet"
    },
    {
        render: ( _, record ) => (
            <div className="fw-600">
                { record?.cost }
            </div>
        ),
        title: "Fiyat"
    }
];

const Url = cartUrl;
const ListUrl = `${Url}${listUrl}`;

export { Url, ListUrl, Columns };

