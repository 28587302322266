import { notification } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { authenticateMeUrl } from "services";
import { AccountRouter } from "./account";
import { BufipayRouter } from "./bufipay";
import { CatalogRouter } from "./catalog";
import { DashboardPage } from "./dashboard";
import { IntegrationRouter } from "./integration";
import { MemberShipRouter } from "./membership";
import { SalesRouter } from "./sales";
import { SettingsRouter } from "./settings";
import { MasterLayout, Spinner } from "components";
import Header from "components/core/Header";
import { useAuth } from "context";
import { get } from "helper";

const AppRenderer = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { createSession, removeSession } = useAuth();

    useEffect(() => {
        const handleAuth = async () => {
            try {
                setLoading(true);
                const { success, message, user, tokens } = await get(authenticateMeUrl);
                setLoading(false);
                if (!success) {
                    notification.error({
                        message: "Warning",
                        description: message,
                        placement: "top"
                    });
                    removeSession();
                    navigate("/auth/login");
                    return;
                }
                createSession({ user, tokens });
            } catch (e) {
                setLoading(false);
                navigate("/auth/login");
                removeSession();
                notification.error({
                    message: "Bilgilendirme",
                    description: e.toString(),
                    placement: "top"
                });
            }
        };
        handleAuth();
    }, [] );
    
    if ( loading ) <Spinner />;
    
    return (
        <>
            <Header></Header>
            <MasterLayout>
                <Routes>
                    <Route path="dashboard" element={ <DashboardPage /> } />
                    <Route path="catalog/*" element={ <CatalogRouter /> } />
                    <Route path="membership/*" element={ <MemberShipRouter /> } />
                    <Route path="account/*" element={ <AccountRouter /> } />
                    <Route path="integration/*" element={ <IntegrationRouter /> } />
                    <Route path="bufipay/*" element={ <BufipayRouter /> } />
                    <Route path="settings/*" element={ <SettingsRouter /> } />
                    <Route path="sales/*" element={ <SalesRouter /> } />
                </Routes>
            </MasterLayout> 
        </>
    );
};

export default AppRenderer;
