
import { PageTitle } from "context";

const Page = () => {
    return (
        <>
            <PageTitle>Dashboard</PageTitle>
            <div>Dashboard</div>
        </>
        
    );
};

export default Page;
