import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { accountCompany, accountCompanyUrl, companyListUrl, overviewUrl } from "services";
import { Breadcrumbs } from "./Helper";
import { CompanyProfileCardPage } from "./profileCard";
import { PageTitle } from "context";
import { post } from "helper";
import { notificationError } from "helper/NotificationHelpers";

const Page = () => {
    const { id: companyId } = useParams();
    const navigate = useNavigate();

    const [breadcrumbs, setBreadcrumbs] = useState();
    const [activeTab, setActiveTab] = useState("1");
    const [company,setCompany] = useState();
    const [outRef,setOutRef] = useState();

    const changeTab = (activeKey) => setActiveTab(activeKey);

    const getCompanyInfo = async () => {
        const { result, success, message } = await post(companyListUrl, {
            pageSize: 1000,
            filter: { id : parseInt(companyId) }
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCompany(result[0]);
        const breadcrumbs = [
            ...Breadcrumbs,
            {
                path:`${accountCompany}${companyId}${overviewUrl}`,
                breadcrumbName:result?.at(0).name
            }
        ];
        setBreadcrumbs(breadcrumbs);
        setOutRef(result[0].out_ref);
    };

    useEffect(() => {
        getCompanyInfo();
    }, []);

    return(
        <>
            <PageTitle breadcrumbs={ breadcrumbs }>{ companyId + "-" + company?.name }</PageTitle>
            <Row align="space-between" className="mb-10">
                <Col>  
                    <Typography.Title strong level={ 4 }>
                        Firma Bilgileri
                    </Typography.Title>
                </Col>
                <Col>       
                    <Button
                        icon={ <ArrowLeftOutlined /> }
                        onClick={ () => navigate(accountCompanyUrl) }
                    >
                        Geri
                    </Button>
                </Col>
            </Row>
            <CompanyProfileCardPage activeTab={ activeTab } changeTab={ changeTab } outRef={ outRef } />
        </>
    );
};

export default Page;
