import { PhoneOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { emailTestUrl, smsTestUrl } from "services";
import { CustomNumberInput } from "components";
import { post } from "helper";
import { serviceRequest } from "routes/settings/Helper";

const TestSmsMailPage = ({ form1, selectedCategory, formValue, settingsId }) => {
    const [form] = Form.useForm();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        form.submit();
        setIsModalOpen(prev=>!prev);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(prev=>!prev);
    };

    const handleSubmit = async (values) => {
        if (settingsId) {
            const editValues = form1.getFieldsValue();
            const copyEdit = { ...editValues };
            delete editValues.category;
            delete editValues.firm;
            delete editValues.is_default;
            const newValues = {
                category: selectedCategory,
                firm: copyEdit.firm,
                is_default: copyEdit.is_default || false,
                keys: { ...editValues },
                phone: "905"+values.tel,
                sendTo : values.mail
            };
            if(!newValues.sendTo) {
                delete newValues.sendTo;
                serviceRequest(post,smsTestUrl,"Sms Kodu Gönderildi!",newValues);
            }
            else {
                delete newValues.phone;
                serviceRequest(post,emailTestUrl,"E-Mail Kodu Gönderildi!",newValues);
            }
        }
        else {
            const copy = { ...formValue };
            delete formValue.category;
            delete formValue.firm;
            delete formValue.is_default;
            const newValues = {
                category: selectedCategory,
                firm: copy.firm,
                is_default: copy.is_default || false,
                keys: { ...formValue },
                phone: "905"+values.tel,
                sendTo : values.mail
            };
            if(!newValues.sendTo) {
                delete newValues.sendTo;
                serviceRequest(post,smsTestUrl,"Sms Kodu Gönderildi!",newValues);
            }
            else {
                delete newValues.phone;
                serviceRequest(post,emailTestUrl,"E-Mail Kodu Gönderildi!",newValues);
            }
        }
        form.resetFields();
    };

    return (
        <>
            <Col>
                <Button 
                    htmlType="button" 
                    type="link" 
                    icon={ <PhoneOutlined /> }
                    onClick={ showModal }
                >
                    { `Test ${selectedCategory}` }
                </Button>
            </Col>
            <Modal 
                title={ `Test ${selectedCategory}` }
                open={ isModalOpen } 
                onCancel={ handleCancel } 
                footer={ [
                    <Button key="back" onClick={ handleCancel }>
                        Vazgeç
                    </Button>,
                    <Button key="submit" type="primary" onClick={ handleOk }>
                        Kod Gönder
                    </Button>
                ] }>
                <Form
                    onFinish={ handleSubmit }
                    form={ form }
                >
                    { selectedCategory === "SMS" ?
                        <> 
                            <p>Telefon numarası giriniz</p>
                            <Form.Item
                                name="tel"
                            >
                                <CustomNumberInput digitsOfNumber={ 9 } prefix="+90 5" />
                            </Form.Item>
                        </> 
                        : 
                        <>
                            <p>E-mail adresi giriniz</p>
                            <Form.Item
                                name="mail"
                                rules={ [
                                    {
                                        message: "Lütfen email adresinizi giriniz.",
                                        required: true
                                    },
                                    {
                                        message: "Lütfen email adresinizi giriniz.",
                                        type: "email"
                                    }
                                ] }
                            >
                                <Input placeholder="E-Posta Adresi" />
                            </Form.Item>
                        </>
                    }
                </Form>
            </Modal>
        </>
    );
};

export default TestSmsMailPage;
