import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const CardHeader = ( { headContent, link, navigateState } ) => {

    const navigate = useNavigate();
 
    return (
        <Row wrap={ false } className="header_row" align="middle">
            <Col  offset={ 0 }>
                <Button type="link" size={ "small" }
                    onClick={ () => {
                        navigate( link, { ...navigateState } );
                    } }>
                    <LeftOutlined /></Button>
            </Col>
            <Col > <h4 >{ headContent }</h4></Col>
        </Row>  
    );
};

export default CardHeader;
