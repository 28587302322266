import { Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import { statisticalPages, sumItems } from "./Constant";

const { Title } = Typography;

const SumPage = ({ data }) => (
    <>
        <Card>
            <Title level={ 5 }>Ürün Önizleme</Title>
            <Divider />
            { sumItems?.map((item, i) => {
                let labelLeft;
                let labelRight;
                if (item[1].length > 1 && data) {
                    labelRight = data[item[1][0]][item[1][1]];
                    labelLeft = item[0];
                }
                if (item[1].length === 1 && data) {
                    labelRight = data[item[1][0]];
                    labelLeft = item[0];
                }

                return (
                    <Row key={ i }>
                        <Col span={ 6 }>
                            <p>{ labelLeft }</p>
                        </Col>
                        <Col>
                            <p>: { labelRight }</p>
                        </Col>
                    </Row>
                );
            }) }
        </Card>
        { statisticalPages }
    </>
);

export default SumPage;
