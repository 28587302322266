import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, Col, Row  } from "antd";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "./Helper";
import { PageTitle } from "context";

const Page = () => {
    const navigate = useNavigate();
    return (
        <>
            <PageTitle breadcrumbs={ Breadcrumbs }>Ayarlar</PageTitle>
            <div className="site-card-wrapper">
                <Row gutter={ 16 }>
                    <Col span={ 10 }>
                        <Card title="Mail ve Sms Ayarları" bordered={ false } actions={ [                     
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/settings/mail-sms-settings") }/>
                        ] } >                     
                        </Card>
                    </Col>        
                </Row>
            </div>
        </>
    );
};

export default Page;
