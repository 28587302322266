import { Checkbox, Col, Form, Row } from "antd";
import React from "react";
import { NumericFormat } from "react-number-format";

const PaymentRow = ({ item, firmMethods, allMethods, setExternal, external }) => {
    let selected = firmMethods?.find((index) => index.id === item);
    if (!selected) selected = allMethods?.find((index) => index.id === item);

    const onChange = (e) => {
        setExternal(e.target.checked);
    };

    return (
        <Row gutter={ 24 }>
            <Col span={ 4 } push={ 2 }>
                <p className="mt-5">{ selected?.name }</p>
            </Col>
            <Col span={ 7 } push={ 1 }>
                <Form.Item 
                    name={ selected?.slug+"Min" }
                    label="Min"
                    rules={ [
                        {
                            required: true,
                            message: "Lütfen değer giriniz"
                        }
                    ] }
                >
                    <NumericFormat
                        allowedDecimalSeparators={ [ "." ] }
                        fixedDecimalScale
                        allowLeadingZeros={ false }
                        allowNegative={ false }
                        decimalSeparator="."
                        decimalScale={ 2 }
                        className="ant-input" />
                </Form.Item>
            </Col>
            <Col span={ 7 } push={ 1 }>
                <Form.Item 
                    name={ selected?.slug+"Max" }
                    label="Max"
                    dependencies={ [selected?.slug+"Min"] }
                    rules={ [
                        {
                            required: true, message:"Lütfen değer giriniz"
                        },
                        ({ getFieldValue }) => ({
                            validator(_,value) {
                                if (parseFloat(getFieldValue( [selected?.slug+"Min"] )) <= parseFloat(value))
                                    return Promise.resolve();
                                else return Promise.reject(`${selected.name} min değeri max değerinden küçük olmalıdır`);
                            }
                        })
                    ] }
                >
                    <NumericFormat
                        allowedDecimalSeparators={ [ "." ] }
                        fixedDecimalScale
                        allowLeadingZeros={ false }
                        allowNegative={ false }
                        decimalSeparator="."
                        decimalScale={ 2 }
                        className="ant-input" />
                </Form.Item>
            </Col>
            {
                selected.slug === "kredi-karti" ?  
                    <Col span={ 6 }>
                        <Form.Item
                            name="is_external"
                        >
                            <Checkbox onChange={ onChange } checked= { external }>Harici Ödeme Açık</Checkbox>
                        </Form.Item>
                    </Col> : undefined
            }
        </Row>
    );
};

export default PaymentRow;
