import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { salesOrderUrl } from "services";
import { Columns, ListUrl, Path } from "./Constant";
import { getColumns } from "helper";
import { usePost } from "helper/Hook";

export const useTable = (mutateData) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate(Path);
    };

    const renderAction = (_, record) => (
        <>
            <Button 
                onClick={ () => navigate(`${salesOrderUrl}${record?.id}/edit`) } 
                type="link" 
                size="small"
            >
                <i className="flaticon2-file text-muted" />
            </Button>
        </>
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns, onHide };
};

export const useData = (queryParams) => {
    return usePost(ListUrl, queryParams);
};

export const getFilterQuery = (queryParams, values) => {
    const path = location?.pathname.split("/");
    const userId = parseInt(path?.at(-2));
    const { name } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (name) filter.order_key__ilike = `%${name}%`;
    if (userId) filter.user_id = userId;
    newQueryParams.filter = filter;
    return newQueryParams;
};
