import { Image, Typography } from "antd";
import { Link, Route, Routes } from "react-router-dom";
import { userUrl } from "services";
import { AddView } from "./add";
import { UserDetailPage } from "./detail";
import { ProtectedLink, ProtectedRoute } from "components";

const Breadcrumbs = [
    {
        breadcrumbName: "Üyelik İşlemleri",
        path: "/membership"
    },
    {
        breadcrumbName: "Kullanıcılar",
        path: "/user"
    }
];

const BreadcrumbsAdd = [
    {
        breadcrumbName: "Üyelik İşlemleri",
        path: "/membership"
    },
    {
        breadcrumbName: "Kullanıcılar",
        path: "/membership/user"
    },
    {
        breadcrumbName: "Yeni Kullanıcı",
        path: "/add"
    }
];

const Columns = [
    {
        render: (_, record) => (
            <Link to={ `${record?.id}` }>
                <span className="symbol symbol-50px">
                    <Image 
                        className="product img-thumbnail"
                        src={ record.image }
                    />
                </span>
            </Link>
        ),
        title: "",
        width: 80
    },
    {
        render: (_, record) => (
            <div>
                <ProtectedLink permissionKey={ "product.edit" } path={ `${record?.id}/overview` }>
                    <Typography.Text className="fw-600">
                        { record?.full_name }
                    </Typography.Text>
                </ProtectedLink>
                <div className="text-muted">{ record?.email }</div>
                <div className="text-muted">{ record?.gsm }</div>
                <div className="text-muted">Kaynak Kodu { record?.out_ref }</div>
                <div className="text-muted">Kayıt Tarihi { record?.create_date }</div>
                <div className="text-muted">Güncelleme Tarihi { record?.update_date }</div>
            </div>
        ),
        title: "Kullanıcı"
    },
    {
        render: ( _, record ) => (
            <div>
                <div className="text-muted">{ record?.roles[0]?.name }</div>
                <div className="text-muted">{ record?.company?.name }</div>
            </div>
        ),
        title: "Firma"
    }
];

const Url = userUrl;
const ListUrl = `${Url}/list`;
const Path = "/membership/user";
const addPath = "/membership/user/add";

const Router = ({ onHide }) => (
    <Routes>
        <Route element={ <ProtectedRoute permissionKey={ "user.add" } /> }>     
            <Route path="add" element={ <AddView onHide={ onHide }/> } />
        </Route>
    </Routes>
);

export { addPath, BreadcrumbsAdd, Breadcrumbs, Columns, Path, Url, ListUrl, Router };

