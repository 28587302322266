import { Col, Row, Switch } from "antd";
import React, { useState } from "react";
import { productToggleSaleUrl } from "services";
import { notificationError, put } from "helper";

const OnSaleSwitcher = ({ productId, onSale, mutate }) => {
    const [isLoading, setIsLoading] = useState(false);

    const onChange = async (checked) => {
        changeOnSale();
        await mutate();
    };

    const changeOnSale = async (  ) => {	
        try {
            setIsLoading(true);
            const { success, message } =  await put(productToggleSaleUrl, { sku_ids:[productId] });
            if(!success){
                notificationError( message);
                return;
            }
            setTimeout(()=>{ 
                setIsLoading(false);
            },500);           
        } catch (error) {
            setIsLoading(false); 
            console.error(error);      
        }
    };
    
    return ( 
        <Switch
            checkedChildren={ "Satışa kapat" }
            unCheckedChildren={ "Satışa aç" }
            checked={ onSale }
            onChange={ onChange }
            disabled={ !!isLoading }
        /> 
    );
};

export default OnSaleSwitcher;
