import { Navigate, Route, Routes } from "react-router-dom";
import AppRenderer from "./AppRenderer";
import { AuthWrapper, ForgotPasswordPage, LoginPage, ResetPasswordPage, ResetSuccessfull } from "./authentication";
import { Error } from "components";
import { useAuth } from "context";

const Default = () => {
    const { authState } = useAuth();

    return (
        <Routes>            
            <Route path="/*" element={ !authState?.tokens?.access_token ?
                <Navigate to="/auth/login" /> : <AppRenderer /> } /> 
            <Route path="/auth" element={ <AuthWrapper /> } >
                <Route path="login" element={ <LoginPage /> } ></Route>
                <Route path="forgot-password" element={ <ForgotPasswordPage /> } ></Route>
                <Route path="reset-password/:token" element={ <ResetPasswordPage /> } ></Route>    
                <Route path="reset-successfull" element={ <ResetSuccessfull/> } ></Route>   
            </Route>          
            <Route path="*" element={ <Error /> } />
        </Routes>
    );
};

export default Default;
