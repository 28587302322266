import { Tag } from "antd";
import { Link } from "react-router-dom";
import { editUrl, salesOrderUrl } from "services";
import { colorStatus } from "routes/sales/order/Constant";

export const Columns = [
    {
        render: (_, record) => {
            const { id,order_key,buyer,date }=record || {};
            return (
                <div>
                    <Link to={ `${salesOrderUrl}${id}${editUrl}` } 
                        className="fw-600">{ order_key }</Link>
                    <div className="text-muted">{ buyer?.company?.name }</div>
                    <div className="text-muted">{ date }</div>
                </div>
            );},
        title: "Sipariş"
    },
    {
        dataIndex: "total_count",
        key: "total_count",
        title: "Adet"
    },
    {
        dataIndex: "total_cost",
        key: "total_cost",
        title: "Fiyat"
    },
    {
        render: ( _, record ) =>  (
            <>
                <Tag color={ colorStatus[record.status_label ] }>
                    { record.status_label }
                </Tag>
            </>
        ),
        title: "Sipariş Durumu"
    }
];
