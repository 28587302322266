import { Tag } from "antd";
import { Link } from "react-router-dom";
import { listUrl, orderUrl } from "services";

const Breadcrumbs = [
    {
        breadcrumbName: "Satış İşlemleri",
        path: "/sales"
    },
    {
        breadcrumbName: "Siparişler",
        path: "/sales/order"
    }
];

const Columns = [
    {
        render: (_, record) => {
            const { id, order_key, buyer, date, sent_date , is_send } = record || {};
            return (
                <div>
                    <Link to={ `/sales/order/${id}/edit` }
                        className="fw-600">{ order_key }</Link>
                    <div className="text-muted">{ buyer?.company?.name }</div>

                    <div className="font-weight-light font-size-xs">
                        Kayıt Tarihi: { date }
                    </div>
                    { !is_send ? (
                        <div className="font-weight-light font-size-xs">
                            Gönderilme Tarihi: { sent_date }
                        </div>
                    ) : (
                        <div className="font-weight-light font-size-xs">
                            Erpye gönderilmedi..
                        </div>
                    ) }

                </div>
            );
        },
        title: "Sipariş"
    },
    {
        render: (_, record) => (
            <div className="text-muted">{ record?.total_count }</div>
        ),
        title: "Adet"
    },
    {
        render: (_, record) => (
            <div className="text-muted">{ record?.total_cost }</div>
        ),
        title: "Fiyat"
    },
    {
        render: (_, record) => {
            const { source } = record || {};
            return (
                <Tag color={ sourceStatus[source] }>
                    { source?.charAt(0)?.toUpperCase() + source?.slice(1)?.toLowerCase() }
                </Tag>
            );
        },
        title: "Kaynak"
    },
    {
        render: (_, record) => (
            <>
                <Tag color={ colorStatus[record.status_label] }>
                    { record.status_label }
                </Tag>
            </>
        ),
        title: "Sipariş Durumu"
    }
];

export const colorStatus =
{
    "Hazırlandı": "blue",
    "Hazırlanıyor": "purple",
    "Kısmi Sevk": "magenta",
    "Sevk Aşamasında": "lime",
    "Sipariş Alındı": "cyan",
    "Teslim Edildi": "green",
    "Ödeme Bekleniyor": "yellow",
    "İade Edildi": "orange",
    "İptal": "red"
};

export const sourceStatus =
{
    "erp": "blue",
    "mobil": "green",
    "web": "purple"
};

const erpCondition = [{
    label: "Gönderildi",
    value: true
},
{
    label: "Gönderilmedi",
    value: false
}];

const orderStatus = [
    { label: "İade Edildi", value: "returned" },
    { label: "İptal", value: "cancelled" },
    { label: "Hazırlandı", value: "prepared" },
    { label: "Hazırlanıyor", value: "preparing" },
    { label: "Kısmi Sevk", value: "partial_sent" },
    { label: "Ödeme Bekleniyor", value: "payment_due" },
    { label: "Sevk Aşamasında", value: "in_transit" },
    { label: "Sipariş Alındı", value: "order_taken" },
    { label: "Teslim Edildi", value: "delivered" }
];
const Url = orderUrl;
const ListUrl = `${Url}${listUrl}`;

export { Breadcrumbs, Columns, Url, ListUrl, orderStatus, erpCondition };

