import { EditOutlined } from "@ant-design/icons";
import { Modal, Tooltip, Upload } from "antd";
import React, { useState } from "react";
import { fileToBase64, notificationWarning } from "helper";

const ImageUpload = ({ fileList, fileListHandler, imageListHandler }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const toggleImagePreview = () => setPreviewOpen((prev) => !prev);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await fileToBase64(file.originFileObj);
        }
        setPreviewImageUrl(file.url || file.preview);
        toggleImagePreview();
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const handleImageChange = async ({ fileList: newFileList }) => {
        let tempFileList = [];
        const maxFileSize = 5 * 1024 * 1024;
        if (newFileList?.at(-1)?.size > maxFileSize) {
            tempFileList = newFileList.slice(0, -1);
            fileListHandler(tempFileList);
            notificationWarning("Resim boyutu en fazla 5 mb olabilir!");
            return;
        }
        tempFileList.push(newFileList?.at(-1));
        fileListHandler(tempFileList);
        if (tempFileList?.length > 0) {
            const base64 = await fileToBase64(tempFileList?.at(0)?.originFileObj);
            imageListHandler(base64);
        }
    };

    const props = {
        onRemove: () => {
            fileListHandler([]);
            return null;
        },
        beforeUpload: (file) => {
            fileListHandler( [ ...fileList, file ] );
            return false;
        },
        fileList
    };

    return (
        <>
            <Upload
                className="catalog_upload"
                listType="picture-card"
                onPreview={ handlePreview }
                onChange={ handleImageChange }
                fileList={ fileList }
                { ...props }
            >
                <Tooltip placement="topLeft" title="Ürün resmini değiştir">
                    <EditOutlined />
                </Tooltip>
            </Upload>
            <Modal
                open={ previewOpen }
                title={ previewTitle }
                footer={ null }
                onCancel={ toggleImagePreview }
            >
                <img
                    alt="product_SKU"
                    style={ {
                        width: "100%"
                    } }
                    src={ previewImageUrl }
                />
            </Modal>
        </>
    );
};

export default ImageUpload;
