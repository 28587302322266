import React from "react";
import { useAuth } from "context";

const ProtectedElement= ({ children, permissionKey }) => {
    const { user } =useAuth();
    const { is_admin = false, permissions = [] } = user;

    const renderingElement=is_admin || permissions?.indexOf(permissionKey)!== -1 
        ? children 
        :null ;  
    return (
        <>
            { renderingElement }
        </>
    );
};

export default ProtectedElement;
