import { ConfigProvider } from "antd";
import turkish from "antd/lib/locale/tr_TR";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./assets/style/index.less";
import "./assets/style/App.less";
import "./assets/style/plugins/keenthemes-icons/font/ki.css";
import "./assets/style/plugins/flaticon/flaticon.css";
import "./assets/style/plugins/flaticon2/flaticon.css";

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <ConfigProvider locale={ turkish }>
            <App />
        </ConfigProvider>
    </BrowserRouter>
);
