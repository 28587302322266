import { listUrl, productPriceUrl } from "services";

const Columns = [
    {
        title: "Kategori",
        render: (_, record) => (
            <div className="text-muted">{ record?.category_name }</div>
        )
    },
    {
        title: "Perakende Fiyatı",
        render: (_, record) => (
            <div className="text-muted">{ record?.retail }</div>
        )
    },
    {
        title: "Toptan Liste Fiyatı",
        render: (_, record) => (
            <div className="text-muted">{ record?.wholesale }</div>
        )
    },
    {
        title: "Alış Fiyatı",
        render: (_, record) => (
            <div className="text-muted">{ record?.wholesale_discounted }</div>
        )
    }
];

const Path = "/account/company";
const Url = productPriceUrl;
const ListUrl = `${Url}${listUrl}`;

export { Path, Url, ListUrl, Columns };
