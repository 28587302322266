import { Card, Table, Typography } from "antd";
import React from "react";

const OrderHistory = () =>  (
    <Card>        
        <Typography.Title level={ 5 }>Sipariş Geçmişi</Typography.Title>
        <Table/>
    </Card>
);

export default OrderHistory;
