/* eslint-disable jsx-a11y/alt-text */
import { Button, Col, Layout, Row, Space } from "antd";
import {
    bufiIcon,
    helpSupportIcon,
    messageIcon,
    notificationIcon
} from "assets/media";
import { useNavigate } from "react-router-dom";

const { Header: AntdHeader } = Layout;

const svgArray = [
    { image: notificationIcon, text: "Bildirimler" },
    { image: messageIcon, text: "Mesajlar" },
    { image: helpSupportIcon, text: "Yardım & Destek" }
];

const Header = () => {
    const navigate = useNavigate();

    const onClickBufiIcon = () => {
        navigate("/dashboard");
    };

    return (
        <AntdHeader className="header">
            <Row justify="space-between" className="w-100">
                <Col>
                    <Button
                        onClick={ onClickBufiIcon }
                        type="link"
                        className="header-bufiicon-btn"
                    >
                        <img src={ bufiIcon } className="header-bufiicon" />
                    </Button>
                </Col>
                <Col>
                    <Space>
                        { svgArray?.map((item, index) => (
                            <nav
                                key={ index + 1 }
                                className="header-navbar pointer"
                            >
                                <img src={ item.image } className="header-img" />
                                { item.text }
                            </nav>
                        )) }
                    </Space>
                </Col>
            </Row>
        </AntdHeader>
    );
};
export default Header;
