import { Checkbox } from "antd";
import cloneDeep from "lodash/cloneDeep";

export const onIsAllPermissionsAllowed = (record, permissionType) =>    
    record.children
        .filter((child)=> (permissionType in child && "is_allowed" in child[permissionType]))
        .every((item) => 
            item[permissionType].is_allowed===true
        );

export const onIsAllPermissionsNotAllowed = (record, permissionType) =>
    record.children
        .filter((child)=> (permissionType in child && "is_allowed" in child[permissionType]))
        .every((item) => 
            item[permissionType].is_allowed===false
        );

export const isMainHasAChild = (record, permissionType) =>
    record.children.some((item) => Object.prototype.hasOwnProperty.call(item, permissionType) 
    && typeof item[permissionType] === "object" 
    );

export const useMainColumnsHelper = ({ newData, setNewData }) => {

    const onChangeHandler = (e, isMain, rowKey, id, permissionType) => {     
        if (isMain) {
            let data = cloneDeep(newData);
            data = data.map((item) => {
                if (item.key === rowKey) {
                    item[permissionType] = e.target.checked;
                    item.children.map((item1) => {
                        if (permissionType in item1) {
                            item1[permissionType].is_allowed = e.target.checked;
                        }
                    });
                }
                return item;
            });
            setNewData(data);
        } else {
            let data = cloneDeep(newData);
            let mainCategoryKey = 0;
            data.map((item) => {
                item.children.map((item1) => {
                    if (item1[permissionType]?.id === id) {
                        item1[permissionType].is_allowed = e.target.checked;
                        mainCategoryKey = item.key;
                    }
                });
                return item;
            });
            const allIsTrue = onIsAllPermissionsAllowed(
                data.find((item) => item.key === mainCategoryKey),
                permissionType
            );
            const allIsFalse = onIsAllPermissionsNotAllowed(
                data.find((item) => item.key === mainCategoryKey),
                permissionType
            );

            data = data.map((item) => {
                if (item.key === mainCategoryKey) {
                    if (allIsTrue) item[permissionType] = true;
                    if (allIsFalse) item[permissionType] = false;
                }
                return item;
            });
            setNewData(data);
        }

    };

    const mainColumns = [
        {
            key: "category",
            render: (_, record) => (
                <p>{ record.isMain ? <b> { record.category }</b> : record.category }</p>
            ),
            title: "Kategori Adı"
        },
        {
            key: "view",
            render: (_, record) => {
                const { isMain, key, view } = record || {};
                const permissionType = "view";
                const isHasAChild = isMain && isMainHasAChild(record, permissionType);            
                const isIndeterminate = isMain
                    ? !(
                        onIsAllPermissionsAllowed(record, permissionType) ||
              onIsAllPermissionsNotAllowed(record, permissionType)
                    )
                    : false;
                return (
                    <>
                        { ((isMain && isHasAChild) || view?.id) && (
                            <Checkbox
                                onChange={ (e) =>
                                    onChangeHandler(e, isMain, key, view?.id, permissionType)
                                }
                                checked={ isMain ? view : view?.is_allowed }
                                indeterminate={ isIndeterminate }
                            />
                        ) }
                    </>
                );
            },
            title: "Listeleme"
        },
        {
            key: "add",
            render: (_, record) => {
                const { isMain, key, add } = record || {};
                const permissionType = "add";
                const isHasAChild = isMain && isMainHasAChild(record, permissionType);
                const isIndeterminate = isMain
                    ? !( onIsAllPermissionsAllowed(record, permissionType) || 
                    onIsAllPermissionsNotAllowed(record, permissionType)
                    )
                    : false;
                return (
                    <>
                        { ((isMain && isHasAChild) || add?.id) && (
                            <Checkbox
                                onChange={ (e) =>
                                    onChangeHandler(e, isMain, key, add.id, permissionType)
                                }
                                checked={ isMain ? add : add?.is_allowed }
                                indeterminate={ isIndeterminate }
                            />
                        ) }
                    </>
                );
            },
            title: "Ekleme"
        },
        {
            key: "edit",
            render: (_, record) => {
                const { isMain, key, edit } = record || {};
                const permissionType = "edit";
                const isHasAChild = isMain && isMainHasAChild(record, permissionType);
                const isIndeterminate = isMain
                    ? !(
                        onIsAllPermissionsAllowed(record, permissionType) ||
              onIsAllPermissionsNotAllowed(record, permissionType)
                    )
                    : false;
                return (
                    <>
                        { ((isMain && isHasAChild) || edit?.id) && (
                            <Checkbox
                                onChange={ (e) =>
                                    onChangeHandler(e, isMain, key, edit.id, permissionType)
                                }
                                checked={ isMain ? edit : edit?.is_allowed }
                                indeterminate={ isIndeterminate }
                            />
                        ) }
                    </>
                );
            },
            title: "Değiştirme"
        },
        {
            key: "delete",
            render: (_, record) => {
                const { isMain, key, delete: erase } = record || {};
                const permissionType = "delete";
                const isHasAChild = isMain && isMainHasAChild(record, permissionType);
                const isIndeterminate = isMain
                    ? !(
                        onIsAllPermissionsAllowed(record, permissionType) ||
              onIsAllPermissionsNotAllowed(record, permissionType)
                    )
                    : false;
                return (
                    <>
                        { ((isMain && isHasAChild) || erase?.id) && (
                            <Checkbox
                                onChange={ (e) =>
                                    onChangeHandler(e, isMain, key, erase.id, permissionType)
                                }
                                checked={ isMain ? erase : erase?.is_allowed }
                                indeterminate={ isIndeterminate }
                            />
                        ) }
                    </>
                );
            },
            title: "Silme"
        }
    ];
    return { mainColumns };
};

export const keyGenerator = () =>
    Math.floor(Math.random() * 10000000).toString();

export const dataFormatter = (comingData) => {
    let clonedData = cloneDeep(comingData);
    clonedData.map((item) => {
        ["view", "edit", "delete", "add"].map(
            (permissionType) =>
            {
                if ( onIsAllPermissionsAllowed( item,permissionType )) item[permissionType]=true;
                if (  onIsAllPermissionsNotAllowed(item, permissionType)) item[permissionType]=false;
            }
        );

        item["key"] = keyGenerator();
        item["children"] = item?.children?.map((item1) => ({
            ...item1,
            key: keyGenerator()
        }));


        return item;
    });
    return clonedData;
};

export const setIsAllowedValues = (comingData, isChecked) => {
    let clonedData = cloneDeep(comingData);

    clonedData.forEach((item) => {
        ["view", "edit", "delete", "add"].map(
            (permissionType) =>
            {
                if ( permissionType in item) item[permissionType]=isChecked;
            }
        );

        item.children.forEach((child) => {
            Object.keys(child).forEach((key) => {
                if (
                    key !== "key" &&
          key !== "category" && typeof child[key] === "object" && "is_allowed" in child[key]) 
                    child[key].is_allowed = isChecked;               
            });
        });
    });

    return clonedData;
};

export const getAllowedIds = (comingData) => {
    let clonedData = cloneDeep(comingData);
    const allowedIds = [];
    clonedData.map((item) =>
        item?.children.forEach((child) => {
            Object.keys(child).forEach((key) => {
                if (
                    key !== "key" &&
          key !== "category" &&
          typeof child[key] === "object" &&
          "is_allowed" in child[key]
                ) {
                    if (child[key].is_allowed) {
                        allowedIds.push(child[key].id);
                    }
                }
            });
        })
    );
    return allowedIds;
};
