import { Tag } from "antd";
import { Link } from "react-router-dom";
import { listUrl, notificationUrl } from "services";

const Columns = [
    {
        render: ( _, record ) => <Link className="fw-600">{ record?.title }</Link>,
        title: "Başlık"
    },
    {
        dataIndex: "content",
        render: ( content ) => content,
        title: "İçerik"
    },
    {
        dataIndex: "is_read",
        render: ( is_read ) => (
            <Tag color={ is_read ? "green" : "red" }> { is_read ? "Okundu" : "Okunmadı " } </Tag>
        ),
        title: "Okundu Durumu" 
    }
];

const Url = notificationUrl;
const ListUrl = `${Url}${listUrl}`;

export { Url, ListUrl, Columns };
