/* eslint-disable react-hooks/rules-of-hooks */
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { membershipUserUrl } from "services";
import { Breadcrumbs } from "./Helper";
import { UserProfileCardPage } from "./profileCard";
import { Spinner } from "components";
import { PageTitle } from "context";
import { useUser } from "helper/Hook";

const Page = () => {
    const { id: userId } = useParams();
    const { data, error } = useUser(userId);
    const navigate = useNavigate();

    const [breadcrumbs, setBreadcrumbs] = useState();
    const [activeTab, setActiveTab] = useState("1");
    
    const changeTab = (activeKey) => setActiveTab(activeKey);

    const handleBreadcrumbs = () => {
        if (data) {
            const { result: user } = data;
            const breadcrumbs = [
                ...Breadcrumbs,
                {
                    breadcrumbName: user?.full_name,
                    path: `/membership/user/${user?.id}`
                }
            ];
            setBreadcrumbs(breadcrumbs);
        }
    };

    useEffect(() => {
        handleBreadcrumbs();
    }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

    if (!data && !error) {
        return <Spinner />;
    }

    const { result: user } = data;

    return (
        <>
            <PageTitle breadcrumbs={ breadcrumbs }>{ user?.full_name }</PageTitle>
            <Row align="space-between" className="mb-10">
                <Col>  
                    <Typography.Title strong level={ 4 }>
                        Kullanıcı Bilgileri
                    </Typography.Title>
                </Col>
                <Col>    
                    <Button
                        icon={ <ArrowLeftOutlined /> }
                        onClick={ () => navigate(membershipUserUrl) }
                    >
                        Geri
                    </Button>
                </Col>
            </Row>
            <UserProfileCardPage activeTab={ activeTab } changeTab={ changeTab } />
        </>
    );
};

export default Page;
