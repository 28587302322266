import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const dropdownHelper=(sendToServerAction, updateOrderAction )=>
    ( [
        {
            icon: <i className="flaticon2-send-1" />,
            key: "1",
            label: (
                <>
                    <Button
                        type="text"
                        block
                        onClick={ sendToServerAction  }>
                        Gönder
                    </Button>
                </>
            )
        },
        {
            icon:  <CloudDownloadOutlined />,
            key: "2",
            label: (
                <Button 
                    type="text"
                    block
                    onClick={ updateOrderAction }>
                    Güncelle
                </Button>
            ) 
        }
    ] 
    );
