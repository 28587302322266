import { Button, Card, Checkbox, Col, Form, Row, Select, Space, Table, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { virtualListUrl, virtualPosConfigChangeUrl, virtualPosConfigListUrl } from "services";
import { BreadcrumbsMerchantSettings } from "./Constant";
import { Spinner } from "components";
import { PageTitle } from "context";
import { get, notificationError, post, put } from "helper";

const MemberBusinessPage = () => {
    const [form] = Form.useForm();

    const [fetchedVirtuals, setFetchedVirtuals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [posList,setPosList] = useState([]);
    const [defaultBank,setDefaultBank] = useState();
    const [checkedValue,setCheckedValue] = useState();
    const [defaultPosInfo,setDefaultPosInfo] = useState();
    
    const getVirtualPosInfo = async () => {
        setLoading(true);
        const { virtual_configs, default_pos , single_installment, success } = await get(virtualPosConfigListUrl);
        const newResult = virtual_configs?.map((item) => ({ ...item, key: item.bank_id }));
        setFetchedVirtuals(newResult);
        setDefaultPosInfo(default_pos);
        setCheckedValue(single_installment);
        form.setFieldsValue({ "checked": single_installment, "default_pos": (default_pos).toString() });
        setLoading(false);
        if (!success) {
            setError(true);
            return;
        }
    };

    const getPos = async () => {
        const { success,result,message } = await post (virtualListUrl,{
            filter: { active : 1 },
            pageNumber: 1,
            pageSize: 100,
            sortField: "pos_name",
            sortOrder: "asc"
        });
        if (!success) {
            notificationError(message);
            return;
        }
        result.unshift({
            id:0,
            pos_name:"Tanımlı Banka"
        });
        setPosList(result);
    };

    const handleFormSave = async (values) => {
        setLoading(true);
        delete values.default_pos;
        let bankIds = Object.keys(values);
        const posIds = [];
        bankIds.map(item => values[item] === undefined ? values[item] = defaultBank : undefined );
        bankIds.map((item,i) => [ bankIds[i] = parseInt(item) , posIds.push(parseInt(values[item]))]);
        bankIds.pop();
        posIds.pop();
        const newValues = {
            bank_ids : bankIds,
            default_pos: parseInt(defaultBank) || defaultPosInfo,
            pos_ids:posIds,
            single_installment: checkedValue
        };
        const { success, message } = await put(virtualPosConfigChangeUrl, newValues);
        setLoading(false);
        if (!success) {
            notificationError( message );
            return;
        }
        notification.success({
            description: "Değişiklikler başarıyla kaydedildi!",
            message: "Bilgilendirme",
            placement: "top"
        });
    };

    useEffect(() => {
        getVirtualPosInfo();
        getPos();
    }, []);

    const columns = [
        {  
            dataIndex: "bank_name",
            title: "Bankalar"
        },
        {
            dataIndex: "posAccounts",
            render: ( _, record ) =>(
                <Form.Item
                    name={ [`${record?.bank_id}`] }
                    className="product-stock_input_border"
                    initialValue={ `${record.pos_id}` }
                >
                    <Select
                        placeholder="Lütfen banka seçiniz"
                        allowClear
                        filterOption={ (inputValue, option) =>
                            option.children?.toLowerCase().includes(inputValue.toLowerCase())
                        }
                        className="mt-15"
                    >
                        { posList?.map((option) => (<Select.Option key={ option.id }>{ option.pos_name }
                        </Select.Option>
                        )) }
                    </Select>
                </Form.Item>
            ),
            title: "Pos Listesi"
        }
    ];

    return (
        <Card>
            <PageTitle breadcrumbs={ BreadcrumbsMerchantSettings }>
                { BreadcrumbsMerchantSettings?.at(-1)?.breadcrumbName }
            </PageTitle>
            <Form
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleFormSave }
                form={ form }
            >
                <Row align="space-between">
                    <Col>
                        <Typography.Title strong level={ 4 }>
                            { "Tanımlı Pos Bilgileri" }
                        </Typography.Title>
                    </Col>
                    <Col>
                        <Space className="flex-btw" value={ "large" }>
                            <Space>
                                <Button type="primary" loading={ loading } htmlType="submit">
                                    Kaydet
                                </Button>
                            </Space>
                        </Space>
                    </Col>
                </Row>
                <br />
                { !fetchedVirtuals && !error ? (
                    <Spinner />
                ) : (
                    <>
                        <Row justify="center" className="mb-10">
                            Tanımlı banka seçiniz
                        </Row>
                        <Row gutter={ 16 } justify="center">
                            <Form.Item
                                name="default_pos"
                            >
                                <Select
                                    placeholder="Lütfen banka seçiniz"
                                    allowClear
                                    filterOption={ (inputValue, option) =>
                                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                    onChange={ (e)=> setDefaultBank(e) }
                                >
                                    { posList?.map((option,i) => i !== 0 && (<Select.Option key={ option.id }>{ option.pos_name }
                                    </Select.Option>
                                    )) }
                                </Select>
                            </Form.Item>
                        </Row>
                        <Row justify="center" className="mt--15">
                            <Form.Item
                                name="checked"
                                valuePropName="checked"
                            >
                                <Checkbox
                                    className="ml-10"
                                    onChange={ (e) => setCheckedValue(e.target.checked) }
                                >
                                    Tüm Tek çekim işlemlerini tanımlı bankaya yönlendir
                                </Checkbox>
                            </Form.Item>
                        </Row>
                        <Table
                            bordered
                            loading={ loading }
                            dataSource={ fetchedVirtuals }
                            columns={ columns }
                            pagination={ {
                                pageSize: 50
                            } }
                        />
                    </>
                ) }
            </Form>
        </Card>
    );
};

export default MemberBusinessPage;
