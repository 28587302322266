import { Card, Col, Divider, Image, Row, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { companyListUrl } from "services";
import { tableItems } from "./Constant";
import { CompanyAddressPage } from "../address";
import { CompanyOrderPage } from "../order";
import { CompanyOverviewPage } from "../overview";
import { CompanyPaymentPage } from "../payment";
import { CompanyPricePage } from "../price";
import { CompanyUserPage } from "../user";
import { post } from "helper";
import { notificationError } from "helper/NotificationHelpers";
import ProductDetails from "routes/catalog/product-stock/edit/EditView/ProductDetails";

const { Title } = Typography;

const Page = ({ outRef }) => {
    const { id: Id } = useParams();

    const [companyInfo,setCompanyInfo] = useState();
    const [activeTab, setActiveTab] = useState("item-1");

    const onChangeTab = ( activeKey ) => setActiveTab( activeKey );

    const getCompanyInfo = async () => {
        const { result, success, message } = await post(companyListUrl, {
            pageSize: 1000,
            filter: { id: Id }
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCompanyInfo(result[0]);
    };

    useEffect(() => {
        getCompanyInfo();
    }, []);

    const activateItems =
    {
        "item-1":<CompanyOverviewPage />,
        "item-2":<CompanyOrderPage companyId={ Id } outRef={ outRef } />,
        "item-3":<CompanyAddressPage companyId={ Id } />,
        "item-4":<CompanyUserPage companyId={ Id } />,
        "item-5":<CompanyPricePage companyId={ Id } outRef={ outRef } />,
        "item-6":<CompanyPaymentPage />
    };

    return (
        <>
            <Card>
                <Title level={ 5 }>{ companyInfo?.name }</Title>
                <Row gutter={ [24,24] } align={ "middle" }>
                    <Col>
                        <Image
                            src={ companyInfo?.image }                                    
                            alt="product_sku"
                            preview={ false }
                            height={ 112 }
                            style={ { maxWidth: "148px" } }
                        />                            
                    </Col>          
                    <ProductDetails />
                </Row>
                <Divider />
                <Row style={ { marginBottom:-42 } }>
                    <Tabs
                        items={ tableItems }
                        activeKey={ activeTab }
                        onChange={ onChangeTab }
                        tabPosition={ "top" }
                    />
                </Row>
            </Card>
            <br />      
            { activateItems[ activeTab ] }
        </>
    );
};

export default Page;
