import { Card, Row } from "antd";
import React from "react";

export const Overview = ({ children }) => {
    return (
        <Card>
            <Row gutter={ 24 }>{ children }</Row>
        </Card>
    );
};
