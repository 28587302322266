export const removeStorage = (key) => {
    try {
        localStorage.removeItem(key);
        localStorage.removeItem(key + "_expiresIn"); // Eğer varsa, süre bitiş zamanını da kaldırın
    } catch (e) {
        console.error(`removeStorage: Error removing key [${key}] from localStorage: ${e}`);
        return false;
    }
    return true;
};

export const getStorage = (key) => {
    try {
        const now = Date.now();
        const expiresIn = localStorage.getItem(key + "_expiresIn");
        if (expiresIn && now > parseInt(expiresIn, 10)) {
            removeStorage(key); // Süresi geçmişse, veriyi ve süresi geçmiş zamanı kaldır
            return null;
        }
        return localStorage.getItem(key);
    } catch (e) {
        console.error(`getStorage: Error reading key [${key}] from localStorage: ${e}`);
        return null;
    }
};

export const setStorage = (key, value, expires) => {
    if (expires === undefined || expires === null) {
        expires = 24 * 60 * 60; // Varsayılan olarak 1 gün
    }
    const now = Date.now();
    const schedule = now + expires * 1000;
    try {
        localStorage.setItem(key, value);
        localStorage.setItem(key + "_expiresIn", schedule.toString());
    } catch (e) {
        console.error(`setStorage: Error setting key [${key}] in localStorage: ${e}`);
        return false;
    }
    return true;
};

// `setStorage1` fonksiyonunu kaldırdım çünkü `setStorage` ile aynı işlevi görebiliyor.
// Eğer `setStorage1`'in özel bir kullanımı yoksa, `setStorage` kullanılabilir.
