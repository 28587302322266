import { Image, Typography } from "antd";
import { Route, Routes } from "react-router-dom";
import { categoryUrl, listUrl } from "services";
import { AddView } from "./add";
import { ProtectedRoute } from "components";

const Breadcrumbs = [
    {
        path: "/catalog",
        breadcrumbName: "Katalog"
    },
    {
        path: "/category",
        breadcrumbName: "Kategoriler"
    }
];

const Columns = [
    {
        title: "",
        width: 80,
        render: (_, record) => (
            <Image 
                className="product img-thumbnail"              
                src={ record?.image }
            />
        )
    },
    {
        render: (_, record) => {
            const { name, out_ref, update_date, create_date } = record;
            return(
                <>
                    <div className="mb-5">  { name }</div>
                    <div className="font-weight-light font-size-xs">
                        Kaynak Kodu { out_ref }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Kayıt Tarihi: { create_date }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Güncelleme Tarihi: { update_date }
                    </div>
                </>
            );},
        title: "Kategori"
    },
    {
        title: "Üst Kategori",
        render: ( _, record ) => {
            const upperCategory=record?.upper_category?.name;
            return ( <Typography.Text>{ upperCategory ? upperCategory : null }</Typography.Text> );
        }
    }

];
const Path = "/catalog/category";
const Url = categoryUrl;
const ListUrl = `${Url}${listUrl}`;

const Router = ({ onHide }) => (
    <Routes>
        <Route element={ <ProtectedRoute permissionKey={ "category.add" } /> }>
            <Route path="add" element={ <AddView onHide={ onHide } /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "category.edit" } /> }>
            <Route path=":id/edit" element={ <AddView onHide={ onHide } /> } />
        </Route>
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

