import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Space, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "./Constant";
import { PageTitle } from "context";

const NetgsmPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <PageTitle breadcrumbs={ Breadcrumbs }>Entegrasyonlar</PageTitle>
            <Card bordered={ false }>
                <Col span={ 24 }>
                    <Form
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Space className="flex-btw" value={ "large" }>
                            <Typography.Title strong level={ 4 }>
                                Netgsm
                            </Typography.Title>
                            <Space>
                                <Button htmlType="button" icon={ <ArrowLeftOutlined /> }
                                    onClick={ () => navigate( "/integration" ) }>
                                    Geri
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Kaydet
                                </Button>
                            </Space>
                        </Space>
                        <Form.Item
                            label="Netgsm Kullanıcı Adı"
                            name="username"                      
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Netgsm Parola"
                            name="password"                      
                        >
                            <Input.Password />
                        </Form.Item>
                    </Form>
                </Col>
            </Card>
        </>
    );
};

export default NetgsmPage;
