import React, { useEffect } from "react";
import { useData, useTable } from "./Orders/Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const OrderPage = ({ data:id }) => {
    const { queryParams, setQueryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns } = useTable(mutate);

    useEffect(() => {
        setQueryParams({
            filter: { product_id : parseInt(id) },
            keyword : "string",
            pageNumber: 1,
            pageSize: 10,
            sortField: "id",
            sortOrder: "desc"
        });
    }, []);

    return(
        <PageContent
            tableData={ useData } 
            tableColumns={ columns } 
        />
    );
};

export default OrderPage;
