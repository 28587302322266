import { Image, Tag } from "antd";
import { Link } from "react-router-dom";
import { accountCompany, listUrl, orderUrl, salesOrderUrl } from "services";

const colorStatus =
{
    "Sipariş Alındı": "cyan",
    "Hazırlanıyor": "purple",
    "Teslim Edildi": "green",
    "Kısmi Sevk": "magenta",
    "İptal": "red",
    "Hazırlandı": "blue",
    "Sevk Aşamasında": "lime",
    "Ödeme Bekleniyor":"yellow" ,
    "İade Edildi":"orange"
};

const Columns = [
    {
        title: "",
        width: 80,
        render: (_, record) => (
            <Link to={ `${accountCompany}${record?.id}` }>
                <span className="symbol symbol-50px">
                    <Image preview={ false } src={ record.image } />
                </span>
            </Link>
        )
    },
    {
        title: "Sipariş",
        render: (_, record) => (
            <div>
                <Link
                    to={ `${salesOrderUrl}${record?.id}/edit` }
                    className="fw-600"
                >
                    { record?.order_key }
                </Link>
                <div className="text-muted">{ record?.buyer?.company?.name }</div>
                <div className="text-muted">{ record?.date }</div>
            </div>
        )
    },
    {
        title: "Adet",
        render: (_, record) => (
            <div className="text-muted">{ record?.total_count }</div>
        )
    },
    {
        title: "Fiyat",
        render: (_, record) => (
            <div className="text-muted">{ record?.total_cost }</div>
        )
    },
    {
        title: "Sipariş Durumu",
        render: ( _, record ) =>  (
            <>
                <Tag color={ colorStatus[record.status_label ] }>
                    { record.status_label }
                </Tag>
            </>
        )
    }
];

const Path = "/account/company";
const Url = orderUrl;
const ListUrl = `${Url}${listUrl}`;

export { Path, Url, ListUrl, Columns };
