import { Tag } from "antd";

export const handleTableChange = (setQueryParams) => {
    return (pagination, filter, sorter) => {
        const pageNumber = pagination.current || 1;
        const pageSize = pagination.pageSize || 10;
        const sortField = sorter?.field || "name";
        const sortOrder = sorter?.order === "ascend" ? "asc" : "desc" || "desc";
        setQueryParams((prev) => ({
            ...prev,
            pageNumber,
            pageSize,
            sortField,
            sortOrder
        }));
    };
};

export const formatResult = (
    datas,
    label = "name",
    value = "id",
    type = "type"
) => {
    return datas
        ?.map((item) => ({
            label: item[label],
            type: item[type],
            value: item[value]
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const getColumns = (
    cols = [],
    renderAction = null,
    id = false,
    active = false,
    operation = false
) => {
    let returnArray = [];
    const idColumn = [
        {
            dataIndex: "id",
            defaultSortOrder: "descend",
            sorter: true,
            title: "ID",
            width: 40
        }
    ];
    const activeColumn = [
        {
            dataIndex: "active",
            render: (active) => (
                <Tag color={ active ? "green" : "volcano" }>
                    { active ? "Aktif" : "Pasif" }
                </Tag>
            ),
            title: "Durum",
            width: 100
        }
    ];
    const operationColumn = [
        {
            dataIndex: "",
            render: renderAction,
            title: "İşlem",
            width: 120
        }
    ];
    if (id) returnArray.push(...idColumn);
    if (cols) returnArray.push(...cols);
    if (active) returnArray.push(...activeColumn);
    if (operation) returnArray.push(...operationColumn);
    if (cols && !id && !active && !operation)
        returnArray = [...idColumn, ...cols, ...activeColumn, ...operationColumn];
    return returnArray;
};
