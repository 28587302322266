import { CheckOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { settingConfigTableListUrl } from "services";

const Breadcrumbs = [
    {
        breadcrumbName: "Ayarlar",
        path: "/settings"
    }
];

const Columns = [
    {
        render: ( _, record ) => {      
            return(
                <>   
                    <Typography.Text className="text-dark-75 font-weight-bold text-hover-info mb-1">
                        { record?.category }
                    </Typography.Text>
                </>
            );},
        title: "Kategori"
    },
    {
        render: ( _,record ) => <>
            <span>{ record?.firm }</span>
        </>,
        title: "Firma"
    },
    {
        render: ( _,record ) => <>
            { record?.is_default ? <CheckOutlined style={ { color:"green" } } /> : undefined  }
        </>,
        title: "Varsayılan"
    }
];

const ListUrl = settingConfigTableListUrl;

export { Breadcrumbs, Columns, ListUrl };


