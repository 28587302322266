export const setPaymentMethods = (values, allMethods, external) => {
    const array = [];
    allMethods?.map(item => array.push(
        item.slug ==="kredi-karti" ?
            { id:item.id,max:values[[item.slug]+"Max"],min:values[[item.slug]+"Min"], is_external:external } :
            { id:item.id,max:values[[item.slug]+"Max"],min:values[[item.slug]+"Min"] } 
    ));
    return values.payment_method?.map(item => array.find((item1) => item1.id === item)); 
};

export const setFormValue = (firmMethods) => {
    const array = [];
    firmMethods?.map(item => array.push({ [[item.slug]+"Min"]:item.min,[[item.slug]+"Max"]:item.max }));
    return array;
};
