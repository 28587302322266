import { Layout as AntLayout, BackTop } from "antd";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Layout, SideBar } from "./";
import ErrorFallback from "./ErrorFallback";
import { Spinner } from "../common";
import { FilterProvider } from "context";

const myErrorHandler = ( error, info ) => {
    console.error(error, info);
};

const MasterLayout = ( { children } ) => (
    <AntLayout style={ { minHeight: "100vh" } }>
        <SideBar />
        <Suspense fallback={ <Spinner /> }>
            <FilterProvider>
                <Layout>
                    <ErrorBoundary
                        FallbackComponent={ ErrorFallback }
                        onError={ myErrorHandler }
                    >
                        { children }
                    </ErrorBoundary>
                </Layout>
            </FilterProvider>
        </Suspense>
        <BackTop />
    </AntLayout>
);


export default MasterLayout;
