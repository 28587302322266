import { Button, Form, Input, notification } from "antd";
import React, { useState } from "react";
import { post } from "helper";

const GetCodeWithEmail = ({ setStepData }) => {
    const [buttonText, setButtonText] = useState("Gönder");
    const [loading, setLoading] = useState(false);
    
    const onFormSubmit = async (data) => {  
        const email={ ...data, type:1 };
        setLoading(true);
        setButtonText( "Bekleyiniz..." );
        try {
            const { success, message } = await post(
                "/authenticate/createConfirmCode",
                { ...email }
            );
            if (!success) {
                setLoading(false);
                setButtonText("Gönder");
                notification.error({
                    message: "Bilgilendirme",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setButtonText( "Kod Gönderildi!" );
            setStepData({ ...email, from:"forgotEmail", sent:true });
        } catch (e) {
            setButtonText("Gönder");
            setLoading(false);
            notification.error({
                message: "Bilgilendirme",
                description: e.toString(),
                placement: "top"
            });
        }
    };

    return (
        <Form onFinish={ onFormSubmit } autoComplete="off" >
            <Form.Item
                name="email"
                rules={ [
                    {
                        required: true,
                        message: "Lütfen email adresinizi giriniz."
                    },
                    {
                        type: "email",
                        message: "Lütfen email adresinizi giriniz."
                    }
                ] }
            >
                <Input bordered={ false } placeholder="E-Posta Adresi" />
            </Form.Item>
            <Button block type="primary" htmlType="submit" loading={ loading }>
                { buttonText }
            </Button>
        </Form>  
    );
};

export default GetCodeWithEmail;
