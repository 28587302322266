import { Drawer } from "antd";
import React from "react";

const DrawerComp = ({
    title,
    onClose,
    open,
    children
}) => {
    return (
        <Drawer
            title={ title }
            placement="right"
            onClose={ onClose }
            open={ open }
            maskClosable={ false }
        >
            { children }
        </Drawer>
    );
};

export default DrawerComp;
