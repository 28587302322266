import {  ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Space, Tabs, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { catalogProductstockContinureUrl } from "services";
import CategoryPricesPage from "./editview/CategoryPricesPage";
import CompanyPricesPage from "./editview/CompanyPricesPage";
import { productPriceTabItems } from "./editview/Constant";
import ProductPriceDetails from "./editview/ProductPriceDetails";
import SumPage from "./editview/SumPage";
import { Breadcrumbs } from "../Constant";
import { ProtectedElement, Spinner } from "components";
import ErrorMessage from "components/common/ErrorMessage";
import { PageTitle } from "context";
import { usePrice } from "helper";

const { Title } = Typography;

const EditView = () => {
    const { id: primaryKey } = useParams();
    const { data, error, mutateData, isLoading } = usePrice(primaryKey);
    const navigate = useNavigate();

    const [breadcrumbs, setBreadcrumbs] = useState();
    const [activeTab, setActiveTab] = useState("item-1");

    const handleBreadcrumbs =useCallback( () => {
        if (data) {
            const { result: productSku } = data;
            const breadcrumbs = [
                ...Breadcrumbs,
                {
                    breadcrumbName: data?.result?.name,
                    path: `${catalogProductstockContinureUrl}${productSku.id}`
                }
            ];
            setBreadcrumbs(breadcrumbs);
        }
    },[data]);

    const onChangeTab = ( activeKey ) => setActiveTab( activeKey );

    const renderCompanyPrices=useMemo(()=> (<CompanyPricesPage 
        data={ data?.result }
        mutate={ ()=>mutateData() }
        primaryKey={ primaryKey }
    />),[data, primaryKey,mutateData ]);

    const renderCategoryPrices=useMemo(()=> (<CategoryPricesPage
        data={ data?.result }
        mutate={ mutateData }
        primaryKey={ primaryKey }
    />),[data,primaryKey, mutateData]);


    useEffect(() => {
        handleBreadcrumbs();
    }, [handleBreadcrumbs]);

    if(error)return (<Card><ErrorMessage/></Card>);
    if (isLoading) return (<Card><Spinner /></Card>);

    const { result } = data ;

    const activeItems = {
        "item-1": <SumPage data={ result } />,
        "item-2": data ? (
            <ProtectedElement permissionKey={ "product.edit" }>  
                { renderCategoryPrices }
            </ProtectedElement>
        ) : null,
        "item-3":     
    <ProtectedElement permissionKey={ "product.edit" }>  
        { renderCompanyPrices }
    </ProtectedElement>
    };

    return (
        <>
            <PageTitle breadcrumbs={ breadcrumbs }>{ result?.name }</PageTitle>
            <Space className="flex-btw" value={ "large" }>
                <Typography.Title strong level={ 4 }>
                    Ürün Fiyat Bilgileri
                </Typography.Title>
                <Space>
                    <Button
                        icon={ <ArrowLeftOutlined /> }
                        onClick={ () => navigate("/catalog/product-price") }
                    >
                        Geri
                    </Button>
                </Space>
            </Space>
            <Card >
                <Row align="space-between">
                    <Col>
                        <Row>
                            <Col>
                                <Title level={ 5 }>{ result?.name }</Title>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={ [24,24] } align={ "middle" }>
                    <ProductPriceDetails />
                </Row>
                <Divider></Divider>
                <Row style={ { marginBottom:-42 } }>
                    <Tabs
                        items={ productPriceTabItems }
                        activeKey={ activeTab }
                        onChange={ onChangeTab }
                        tabPosition={ "top" }
                    />
                </Row>
            </Card>
            <br />      
            { activeItems[ activeTab ] } 
        </>
    );
};
export default EditView;
