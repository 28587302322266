import { Button, Form, Input } from "antd";

export const columnAdd = ( productDetails, onTableFormChangeHandler, deleteHandler )=> {	
    const columns = [
        {
            title: "Barkod",
            dataIndex: "barcode",
            width: "12%",
            render: (_, record, i) => {
                return (
                    <Form.Item 
                        name={ `barcode_${ i }` }
                        className="product-stock_input_border"
                        rules={ [
                            { required: true, message: "Lütfen ürün barkodu giriniz!" },
                            { pattern:/^\d/g, message:"Lütfen rakam giriniz!" }
                        ] }  
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("barcode", record) }
                            value={ productDetails[ record.key ]?.barcode }
                        />
                    </Form.Item>
                );
            }
        },
        {
            title: "Fiyat",
            dataIndex: "sales_price",
            width: "9%",
            render: (_, record, i) => {
                return (
                    <Form.Item
                        name={ `sales_price_${ i }` }
                        className="product-stock_input_border"
                        rules={ [ { required: true, message: "Lütfen fiyat giriniz" },
                            { pattern: /^\d/g, message: "Lütfen rakam giriniz" } ] }              
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("sales_price", record) }
                            value={ productDetails[ record.key ]?.sales_price }
                        />
                    </Form.Item>
                );
            }
        },
        {
            title: "Stok",
            dataIndex: "stock",
            width: "9%",
            render: (_, record, i) => {
                return (
                    <Form.Item
                        name={ `stock_${ i }` }
                        className="product-stock_input_border"
                        rules={ [
                            { required: true, message: "Lütfen stok sayısını giriniz!" },
                            { pattern: /^\d/g, message: "Lütfen rakam giriniz" }
                        ] }
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("stock", record) }
                            value={ productDetails[ record.key ]?.stock }
                        />
                    </Form.Item>
                );
            }
        },
        { 
            title: "KDV",
            dataIndex: "value_added_tax",
            width: "9%",
            render: (_, record, i) => {
                return (
                    <Form.Item
                        name={ `Value_added_tax_${ i }` }
                        className="product-stock_input_border"
                        rules={ [ { required: true, message: "Lütfen KDV giriniz!" },
                            { pattern: /^\d/g, message: "Lütfen rakam giriniz" } ] }  
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("value_added_tax", record) }
                            value={ productDetails[ record.key ]?.value_added_tax }
                        />
                    </Form.Item>
                );
            }
        },
        {
            title: "Stok Kodu",
            dataIndex: "stock_code",
            width: "12%",
            render: (_, record, i) => {
                return (
                    <Form.Item
                        name={ `stock_code_${ i }` }
                        className="product-stock_input_border"
                        rules={ [ { required: true, message: "Lütfen stok kodunu giriniz!" }
                        ] }
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("stock_code", record) }
                            value={ productDetails[ record.key ]?.stock_code }
                        />
                    </Form.Item>
                );
            }
        },
        {
            key: "actions",
            title: "İşlem",
            width: "5%",
            render: (_, record) => {
                return (
                    <Button typeof="primary" onClick={ () => deleteHandler( record ) }>
                        Sil
                    </Button >
                );
            }
        }
    ];
    return columns;
};

export const cartesianHelper = (args) => {
    var r = [],
        max = args.length - 1;
    const helper = (arr, i) => {
        for (let j = 0, l = args[i]?.length; j < l; j++) {
            let a = arr.slice(0); // clone arr
            a.push(args[i][j]);
            if (i === max) r.push(a);
            else helper(a, i + 1);
        }
    };
    helper([], 0);
    return r;
};
