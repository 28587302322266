import { Button, Card, Form, Input, Row, Space, notification } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "helper";

const LoginWithPhone = ({ setLoading, loading, setSmsLogin, setStepData }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [buttonText, setButtonText] = useState("Kod Gönder");

    const onFormSubmit = async (data) => {
        const phone = { phone: "+905" + data.phone, type: 0 };
        setLoading(true);
        setButtonText("Bekleyiniz...");
        try {
            const { success, message } = await post(
                "/authenticate/createConfirmCode",
                {
                    ...phone
                }
            );
            if (!success) {
                setLoading(false);
                setButtonText("Kod Gönder");
                notification.error({
                    description: message,
                    message: "Bilgilendirme",
                    placement: "top"
                });
                return;
            }
            setLoading(false);
            setButtonText("Kod gönderildi!");
            setStepData({ ...phone, from: "loginPhone", sent: true });
        } catch (e) {
            setButtonText("Kod Gönder");
            setLoading(false);
            console.error(e.toString());
        }
    };

    return (
        <Card className="login_card" align="center">
            <Form form={ form } onFinish={ onFormSubmit } autoComplete="off" size="small">
                <Space size="small" direction="vertical" style={ { width: "100%" } }>
                    <Form.Item
                        className="phone-Input"
                        name="phone"
                        rules={ [
                            {
                                message: "Lütfen telefon numaranızı yazınız!",
                                required: true
                            },
                            {
                                max: 9,
                                message: "Lütfen geçerli bir telefon  yazınız!",
                                min: 9
                            }
                        ] }
                    >
                        <Input
                            bordered={ false }
                            prefix="+90 5"
                            placeholder=" Telefon numaranızı giriniz!"
                            onChange={ (e) =>
                                form.setFieldsValue({
                                    phone: e.target.value.replace(/\D/g, "").slice(0, 9)
                                })
                            }
                        />
                    </Form.Item>
                    <Row align="end">
                        <Button
                            type="link"
                            className="link_button"
                            size={ "small" }
                            onClick={ () => navigate("/auth/forgot-password") }
                        >
                            Şifremi unuttum
                        </Button>
                    </Row>
                    <Button block type="primary" htmlType="submit" loading={ loading }>
                        { buttonText }
                    </Button>
                    <Button
                        block
                        htmlType="button"
                        onClick={ () => setSmsLogin((prev) => !prev) }
                    >
                        E-Posta ile Giriş Yap
                    </Button>
                </Space>
            </Form>
        </Card>
    );
};

export default LoginWithPhone;
