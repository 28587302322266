import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "./Constant";
import { PageTitle } from "context";

const Page = () => {
    const navigate = useNavigate();
    return (
        <>
            <PageTitle breadcrumbs={ Breadcrumbs }>Satış İşlemleri</PageTitle>
            <div className="site-card-wrapper">
                <Row gutter={ 16 }>
                    <Col span={ 8 }>
                        <Card title="Siparişler" bordered={ false } actions={ [
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/sales/order") }/>
                        ] } >
                            <Statistic title="Active Users" value={ 112893 } />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Page;
