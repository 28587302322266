import { Image } from "antd";
import { Route, Routes } from "react-router-dom";
import { brandUrl, listUrl } from "services";
import { AddView } from "./add";
import { ProtectedRoute } from "components";

const Breadcrumbs = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog",
    },
    {
        breadcrumbName: "Markalar",
        path: "/brand",
    },
];

const Columns = [
    {
        render: (_, record) => {
            return (
                <span className="symbol symbol-50px">
                    <Image className="product img-thumbnail" src={record.image} />
                </span>
            );
        },

        title: "", width: 80
    },
    {
        render: (_, record) => (
            <>
                <div className="mb-5">  {record?.name}</div>
                <div className="font-weight-light font-size-xs">
                    Kaynak Kodu {record?.out_ref}
                </div>
                <div className="font-weight-light font-size-xs">
                    Kayıt Tarihi: {record?.create_date}
                </div>
                <div className="font-weight-light font-size-xs">
                    Güncelleme Tarihi: {record?.update_date}
                </div>
            </>
        ),
        title: "Marka"
    }
];

const Path = "/catalog/brand";
const Url = brandUrl;
const ListUrl = `${Url}${listUrl}`;

const Router = ({ onHide }) => (
    <Routes>
        <Route element={<ProtectedRoute permissionKey={"brand.add"} />}>
            <Route path="add" element={<AddView onHide={onHide} />} />
        </Route>
        <Route element={<ProtectedRoute permissionKey={"brand.edit"} />}>
            <Route path=":id/edit" element={<AddView onHide={onHide} />} />
        </Route>
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };
