import { Divider, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { originListUrl } from "services";
import { notificationError, post } from "helper";

const OriginList = ({ roleId }) => {
    const [originList, setOriginList] = useState([]);

    const getOriginList = async () => {
        const { result, success, message } = await post(originListUrl,{});
        if (!success) {
            notificationError( message );
            return;
        }
        const newResult=result
            .map((origin=>({ label:origin.domain, value:origin.domain })))
            .sort((a, b) => a.label.localeCompare(b.label));
        setOriginList(newResult);
    };

    useEffect(() => {
        getOriginList();
    }, []);

    return (
        <>
            <Divider orientation="left">{ roleId? "Rol Bilgilerini Düzenle":"Rol Bilgilerini Ekle" }</Divider>
            <Form.Item
                label="Yetki Verilecek Domainler"
                name="origins"
                rules={ [{ message:"Lütfen yetki verilecek domainleri seçiniz", required: true }] }
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                >         
                    { originList ?.map((option) => (
                        <Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select> 
            </Form.Item>
        </>
    );
};

export default OriginList;
