import { Button, Card, Checkbox, Col, Form, Row } from "antd";
import React from "react";

const PreferenceInfo = () => {
    return (
        <>
            <Card className="title">
                <p className="title-p">Tercih Bilgileri</p>
            </Card>
            <Card className="preference-info-card-content">
                <Form
                    name="basic"
                    labelCol={ {
                        // xs: { span: 5,
                        //     pull:2 },
                        // sm: { span: 5,
                        //     pull:2 },
                        // md: { span: 5,
                        //     pull:2 },
                        lg: { pull:0,
                            span: 5 },
                        xl: { pull:1,
                            span: 5 },
                        xxl: { pull:2,
                            span: 5 }
                    } }
                    wrapperCol={ {
                        push: 4,
                        span: 14
                    } }
                    initialValues={ {
                        remember: true
                    } }
                    // onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item 
                        label="İletişim"
                        // name="preference"
                    >
                        <Checkbox>Mail</Checkbox>
                        <Checkbox>Telefon</Checkbox>
                        <Checkbox>Bildirim</Checkbox>
                    </Form.Item>
                </Form>
            </Card>
            <Card>
                <Row gutter={ 24 }>
                    <Col 
                        xs={ {
                            push:13,
                            span: 1
                        } }
                        sm={ {
                            push:14,
                            span: 2
                        } }
                        md={ {
                            push:16,
                            span: 4
                        } }
                        lg={ {
                            push:18,
                            span: 6
                        } }
                        xl={ {
                            push:18,
                            span: 8
                        } }
                        xxl={ {
                            push:18,
                            span: 10
                        } }>
                        <Button className="footer" type="primary">
                            Değişiklikleri Kaydet
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default PreferenceInfo;
