import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import React, { useEffect, useState } from "react";
import { ordersSendToServerUrl, updateAnOrderUrl } from "services";
import { dropdownHelper } from "./Helper";
import { ProtectedElement } from "components";
import { useTableData } from "context/TableProvider";
import { notificationError, notificationSuccess, notificationWarning, post } from "helper";

const UpdateSend = ({ data, mutate }) => {
    const { selectedRows } = useTableData();
    const [loading, setLoading] = useState(false);
    const [newData, setNewData] = useState();

    const sendToServerAction = async () => {
        setLoading(true);
        const filteredIds = newData
            ?.filter((item) => selectedRows?.find((item1) => item1 === item.id))
            ?.filter((item2) => !item2?.out_ref && !item2?.is_sent  )?.map((item3)=>+item3?.id);
        const { success, message } = await post(`${ordersSendToServerUrl}`,{
            "order_ids": filteredIds
        });

        setTimeout(() => {
            setLoading(false);
        },500);

        if (!success) {
            notificationError(message);
            return;
        }
        notificationSuccess("Seçili ürünler başarıyla gönderildi");
        await mutate();
    };

    const updateOrdersAction = async () => {
        setLoading(true);
        const filteredOutRefs = newData
            ?.filter((item) => selectedRows?.find((item1) => item1 === item.id))
            ?.filter((item2) => item2?.out_ref !== null)?.map((item3)=>+item3?.out_ref);

        if(filteredOutRefs?.length<1){
            notificationWarning("Seçili ürünlerin erp kaydı bulunamadı");
            return;
        }
        const { success, message } = await post(updateAnOrderUrl,{ out_refs:filteredOutRefs });
        setTimeout(() => {
            setLoading(false);
        },500);
        if (!success) {
            notificationError(message);
            return;
        }
        notificationSuccess("Seçili ürünler başarıyla güncellendi");
        await mutate();
    };

    const items= dropdownHelper(sendToServerAction, updateOrdersAction) ;

    useEffect(() => {
        setNewData(data);
    }, [data]);

    return (
        <>
            { selectedRows?.length>0 && 
                        (   
                            <ProtectedElement permissionKey={ "sync.add" }>   
                                <Dropdown 
                                    menu={ { items } }
                                    loading={ loading }
                                >
                                    <Button
                                        shape="round"
                                        type="dashed"
                                        onClick={ (e) => e.preventDefault() }>
                                        <Space>
                                            Seçili Siparişleri
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </ProtectedElement>) }
        </>
    );
};

export default UpdateSend;
