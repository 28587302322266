import { Image } from "antd";
import { Link } from "react-router-dom";
import { listUrl, membershipUserContinueUrl, userUrl } from "services";

const Columns = [
    {
        title: "",
        width: 80,
        render: (_, record) => (
            <Link to={ `${membershipUserContinueUrl}${record.id}/overview` }>
                <span className="symbol symbol-50px">
                    <Image 
                        preview={ false }
                        src={ record.image }
                    />
                </span>
            </Link>
        )
    },
    {
        title: "Kullanıcı",
        render: (_, record) => (
            <div>
                <Link 
                    to={ `${membershipUserContinueUrl}${record.id}/overview` } 
                    className="fw-600">
                    { record?.full_name }
                </Link>
                <div className="text-muted">{ record?.email }</div>
                <div className="text-muted">{ record?.gsm }</div>
            </div>
        )
    },
    {
        dataIndex: "roles",
        title: "Rol",
        render: (roles) => roles[0]?.name
    },
    {
        title:"Son Giriş"
    },
    {
        dataIndex:"create_date",
        title:"Kayıt Tarihi"
    },
    {
        title:"Online"
    }
];

const Path = "/account/company";
const Url = userUrl;
const ListUrl = `${Url}${listUrl}`;

export { Path, Url, ListUrl, Columns };
