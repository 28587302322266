import {  Card, Col, Row } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { companyContinueUrl } from "services";
import { Overview } from "./Overview";
import { getUserFields } from "../Helper";
import { get } from "helper";
import { notificationError } from "helper/NotificationHelpers";

const Page = () => {
    const { id: userId } = useParams();

    const [company,setCompany] = useState();

    const getCompanyInfo = async () => {
        const { result, success, message } = await get(`${companyContinueUrl}${userId}`);
        if (!success) {
            notificationError(message);
            return;
        }
        setCompany(result);
    };

    const fields = getUserFields(company);

    useEffect(() => {
        getCompanyInfo();
    }, []);
    

    return (
        <div className="overview-card">
            <Card>
                <Row gutter={ 24 }>
                    <Col
                        span={ 20 }
                        className="title"
                        style={ { display: "flex", alignItems: "center" } }
                    >
                        <p style={ { margin: 0 } }>Profil Önizleme</p>
                    </Col>
                </Row>
            </Card>
            <Overview>
                { fields?.map((item,index) => (
                    <Fragment key={ index }>
                        <Col span={ 6 } className="gray-color user-overview-col">
                            { item.title }
                        </Col>
                        <Col span={ 18 } className="fw-600">
                            { item.value }
                        </Col>
                    </Fragment>
                ) ) }               
            </Overview>
            <Row className="mt-30" gutter={ 24 }>
                <Col span={ 12 }>
                    <Card>
                        <p className="title">Recent Statistics</p>
                    </Card>
                </Col>
                <Col span={ 12 }>
                    <Card>
                        <p className="title">Task Overview</p>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-30" gutter={ 24 }>
                <Col span={ 12 }>
                    <Card>
                        <p className="title">Activities</p>
                    </Card>
                </Col>
                <Col span={ 12 }>
                    <Card>
                        <p className="title">Latest Products</p>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Page;
