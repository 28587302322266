import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import { useNavigate } from "react-router-dom";
import { pageTitles } from "./Constant";
import { Breadcrumbs } from "./Helper";
import { PageTitle } from "context";

const Page = () => {
    const navigate = useNavigate();
    return (
        <>
            <PageTitle breadcrumbs={ Breadcrumbs }>Bufipay</PageTitle>
            <div className="site-card-wrapper">
                <Row gutter={ 16 }>
                    { pageTitles.map( (column, i) => {
                        return (<Col span={ 8 } key={ i }>
                            <Card
                                title={ column?.title }
                                bordered={ false }
                                actions={ [
                                    <ArrowRightOutlined
                                        key="show"
                                        onClick={ () => navigate(column?.path) }
                                    />
                                ] }
                            >
                                <Statistic title="Aktif Ödemeler" value={ 112893 } />
                            </Card>
                            <br />
                        </Col>);
                    } ) }
                </Row>
            </div>
        </>
    );
};

export default Page;
