import { Table } from "antd";
import React from "react";
import { columnsLots } from "./Constant";

const LotsDetailsPage = ({ data }) => {

    data.map(item => item.key= item.id);
    
    return (
        <Table 
            dataSource={ data } 
            columns={ columnsLots } 
        />
    );
};

export default LotsDetailsPage;
