import { Col, Tag } from "antd";
import React from "react";
import CustomCard from "./CustomCard";

const OrderDetails = ({ data, source }) =>{
    const renderingColumns=data?.map((tag, i) => (
        <Col key={ i }>
            <CustomCard
                cardTitle={ tag[0] }
                cardValue={tag[1] ? tag[1] : " "}
                cardPrecision={ tag[2] }
                cardPrefix={ tag[3] }
                cardSuffix={ tag[4] }
                cardColor={ tag[5] }
            />
        </Col>
    ) );

    return (
        <>
            { renderingColumns }
            { typeof source ==="string" && <Col>            
                <Tag>{  source?.toUpperCase() }</Tag>
            </Col> }    
        </>); 
};
    
export default OrderDetails;
