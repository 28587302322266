import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Space, Table, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { installmentsListUrl, installmentsUpdateUrl, virtualPosVirtualListUrl } from "services";
import { columns } from "./Constant";
import { BreadcrumbsInstallments } from "../Constant";
import { Spinner } from "components";
import { PageTitle } from "context";
import { notificationError, post } from "helper";

const InstallmentsView = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [fetchedInstallments, setFetchedInstallments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [posName,setPosName] = useState();
    const [error, setError] = useState(false);
    
    const getInfo = async (pos_id) => {
        const { result, success, message:msg } = await post(virtualPosVirtualListUrl, {
            filter : { id : pos_id },
            pageSize: 1000
        });
        if (!success) {

            notificationError(msg);
            return;
        }
        setPosName(result[0]?.pos_name);
    };
    
    const getInstallments = async () => {
        setLoading(true);
        const { result, success, message } = await post(
            installmentsListUrl,
            {
                filter: {
                    pos_id: params?.id
                },
                pageNumber: 1,
                pageSize: 20,
                sortField: "installment",
                sortOrder: "asc"
            }
        );      
        setLoading(false);
        if (!success) {
            setError(true);
            notificationError( message );
            return;
        }
        if( result?.length > 0 ) {
            const newResult = result.map((item) => ({ ...item, key: item.id }));
            setFetchedInstallments(newResult);
            setError(false);
        }              
    };

    const handleFormSave = async (values) => {
        setLoading(true);
        const installmentList = Object.entries(values).map((installment) => 
            ({ id: +installment?.at(0), ...installment?.at(1), pos_id: +params?.id })
        );
        const { success, message } = await post(
            installmentsUpdateUrl,
            installmentList
        );
        setLoading(false);
        if (!success) {
            notificationError( message );
            return;
        }
        notification.success({
            description: "Komisyon bilgileri başarıyla kaydedildi!",
            message: "Bilgilendirme",
            placement: "top"
        });
    };

    useEffect(() => {
        getInstallments();
        getInfo(params.id);
    }, []);

    return (
        <Card>
            <PageTitle breadcrumbs={ BreadcrumbsInstallments }>
                { BreadcrumbsInstallments?.at(-1)?.breadcrumbName }
            </PageTitle>
            <Form
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleFormSave }
            >
                <Row align="space-between">
                    <Col>
                        <Typography.Title strong level={ 4 }>
                            { posName }
                        </Typography.Title>
                    </Col>
                    <Col>
                        <Space className="flex-btw" value={ "large" }>
                            <Space>
                                <Button
                                    icon={ <ArrowLeftOutlined /> }
                                    onClick={ () => navigate("/bufipay/virtual-posaccounts") }
                                >
                                    Geri
                                </Button>
                                <Button type="primary" loading={ loading } htmlType="submit">
                                    Kaydet
                                </Button>
                            </Space>
                        </Space>
                    </Col>
                </Row>
                <br />
                { !fetchedInstallments && !error ? (
                    <Spinner />
                ) : (
                    <Table
                        bordered
                        loading={ loading }
                        dataSource={ fetchedInstallments }
                        columns={ columns }
                        pagination={ {
                            pageSize: 20
                        } }
                    />
                ) }
            </Form>
        </Card>
    );
};
export default InstallmentsView;
