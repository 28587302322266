import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BrandPage, CatalogPage, CategoryPage } from "./";
import { CampaignPage } from "./campaign";
import { CollectionPage } from "./collection";
import { ProductPage } from "./product";
import { AddProductRouter } from "./product/add";
import { ProductPricePage } from "./product-price";
import { AddProductPriceRouter } from "./product-price/add";
import { EditProductPriceRouter } from "./product-price/edit";
import { ProductStockPage } from "./product-stock";
import { AddProductStockRouter } from "./product-stock/add";
import { EditProductStockRouter } from "./product-stock/edit";
import { ProductAttributePage } from "./productattribute";
import { ProductAttributeValuePage } from "./productattributevalue";
import { ProductTypePage } from "./producttype";
import { ProtectedRoute } from "components";

const Router = () => (
    <Routes>
        <Route path="/" element={ <CatalogPage /> } />   
        <Route path="catalogsum" element={ <Navigate to="/catalog" replace /> } />  
        <Route element={ <ProtectedRoute permissionKey={ "category.view" } /> }>
            <Route path="category/*" element={ <CategoryPage /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "brand.view" } /> }>
            <Route path="brand/*" element={ <BrandPage /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "product.view" } /> }>
            <Route path="product-type/*" element={ <ProductTypePage /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "product.view" } /> }>
            <Route path="product-attribute/*" element={ <ProductAttributePage /> } />
        </Route>
        <Route path="product-attribute-value/:id/*" element={ <ProductAttributeValuePage /> } />
        <Route path="product/*"  element={ <ProductPage/> }/>
        <Route path="product/add/*" element={ <AddProductRouter /> } />
        <Route element={ <ProtectedRoute permissionKey={ "product.view" } /> }>
            <Route path="product-stock/*">
                <Route index element={ <ProductStockPage /> } />
                <Route element={ <ProtectedRoute permissionKey={ "product.add" } /> }>
                    <Route path="add/*" element={ <AddProductStockRouter /> } />
                </Route>
                <Route element={ <ProtectedRoute permissionKey={ "product.edit" } /> }>
                    <Route path=":id/edit/*" element={ <EditProductStockRouter /> } />
                </Route>
            </Route>
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "product.view" } /> }>
            <Route path="product-price/*">
                <Route index element={ <ProductPricePage/> } />
                <Route element={ <ProtectedRoute permissionKey={ "product.add" } /> }>
                    <Route path="add/*" element={ <AddProductPriceRouter/> } />
                </Route>
                <Route element={ <ProtectedRoute permissionKey={ "product.edit" } /> }>
                    <Route path=":id/edit/*" element={ <EditProductPriceRouter/> } />
                </Route>
            </Route>
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "campaign.view" } /> }>
            <Route path="campaign/*" element={ <CampaignPage /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "collection.view" } /> }>
            <Route path="collection/*" element={ <CollectionPage /> } />
        </Route>
    </Routes>  
);

export default Router;
