import { Typography } from "antd";
import { listUrl, productPriceUrl } from "services";
import { ProtectedLink } from "components";

const Breadcrumbs = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog"
    },
    {
        breadcrumbName: "Ürün Fiyatları",
        path: "/catalog/product-price"
    }
];

const BreadcrumbsAdd = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog"
    },
    {
        breadcrumbName: "Ürün Fiyatları",
        path: "/product-price"
    },
    {
        breadcrumbName: "Yeni Fiyat",
        path: "/add"
    }
];

const Columns = [
    {
        render: (_, record) => {
            const { id, name } = record;
            return (
                <>
                    <ProtectedLink permissionKey={ "product.edit" } path={ `${id}/edit` }>
                        <Typography.Text className="text-dark-75 font-weight-bold text-hover-info mb-1">
                            { name }
                        </Typography.Text>
                    </ProtectedLink>

                    <div className="font-weight-light font-size-xs">
                        Kayıt Tarihi: { record?.create_date }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Güncelleme Tarihi: { record?.update_date }
                    </div>
                </>
            );
        },
        title: "Şablon İsmi"
    },
    {
        dataIndex: "trans_code",
        title: "Şablon Kodu"
    }
];

const Path = "/catalog/product-price";
const Url = productPriceUrl;
const ListUrl = `${Url}${listUrl}`;

export { Breadcrumbs, BreadcrumbsAdd, Columns, Path, Url, ListUrl };
