import { message } from "antd";
import { notificationError } from "helper";

export const Breadcrumbs = [
    {
        breadcrumbName: "Ayarlar",
        path: "/settings"
    }
];

export const serviceRequest = async (request,url,messageString,value) => {
    const { success, message:msg } = await request(url, value);
    if (!success) {
        notificationError(msg);
        return;
    }
    message.success(messageString);
};
