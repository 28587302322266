import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Popconfirm, notification } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";
import { Columns, ListUrl, Path, Url } from "./Constant";
import { ProtectedElement } from "components";
import { delete_, getColumns } from "helper";
import { usePost } from "helper/Hook";

export const useTable = (mutateData) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate(Path);
    };

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${Url}/${primaryKey}`);
        if(!success){
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        onHide();
    };

    const renderAction = (_, record) => (
        <>
            <ProtectedElement permissionKey={ "product.edit" }>
                <Button onClick={ () => navigate(`${record.id}/edit`) } type="link" size="small">
                    <i className="flaticon2-edit text-muted" />
                </Button>
            </ProtectedElement>
            <ProtectedElement permissionKey={ "product.delete" }>
                <Popconfirm okText="Evet" cancelText="Hayır" 
                    title="Eminmisiniz?" onConfirm={ () => deleteAction(record.id) }>
                    <Button type="link" size="small" danger>
                        <i className="flaticon2-trash" />
                    </Button>
                </Popconfirm>
            </ProtectedElement>
        </>
        
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns, onHide };
};

export const useData = (queryParams) => {
    const newParams=cloneDeep(queryParams);
    return usePost(ListUrl, newParams);
};

export const getFilterQuery = (queryParams, values) => {
    const { active, name, brand, on_sale, category_id, type_id, has_images } = values;

    const newQueryParams =  cloneDeep(queryParams);
    const filter = {};
    if (active) filter.active = +active;
    if (brand) filter.brand_id =brand;
    if (category_id) filter.category_id = category_id;
    if (type_id) filter.type_id = type_id;
    if (on_sale) filter.on_sale = on_sale;
    if (has_images === "1") filter.has_images = true;
    if (has_images === "0") filter.has_images = false;
    if (name) newQueryParams.keyword=name;
    else delete newQueryParams?.keyword;
    newQueryParams.filter = filter;
    return newQueryParams;
};

export const dropdownHelper=(productFee, productLot, productStock, productSku )=>
    ([
        {
            icon: <CloudDownloadOutlined />,
            key: "1",
            label: (
                <>
                    <Button
                        type="text"
                        block
                        onClick={ productFee  }>
                        Ürün Fiyatları
                    </Button>
                </>
            )
        },
        {
            icon:  <CloudDownloadOutlined />,
            key: "2",
            label: (
                <Button 
                    type="text"
                    block
                    onClick={ productLot }>
                    Ürün Lotları
                </Button>
            ) 
        },
        {
            icon:  <CloudDownloadOutlined />,
            key: "3",
            label: (
                <Button 
                    type="text"
                    block
                    onClick={ productStock }>
                    Ürün Stokları
                </Button>
            ) 
        },
        {
            icon:  <CloudDownloadOutlined />,
            key: "4",
            label: (
                <Button 
                    type="text"
                    block
                    onClick={ productSku }>
                    Stok Kartları
                </Button>
            ) 
        }
    ]);


