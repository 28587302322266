import { Button, Form, Input, notification } from "antd";
import React, { useState } from "react";
import { post } from "helper";

const GetCodeWithPhone = ({ setStepData }) => {
    const [form] = Form.useForm();
    const [buttonText, setButtonText] = useState("Gönder");
    const [loading, setLoading] = useState( false );
    
    const onFormSubmit = async ( data ) => { 
        const phone = { phone: "+905" + data.phone, type:0 };
        setLoading(true);
        setButtonText( "Bekleyiniz..." );
        try {
            const { success, message } = await post(
                "/authenticate/createConfirmCode",
                { ...phone }
            );
            if (!success) {
                setLoading(false);
                setButtonText("Gönder");
                notification.error({
                    message: "Bilgilendirme",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setButtonText("Kod Gönderildi!");
            setStepData({ ...phone, from:"forgotPhone", sent:true });
        } catch (e) {
            setButtonText("Gönder");
            setLoading(false);
            notification.error({
                message: "Bilgilendirme",
                description: e.toString(),
                placement: "top"
            });
        }
    };
  
    return (
        <Form onFinish={ onFormSubmit } autoComplete="off" form={ form }>
            <Form.Item
                className="phone-Input"
                name="phone"
                rules={ [
                    {
                        required: true,
                        message: "Lütfen telefon numaranızı yazınız."
                    },
                    {
                        min: 9,
                        max: 9,
                        message: "Lütfen geçerli bir telefon  yazınız."
                    }
                ] }>
                <Input
                    bordered={ false }
                    prefix="+90 5"
                    placeholder=" Telefon numarası"
                    onChange={ (e) =>
                        form.setFieldsValue({
                            phone: e.target.value.replace(/\D/g, "").slice(0, 9)
                        })
                    }
                />
            </Form.Item>
            <Button block type="primary" htmlType="submit" loading={ loading }>
                { buttonText }
            </Button>
        </Form>
 
    );

};

export default GetCodeWithPhone;
