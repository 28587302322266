import { LeftOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Space,
    Typography,
    notification
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import { useAuth } from "context";
import { post } from "helper";

const { Text } = Typography;

const loginPhoneVerifyUrl = "authenticate/validate_and_login";
const resetPhoneVerifyUrl = "authenticate/validate-confirm-code";

const CodeVerificationPage = ({ stepData, setStepData }) => {
    const navigate = useNavigate();
    const { createSession } = useAuth();
    const codeInputRef = useRef();
    const [form] = Form.useForm();

    const [submitButtonText, setSubmitButtonText] = useState("Tamam");
    const [loading, setLoading] = useState(false);
    const [isSmsValid, setIsSmsValid] = useState({ isValid: true, message: "" });
    const [progress, setProgress] = useState(120);

    const { phone, email, type, from } = stepData;
    const URL = from === "loginPhone" ? loginPhoneVerifyUrl : resetPhoneVerifyUrl;

    const dataHandler = () => {
        const code = form.getFieldValue("code");
        if (from === "forgotEmail") return { email, code, type };
        if (from === "forgotPhone" || from === "loginPhone")
            return { phone, code, type };
        return undefined;
    };

    const onFormSubmit = async () => {
        if (progress === 0) return;

        const data = dataHandler();
        setLoading(true);
        setSubmitButtonText("Bekleyiniz...");
        try {
            const { user, tokens, success, message, token } = await post(URL, {
                ...data
            });
            if (!success) {
                setLoading(false);
                setSubmitButtonText("Tamam");
                setIsSmsValid({ isValid: false, message: message });
                return;
            }
            setIsSmsValid({ isValid: true, message: "" });
            setLoading(false);
            setSubmitButtonText("Giriş başarılı!");
            if (stepData?.from === "loginPhone") {
                await createSession({ user, tokens });
                navigate("/dashboard");
            } else {
                navigate(`/auth/reset-password/${token}`, {
                    state: {
                        from: "codeVerification"
                    }
                });
            }
        } catch (e) {
            setSubmitButtonText("Tamam");
            setLoading(false);
            notification.error({
                message: "Bilgilendirme",
                description: e.toString(),
                placement: "top"
            });
        }
    };

    const sendingCodeHandler = async () => {
        const data = dataHandler();
        form.resetFields();
        setProgress(120);
        try {
            const { success, message } = await post(
                "/authenticate/createConfirmCode",
                { ...data }
            );
            if (!success) {
                setLoading(false);
                setSubmitButtonText("Giriş Yap");
                notification.error({
                    message: "Bilgilendirme",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            notification.error({
                message: "Bilgilendirme",
                description: e.toString(),
                placement: "top"
            });
        }
    };

    useEffect(() => {
        codeInputRef.current.focus();
    }, []);

    return (
        <div className="forgotpassword_container">
            <Card>
                <Row wrap={ false } className="header_row" align="middle">
                    <Col offset={ 0 }>
                        <Button
                            type="link"
                            size={ "small" }
                            onClick={ () => setStepData((prev) => ({ ...prev, sent: false })) }
                        >
                            <LeftOutlined />
                        </Button>
                    </Col>
                    <Col>
                        { " " }
                        <h4>{ "Doğrulama Kodu" }</h4>
                    </Col>
                </Row>
                <Form form={ form } onFinish={ onFormSubmit } autoComplete="off">
                    <Row gutter={ 8 } align="center">
                        <Form.Item
                            name="code"
                            rules={ [
                                {
                                    required: true,
                                    message: "Lütfen sms kodunu yazınız!"
                                },
                                {
                                    min: 6,
                                    max: 6,
                                    message: "Lütfen geçerli sms kodu yazınız!"
                                }
                            ] }
                        >
                            <Input
                                ref={ codeInputRef }
                                maxLength={ 6 }
                                onChange={ (e) => {
                                    setIsSmsValid({ isValid: true, message: "" });
                                    form.setFieldsValue({
                                        code: e.target.value.replace(/\D/g, "").slice(0, 6)
                                    });
                                } }
                                disabled={ progress === 0 }
                                className="codeverify_input"
                                bordered={ false }
                            />
                        </Form.Item>
                    </Row>
                    <Space size="small" direction="vertical">
                        <Row align="space-between">
                            <Col>
                                { !isSmsValid.isValid && (
                                    <Text type="danger">{ isSmsValid.message }</Text>
                                ) }
                            </Col>
                            <Col span={ 3 } style={ { marginRight: "2rem" } }>
                                { progress !== 0 && (
                                    <ProgressBar progress={ progress } setProgress={ setProgress } />
                                ) }
                            </Col>
                        </Row>
                        { progress === 0 && (
                            <Button
                                block
                                type="link"
                                htmlType="button"
                                onClick={ sendingCodeHandler }
                            >
                                Tekrar Kod Gönder!
                            </Button>
                        ) }
                        { progress > 0 && (
                            <Button block type="primary" htmlType="submit" loading={ loading }>
                                { submitButtonText }
                            </Button>
                        ) }
                        { progress < 1 && (
                            <Button
                                block
                                type="primary"
                                onClick={ () =>
                                    navigate("/auth/login", { state: { activeTab: "1" } })
                                }
                                loading={ loading }
                            >
                                Giriş Yap
                            </Button>
                        ) }
                        <Text>
                            Sms olarak kodu almadıysanız süre dolduktan sonra tekrar kod
                            isteyiniz.
                        </Text>
                    </Space>
                </Form>
            </Card>
        </div>
    );
};

export default CodeVerificationPage;
