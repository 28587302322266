import { UserOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";

const Name = () => (
    <Form.Item
        name="customer_name"
        rules={ [
            {
                required: true,
                message: "Lütfen isim giriniz!"
            },
            {
                whitespace: true,
                message: "Lütfen isim giriniz!"
            },
            {
                pattern: "^[A-Za-za-züÜçÇıİşŞğĞöÖ s*]+$",
                message: "Lütfen sadece harf giriniz!"
            }
        ] }
    >
        <Input
            placeholder="Lütfen isim giriniz"
            prefix={ <UserOutlined className="mr-7"/> }
        />
    </Form.Item>
);

export default Name;
