import React from "react";
import { Route, Routes } from "react-router-dom";
import { EditView } from ".";

const Router = () => (
    <Routes>
        <Route path="/" element={ <EditView /> } /> 
    </Routes>  
);

export default Router;
