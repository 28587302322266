import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { 
    settingAddConfigUrl, 
    settingConfigFirmsAndCategoriesUrl, 
    settingConfigListUrl, 
    settingEditConfigContinueUrl
} from "services";
import ErrorPage from "./ErrorPage";
import PropsRowPage from "./PropsRow";
import TestSmsMailPage from "./TestSmsMail";
import { Breadcrumbs } from "../Constant";
import { PageTitle } from "context";
import { get, notificationError, notificationSuccess, post, put } from "helper";

const SettingsAddPage = ({ settingsId, setType }) => {
    const [form] = Form.useForm();

    const selectedCategory = Form.useWatch("category", form);
    const selectedFirm = Form.useWatch("firm", form);

    const [categoryOptions,setCategoryOptions] = useState([]);
    const [firmOptions,setFirmOptions] = useState([]);
    const [props,setProps] = useState([]);
    const [propsView,setPropsView] = useState(false);
    const [disable, setDisable ] = useState( false );
    const [checkboxValue,setCheckboxValue] = useState();
    const [messageError,setMessageError] = useState(false);
    const [arrayRequiredProps,setArrayRequiredProps] = useState([]);
    const [formObject,setFormObject] = useState({});
    const [isDisabledBtn,setIsDisabledBtn] = useState(false);
    const [formValue,setFormValue] = useState();
    const [editType,setEditType] = useState(false);

    const getFirmsAndCategoriesInfo = async () => {
        const arrayFirm = [];
        const { result, success } = await get(settingConfigFirmsAndCategoriesUrl);
        if (!success) {
            notificationError("Bir hata meydana geldi");
            return;
        }
        const array = Object.keys(result);
        array.map(item => arrayFirm.push({ label:item,value:item }));
        setCategoryOptions(arrayFirm);
        const arrayCategory = [];
        array.map(item => item === selectedCategory ? result[item].map(item1 => arrayCategory.push({ label:item1,value:item1 })) : undefined );
        setFirmOptions(arrayCategory);
    };

    const onClickHandler= () => {
        form.submit();
        setDisable();
        setTimeout(()=>{
            setDisable(false);
        },1500);
    };

    const handleSubmit = async (values) => {
        const copy = { ...values };
        delete values.category;
        delete values.firm;
        delete values.is_default;
        const newValues = {
            category : selectedCategory,
            firm :selectedFirm,
            is_default: checkboxValue || copy.is_default ,
            keys : values
        };
        const { success } = !settingsId ? await post(settingAddConfigUrl, newValues) : 
            await put(`${settingEditConfigContinueUrl}${settingsId}`, newValues);
        if (!success) {
            notificationError("Bir Hata Oluştu Tekrar Deneyin");
            return;
        }
        notificationSuccess("Kaydetme İşlemi Başarılı");
    };

    const onChangeFirm = async (e) => {
        setFormObject({});
        setEditType(true);
        setProps([]);
        setIsDisabledBtn(false);
        const { result, success } = await post(settingConfigListUrl,{
            category:selectedCategory,
            firm:e
        });
        if (!success) {
            notificationError("Bir hata meydana geldi");
            return;
        }
        setArrayRequiredProps(result?.settings?.at(0).props.filter(item => item.required === true));
        setProps(result?.settings?.at(0).props);
        setPropsView(true);
    };

    const getEditInfo = async () => {
        const { result, success } = await post(settingConfigListUrl,{
            category:null,
            firm:null,
            id: settingsId
        });
        if (!success) {
            notificationError("Bir hata meydana geldi");
            return;
        }
        if (result?.settings?.length === 0) {
            setMessageError(true);
            return;
        }
        form.setFieldsValue({ "category":result?.settings[0]?.category , "firm":result?.settings[0]?.firm });
        setProps(result?.settings?.at(0)?.props);
        setPropsView(true);
        setIsDisabledBtn(true);
    };

    const onChangeCategory = async () => {
        form.setFieldsValue({ "firm":undefined });
        setIsDisabledBtn(false);
        setPropsView(false);
    };

    useEffect(()=> {
        getFirmsAndCategoriesInfo();
    },[selectedCategory]);

    useEffect(()=>{
        if ( settingsId ) getEditInfo();
    },[]);

    useEffect(()=>{
        if ( Object.keys(formObject).length === 0 || Object.keys(formObject).length !== arrayRequiredProps.length ) {
            setIsDisabledBtn(false);
            return;
        } 
        else {
            const conditionStatement = (Object.keys(formObject).every(item => formObject[item] === true ));
            if (conditionStatement) setFormValue(form.getFieldsValue());
            setIsDisabledBtn(conditionStatement);
        }
    },[formObject]);

    return (
        <>
            <PageTitle breadcrumbs={ Breadcrumbs }>
                { Breadcrumbs[Breadcrumbs.length-1]?.breadcrumbName }
            </PageTitle> 
            { messageError ? 
                <ErrorPage setType={ setType } /> : 
                <Card>
                    <Row align="space-between">
                        <Col>
                            <Typography.Title strong level={ 4 }>
                                { !settingsId 
                                    ? "Yeni Ayar Ekle"
                                    : "Ayar Düzenle" }
                            </Typography.Title>
                        </Col>
                        { isDisabledBtn ? <TestSmsMailPage form1={ form } selectedCategory={ selectedCategory } formValue={ formValue } settingsId={ settingsId } /> : undefined }
                        <Col>
                            <Space className="flex-btw" value={ "large" }>
                                <Space>
                                    <Button
                                        icon={ <ArrowLeftOutlined /> }
                                        onClick={ () => setType("list") }
                                    >
                                        Geri
                                    </Button>
                                    <Button type="primary" 
                                        disabled={ disable } 
                                        onClick={ onClickHandler }
                                    >
                                        Kaydet
                                    </Button>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                    <Form
                        layout="vertical"
                        autoComplete="off"
                        onFinish={ handleSubmit }
                        form={ form }
                    >
                        <Row gutter={ 24 }>
                            <Col span={ 12 } push={ 6 }>
                                <Form.Item
                                    label="Kategori"
                                    name="category"
                                    required={ true }
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        onChange={ onChangeCategory }
                                        filterOption={ (inputValue, option) =>
                                            option.children?.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                    > { categoryOptions?.map( ( option ) =>
                                            ( <Select.Option key={ option.value }>{ option.label }</Select.Option>
                                            ) ) }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ 24 }>
                            <Col span={ 12 } push={ 6 }>
                                { selectedCategory !== undefined ? 
                                    <Form.Item
                                        label="Firma"
                                        name="firm"
                                        required={ true }
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            onChange={ onChangeFirm }
                                            filterOption={ (inputValue, option) =>
                                                option.children?.toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                        > { firmOptions?.map( ( option ) =>
                                                ( <Select.Option key={ option.value }>{ option.label }</Select.Option>
                                                ) ) }
                                        </Select>
                                    </Form.Item> 
                                    : undefined 
                                }
                            </Col>
                        </Row>
                        <Row gutter={ 24 }>
                            <Col span={ 12 } push={ 6 }>
                                { propsView ? props?.map((item,i) => 
                                    <PropsRowPage key={ i } data={ item } form={ form } type={ settingsId ? "Edit" : "Add" } setCheckboxValue={ setCheckboxValue } setFormObject={ setFormObject } editType={ editType } /> 
                                ) : undefined }
                            </Col>
                        </Row>
                    </Form>
                </Card> }
        </>
    );
};

export default SettingsAddPage;
