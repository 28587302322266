import { EnvironmentOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Image, Row, Switch, Tabs, Typography, message } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { userActivationContinueUrl, userActivationSMSUrl, userConfirmContinueUrl, userContinueUrl } from "services";
import Tags from "./Tags";
import { activateItems, tableItems } from "../Constant";
import { EmptyMessage, Spinner } from "components";
import ErrorMessage from "components/common/ErrorMessage";
import {  delete_, notificationError, put } from "helper";
import { useUser } from "helper/Hook";
import ProductDetails from "routes/catalog/product-stock/edit/EditView/ProductDetails";

const { Title } = Typography;

const Page = () => {
    const { id: userId } = useParams();
    const { data,error,mutateData } = useUser(userId);
    const [activeTab, setActiveTab] = useState("item-1");
    const isLoading=!data && !error;

    const [isLoadingSwitch, setIsLoadingSwitch] = useState(false);

    const onChangeTab = ( activeKey ) => setActiveTab( activeKey );
    
    const activateMailClick = async () => {
        const { success } = await put(`${userActivationContinueUrl}${parseInt(userId)}`);
        if ( success ) message.success("Aktivasyon maili gönderildi!");
    };
    const activateSMSClick = async () => {
        const { success } = await put(`${userActivationSMSUrl}${parseInt(userId)}`);
        if ( success ) message.success("Aktivasyon SMS'i gönderildi!");
    };

    const confirmClick = async () => {
        const { success } = await put(`${userConfirmContinueUrl}${parseInt(userId)}`);
        if ( success ) message.success("Kullanıcı onaylandı!");
        mutateData();
    };

    const onChange = async () => {
        setIsLoadingSwitch(true);
        const { success, message } = await delete_(`${userContinueUrl}${parseInt(userId)}`);
        if(!success){
            notificationError( message);
            return;
        }
        setTimeout(()=>{ 
            setIsLoadingSwitch(false);
        },500);
    };

    if (error) return (<ErrorMessage/>  );
    if (isLoading) return <Spinner />;
    if(!isLoading && data?.result?.length<1)return <EmptyMessage/>;

    const {
        result: {
            full_name,
            id,
            email,
            is_confirmed,
            image,
            is_online,
            is_new,
            source,
            is_admin,
            company_id,
            active
        }
    } = data || {};

    const newTableItems=(is_admin || !company_id)? tableItems?.filter((item)=>item?.label!=="Sepet"):tableItems;

    return (
        <>
            <Card >
                <Row align="space-between">
                    <Col>
                        <Title level={ 5 }>
                            <UserOutlined className="mr-3" />{ full_name } 
                            <EnvironmentOutlined className="mr-3 ml-20" />{ id } 
                            <MailOutlined className="mr-3 ml-20" /> { email } 
                        </Title>
                    </Col>
                    <Col>
                        <Row className="ml-15">
                            <Switch
                                checkedChildren={ "Aktif" }
                                unCheckedChildren={ "Pasif" }
                                defaultChecked={ active ? true : false }
                                onChange={ onChange }
                                disabled={ !!isLoadingSwitch }
                                className="mr-5"
                            />
                            <Tags user={ { is_confirmed, is_new, is_online, source } } />
                        </Row>
                        <Row gutter={ 24 } className="mt-5">
                            <Col span={ 12 }>
                                <Button type="primary" onClick={ activateMailClick }>Mail İle Aktivasyon</Button>
                            </Col>
                            <Col span={ 12 }>
                                <Button type="primary" onClick={ activateSMSClick }>Sms İle Aktivasyon</Button>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            { is_confirmed !== true ?
                                <Col span={ 8 }>
                                    <Button type="primary" onClick={ confirmClick }>Onayla</Button>
                                </Col> : 
                                undefined 
                            }
                        </Row>
                    </Col>
                </Row>
                <Row gutter={ [24,24] } align={ "middle" }>
                    <Col>
                        <Image
                            src={ image }                                    
                            alt="Kullanıcı"
                            preview={ false }
                            height={ 112 }
                            style={ { maxWidth: "148px" } }
                        />                            
                    </Col>          
                    <ProductDetails/>                    
                </Row>
                <Divider></Divider>
                <Row style={ { marginBottom:-42 } }>
                    <Tabs
                        items={ newTableItems }
                        activeKey={ activeTab }
                        onChange={ onChangeTab }
                        tabPosition={ "top" }
                    />
                </Row>
            </Card>
            <br />      
            { activateItems[ activeTab ] }
        </>
    );
};

export default Page;
