import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    message,
    notification
} from "antd";
import { userIcon } from "assets/media/index";
import React from "react";
import { useParams } from "react-router-dom";
import { put } from "helper";
import { useUser } from "helper/Hook";

const UserInfo = () => {
    const { id: userId } = useParams();
    const { data, mutateData } = useUser(userId);
    const { result: user } = data;
    const [form] = Form.useForm();

    const handleSubmit = async (values) => {
        const { first_name,last_name,id,email } = user;
        const newValues = {
            email: email,
            first_name: values.first_name ? values.first_name : first_name,
            last_name: values.last_name ? values.last_name : last_name,
            user_id: id
        };
        const { success, message: msg } = await put(
            `user/${user.id}`,
            newValues
        );
        if (!success) {
            notification.error({
                description: msg,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        message.success("Bilgiler güncellendi!");
        mutateData();
    };

    return (
        <>
            <Card className="title">
                <p className="title-p">Kullanıcı Bilgileri</p>
            </Card>
            <Card className="user-info-card-content">
                <Row gutter={ 20 }>
                    <Col span={ 8 } push={ 2 } className="user-info-card-avatar-p">
                        Avatar
                    </Col>
                    <Col span={ 12 } className="user-info-card-avatar">
                        <img
                            src={ userIcon }
                            className="user-info-card-avatar-img"
                            alt=""
                        />
                    </Col>
                </Row>
                <Form
                    className="user-info-card-form"
                    name="basic"
                    form={ form }
                    labelCol={ {
                        // xs: { span: 5,
                        //     pull:2 },
                        // sm: { span: 5,
                        //     pull:2 },
                        // md: { span: 5,
                        //     pull:2 },
                        lg: { pull:0,
                            span: 5 },
                        xl: { pull:1,
                            span: 5 },
                        xxl: { pull:2,
                            span: 5 }
                    } }
                    wrapperCol={ {
                        push: 2,
                        span: 12
                    } }
                    initialValues={ {
                        ["first_name"]: user?.first_name,
                        ["last_name"]: user?.last_name
                    } }
                    onFinish={ handleSubmit }
                    autoComplete="off"
                >
                    <Form.Item 
                        label="Ad" 
                        name="first_name" 
                        rules={ [
                            {
                                message:"Lütfen adınızı giriniz!",
                                whitespace: true
                            },
                            {
                                max: 20,
                                message:"Adınız en az 3 en fazla 20 karakter olmalıdır!",
                                min: 3
                            },
                            {
                                message: "Lütfen sadece harf giriniz!",
                                pattern: "^[A-Za-za-züÜçÇıİşŞğĞöÖ s*]+$"
                            }
                        ] }>
                        <Input
                            className="user-info-card-form-input"
                        />
                    </Form.Item>
                    <Form.Item 
                        label="Soyad" 
                        name="last_name"
                        rules={ [
                            {
                                message:"Lütfen soyadınızı giriniz!",
                                whitespace: true
                            },
                            {
                                max: 20,
                                message:"Soyadınız en az 2 en fazla 20 karakter olmalıdır!",
                                min: 2
                            },
                            {
                                message: "Lütfen sadece harf giriniz!",
                                pattern: "^[A-Za-züÜçÇıİşŞğĞöÖ s*]+$"
                            }
                        ] }>
                        <Input
                            className="user-info-card-form-input"
                        />
                    </Form.Item>
                </Form>
            </Card>
            <Card className="user-info-card-footer">
                <Row gutter={ 24 }>
                    <Col 
                        xs={ {
                            push:13,
                            span: 1
                        } }
                        sm={ {
                            push:14,
                            span: 2
                        } }
                        md={ {
                            push:16,
                            span: 4
                        } }
                        lg={ {
                            push:18,
                            span: 6
                        } }
                        xl={ {
                            push:18,
                            span: 8
                        } }
                        xxl={ {
                            push:18,
                            span: 10
                        } } >
                        <Button
                            className="user-info-card-footer-btn"
                            type="primary"
                            onClick={ () => {
                                form.submit();
                            } }
                        >
                            Değişiklikleri Kaydet
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default UserInfo;
