import { Button, Form, Input } from "antd";

export const columnAdd = ( productDetails, onTableFormChangeHandler, deleteHandler )=> {	
    const columns = [
        {
            dataIndex: "barcode",
            render: (_, record, i) => {
                return (
                    <Form.Item 
                        name={ `barcode_${i}` }
                        rules={ [
                            { message: "Lütfen ürün barkodu giriniz!", required: true },
                            { message:"Lütfen rakam giriniz!", pattern:/^\d/g }
                        ] }                        
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("barcode", record) }
                            value={ productDetails[record.key]?.barcode }
                        />
                    </Form.Item>
                );
            },
            title: "Barkod",
            width: "12%"
        },
        {
            dataIndex: "sales_price",
            render: (_, record, i) => {
                return (
                    <Form.Item
                        name={ `sales_price_${i}` }
                        rules={ [ { message: "Lütfen fiyat giriniz", required: true },
                            { message: "Lütfen rakam giriniz", pattern: /^\d/g } ] }                    
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("sales_price", record) }
                            value={ productDetails[record.key]?.sales_price }
                        />
                    </Form.Item>
                );
            },
            title: "Fiyat",
            width: "9%"
        },
        {
            dataIndex: "stock",
            render: (_, record, i) => {
                return (
                    <Form.Item
                        name={ `stock_${i}` }
                        rules={ [
                            { message: "Lütfen stok sayısını giriniz!", required: true },
                            { message: "Lütfen rakam giriniz", pattern: /^\d/g }
                        ] }
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("stock", record) }
                            value={ productDetails[record.key]?.stock }
                        />
                    </Form.Item>
                );
            },
            title: "Stok",
            width: "9%"
        },
        { 
            dataIndex: "value_added_tax",
            render: (_, record, i) => {
                return (
                    <Form.Item
                        name={ `Value_added_tax_${i}` }
                        rules={ [ { message: "Lütfen KDV giriniz!", required: true },
                            { message: "Lütfen rakam giriniz", pattern: /^\d/g } ] }                      
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("value_added_tax", record) }
                            value={ productDetails[record.key]?.value_added_tax }
                        />
                    </Form.Item>
                );
            },
            title: "KDV",
            width: "9%"
        },
        {
            dataIndex: "stock_code",
            render: (_, record, i) => {
                return (
                    <Form.Item
                        name={ `stock_code_${i}` }
                        rules={ [ { message: "Lütfen stok kodunu giriniz!", required: true }
                        ] }
                    >
                        <Input
                            onChange={ onTableFormChangeHandler("stock_code", record) }
                            value={ productDetails[record.key]?.stock_code }
                        />
                    </Form.Item>
                );
            },
            title: "Stok Kodu",
            width: "12%"
        },
        {
            key: "actions",
            render: (_, record) => {
                return (
                    <Button typeof="primary" onClick={ () => deleteHandler( record ) }>
                        Sil
                    </Button>
                );
            },
            title: "İşlem",
            width: "5%"
        }
    ];
    return columns;
};

export const cartesianHelper = (args) => {
    var r = [],
        max = args.length - 1;
    const helper = (arr, i) => {
        for (let j = 0, l = args[i]?.length; j < l; j++) {
            let a = arr.slice(0); // clone arr
            a.push(args[i][j]);
            if (i === max) r.push(a);
            else helper(a, i + 1);
        }
    };
    helper([], 0);
    return r;
};
