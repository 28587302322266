import { Form, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { brandListUrl } from "services";
import { formatResult, post } from "helper";

const Brands = () => {
    const [ brandList, setBrandList ] = useState( undefined );

    const getBrandList = async  ( ) => {
        try {
            const { result, success, message } = await post(brandListUrl, {
                pageSize: 1000,
                filter: { active: 1 }
            });
            if (!success) {
                notification.error({
                    message: "Bilgilendirme",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setBrandList(formatResult(result));  
        } catch ( e ) {
            console.log(e.toString());   
        }
    };
	
    useEffect( () => {
        getBrandList();
    }, [] );

    return (
        <Form.Item
            label="Marka"
            name="brand"
            rules={ [{ required: true, message: "Lütfen marka seçiniz!" }] }
        >
            <Select
                placeholder="Lütfen marka seçiniz!"
                allowClear
                showSearch
                filterOption={ (inputValue, option) =>
                    option.children?.toLowerCase().includes(inputValue.toLowerCase())
                }     
            >
                { brandList?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                )) }
            </Select>
        </Form.Item>
    );
};

export default Brands;
