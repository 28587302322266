import { Table } from "antd";
import { useEffect, useMemo } from "react";
import EmptyMessage from "./EmptyMessage";
import ErrorMessage from "./ErrorMessage";
import { useFilter, useLayout } from "context";
import { useTableData } from "context/TableProvider";
import { handleTableChange } from "helper";

const DataTable = ({
    useData,
    columns,
    initialQueryParams = undefined,
    setInitialQueryParams = undefined,
    selection
}) => {
    const { queryParams, setQueryParams } = useFilter();
    const { selectedRows, setSelectedRows } = useTableData();

    const queryParams_ = useMemo(
        () => (initialQueryParams ? initialQueryParams : queryParams),
        [initialQueryParams, queryParams]
    );
    const setQueryParams_ = setInitialQueryParams
        ? setInitialQueryParams
        : setQueryParams;
    const { pageSize, pageNumber } = queryParams_;
    const { data, error, mutate, isValidating, isLoading } =
    useData(queryParams_);
    const { tableDataErrorHandle } = useLayout();

    const rowSelection = {
        onChange: (keys) => setSelectedRows(keys),
        selectedRowKeys: selectedRows,
        type: "checkbox"
    };
    const pagination = {
        current: pageNumber,
        disabled: isValidating || isLoading,
        pageSize: pageSize,
        showLessItems: true,
        showTotal: (total, range) => (
            <span className="text-muted">
                <b>{ total }</b> kayıttan <b>{ range[0] }</b> - <b>{ range[1] }</b> arası
            </span>
        ),
        total: data?.total_count
    };

    useEffect(() => {
        mutate();
    }, [queryParams_]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tableDataErrorHandle(error ? true : false);
    }, [error]);// eslint-disable-line react-hooks/exhaustive-deps

    if (error) return (<ErrorMessage/>  );
    if(data &&  data?.result?.length<1)return <EmptyMessage/>;

    return (
        <Table
            columns={ columns }
            showSorterTooltip={ { title: "Sıralamayı değiştir" } }
            dataSource={ data?.result }
            rowKey="id"
            onChange={ handleTableChange(setQueryParams_) }
            pagination={ pagination }
            rowSelection= { selection ? rowSelection : undefined }
            sortDirections={ ["ascend", "descend", "ascend"] }
            loading={ isLoading  }
        />
    );
};
export default DataTable;
