
import { Card, Col, Divider, Row, Typography } from "antd";
const { Title } = Typography;

export const productPriceTabItems = [
    { key: "item-1", label: "Özet" },
    { key: "item-2", label: "Kategori Fiyatları" },
    { key: "item-3", label: "Firmalar" }
];

export const sumItems = [
    // ["Adı", ["name"]],
    // ["Marka", ["brand", "name"]],
    // ["Kategori", ["category", "name"]],
    // ["Satış Sayısı", ["sold_count"]],
    // ["Satış Adedi", ["sold_quantity"]],
    // ["Favori Sayısı", ["favorite_count"]],
    // ["Puan", ["rating", "score"]],
    // ["Oluşturma Tarihi", ["create_date"]],
    // ["Ürün Kodu",["code"]],
    // ["Kaynak Kodu",["out_ref"]],
    // ["Ürün Tipi",["stock", "type"]],
    // ["Ürün Miktar",["stock", "quantity"]],
    // ["Ürün Birim",["stock", "unit"]]
];

export const statisticalPages = (
    <Row gutter={ [24, 24] } style={ { marginTop: "30px" } }>
        <Col span={ 12 }>
            <Card>
                <Title level={ 5 }>İstatistikler</Title>
                <Divider />
            </Card>
        </Col>
        <Col span={ 12 }>
            <Card>
                <Title level={ 5 }>Görevleri Gözden Geçir</Title>
                <Divider />
            </Card>
        </Col>
        <Col span={ 12 }>
            <Card>
                <Title level={ 5 }>Aktiviteler</Title>
                <Divider />
            </Card>
        </Col>
        <Col span={ 12 }>
            <Card>
                <Title level={ 5 }>En Son Satılan Ürünler</Title>
                <Divider />
            </Card>
        </Col>
    </Row>
);
