import { Button } from "antd";
import { darkIcon, lightIcon } from "assets/media/index";
import React from "react";

const ThemeSelection = ({
    collapsed,
    onChangeDark,
    onChangeLight,
    bgLight,
    colorLight,
    colorDark,
    bgDark,
    icon
}) => {
    return (
        <div className="theme">
            { !collapsed ? (
                <>
                    <Button
                        name="buttonLight"
                        onClick={ onChangeLight }
                        className="theme-uncollapsed-light-btn"
                        style={ {
                            backgroundColor: bgLight,
                            color: colorLight
                        } }
                    >
                        <img
                            src={ lightIcon }
                            alt=""
                            className="theme-uncollapsed-light-icon"
                        />
                        Light
                    </Button>
                    <Button
                        name="buttonDark"
                        // onClick={onChangeDark}
                        className="theme-uncollapsed-dark-btn"
                        style={ {
                            backgroundColor: bgDark,
                            color: colorDark
                        } }
                    >
                        <img
                            src={ darkIcon }
                            alt=""
                            className="theme-uncollapsed-dark-icon"
                        />
                        Dark
                    </Button>
                </>
            ) : (
                <>
                    { icon === lightIcon ? (
                        <Button
                            type="link"
                            name="buttonLight"
                            onClick={ onChangeLight }
                            className="theme-collapsed-light-btn"
                            style={ {
                                backgroundColor: bgLight,
                                color: colorLight
                            } }
                        >
                            <img
                                src={ icon }
                                alt=""
                                className="theme-collapsed-light-icon"
                            />
                        </Button>
                    ) : (
                        <Button
                            type="link"
                            name="buttonDark"
                            onClick={ onChangeDark }
                            className="theme-collapsed-dark-btn"
                            style={ {
                                backgroundColor: bgDark,
                                color: colorDark
                            } }
                        >
                            <img
                                src={ icon }
                                alt=""
                                className="theme-collapsed-dark-icon"
                            />
                        </Button>
                    ) }
                </>
            ) }
        </div>
    );
};

export default ThemeSelection;
