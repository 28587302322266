/* eslint-disable no-restricted-imports */
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { productFeeUpdateUrl } from "services";
import { useData } from "./Helper";
import { useFilter } from "context";
import { notificationError, notificationSuccess, post } from "helper";

const Filter = ({ outRef }) => {
    const { queryParams } = useFilter();
    const { mutate } = useData(queryParams);

    const [loading,setLoading] = useState(false);

    const syncOrderList = async () => {
        setLoading(true);
        const { success, message } = await post(`${productFeeUpdateUrl}${outRef}`,{
            out_ref:outRef
        });
        if (!success) {
            setLoading(false);
            notificationError(message);
            return;
        }
        setLoading(false);
        notificationSuccess("Güncelleme başarıyla tamamlandı");
    };

    useEffect(()=>{
        mutate();
    },[queryParams]);

    return (
        <Form layout="vertical" requiredMark={ "Required" }>
            <Row align="space-between" className="mb-20">
                <Col />
                <Col>
                    <Space>
                        { outRef ? <Button
                            shape="round"
                            type="dashed"
                            onClick={ () => syncOrderList() }
                            block
                            icon={ <CloudDownloadOutlined /> }
                            loading={ loading }
                        >
                            Güncelle
                        </Button> : undefined 
                        }
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
export default Filter;
