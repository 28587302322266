import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "context";

const ProtectedLink = ({ children, permissionKey, path }) => {
    const { user } =useAuth();
    const { is_admin = false, permissions = [] } = user;

    const renderingLink =is_admin || permissions?.indexOf(permissionKey) !== -1 ? (
        <Link to={ path } >{ children }</Link>
    ) : (
        children
    );
    return <>{ renderingLink }</>;
};

export default ProtectedLink;
