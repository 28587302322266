import { Form, Select, Spin } from "antd";
import React from "react";

const Company = ({ loading, companies }) => {
    return (
        <Form.Item
            label="Firma"
            name="company_id"
            rules={ [
                {
                    required: true,
                    message: "Lütfen firma seçiniz!"
                }
            ] }
        >
            { loading ? (
                <Spin spinning={ loading }>
                    <Select />
                </Spin>
            ) : (
                <Select
                    placeholder="Lütfen firma seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                >
                    { companies?.map((option) => (
                        <Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            ) }
        </Form.Item>
    );
};

export default Company;
