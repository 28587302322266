import { Form, Input } from "antd";
import React from "react";
import InputMask from "react-input-mask";

const DatePick = ( { onCardExpiryDateChange, ...rest } ) => {
    const onChangeHandler = ( e ) => { 
        if ( !e?.target.value.includes("_") ) {
            const newDate = e.target.value.split( "/" );
            onCardExpiryDateChange({  card_expire_month: newDate[ 0 ], card_expire_year:newDate[ 1 ] });
        }
    };

    const beforeMaskedStateChange =( { nextState } )=> {
        let { value } = nextState;
        if ((value[0]+value[1])>12) {
            value = "12";
        }
        if ((value[0]+value[1])< 1) {
            value = "01";
        }
        if ((value[3]+value[4])<23) {
            value = (value[0]+value[1]+"/")+"25";
        }
        return {
            ...nextState,
            value
        };
    };

    return (
        <Form.Item
            name="date"
            rules={ [
                {
                    required: true,
                    message: "Lütfen kartınızın son kullanma tarihini giriniz!"
                },
                {
                    validator: async ( _, value ) => {
                        if ( value.includes("_") ) return Promise.reject( "Lütfen son kullanma tarihini giriniz!" );
                        return Promise.resolve();
                    }
                }
            ] }
        >
            <InputMask { ...rest }
                mask="99/99" 
                beforeMaskedStateChange={ beforeMaskedStateChange }
                onChange={ onChangeHandler }
                placeholder="12/80">
                <Input prefix={ <i className="flaticon2-calendar-2 mr-7"></i> } />
            </InputMask>
        </Form.Item>
    );
};

export default DatePick;
