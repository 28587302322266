export const Columns = [
    {
        title: "Firma",
        render: (_, record) => (
            <div className="text-muted">{ record?.company_name }</div>
        )
    },
    {
        title: "Perakende Fiyatı",
        render: (_, record) => (
            <div className="text-muted">{ record?.retail }</div>
        )
    },
    {
        title: "Toptan Liste Fiyatı",
        render: (_, record) => (
            <div className="text-muted">{ record?.wholesale }</div>
        )
    },
    {
        title: "Alış Fiyatı",
        render: (_, record) => (
            <div className="text-muted">{ record?.wholesale_discounted }</div>
        )
    }
];
