export const priceDataFormatter = (data) => {
    const formatteddata=data?.map((category) => {
        const { id,category_id,name, category_name, price1,price2,price3,price4,price5 }=category;
        return ({
            category_id: id || category_id,
            key: id?.toString() || category_id?.toString(),
            name:name || category_name,
            price1: price1 || 0,
            price2: price2 || 0,
            price3: price3 || 0,
            price4: price4 || 0,
            price5: price5 || 0
        });
    })?.sort((a, b) => a.name.localeCompare(b.name));

    return formatteddata;
};
