import { Card, Descriptions, Row, Typography } from "antd";
import React from "react";

const ShippingAddress = ( { data } ) =>  (    
    <Card>   
        <Descriptions
            title={
                <Row justify="space-between">
                    <Typography.Text>Sevk Adres Bilgileri</Typography.Text>
                    <> { data?.addressType }</>
                </Row>  }
            bordered
            column={ 1 }
            size={ "small" }
        >
            { data?.newData?.map((tag, i) => (
                <Descriptions.Item label={ tag[0] } key={ i }>
                    { [tag[1] || null] }
                </Descriptions.Item>
            )) }
        </Descriptions>
    </Card>
);

export default ShippingAddress;
