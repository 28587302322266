import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { comparingInitialFilter, initialFilter } from "../constant";
const FilterContext = createContext();

export function useFilter() {
    return useContext(FilterContext);
}

export function FilterProvider({ children }) {
    const location = useLocation();
    const path = location.pathname.split("/");
    const url = path[1]+path[2];
    const [queryParams, setQueryParamS] = useState(initialFilter);
    const [ searchText, setSearchTexT ] = useState( "");

    const clone = cloneDeep(queryParams);
    delete clone.pageNumber;
    const isDefaultParams = isEqual( clone, comparingInitialFilter );

    const setQueryParams=(object)=>setQueryParamS(object);
    const setSearchText=(text)=> setSearchTexT(text);
    
    const value = {  isDefaultParams, queryParams, setQueryParams,  searchText, setSearchText };

    useEffect(()=>{
        setQueryParamS(initialFilter);
        setSearchTexT("");
    },[url]);

    return (
        <FilterContext.Provider value={ value }>{ children }</FilterContext.Provider>
    );
}


