import { ArrowLeftOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Row,
    Space,
    Table,
    Typography
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { roleAddUrl, roleUrl, templateListUrl } from "services";
import CompanyList from "./editview/CompanyList";
import OriginList from "./editview/OriginList";
import RoleInfo from "./editview/RoleInfo";
import RoleList from "./editview/RoleList";
import {
    dataFormatter,
    getAllowedIds,
    setIsAllowedValues,
    useMainColumnsHelper
} from "./Helper";
import { Breadcrumbs } from "../Constant";
import { EmptyMessage } from "components";
import ErrorMessage from "components/common/ErrorMessage";
import { PageTitle, useAuth } from "context";
import {
    notificationError,
    notificationSuccess,
    post,
    put,
    usePermission
} from "helper";

const EditView = () => {
    const { id: roleId } = useParams();
    const [newRoleId, setNewRoleId] = useState(roleId ? roleId : undefined);
    const { data, error, mutateData } = usePermission(newRoleId);
    const navigate = useNavigate();
    const {
        user: { is_admin }
    } = useAuth();
    const [form] = Form.useForm();
    const { pathname } = useLocation();

    const [newData, setNewData] = useState([]);
    const { mainColumns } = useMainColumnsHelper({ newData, setNewData });
    const [breadcrumbs, setBreadcrumbs] = useState();
    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [roleType, setRoleType] = useState(undefined);
    const [roleList, setRoleList] = useState([]);
    const [editRolName, setEditRolName] = useState(undefined);
    const isLoading = !data && !error;

    const handleSubmit = async (values) => {
        const allowedIds = getAllowedIds(newData);
        const newValues = { ...values, permissions: allowedIds, type: roleType };
        const { success, message } = roleId
            ? await put(`${roleUrl}/${newRoleId}`, newValues)
            : pathname?.includes("add")
                ? await post(roleAddUrl, newValues)
                : null;
        if (!success) {
            notificationError(message);
            return;
        }
        mutateData();
        notificationSuccess(
            roleId ? "Yetkiler Başarıyla Değiştirildi" : "Yetkiler Başarıyla Eklendi"
        );
    };

    const handleBreadcrumbs = useCallback(() => {
        const name = roleId ? form.getFieldValue("name") : "Yeni Rol";
        const breadcrumbs = [
            ...Breadcrumbs,
            {
                path: `/membership/role/${name}`,
                breadcrumbName: name
            }
        ];
        setBreadcrumbs(breadcrumbs);
    }, []);

    const onSelectionAllCheckbox = (e) => {
        setCheckboxSelection(e.target.checked);
        if (e.target.checked) {
            const allOfAllowed = setIsAllowedValues(newData, true);
            setNewData(allOfAllowed);
        } else {
            const allOfNotAllowed = setIsAllowedValues(newData, false);
            setNewData(allOfNotAllowed);
        }
    };
    const onSelectedRoleHandler = (selectedValue, roleType) => {
        setRoleType(roleType);
        setNewRoleId(selectedValue);
    };

    const getRoleList = useCallback(async () => {
        const { result, success, message } = await post(templateListUrl, {});
        if (!success) {
            notificationError(message);
            return;
        }
        const newResult = result
            .map((role) => ({ label: role.name, value: role.id, type: role?.type }))
            .sort((a, b) => a.label.localeCompare(b.label));
        setRoleList(newResult);
    }, []);

    useEffect(() => {
        getRoleList();
    }, []);

    useEffect(() => {
        handleBreadcrumbs();
        if (data) {
            setNewData(dataFormatter(data?.result));
        }
    }, [data?.result, newRoleId]);

    if (error) return <ErrorMessage error={ error } />;
    if (data?.result?.length < 1) return <EmptyMessage />;

    return (
        <>
            <PageTitle breadcrumbs={ breadcrumbs } />
            <Form
                layout="vertical"
                autoComplete="off"
                onFinish={ handleSubmit }
                mode="multiple"
                form={ form }
            >
                <Space className="flex-btw" value={ "large" }>
                    <Typography.Title strong level={ 4 }>
                        { roleId ? editRolName : "Yeni Rol Ekle" }
                    </Typography.Title>
                    <Space>
                        <Button
                            icon={ <ArrowLeftOutlined /> }
                            onClick={ () => navigate("/membership/role") }
                        >
                            Geri
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Kaydet
                        </Button>
                    </Space>
                </Space>
                <Card>
                    <Row justify="center" align="middle">
                        <Col span={ 15 }>
                            { is_admin && <OriginList roleId={ roleId } /> }
                            { !roleId && (
                                <RoleList
                                    onSelectedRoleHandler={ onSelectedRoleHandler }
                                    roleList={ roleList }
                                />
                            ) }
                            <CompanyList />
                            { (newRoleId || roleId) && (
                                <RoleInfo
                                    roleId={ newRoleId }
                                    form={ form }
                                    setRoleType={ setRoleType }
                                    roleList={ roleList }
                                    isEdit={ !!roleId }
                                    editRolName={ editRolName }
                                    setEditRolName={ (name) => setEditRolName(name) }
                                />
                            ) }
                            { data && (
                                <>
                                    <Divider orientation="left">
                                        { roleId ? "Yetkileri Düzenle" : "Yetki Ekle" }
                                    </Divider>
                                    <Row justify="space-between" className="mb-10">
                                        <Col>
                                            <Checkbox
                                                onChange={ onSelectionAllCheckbox }
                                                checked={ checkboxSelection }
                                            >
                                                { checkboxSelection ? "Hiçbirini Seçme" : "Hepsini Seç" }
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Table
                                        columns={ mainColumns }
                                        dataSource={ newData || [] }
                                        expandable={ {
                                            rowExpandable: (record) => record.subcategory?.length > 1
                                        } }
                                        pagination={ false }
                                        loading={ isLoading }
                                    />
                                </>
                            ) }
                        </Col>
                    </Row>
                </Card>
            </Form>
        </>
    );
};

export default EditView;
