import { Link, Route, Routes } from "react-router-dom";
import { addressUrl } from "services";
import { AddView } from "routes/account/address/add";

const Columns = [
    {
        render: ( _, record ) => (
            <div>
                <Link to={ `${record.id}/edit` } 
                    className="fw-600">{ record?.title }</Link>
                <div className="text-muted">{ record?.name }</div>
                <div className="text-muted">{ record?.address1 }</div>
                <div className="text-muted">{ record?.address2 }</div>
            </div>
        ),
        title: "Adres"
    },
    {
        dataIndex: "city",
        render: ( city ) => city.name,
        title: "İl"
    },
    {
        dataIndex: "county",
        render: ( county ) => county.name,
        title: "İlçe"
    }
];

const Path = "/membership/user";
const Url = addressUrl;
const ListUrl = `${Url}/list`;

const Router = ( { onHide } ) => (
    <Routes>
        <Route path=":id/edit" element={ <AddView onHide={ onHide } /> } />
    </Routes>
);

export { Path, Url, ListUrl, Columns, Router };
