import { Card, Descriptions } from "antd";

const CustomerDetails = ( { data } ) =>  (
    <Card style={ { width: "100%" } }>
        <Descriptions
            title="Müşteri Bilgileri"
            bordered
            column={ 1 }
            size={ "small" }
        >
            { data?.map( ( tag, i ) =>
                <Descriptions.Item key={ i } label={ tag[ 0 ] }>{ tag[ 1 ] || null }
                </Descriptions.Item> ) }
        </Descriptions>
    </Card>
);

export default CustomerDetails;
