import { CreditCardOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { cardNumberFormatter } from "../Helper";

const CardNumberInput = ({
    value = "",
    onChange
}) => {
    const triggerChange = (changedValue) => onChange?.(changedValue);

    const onNumberChange = async ( event ) => {
        const cardInput = event?.target?.value.replace( /\D/g, "" ).slice( 0, 16 );
        triggerChange(cardInput);
    };

    return (
        <Input 
           
            type="text"
            value={ cardNumberFormatter(value) }
            onChange={ onNumberChange }
            placeholder="4242 4242 4242 4242"
            prefix={ <CreditCardOutlined  className="mr-7"/> }
        />
    );
};

export default CardNumberInput;
