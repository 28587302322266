import { Button, Card, Result, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const WarningResult = ( { message } ) => {
    const navigate= useNavigate();
    const newMessage=message ? message : "Hata: Bir hata meydana geldi!";
    return (
        <Card>
            <Row justify="center">
                <Result
                    status="warning"
                    title={ newMessage }
                    extra={
                        <Button type="primary" onClick={ ()=>navigate("/bufipay/virtual-posaccounts") }>
                            Pos Sayfasına Dön
                        </Button>
                    }
                />
            </Row> 
        </Card> 
    );
};

export default WarningResult;
