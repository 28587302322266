import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Image, Row, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
const { Title } = Typography;

export const tableItems = [
    { key: "item-1", label: "Özet" },
    { key: "item-2", label: "Düzenle" },
    { key: "item-3", label: "Resimler" },
    { key: "item-4", label: "Fiyat Listesi" },
    { key: "item-5", label: "Varyantlar" },
    { key:"item-6", label: "Siparişler" }
];

export const tableItemsLots = [
    { key: "item-1", label: "Özet" },
    { key: "item-2", label: "Düzenle" },
    { key: "item-3", label: "Resimler" },
    { key: "item-4", label: "Fiyat Listesi" },
    { key: "item-5", label: "Varyantlar" },
    { key:"item-6", label: "Siparişler" },
    { key:"item-7", label: "Lot Detayları" }
];

export const columnsLots = [
    {
        dataIndex: "name",
        key: "name",
        title: "Name"
    },
    {
        dataIndex: "quantity",
        key: "quantity",
        title: "Quantity"
    }
];

export const sumItems = [
    ["Adı", ["name"]],
    ["Marka", ["brand", "name"]],
    ["Kategori", ["category", "name"]],
    ["Satış Sayısı", ["sold_count"]],
    ["Satış Adedi", ["sold_quantity"]],
    ["Favori Sayısı", ["favorite_count"]],
    ["Puan", ["rating", "score"]],
    ["Oluşturma Tarihi", ["create_date"]],
    ["Ürün Kodu",["code"]],
    ["Kaynak Kodu",["out_ref"]],
    ["Ürün Tipi",["stock", "type"]],
    ["Ürün Miktar",["stock", "quantity"]],
    ["Ürün Birim",["stock", "unit"]]
];

const handleStock = (record) => {
    const { name, attributes_map, stock, on_sale } = record;
    return (
        <>
            <Link
                to={ `/catalog/product-stock/${record?.id}/edit` }
                className="text-dark-75 font-weight-bold text-hover-info mb-1"
            >
                { name }
            </Link>
            <div className={ "font-weight-light text-muted" }>
                { Object.keys(attributes_map).map((key, i) => (
                    <span key={ i }>
                        <span>{ key } : </span>
                        <span>{ attributes_map[key] } </span>
                    </span>
                )) }
            </div>
            <div className="font-weight-light font-size-xs">
                Stok { stock.quantity } { stock.type } ( Birim: { stock.unit } { stock.type } )
            </div>
            { /* <div className="font-weight-light font-size-xs">
                Oluşturma Tarihi: { record?.create_date }
            </div>  */ }
            <div className="font-weight-light font-size-xs mt-3">
                <span className={ on_sale === true ? "on-sale" : "not-on-sale" }>
                    { on_sale === true ? "Satışta" : "Satışta Değil" }
                </span>
            </div>
        </>
    );
};

export const Columns = [
    {
        dataIndex: "id",
        defaultSortOrder: "descend",
        sorter: (a, b) => a?.id - b?.id,
        title: "ID",
        width: 40
    },
    {
        render: (_, record) => (
            <Link to={ record?.id }>
                <span className="symbol symbol-50px">
                    <Image
                        className="product img-thumbnail"
                        preview={ false }
                        src={ record?.image }
                    />
                </span>
            </Link>
        ),
        title: "Ü. Resmi",
        width: 80
    },
    {
        render: (_, record) => handleStock(record),
        title: "Ürün"
    },
    {
        dataIndex: "stock",
        render: (stock) => (
            <Tag color={ stock?.in_stock === 0 ? "volcano" : "green" }>
                { stock?.in_stock === 0 ? "Tükendi" : "Stokta" }
            </Tag>
        ),
        title: "Stok"
    }
];

export const boxArray = [
    {
        icon: <ArrowUpOutlined style={ { color: "green" } } />,
        text: "Earnings",
        value: "4500$"
    },
    {
        icon: <ArrowDownOutlined style={ { color: "red" } } />,
        text: "Projects",
        value: "75"
    },
    {
        icon: <ArrowUpOutlined style={ { color: "green" } } />,
        text: "SuccessRate",
        value: "60%"
    }
];

export const statisticalPages = (
    <Row gutter={ [24, 24] } style={ { marginTop: "30px" } }>
        <Col span={ 12 }>
            <Card>
                <Title level={ 5 }>İstatistikler</Title>
                <Divider />
            </Card>
        </Col>
        <Col span={ 12 }>
            <Card>
                <Title level={ 5 }>Görevleri Gözden Geçir</Title>
                <Divider />
            </Card>
        </Col>
        <Col span={ 12 }>
            <Card>
                <Title level={ 5 }>Aktiviteler</Title>
                <Divider />
            </Card>
        </Col>
        <Col span={ 12 }>
            <Card>
                <Title level={ 5 }>En Son Satılan Ürünler</Title>
                <Divider />
            </Card>
        </Col>
    </Row>
);
