import { Image, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { favoriteUrl, listUrl } from "services";
import { ProtectedLink } from "components";

const handleStock = (record) => {
    const { name, attributes_map, stock, on_sale } = record;
    return (
        <>
            <ProtectedLink permissionKey={ "product.edit" } path={ `${record?.id}/edit` }>
                <Typography.Text className="text-dark-75 font-weight-bold text-hover-info mb-1">
                    { name }
                </Typography.Text>
            </ProtectedLink>
            <div className={ "font-weight-light text-muted" }>
                {
                    Object.keys(attributes_map).map((key, i) =>
                        <span key={ i }>
                            <span>{ key } : </span><span>{ attributes_map[key] } </span>
                        </span>
                    )
                }
            </div>
            <div className="font-weight-light font-size-xs">
                Stok { stock.quantity } { stock.type } ( Birim: { stock.unit } Metrekare )
            </div>
            <div className="font-weight-light font-size-xs">
                Oluşturma Tarihi: { record.create_date }
            </div>
            <div className="font-weight-light font-size-xs mt-3">
                <span className={ on_sale === true ? "on-sale" : "not-on-sale" }>
                    { on_sale === true ? "Satışta" : "Satışta Değil" }
                </span>
            </div>
        </>
    );
};

const Columns = [
    {
        render: (_, record) => (
            <Link to={ record?.id }>
                <span className="symbol symbol-50px">
                    <Image
                        className="product img-thumbnail"
                        src={ record.image }
                    />
                </span>
            </Link>
        ),
        width: 80
    },
    {
        render: (_, record) => handleStock(record),
        title: "Ürün"
    },
    {
        dataIndex: "brand",
        render: (brand) => brand.name,
        title: "Marka"
    },
    {
        dataIndex: "stock",
        render: (stock) => ( <Tag color={ stock.in_stock === 0 ? "volcano" : "green" }>
            { stock.in_stock === 0 ? "Tükendi": "Stokta" }
        </Tag>
        ),
        title: "Stok"
    }
];

const Url = favoriteUrl;
const ListUrl = `${Url}${listUrl}`;

export { Url, ListUrl, Columns };
