/* eslint-disable jsx-a11y/alt-text */
import { Carousel, Col, Layout, Row, Space } from "antd";
import { bufiIcon, helpSupportIcon } from "assets/media";
import { Outlet } from "react-router-dom";
import { Footer } from "components";

const { Content } = Layout;
const { Header } = Layout;
const contentStyle = {
    height: "88Vh",
    color: "#fff",
    lineHeight: "360px",
    textAlign: "center",
    background: "#364d79",
    marginBottom: 0
};

const AuthWrapper = () => (
    <Layout className="authwrapper_container">
        <Header className="header header-fixed">
            <Row justify="space-between">
                <Col>
                    <img src={ bufiIcon } />
                </Col>
                <Col>
                    <Space className="pointer userSelectNone">
                        <nav>
                            <img src={ helpSupportIcon } className="header-img" />
                            Yardım & Destek
                        </nav>
                    </Space>
                </Col>
            </Row>
        </Header>
        <Content className="mt-64">
            <Row justify="center" align="middle" className="authwrapper_row">
                <Col sm={ 12 } xs={ 0 }>
                    <Col offset={ 6 } xs={ 24 } sm={ 18 }>
                        <Carousel autoplay>
                            <div>
                                <h3 style={ contentStyle }>1</h3>
                            </div>
                            <div>
                                <h3 style={ contentStyle }>2</h3>
                            </div>
                            <div>
                                <h3 style={ contentStyle }>3</h3>
                            </div>
                            <div>
                                <h3 style={ contentStyle }>4</h3>
                            </div>
                        </Carousel>
                    </Col>
                </Col>
                <Col xs={ 24 } sm={ 12 }>
                    <Row justify="center" align="middle">
                        <Col xs={ 24 } xxl={ 10 } xl={ 14 } lg={ 16 } md={ 18 } sm={ 22 } align="middle">
                            <Row justify="center"><div className="bufi_login_logo"></div>
                                <h2 className="loginyonetimpaneli">Yönetim Paneli</h2></Row>
                            <Outlet />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Content>
        <Footer className="authwrapper_footer" />
    </Layout>
);

export default AuthWrapper;
