import React, { useEffect } from "react";
import { Router } from "./Constant";
import Filter from "./Filter";
import { useData, useTable } from "./Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const Page = ({ companyId }) => {
    const { queryParams, setQueryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns, onHide } = useTable(mutate);

    useEffect(() => {
        setQueryParams({
            pageNumber: 1,
            pageSize: 10,
            sortField: "id",
            sortOrder: "desc",
            filter: { company_id : parseInt(companyId) }
        });
    }, []);
    
    return (
        <PageContent 
            tableData={ useData } 
            tableColumns={ columns } 
            dataFilter={ <Filter /> }
            pageRoutes={ <Router onHide={ onHide } /> }
        />
    );
};

export default Page;

