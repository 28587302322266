import { Card } from "antd";
import { DataTable } from "components";
import { PageTitle, useLayout } from "context";

const PageContent = ( { breadcrumbs, tableData, tableColumns, dataFilter, pageRoutes, selection=true } ) => {
    const { tableDataError } = useLayout();
    return (
        <>
            { breadcrumbs && 
                <PageTitle breadcrumbs={ breadcrumbs }>
                    { breadcrumbs[breadcrumbs.length-1]?.breadcrumbName }
                </PageTitle> }
            <Card bordered={ false }>
                { !tableDataError && dataFilter }
                { <DataTable selection={ selection } useData={ tableData } columns={ tableColumns } /> }
            </Card> 
            { pageRoutes }
        </>
    );
};

export default PageContent;
