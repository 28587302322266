const Breadcrumbs = [
    {
        breadcrumbName: "Entegrasyonlar",
        path: "/integration"
    },
    {
        breadcrumbName: "Netgsm",
        path: "/netgsm"
    }
];
export { Breadcrumbs };
