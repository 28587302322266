import { MemberBusinessPage } from "./MemberBusiness";

export const tableItems = [
    { key: "item-1", label: "Üye İşyeri Ayarları" }
];

export const activateItems =
{
    "item-1":<MemberBusinessPage />
};
