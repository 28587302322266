import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    LoadingOutlined
} from "@ant-design/icons";
import { Button, Card, Form, Input, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../common/CardHeader";
import { post } from "helper";

const ResetPasswordPage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { token } = useParams();

    const [buttonText, setButtonText] = useState("Kaydet");
    const [loading, setLoading] = useState(false);
    const [verifying, setVerifying] = useState(false);

    const onFormSubmit = () => {
        const query = {
            password: form.getFieldValue("password"),
            token
        };
        setLoading(true);
        setButtonText("Bekleyiniz...");

        setTimeout(async () => {
            try {
                const { success, message } = await post(
                    "/authenticate/create-password",
                    { ...query }
                );
                if (!success) {
                    setLoading(false);
                    setButtonText("Kaydet");
                    notification.error({
                        message: "Bilgilendirme",
                        description: message,
                        placement: "top"
                    });
                    return;
                }
                setLoading(false);
                formResetHandler();
                setButtonText("Şifreniz yenilendi!");
                navigate("/auth/reset-successfull", { state: { where: "reset" } });
            } catch (e) {
                setButtonText("Kaydet");
                setLoading(false);
                notification.error({
                    message: "Bilgilendirme",
                    description: e.toString(),
                    placement: "top"
                });
            }
        }, 3000);
    };

    const formResetHandler = () => form.resetFields();

    const tokenVerify = async () => {
        setVerifying(true);
        try {
            const { success } = await post("/authenticate/token-verification", {
                token
            });
            if (!success) {
                setVerifying(false);
                navigate("/auth/forgot-password");
                return;
            }
            setVerifying(false);
        } catch (e) {
            setVerifying(false);
            console.log(e.toString());
        }
    };

    useEffect(() => {
        tokenVerify();
    }, []);

    const antIcon = (
        <LoadingOutlined
            style={ {
                fontSize: 24
            } }
            spin
        />
    );

    if (verifying) return <Spin indicator={ antIcon } />;

    return (
        <div className="forgotpassword_container">
            <Card>
                <CardHeader headContent="Şifre oluştur" link="/auth/forgot-password" />
                <Form onFinish={ onFormSubmit } autoComplete="off" form={ form }>
                    <Form.Item
                        name="password"
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen şifrenizi giriniz."
                            },

                            {
                                min: 6,
                                message: "Lütfen en az 6 karakter giriniz!"
                            },
                            {
                                whitespace: true,
                                message: "Lütfen şifrenizi giriniz."
                            }
                        ] }
                    >
                        <Input.Password
                            bordered={ false }
                            placeholder="Şifre"
                            iconRender={ (visible) =>
                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen şifrenizi tekrar giriniz."
                            },
                            {
                                whitespace: true,
                                message: "Lütfen şifrenizi tekrar giriniz."
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value)
                                        return Promise.resolve();
                                    else return Promise.reject("Girdiğiniz şifreler eşleşmiyor!");
                                }
                            })
                        ] }
                    >
                        <Input.Password
                            bordered={ false }
                            placeholder="Yeni Şifre Tekrar"
                            iconRender={ (visible) =>
                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                        />
                    </Form.Item>
                    <Button block type="primary" htmlType="submit" loading={ loading }>
                        { buttonText }
                    </Button>
                </Form>
            </Card>
        </div>
    );
};

export default ResetPasswordPage;
