import React from "react";
import { Route, Routes } from "react-router-dom";
import { UserDetailPage } from "./";

const Router = () => (
    <Routes>
        <Route path="/*" element={ <UserDetailPage /> } /> 
    </Routes>  
);

export default Router;
