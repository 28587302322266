import { Button, DatePicker, Drawer, Form, Input, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { campaignAddUrl, campaignDetailsUrl, imagePlaceHolderUrl } from "services";
import CampaignData from "./CampaignData";
import { dateFormat } from "./Constant";
import { formatDataHelper } from "./Helper";
import ProductList from "./ProductList";
import ImageUpload from "components/common/ImageUpload";
import { get, notificationError, notificationSuccess, post, put } from "helper";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id: primaryKey } = useParams();

    const [campaignData, setCampaignData] = useState([]);
    const [visible, setVisible] = useState(true);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [imageList, setImageList] = useState(undefined);

    const getDataViaPrimaryKey = async () => {
        const { result, success, message } = await get(
            `${campaignDetailsUrl}${primaryKey}`
        );
        if (!success) {
            notificationError(message);
            return;
        }       
        const started_at = moment(result?.started_at, dateFormat);
        const finished_at = moment(result?.finished_at, dateFormat);

        form?.setFieldsValue({
            description:result?.description,
            finished_at,
            name: result?.name,
            started_at
        });
        if (result?.product_skus?.length > 0) {
            const formattedResult = formatDataHelper(result?.product_skus);
            setCampaignData(formattedResult);
            setFileList([
                {
                    name: "image.png",
                    status: "done",
                    uid: "-1",
                    url: result?.image
                }
            ]);
        }
    };
  
    const handleSubmit = async (values) => {
        const product_ids = campaignData.map( ( product ) => product.id );      
        const started_at = values?.started_at.format( "YYYY-MM-DD hh:mm" );
        const finished_at =values?.finished_at.format( "YYYY-MM-DD hh:mm" );
        const newValues = {
            description:values?.description,
            finished_at,
            image: imageList,
            name: values?.name,
            product_ids,
            started_at        
        };
        const { success, message } = !primaryKey
            ? await post(campaignAddUrl, newValues)
            : await put(`${campaignDetailsUrl}${primaryKey}`, newValues);
        if (!success) {
            notificationError(message);
            return;
        }            
        const messageNotification=!primaryKey?"Kampanya detayları başarıyla kaydedildi.":"Kampanya başarıyla eklendi";
        notificationSuccess();
        setCampaignData(messageNotification);
        onClose();
    };

    const onClose = async () => {
        setVisible(false);
        onHide();
    };

    useEffect(() => {
        if (primaryKey && visible) getDataViaPrimaryKey();
        else
            setFileList([
                {
                    name: "image.png",
                    status: "done",
                    uid: "-1",
                    url: imagePlaceHolderUrl
                }
            ]);
    }, []);

    return (
        <Drawer
            placement="right"
            open={ visible }
            onClose={ onClose }
            title={ primaryKey? "Kampanya Düzenle":"Yeni Kampanya Ekle" }
            maskClosable={ false }
            extra={ (<Space>
                <Button
                    type="primary"
                    onClick={ ()=>form.submit() }
                    disabled={ !campaignData?.length>0}
                >
                    Kaydet
                </Button>         
            </Space>) }
        >
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSubmit }
            >
                <ImageUpload
                    fileList={ fileList }
                    fileListHandler={ (file) => setFileList(file) }
                    imageListHandler={ (image) => setImageList(image) }
                />
                <Form.Item
                    label="Başlangıç Tarihi"
                    name="started_at"
                    rules={ [
                        { message: "Lütfen başlangıç tarihi giriniz!", required: true }
                    ] }
                >
                    <DatePicker placeholder="" format={ dateFormat } showTime />
                </Form.Item>
                <Form.Item
                    label="Bitiş Tarihi"
                    name="finished_at"
                    rules={ [
                        { message: "Lütfen bitiş tarihi giriniz!", required: true }
                    ] }
                >
                    <DatePicker placeholder="" format={ dateFormat } showTime />
                </Form.Item>
                <Form.Item
                    label="Başlık"
                    name="name"
                    rules={ [{ message: "Lütfen Kampanya adı giriniz!", required: true }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Açıklama"
                    name="description"
                >
                    <Input/>
                </Form.Item>
                <ProductList
                    filteredOptions={ filteredOptions }
                    onFilteredOptionsHandler={ ( items ) => setFilteredOptions( items ) }
                    onCampaignDataHandler={ ( items ) => setCampaignData( items ) }
                    campaignData={ campaignData }
                    zeroForm={ ()=>form?.setFieldsValue({ products: [] }) }
                />
                <CampaignData
                    campaignData={ campaignData }
                    onCampaignDataHandler={ (items) => setCampaignData(items) }
                    onFilteredOptionsHandler={ (items) => setFilteredOptions(items) }
                />
            </Form>
        </Drawer>
    );
};
export default AddView;
