import { Button, Card, Form, Row } from "antd";
import { priceDataFormatter } from "../../common/Helper";
import PriceTable from "../../common/PriceTable";
import { notificationError, notificationSuccess, put } from "helper";

const CategoryPricesPage = ({ data, mutate, primaryKey }) => {
    const [form] = Form.useForm();
    const tableData=priceDataFormatter(data?.categories);

    const handleSave = async (values) => {
        const { trans_code, name,...rest } = values;

        const companies=data?.companies?.map((item)=>item?.company_id);

        const newValues={
            categories:Object.values(rest),
            companies,
            name,
            trans_code
        };
        const { success, message } = await put(`/product/price/${primaryKey}`, newValues);
        if (!success) {
            notificationError(message);
            return;
        }
        notificationSuccess("Fiyat Şablonu başarıyla güncellendi.");
        mutate();
    };

    return (
        <Card>
            <Row justify="end" className="mb-10">
                <Button type="primary" onClick={ () => form.submit() }>
                    Kaydet
                </Button>
            </Row>
            <Form requiredMark={ "required" } onFinish={ handleSave } form={ form }>
                <PriceTable 
                    tableData={  tableData } 
                    name={ data?.name } 
                    trans_code= { data?.trans_code }/>
            </Form>
        </Card>
    );
};

export default CategoryPricesPage;
