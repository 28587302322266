
import { Layout as AntLayout } from "antd";

const { Content: AntContent } = AntLayout;

const Content = ({ children }) => (
    <AntContent className="mainContent">
        { children }
    </AntContent>)
;

export default Content;
