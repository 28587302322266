import { Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Url } from "../Constant";
import { DrawerComp } from "components/common/Drawer";
import { get, post, put } from "helper";
import { notificationError } from "helper/NotificationHelpers";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const location = useLocation();

    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [open,setOpen] = useState(true);

    let primaryKey= "";
    let attribute_id = 0;
 
    const path =location.pathname.split("/");
    if (path?.at(-1) !== "add" ){
        primaryKey = path?.at(-2);
        attribute_id = path?.at(-3);
    }
    else {
        attribute_id = path?.at(-2);
    }
    
    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notificationError(message);
                return;
            }
            setData(result);
        }
    };

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSubmit = async (values) => {
        const newValues = {
            ...values,
            active :1 ,
            attribute_id : attribute_id
        };
        const { success, message } = !primaryKey
            ? await post( `${ Url }/add`, newValues )
            : await put( `${ Url }/${ primaryKey }`, newValues );
        if (!success) {
            notificationError(message);
            return;
        }
        setData(undefined);
        onClose();
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if(visible && data) form.setFieldsValue({ "name": data.name });
    }, [form, data, visible]);

    return (
        <DrawerComp
            handleSubmit={ handleSubmit }
            setOpen={ setOpen }
            open={ open }
            form={ form }
            type={ "addProductAttributeValue" }
        >
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSubmit }
            >
                <Form.Item
                    label="Özellik Değerleri"
                    name="name"
                    rules={ [{ message: "Lütfen Özellik Değerleri adı giriniz!", required: true }] }
                >
                    <Input />
                </Form.Item>
            </Form>
        </DrawerComp>
    );
};
export default AddView;
