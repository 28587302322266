import React, { useEffect } from "react";
import { Columns } from "./Constant";
import Filter from "./Filter";
import { useData } from "./Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const Page = ({ companyId, outRef }) => {
    const { setQueryParams } = useFilter();

    useEffect(() => {
        setQueryParams({
            pageNumber: 1,
            pageSize: 10,
            sortField: "id",
            sortOrder: "desc",
            filter: { company_id : parseInt(companyId) }
        });
    }, []);
    
    return (
        <PageContent 
            tableData={ useData } 
            tableColumns ={ Columns }
            dataFilter={ <Filter outRef={ outRef } /> }
            selection = { false }
        />
    );
};

export default Page;
