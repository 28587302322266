import Filter from "./Filter";
import { dataHelper } from "./Helper";
import { Breadcrumbs } from "../Constant";
import { useData, useTable } from "../Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const Page = () => {
    const { queryParams } = useFilter();
    const { mutate, data } = useData(queryParams);
    const { columns } = useTable(mutate);

    return (
        <PageContent
            breadcrumbs={ Breadcrumbs }
            tableData={ useData }
            tableColumns={ columns }
            dataFilter={ <Filter mutate={ mutate } data={ dataHelper(data?.result) } /> }
        />
    );
};

export default Page;
