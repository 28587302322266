import { Menu } from "antd";
import React from "react";

const MenuSection = ({
    theme,
    selectedKey,
    openKey,
    SideBarItems,
    handleMenuClick,
    collapsed
}) => {
    return (
        <div className="menu-div">
            <Menu
                className="menu"
                theme={ theme }
                key={ !collapsed && selectedKey }
                selectedKeys={ selectedKey }
                defaultSelectedKeys={ selectedKey }
                defaultOpenKeys={ openKey }
                mode="inline"
                items={ SideBarItems }
                onClick={ handleMenuClick }
            />
        </div>
    );
};

export default MenuSection;
