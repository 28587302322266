export const pageTitles = [
    { title: "Ödemeler", path: "/bufipay/payments" },
    { title: "İptaller", path: "/bufipay/cancellations" },
    { title:"İadeler", path:"/bufipay/returns" },
    { title:"Cevap Alınamayan Ödemeler", path:"/bufipay/unansweredpayments" },
    { title:"Link ve QR ile Ödeme", path:"/bufipay/paybylink" },
    { title:"Raporlar", path:"/bufipay/reports" },
    { title:"Üye İşyeri Ayarları", path:"/bufipay/merchantsettings" },
    { title:"Pos Hesapları", path:"/bufipay/virtual-posaccounts" },
    { title:"Api Yönetimi", path:"/bufipay/apimanagement" }
];
