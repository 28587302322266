import { PlusOutlined } from "@ant-design/icons";

export const uploadButton = (
    <div>
        <PlusOutlined />
        <div
            style={ {
                marginTop: 8
            } }
        >
            Yükle
        </div>
    </div>
);
