import { Button } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";
import { Columns, ListUrl } from "./Constant";
import { ProtectedElement } from "components";
import { getColumns } from "helper";
import { usePost } from "helper/Hook";

export const useTable = () => {
    const navigate = useNavigate();
    
    const renderAction = (_, record) => (
        <ProtectedElement permissionKey={ "payment.edit" }>
            <Button onClick={ () => navigate(`${record?.id}/edit`) } type="link" size="small">
                <i className="flaticon2-file text-muted" />
            </Button>   
        </ProtectedElement>   
    );  
    const columns = getColumns(Columns, renderAction, true,false,true);
    return { columns };
};

export const useData = (queryParams) => {
    const clonedQueryParams=cloneDeep(queryParams);
    clonedQueryParams.filter.method_id=1;
    return usePost(ListUrl, clonedQueryParams);
};

export const getFilterQuery = (queryParams, values) => {
    const { user_id, company_id, bank_name, ordered_at, active, name } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (active) filter.active = +active;
    if (user_id) filter.user_id = +user_id;
    if (company_id) filter.company_id = +company_id;
    if (bank_name) filter.bank_name__ilike = `%${bank_name}%`;
    if (ordered_at) filter.created_at__between = ordered_at;
    if (name) filter.card_owner__ilike = `%${name}%`;
    newQueryParams.filter = filter;
    return newQueryParams;
};
