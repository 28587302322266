/* eslint-disable no-restricted-imports */
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { getFilterQuery, useData } from "./Helper";
import AddAddress from "components/common/AddAddress";
import { DrawerComp } from "components/common/Drawer";
import { useFilter } from "context";

const Filter = () => {
    const [formAdd] = Form.useForm();
    const { queryParams, setQueryParams } = useFilter();
    const { mutate } = useData(queryParams);

    const [openAdd, setOpenAdd] = useState(false);

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleAdd = () => setOpenAdd(true);

    const handleInputChange = (event) => applyFilter({ ...queryParams.filter, name:event.target.value });

    useEffect(()=>{
        mutate();
    },[queryParams]);

    return (
        <Row align="space-between">
            <Col>
                <Form>
                    <Form.Item name="searchText" required>
                        <Input
                            onChange={ handleInputChange }
                            placeholder="Adres Başlığı Giriniz"
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col>
                <Space>
                    <Button
                        shape="round"
                        type="dashed"
                        onClick={ handleAdd }
                        block
                        icon={ <PlusOutlined /> }
                    >
                        Yeni
                    </Button>
                    <DrawerComp
                        setOpen={ setOpenAdd }
                        open={ openAdd }
                        form={ formAdd }
                        type={ "addAddress" }
                    >
                        <AddAddress form={ formAdd } />
                    </DrawerComp>
                </Space>
            </Col>
        </Row>
    );
};
export default Filter;
