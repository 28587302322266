import { Form, Input, notification } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";
import { DrawerComp } from "components/common/Drawer";
import { get, post, put } from "helper";

const EditView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [open,setOpen] = useState(true);

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    description: message,
                    message: "Bilgilendirme",
                    placement: "top"
                });
                return;
            }
            setData(result);
        }
    };
   
    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSubmit = async (values) => {
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <DrawerComp
            handleSubmit={ handleSubmit }
            setOpen={ setOpen }
            open={ open }
            form={ form }
            // type={ "add" }
        >
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSubmit }
            >
                <Form.Item
                    label="Marka"
                    name="name"
                    rules={ [{ message: "Lütfen marka adı giriniz!", required: true }] }
                >
                    <Input />
                </Form.Item>
            </Form>
        </DrawerComp>
    );
};
export default EditView;
