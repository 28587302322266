import { AuthProvider, FilterProvider, LayoutProvider, PageDataProvider } from "./";
import { TableProvider } from "./TableProvider";

export const MasterProvider = ({ children }) => (
    <AuthProvider>
        <LayoutProvider>
            <PageDataProvider>
                <TableProvider>
                    <FilterProvider>{ children }</FilterProvider>
                </TableProvider>
            </PageDataProvider>
        </LayoutProvider>
    </AuthProvider>
);
