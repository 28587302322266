import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row,Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tabItems } from "./EditView/Constant";
import { addKeyHelper, orderDataHelper } from "./EditView/Helper";
import OrderDetails from "./EditView/OrderDetails";
import OrderHistory from "./EditView/OrderHistory";
import OrderSummary from "./EditView/OrderSummary";
import ProductDetails from "./EditView/ProductDetails";
import TopTitle from "./EditView/TopTitle";
import { Breadcrumbs } from "../Constant";
import { Spinner } from "components";
import { PageTitle } from "context";
import { useOrder } from "helper";

const EditView = () => {
    const { id: primaryKey } = useParams();
    let { data, error, mutateData } = useOrder(primaryKey);
    const navigate = useNavigate();

    const [breadcrumbs, setBreadcrumbs] = useState();
    const [activeTab, setActiveTab] = useState("item-1");

    const onChangeTab = ( activeKey ) => setActiveTab( activeKey );
    
    const handleBreadcrumbs = () => {
        const { result: productSku } = data;
        const breadcrumbs = [
            ...Breadcrumbs,
            {
                breadcrumbName: "Sipariş Detayları",
                path: `/sales/order/${productSku.id}`
            }
        ];
        setBreadcrumbs(breadcrumbs);
    };

    useEffect(() => {
        if(data)handleBreadcrumbs();
    }, [data]);

    if ( !data && !error ) {
        return <Spinner />;
    }
    else if ( !data && error ) return ( <Row justify="center"><p>Hata: Bir hata meydana geldi!</p></Row> );
    
    const { result } = data || {};

    const activeItems = {
        "item-1": <OrderSummary data={ result } />,
        "item-2": <ProductDetails data={ addKeyHelper(result?.products) } />,
        "item-3": <OrderHistory />
    };

    return (
        <>
            <PageTitle breadcrumbs={ breadcrumbs }>
                { breadcrumbs?.at(-1)?.breadcrumbName }
            </PageTitle>       
            <Row align="space-between" className="mb-10">
                <Col>  <Typography.Title strong level={ 4 }>
                    Sipariş Bilgileri
                </Typography.Title>
                </Col>
                <Col>       <Button
                    icon={ <ArrowLeftOutlined /> }
                    onClick={ () => navigate("/sales/order") }
                >
                    Geri
                </Button></Col>
            </Row>
            { data && !error && (
                <Card>
                    <Row className="mb-10" align="space-between">
                        <TopTitle 
                            orderKey={ result?.order_key } 
                            orderDate={ result?.date } 
                            mutate={ mutateData }
                            orderOutref={ result?.out_ref }
                            data={ result }
                        >                                                         
                        </TopTitle>
                    </Row>
                    <Row gutter={ [24, 24] }>
                        <OrderDetails
                            data={ orderDataHelper(result) }
                        />
                    </Row>
                    <Divider />
                    <Row style={ { marginBottom: -42 } }>
                        <Tabs
                            items={ tabItems }
                            activeKey={ activeTab }
                            onChange={ onChangeTab }
                            tabPosition={ "top" }
                        />
                    </Row>
                </Card>
            ) }
            <br />
            { activeItems[activeTab] }
        </>
    );
};

export default EditView;
