import { Form, Select } from "antd";
import React from "react";
import { statusOptions } from "constant";

export const DrawerForm = ({ form, handleSubmit }) => {
    return (
        <Form
            form={ form }
            layout="vertical"
            requiredMark={ "Required" }
            initialValues={ {
                active: statusOptions[1].value
            } }
            onFinish={ handleSubmit }
        >
            <Form.Item
                label="Durum"
                name="active"
                required
                tooltip="Veri durumu"
            >
                <Select options={ statusOptions } />
            </Form.Item>
        </Form>
    );
};
