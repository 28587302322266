import React from "react";
import { Route, Routes } from "react-router-dom";
import { CompanyDetailPage } from ".";

const Router = () => (
    <Routes>
        <Route path="/" element={ <CompanyDetailPage/> } /> 
    </Routes>  
);

export default Router;
