import { DatePicker, Form, Select, Tooltip  } from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import React, { useEffect, useState } from "react";
import { companyListUrl } from "services";
import { erpCondition, orderStatus } from "../../Constant";
import { sources } from "constant";
import { notificationError } from "helper";
import { post } from "helper/HttpHelpers";
import { formatResult } from "helper/TableHelpers";
const { RangePicker } = DatePicker;
import "moment/locale/tr";

const FilterMenu = ({ form, handleSubmit, setDate }) => {
    const [companies, setCompanies] = useState(undefined);

    const getCompanies = async () => {
        const { result, success, message } = await post(companyListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCompanies(formatResult(result));
    };

    const onChange = (value, dateString) => {
        setDate(dateString);
    }; 

    useEffect(() => {
        getCompanies();
    }, []);
    
    return (
        <Form
            form={ form }
            layout="vertical"
            requiredMark={ "Required" }
            onFinish={ handleSubmit }
        >
            <Form.Item
                label="Firma"
                name="company_id"
            >
                <Select
                    name="company_id"
                    placeholder="Lütfen firma seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                > { companies?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            </Form.Item>
            <Form.Item
                label="Sipariş Durumu"
                name="status"
            >
                <Select
                    mode="multiple"
                    name="company_id"
                    placeholder="Lütfen sipariş durumu seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                > { orderStatus?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            </Form.Item> 
            <Tooltip title="Kaynak sunucuya gönderim durumu" placement="left">
                <Form.Item
                    label="ERP Durumu"
                    name="is_sent"
                >
                    <Select 
                        mode="multiple"
                        allowClear 
                        options={ erpCondition } 
                        placeholder="Lütfen erp durumu seçiniz" 
                    />
                </Form.Item>
            </Tooltip>
            <Form.Item
                label="Kaynak"
                name="source"
            >
                <Select 
                    mode="multiple"
                    allowClear 
                    options={ sources } 
                    placeholder="Lütfen kaynak durumu seçiniz" 
                />
            </Form.Item>
            <Form.Item
                label="Başlangıç - Bitiş Tarihi"
                name="date"
            >
                <RangePicker locale={ locale }
                    onChange={ onChange }
                />
            </Form.Item>
        </Form>
    );
};
export default FilterMenu;
