/* eslint-disable no-restricted-imports */
import { Col, Form, Input, Row } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { getFilterQuery, useData } from "./Helper";
import { useFilter } from "context";

const Filter = () => {
    const { queryParams, setQueryParams } = useFilter();
    const { mutate } = useData(queryParams);

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleInputChange = (event) => applyFilter({ ...queryParams.filter, name:event.target.value });

    useEffect(()=>{
        mutate();
    },[queryParams]);

    return (
        <Row align="space-between">
            <Col>
                <Form>
                    <Form.Item name="searchText" required>
                        <Input
                            onChange={ handleInputChange }
                            placeholder="Ödeme Giriniz"
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col />
        </Row>
    );
};
export default Filter;
