import { Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Columns } from "./Constant";
import { EmptyMessage } from "components";

const ProductDetails = ({ data }) => {
    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState(data);

    useEffect(() => {
        if(data)setTableData(data);
    }, [data]);

    return (
        <>
            { data?.length > 0 ? (
                <Card>
                    <Table
                        columns={ Columns }
                        dataSource={ tableData }
                        showSorterTooltip={ { title: "Sıralamayı değiştir" } }
                        rowKey={ (record) => record.id }
                        pagination={ {
                            current: page,
                            onChange: (page) => setPage(page),
                            pageSize: 10
                        } }
                    />
                </Card>
            ) : (           
                <EmptyMessage message="Ürün Bilgisi Bulunamadı"></EmptyMessage>     
            ) }
        </>
    );
};
export default ProductDetails;
