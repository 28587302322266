import { DeleteOutlined } from "@ant-design/icons";

export const columnHelper = (deleteHandler) => {
    const columns = [
        {
            title: "Kampanya Ürün Listesi",
            dataIndex: "name"
        },
        {
            title: "İşlem",
            key: "actions",
            render: (record) => (
                <>
                    <DeleteOutlined
                        style={ { color: "red", marginLeft: 10 } }
                        onClick={ () => deleteHandler(record) }
                    />
                </>
            )
        }
    ];
    return columns;
};

export const formatDataHelper =(items)=> items?.map((item) => ({
    name: item.name,
    id: item.id,
    key: item.id.toString()
} ) );
