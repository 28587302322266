
import { UserAddressPage } from "./address";
import { UserCartPage } from "./cart";
import { UserFavoritePage } from "./favorite";
import { UserNotificationPage } from "./notification";
import { UserOrderPage } from "./order";
import { UserOverviewPage } from "./overview";
import { UserPaymentPage } from "./payment";
import { UserSearchHistoryPage } from "./searchHistory";
import { UserSettingsPage } from "./settings";

export const tableItems = [
    { key: "item-1", label: "Önizleme" },
    { key: "item-2", label: "Ayarlar" },
    { key: "item-3", label: "Adresler" },
    { key: "item-4", label: "Siparişler" },
    { key: "item-5", label: "Ödemeler" },
    { key: "item-6", label: "Sepet" },
    { key: "item-7", label: "Favoriler" },
    { key: "item-8", label: "Arama Geçmişi" },
    { key: "item-9", label: "Bildirimler" },
    { key: "item-10", label: "Cihazlar" },
    { key: "item-11", label: "Log" }
];

export const activateItems =
{
    "item-1": ( <UserOverviewPage /> ),
    "item-10": ( <p>Cihazlar</p> ),
    "item-11": ( <p>Log</p> ),
    "item-2": ( <UserSettingsPage /> ),
    "item-3": ( <UserAddressPage /> ),
    "item-4": ( <UserOrderPage /> ),
    "item-5": ( <UserPaymentPage />),
    "item-6": ( <UserCartPage /> ),
    "item-7": ( <UserFavoritePage /> ),
    "item-8": ( <UserSearchHistoryPage/> ),
    "item-9": ( <UserNotificationPage /> )
};
