
import { Form, Input, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { addressListUrl, companyListUrl, roleListUrl, userAddUrl } from "services";
import { emailRegex } from "constant/Regex";
import { useUserList } from "helper";
import { checkIfUserExists } from "helper/CheckUserExist";
import { rolChangeHelper } from "helper/CommonHelpers";
import { post } from "helper/HttpHelpers";
import { notificationError } from "helper/NotificationHelpers";
import { formatResult } from "helper/TableHelpers";

const AddUser = ({ form }) => {
    const location = useLocation();
    const { mutateData } = useUserList();

    const emailWatch = Form.useWatch("email", form);

    const url = location.pathname.split("/");
    const compId = url?.at(3);

    const [roles, setRoles] = useState(undefined);
    const [companies, setCompanies] = useState(undefined);
    const [roleType, setRoleType] = useState(undefined);
    const [email, setEmail] = useState({ value:"" } );
    const [address, setAddress] = useState(undefined);
    const [officialAddress, setOfficialAddress] = useState(undefined);
    const [gsm, setGsm] = useState({ value:"" });

    const getRoles = async () => {
        const { result, success, message } = await post(roleListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setRoles(formatResult(result, "name", "id" , "type"));
    };

    const getCompanies = async () => {
        const { result, success, message } = await post(companyListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCompanies(formatResult(result));
    };

    const getAddress = async (companyId) => {
        const { success, result, message } = await post (addressListUrl, {
            filter: { company_id : companyId , type: "company" },
            pageSize: 1000         
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setAddress(result?.map(item=>{ return { label:item.title,value:item.id };}));
    };

    const getOfficialAddress = async (e) => {
        const { success , result , message } = await post (addressListUrl, {
            filter: { id__in: e },
            pageSize: 1000         
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setOfficialAddress(result?.map(item => { return { label:item.title,value:item.id };}));
    };

    const onRolChange = (e,rolType) => {
        setRoleType(rolType.type);
        const formSetValue = rolChangeHelper(compId,e);
        form.setFieldsValue(formSetValue);
    }; 

    const onChangeCompaniesId = (e) => {
        getAddress(e);
        form.setFieldsValue({ "addresses": undefined , "default_address": undefined });
    };

    const handleSubmit = async (values) => {
        if (gsm.validateStatus === "error") return;
        const newValues = {
            ...values,
            gsm: gsm?.fullGsm
        };
        { compId ? (newValues.roles = [newValues.roles]) : undefined; }
        const { success, message } = await post(userAddUrl, newValues);
        if (!success) {
            notificationError(message);
            return;
        }
        mutateData();
        form.resetFields();
        setGsm({ value:"" });
    };

    const gsmHandleChange = async e => {
        const gsmInput = e.target.value.replace(/\D/g, "").slice(0, 9);
        const fullGsm = "+905"+ gsmInput;
        setGsm(prev=> ({ ...prev,fullGsm,value:gsmInput }));
        if ( !/^\d{9}$/.test( gsmInput) ) {
            setGsm((prevValue) =>
                ({ ...prevValue, errorMsg:"Lütfen geçerli bir telefon numarası yazınız!", validateStatus: "error" }));
            return;         
        } 
        else {
            const isExist = await checkIfUserExists( "gsm", fullGsm );
            const errorMessage = "Bu telefon numarası kayıtlıdır!";
            const errorValidateStatus = { errorMsg: errorMessage, validateStatus: "error" };
            const successValidateStatus = { errorMsg: null, validateStatus: "success" };
            if (!isExist) {
                setGsm((prevValue) => ({ ...prevValue, ...successValidateStatus, value: gsmInput  }));
            }
            else setGsm((prevValue) => ({ ...prevValue, ...errorValidateStatus }));
        }
    };
    
    const emailHandleChange = async e => {
        const emailInput = e.target.value;
        if (!emailRegex.test(emailInput) ){
            const errorMessage = "Lütfen geçerli bir email giriniz";
            const errorValidateStatus = { errorMsg: errorMessage, validateStatus: "error" };
            setEmail((prevValue) => ({ ...prevValue, ...errorValidateStatus }));
            return ;
        } 
        else { 
            const isExist = await checkIfUserExists("email" , emailInput);
            const errorMessage = "Bu email kayıtlıdır!";
            const errorValidateStatus = { errorMsg: errorMessage, validateStatus: "error" };
            const successValidateStatus = { errorMsg: null, validateStatus: "success" };
            if (!isExist) {
                setEmail((prevValue) => ({ ...prevValue, ...successValidateStatus, value: emailInput  }));
            }
            else setEmail((prevValue) => ({ ...prevValue, ...errorValidateStatus }));
        }
    };

    useEffect(() => {
        getRoles();
        getCompanies();
        { compId ? getAddress(compId) : undefined; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if ( gsm.value === "" ) {
            delete gsm.validateStatus;
            delete gsm.errorMsg;
        }
    },[gsm]);

    useEffect(()=>{
        if ( !emailWatch ) {
            delete email.validateStatus;
            delete email.errorMsg;
        }
    },[emailWatch]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form
            form={ form }
            onFinish={ handleSubmit }
            layout="vertical"
            autoComplete="off"
            onFinishFailed={ () => {
                notification.error({
                    description: "Lütfen gerekli alanları doldurunuz! ",
                    duration: "3",
                    message: "Bilgilendirme",
                    placement: "top"
                });
            } }
        >
            <Form.Item
                label="Ad"
                name="first_name"
                rules={ [
                    {
                        message: "Lütfen adınızı giriniz!",
                        required: true
                    },
                    {
                        message:"Lütfen adınızı giriniz!",
                        whitespace: true
                    },
                    {
                        max: 20,
                        message:"Adınız en az 3 en fazla 20 karakter olmalıdır!",
                        min: 3
                    },
                    {
                        message: "Lütfen sadece harf giriniz!",
                        pattern: "^[A-Za-za-züÜçÇıİşŞğĞöÖ s*]+$"
                    }
                ] }
                hasFeedback
            >
                <Input placeholder="Lütfen adınızı yazınız" />
            </Form.Item>
            <Form.Item
                label="Soyad"
                name="last_name"
                rules={ [
                    {
                        message: "Lütfen soyadınızı giriniz!",
                        required: true
                    },
                    {
                        message:"Lütfen soyadınızı giriniz!",
                        whitespace: true
                    },
                    {
                        max: 20,
                        message:"Soyadınız en az 2 en fazla 20 karakter olmalıdır!",
                        min: 2
                    },
                    {
                        message: "Lütfen sadece harf giriniz!",
                        pattern: "^[A-Za-züÜçÇıİşŞğĞöÖ s*]+$"
                    }
                ] }
                hasFeedback
            >
                <Input placeholder="Lütfen soyadınızı yazınız" />
            </Form.Item>
            <Form.Item
                label="E-Posta adresi"
                name="email"
                rules={ [
                    {
                        message: "Lütfen email adresinizi giriniz!",
                        required: true
                    },
                    {
                        message: "Lütfen email adresinizi giriniz!",
                        type:"email"
                    },
                    {
                        message:"Lütfen email adresinizi giriniz!",
                        whitespace:true
                    }
                ] }
                validateStatus={ email?.validateStatus }
                help={ email?.errorMsg }
                hasFeedback
            >
                <Input onChange={ emailHandleChange } value={ email.value }
                    placeholder="Lütfen e-posta adresi yazınız" />
            </Form.Item>
               
            <Form.Item
                label="Gsm"
                hasFeedback
                validateStatus={ gsm.validateStatus }
                help={ gsm.errorMsg }
                className="phone-Input"
                rules={ [
                    {
                        message: "Lütfen telefon numaranızı yazınız!",
                        required: true
                    },
                    {
                        max: 9,
                        message:"Lütfen geçerli bir telefon numarası yazınız!",
                        min: 9                                    
                    }                 
                ] }>
                <Input
                    prefix="+90 5"     
                    type="text" 
                    onChange={ gsmHandleChange }
                    placeholder="  Lütfen telefon numaranızı yazınız" 
                    value={ gsm.value }
                />
            </Form.Item>                      
            <Form.Item
                label="Rol"
                name="roles"
                rules={ [{ message: "Lütfen rol seçiniz!", required: true }] }
                style={ { width: "100%" } }
            >
                <Select
                    placeholder="Lütfen rol seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    onChange={ onRolChange } 
                >
                    { roles?.map((option) => (
                        <Select.Option key={ option.value } type={ option.type }>
                            { option.label }
                        </Select.Option>
                    )) } 
                </Select>
            </Form.Item>
            { (roleType === "companyadmin" || roleType === "companyuser") && (<Form.Item
                label="Firma"
                name="company_id"
                rules={ [{ message: "Lütfen firma seçiniz!", required: true }] }
                style={ { width: "100%" } }
            >
                <Select
                    placeholder="Lütfen firma seçiniz"
                    allowClear
                    showSearch
                    disabled= { compId ? true : false }
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    onChange = { onChangeCompaniesId }
                > { companies?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }</Select>
            </Form.Item> ) }
            { (roleType === "companyuser" ) && (<Form.Item
                label="Yetkili Adres"
                name="addresses"
                rules={ [{ message: "Lütfen adres seçiniz!", required: true }] }
                style={ { width: "100%" } }
            >
                <Select
                    placeholder="Lütfen yetkili adres seçiniz"
                    allowClear
                    mode="multiple"
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }     
                    onChange= { getOfficialAddress } 
                >
                    { address?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            </Form.Item>
            ) }
            { (roleType === "companyuser") && (<Form.Item
                label="Tanımlı Adres"
                name="default_address"
                rules={ [{ message: "Lütfen adres seçiniz!", required: true }] }
                style={ { width: "100%" } }
            >
                <Select
                    placeholder="Lütfen tanımlı adres seçiniz"
                    allowClear
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }     
                >
                    { officialAddress?.map((option) => (<Select.Option key={ option.value }>{ option.label }
                    </Select.Option>
                    )) }
                </Select>
            </Form.Item> ) }
            { (roleType === "companyadmin") && (<Form.Item
                label="Tanımlı Adres"
                name="default_address"
                rules={ [{ message: "Lütfen adres seçiniz!", required: true }] }
                style={ { width: "100%" } }
            >
                <Select
                    placeholder="Lütfen tanımlı adres seçiniz"
                    allowClear
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }     
                >
                    { address?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            </Form.Item> ) }
        </Form>
    );
};
export default AddUser;
