import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ordersSendToServerUrl, updateAnOrderUrl } from "services";
import { Columns, ListUrl } from "./Constant";
import { ProtectedElement } from "components";
import { getColumns, notificationError, notificationSuccess, post } from "helper";
import { usePost } from "helper/Hook";

export const useTable = (mutateData) => {
    const [loading, setLoading]=useState(false);
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate("/sales/order");
    };

    const sendToServerAction = async (primaryKey) => {
        setLoading(true);
        const { success, message } = await post(`${ordersSendToServerUrl}`,{
            "order_ids": [
                primaryKey
            ]
        });
        setLoading(false);
        if (!success) {
            notificationError(message);
            return;
        }
        notificationSuccess("Gönderim isteğiniz alındı");
        onHide();
    };

    const updateOrderAction = async (outRef) => {
        setLoading(true);
        const { success, message } = await post(`${updateAnOrderUrl}${outRef}`);
        setLoading(false);
        if (!success) {
            notificationError(message);
            return;
        }
        notificationSuccess("Sipariş başarıyla güncellendi");
        onHide();
    };

    const renderAction = (_, record) => (
        <>
            <ProtectedElement permissionKey={ "order.edit" }>  
                <Button
                    onClick={ () => navigate(`${record.id}/edit`) }
                    type="link"
                    size="small"
                >
                    <i className="flaticon2-file text-muted" />
                </Button>
            </ProtectedElement>
            <ProtectedElement permissionKey={ "sync.add" }>  
                <Tooltip title="Güncelle">
                    { record?.out_ref && (
                        <Button
                            type="link"
                            size="small"
                            onClick={ () => updateOrderAction(record?.out_ref) }
                            loading={ loading.two }
                        >
                            <CloudDownloadOutlined /> 
                        </Button>
                    ) }
                </Tooltip>
                { record?.is_sent && record?.out_ref ? (
                    <Tooltip title={ <> Sunucuya gönderildi<br/>G.T.: { record?.sent_date }</> } >
                        <Typography.Text
                            style={ { marginLeft:"5px" } }
                        >
                            <i className="flaticon2-check-mark" style={ { color: "green" } } />
                        </Typography.Text>
                    </Tooltip>
                ) : (
                    <Tooltip title="Sunucuya gönder">
                        <Button
                            type="link"
                            size="small"
                            onClick={ () => sendToServerAction(record?.id) }
                            loading={ loading.one }
                        >
                            <i className="flaticon2-send-1" />
                        </Button>
                    </Tooltip>
                ) }
            </ProtectedElement>
        </>
    );

    const columns = getColumns(Columns, renderAction,true,null,true);
    return { columns, onHide };
};

export const useData = (queryParams) => { 

    const newParams=cloneDeep(queryParams);
    newParams.sortField = "ordered_at";
    delete newParams.filter.active;
    return usePost(ListUrl,newParams);
};

export const getFilterQuery = (queryParams, values) => {
    const { name, company_id, status, is_sent, ordered_at, source } = values;

    const newQueryParams =cloneDeep(queryParams);
    const filter = {};
    if (name) newQueryParams.keyword=name;
    else delete newQueryParams?.keyword;
    if (company_id) filter.company_id = company_id;
    if (status) filter.status__in = status;
    if (source) filter.source__in = source;
    if (is_sent !== undefined) filter.is_sent__in = is_sent;
    if (ordered_at) filter.ordered_at__between = ordered_at;
    newQueryParams.filter = filter;
    return newQueryParams;
};
