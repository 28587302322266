import { Button } from "antd";
import { Link, Route, Routes } from "react-router-dom";
import { listUrl, productAttributeUrl } from "services";
import { AddView } from "./add";
import { ProtectedRoute } from "components";

const Breadcrumbs = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog"
    },
    {
        breadcrumbName: "Ürün Özellikleri",
        path: "/product-type"
    }
];

const Columns = [
    
    {
        render: (_, record) => {
            const { name, out_ref, update_date, create_date } = record;
            return(
                <>
                    <div className="mb-5">  { name }</div>
                    <div className="font-weight-light font-size-xs">
                        Kaynak Kodu { out_ref }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Kayıt Tarihi: { create_date }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Güncelleme Tarihi: { update_date }
                    </div>
                </>
            );},
        title: "Özellik"
    },
    
    {
        render: ( _, record ) => {
            return (
                <Link to={ `/catalog/product-attribute-value/${record.id}` }>
                    <Button type="button">Özellik Değerleri</Button>          
                </Link>
            );},
        title: "",
        width: 80
    }
];

const Path = "/catalog/product-attribute";
const Url = productAttributeUrl;
const ListUrl = `${Url}${listUrl}`;

const Router = ({ onHide }) => (
    <Routes>
        <Route element={ <ProtectedRoute permissionKey={ "product.add" } /> }>
            <Route path="add" element={ <AddView onHide={ onHide } /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "product.edit" } /> }>
            <Route path=":id/edit" element={ <AddView onHide={ onHide } /> } />
        </Route>
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

