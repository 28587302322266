import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Columns } from "./Constant";
import { useData } from "./Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const Page = () => {
    const { id: userId } = useParams();
    const { setQueryParams } = useFilter();
    
    useEffect(() => {
        setQueryParams({
            filter: { user_id : parseInt(userId) },
            pageNumber: 1,
            pageSize: 10,
            sortField: "id",
            sortOrder: "desc"
        });
    }, []);
    
    return (
        <PageContent 
            tableData={ useData } 
            tableColumns={ Columns }
            selection={ false }
        />
    );
};

export default Page;

