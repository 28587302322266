import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

const TableContext = createContext();

const TableProvider = ({ children }) => {
    const [selectedRows, setSelectedRowS] = useState([]);

    const setSelectedRows= useCallback((rows) => {
        setSelectedRowS(rows);
    }, []);
    
    const contextValue = useMemo(() => ({
        selectedRows,
        setSelectedRows
    }), [selectedRows, setSelectedRows]);

    return (
        <TableContext.Provider value={ contextValue }>
            { children }
        </TableContext.Provider>
    );
};

export { TableContext, TableProvider };

export const useTableData = () => {
    return useContext(TableContext);
};
