import { createContext, useContext } from "react";
import { removeStorage, usePersistedJsonState } from "../helper";


const STORAGE_KEY = "root";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [authState, setAuthState] = usePersistedJsonState(
        STORAGE_KEY,
        undefined
    );

    const value = {
        authState,
        user: authState?.user,
        createSession: data => setAuthState(data),
        removeSession: async () => await removeStorage(STORAGE_KEY)
    };
    return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>;
}
