import { Navigate, Route, Routes } from "react-router-dom";
import { IntegrationPage } from ".";
import { NetgsmPage } from "./netgsm";

const Router = () => (
    <Routes>
        <Route index element={ <IntegrationPage /> } />    
        <Route path="integrationsum"    element={ <Navigate to="/integration" replace /> } />   
        <Route path="netgsm" element={ <NetgsmPage /> } />
    </Routes>  
);

export default Router;
