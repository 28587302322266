
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "./Helper";
import { PageTitle } from "context";

const Page = () => {
    const navigate = useNavigate();
    return (
        <>
            <PageTitle breadcrumbs={ Breadcrumbs }>Cari İşlemleri</PageTitle>
            <div className="site-card-wrapper">
                <Row gutter={ 16 }>
                    <Col span={ 8 }>
                        <Card title="Firmalar" bordered={ false } actions={ [
                            <PlusOutlined key="add" onClick={ () => navigate("/account/company/add") }/>,
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/account/company") }/>
                        ] } >
                            <Statistic title="Active Users" value={ 112893 } />
                        </Card>
                    </Col>
                    <Col span={ 8 }>
                        <Card title="Adresler" bordered={ false } actions={ [
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/account/address") }/>
                        ] } >
                            <Statistic title="Active Users" value={ 112893 } />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Page;
