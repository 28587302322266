import { DeleteFilled } from "@ant-design/icons";
import { Button, Drawer, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { titleValues } from "constant/Drawer";

export const DrawerComp = ({
    setOpen,
    open,
    form,
    children,
    type = "Filtreleme"
}) => {
    const url = (window.location.pathname.split("/"));
    const path = ("/"+url[1]+"/"+url[2]);
    const editPath = ("/"+url[1]+"/"+url[2]+"/"+url[3]+"/"+url[4]);

    const navigate = useNavigate();

    const onClickDeleteIcon = () => {
        form.resetFields();
        form.submit();
        setOpen(false);
    };

    const onClickFilterBtn = () => {
        form.submit();
        setOpen(false);
        if ( url?.at(-1) === "edit" ) navigate(editPath) ;
        else if ( url?.at(2) === "product-attribute-value" ) return;
        else if ( url?.at(2) === "user" && url?.at(4) === "overview" ) navigate(editPath);
        else if ( url?.at(2) === "company" ) return;
        else navigate(path);
    };

    const handleClose = () => {
        setOpen(false); 
        if ( url?.at(-1) === "edit" && url?.at(2) !== "address" && url?.at(2) !== "category" ) navigate(editPath) ;
        else if ( url?.at(1) === "account" && url?.at(3) === "add" ) navigate(path);
        else if ( url?.at(2) === "company" ) return;
        else if ( url?.at(2) === "user" && url?.at(4) === "overview" ) navigate(editPath);
        else navigate(path);
    }; 

    return (
        <Drawer
            title={ titleValues[type] }
            placement="right"
            onClose={ handleClose }
            open={ open }
            maskClosable={ false }
            extra={
                <Space> 
                    { type === "edit" ? 
                        <> 
                            <Button
                                className="none-border-shadow"
                                onClick={ onClickDeleteIcon }
                            >
                                <DeleteFilled className="filter-delete-icon" />
                            </Button>
                            <Button
                                type="primary"
                                onClick={ onClickFilterBtn }
                            >
                                Filtrele
                            </Button> 
                        </> :
                        <>
                            <Button
                                type="primary"
                                onClick={ onClickFilterBtn }
                            >
                                Kaydet
                            </Button> 
                        </> 
                    }
                </Space>
            }
        >
            { children }
        </Drawer>
    );
};
