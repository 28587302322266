import { Button, Col, Row, notification } from "antd";
import { profileIcon, userIcon } from "assets/media/index";
import React, { useEffect, useState } from "react";
import { authenticateMeUrl } from "services";
import { get } from "helper";

const Profile = ({ collapsed }) => {
    const [userFullName, setUserFullName] = useState();
    const [userMail, setUserMail] = useState();

    const getUser = async () => {
        const { user, success, message } = await get(authenticateMeUrl);
        if (!success) {
            notification.error({
                message: "Bilgilendirme",
                description: message,
                placement: "top"
            });
            return;
        }
        setUserFullName(user.full_name);
        setUserMail(user.email);
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <div className="profile">
            { collapsed ? (
                <>
                    <Row>
                        <Col>
                            <img
                                src={ userIcon }
                                alt=""
                                className="collapsed-user-icon"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Button className="profile-btn" type="link">
                            Profil
                        </Button>
                    </Row>
                </>
            ) : (
                <Row>
                    <Col span={ 14 } push={ 6 }>
                        <p className="user-name">{ userFullName }</p>
                        <p className="user-mail">{ userMail }</p>
                    </Col>
                    <Col span={ 8 } pull={ 16 }>
                        <img src={ userIcon } alt="" className="user-icon" />
                    </Col>
                    <Col span={ 2 } push={ 4 }>
                        <img
                            src={ profileIcon }
                            alt=""
                            className="profile-icon-btn"
                        />
                    </Col>
                </Row>
            ) }
        </div>
    );
};

export default Profile;
