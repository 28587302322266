import { EditOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Modal, Select, Upload, notification } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addCategoryUrl, categoryListUrl, categoryUrl, imagePlaceHolderUrl } from "services";
import { DrawerComp } from "components/common/Drawer";
import { fileToBase64, formatResult, get, notificationError, notificationWarning, post, put } from "helper";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id: primaryKey } = useParams();

    const [categoryData, setCategoryData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [imageList, setImageList] = useState(undefined);
    const [categories, setCategories] = useState(undefined);
    const [open, setOpen] = useState(true);

    const getCategories = async () => {
        const { result, success, message } = await post(categoryListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        if (primaryKey) {
            const filteredCategory = result?.filter((category => category.id !== +primaryKey));
            setCategories(formatResult(filteredCategory));
        }
        else setCategories(formatResult(result));
    };

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${categoryUrl}/${primaryKey}`);
            if (!success) {
                notificationError(message);
                return;
            }
            setCategoryData(result);
            setFileList([{
                name: "image.png",
                status: "done",
                uid: "-1",
                url: result?.image
            }]);
        }
    };

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    const handleSubmit = async (values) => {
        const newValues = { ...values, image: imageList };
        const { success, message } = !primaryKey ? await post(addCategoryUrl, newValues) :
            await put(`${categoryUrl}/${primaryKey}`, { ...newValues });
        if (!success) {
            notificationError(message);
            return;
        }
        setFileList([]);
        setCategoryData(undefined);
        onClose();
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await fileToBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        toggleImagePreview();
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const handleChange = async ({ fileList: newFileList }) => {
        let tempFileList = [];
        const maxFileSize = 5 * 1024 * 1024;
        if (newFileList?.at(-1)?.size > maxFileSize) {
            tempFileList = newFileList.slice(0, -1);
            setFileList(tempFileList);
            notificationWarning("Resim boyutu en fazla 5 mb olabilir!");
            return;
        }
        tempFileList.push(newFileList?.at(-1));
        setFileList(tempFileList);
        if (tempFileList?.length > 0) {
            const base64 = await fileToBase64(tempFileList?.at(0)?.originFileObj);
            setImageList(base64);
        }
    };

    const toggleImagePreview = () => setPreviewOpen(prev => !prev);

    const props = {
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        }
        ,
        fileList,
        onRemove: () => {
            setFileList([]);
            return null;
        }
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        getCategories();
        if (!primaryKey) setFileList([{
            name: "image.png",
            status: "done",
            uid: "-1",
            url: imagePlaceHolderUrl
        }]);
    }, []);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({ description: categoryData?.description, is_corporate: categoryData?.is_corporate, name: categoryData?.name, parent_id: categoryData?.upper_category?.name });
        }
    }, [form, categoryData, visible]);

    return (
        <DrawerComp
            handleSubmit={ handleSubmit }
            setOpen={ setOpen }
            open={ open }
            form={ form }
            type={ "addCategory" }
        >
            <Upload className="catalog_upload"
                listType="picture-card"
                onPreview={ handlePreview }
                onChange={ handleChange }
                fileList={ fileList }
                { ...props }
            >
                <EditOutlined />
            </Upload>
            <br />
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSubmit }
            >
                <Form.Item
                    label="Üst Kategori"
                    name="parent_id"
                    style={ { width: "100%" } }
                >
                    <Select
                        placeholder="Lütfen üst kategori seçiniz"
                        allowClear
                        showSearch
                        filterOption={ (inputValue, option) =>
                            option.children?.toLowerCase().includes(inputValue.toLowerCase())
                        }
                    >
                        { categories?.map((option) => (
                            <Select.Option key={ option.value }>{ option.label }</Select.Option>
                        )) }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Kategori"
                    name="name"
                    rules={ [{ message: "Lütfen kategori adı giriniz!", required: true }] }
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Açıklama"
                    style={ { width: "100%" } }
                    rules={ [{ message: "Lütfen açıklama giriniz!", required: true }] }
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    name="is_corporate"
                    valuePropName={ "checked" }
                    style={ { width: "100%" } }
                >
                    <Checkbox>Kurumsalda Göster</Checkbox>
                </Form.Item>


            </Form>
            <Modal
                open={ previewOpen }
                title={ previewTitle }
                footer={ null }
                onCancel={ toggleImagePreview }
            >
                <img
                    alt="product_SKU"
                    style={ {
                        width: "100%"
                    } }
                    src={ previewImage }
                />
            </Modal>
        </DrawerComp>
    );
};
export default AddView;
