import { Navigate, Route, Routes } from "react-router-dom";
import { BufipayPage } from ".";
import { MerchantSettingsPage } from "./merchant";
import { PaymentPage, SuccessPage } from "./payment";
import { AddPaymentRouter } from "./payment/add";
import { EditPaymentRouter } from "./payment/edit";
import { PosaccountsPage } from "./posaccounts";
import { AddPosaccountsRouter } from "./posaccounts/add";
import { InstallmentsVirtualPosRouter } from "./posaccounts/installments";
import { ProtectedRoute } from "components";

const Router = () => (
    <Routes>
        <Route index element={ <BufipayPage /> } />
        <Route path="bufipaysum"    element={ <Navigate to="/bufipay" replace /> } /> 
        <Route element={ <ProtectedRoute permissionKey={ "payment.view" } /> }>  
            <Route path="payments">
                <Route index element={ <PaymentPage /> } />
                <Route element={ <ProtectedRoute permissionKey={ "payment.add" } /> }>  
                    <Route path="add/*" element={ <AddPaymentRouter /> } />
                    <Route path="success" element={ <SuccessPage /> } />
                </Route>
                <Route element={ <ProtectedRoute permissionKey={ "payment.edit" } /> }>  
                    <Route path=":id/edit/*" element={ <EditPaymentRouter /> } />
                </Route>
                <Route path="*" element={ <Navigate to="/bufipay/payments" /> } />
            </Route>
        </Route>
        <Route path="cancellations" element={ <p>İptaller</p> } />
        <Route path="returns" element={ <p>İadeler</p> } />
        <Route path="unansweredpayments" element={ <p>Cevaplanmayan Ödemeler</p> } />
        <Route path="paybylink" element={ <p>Link veya QR ile Ödeme</p> } />
        <Route path="reports" element={ <p>Raporlar</p> } />
        <Route path="merchantsettings" element={ <MerchantSettingsPage /> } />
        <Route element={ <ProtectedRoute permissionKey={ "virtual.view" } /> }>  
            <Route path="virtual-posaccounts">
                <Route index element={ <PosaccountsPage /> }/>
                <Route element={ <ProtectedRoute permissionKey={ "virtual.add" } /> }>  
                    <Route path="add/*" element={ <AddPosaccountsRouter /> }/>
                </Route>
                <Route element={ <ProtectedRoute permissionKey={ "virtual.edit" } /> }>  
                    <Route path=":id/edit/*" element={ <AddPosaccountsRouter /> } />
                    <Route path=":id/installments/*" element={ <InstallmentsVirtualPosRouter /> } />
                </Route>
                <Route path="*" element={ <Navigate to="/bufipay/virtual-posaccounts" /> } />
            </Route>
        </Route>
        <Route path="apimanagement" element={ <p>Api Management</p> } />
    </Routes>
);

export default Router;
