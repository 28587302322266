import { Route, Routes } from "react-router-dom";
import { addUrl, catalogProductUrl, listUrl, productUrl } from "services";
import { EditView } from "./edit";

const Breadcrumbs = [
    {
        path: "/catalog",
        breadcrumbName: "Katalog"
    },
    {
        path: "/product",
        breadcrumbName: "Ürün Kartları"
    }
];

const BreadcrumbsAdd = [
    {
        path: "/catalog",
        breadcrumbName: "Katalog"
    },
    {
        path: "/catalog/product",
        breadcrumbName: "Ürün Kartları"
    },
    {
        path: "/add",
        breadcrumbName: "Yeni Ürün"
    }
];

const Columns = [
    {
        title: "Ürün",
        render: (_, record) => (
            <div>
                <div>{ record.name }</div>
                <div className="text-muted" style={ { fontSize:"14px" } }>Ürün Türü: { record.type.name }</div>
            </div>
        )

    },
    {
        dataIndex: "category",
        title: "Kategori",
        render: (category) => category.name
    },
    {
        dataIndex: "brand",
        title: "Marka",
        render: (brand) => brand.name
    }
];

const Path = "/catalog/product";
const Url = productUrl;
const ListUrl = `${Url}${listUrl}`;
const addPath = `${catalogProductUrl}${addUrl}`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path=":id/edit" element={ <EditView onHide={ onHide }/> } />
    </Routes>
);

export { addPath, Breadcrumbs, BreadcrumbsAdd, Columns, Path, Url, ListUrl, Router };

