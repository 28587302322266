import { Form, Select } from "antd";

const RoleList = ({ onSelectedRoleHandler, roleList }) => {

    const onRoleChangeHandler = (selectedValue) => {
        const selectedRoleType = roleList?.find(role => role.value === selectedValue)?.type;
        onSelectedRoleHandler(selectedValue, selectedRoleType);
    };

    return (
        <Form.Item
            label="Roller"
            rules={ [{ message:"Lütfen role seçiniz", required: true }] }
        >
            <Select
                allowClear
                onChange={ onRoleChangeHandler }
                showSearch
                filterOption={ (inputValue, option) =>
                    option.children?.toLowerCase().includes(inputValue.toLowerCase())
                }
            >
                { roleList ?.map((option) => (
                    <Select.Option key={ option.value }>{ option.label }</Select.Option>
                )) }
            </Select> 
        </Form.Item>
    );
};

export default RoleList;
