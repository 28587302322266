import { LogoutOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import React from "react";

const Logout = ({ collapsed, logout }) => {
    return (
        <div className="logout">
            { collapsed ? (
                <Popconfirm
                    className="logout-collapsed-confirm"
                    title="Emin misiniz？"
                    okText="Evet"
                    cancelText="Hayır"
                    onConfirm={ logout }
                >
                    <Button
                        type="link"
                        className="logout-collapsed-btn"
                        icon={
                            <LogoutOutlined className="logout-collapsed-icon" />
                        }
                    />
                </Popconfirm>
            ) : (
                <Popconfirm
                    className="logout-uncollapsed-confirm"
                    title="Emin misiniz？"
                    okText="Evet"
                    cancelText="Hayır"
                    onConfirm={ logout }
                >
                    <Button
                        className="logout-uncollapsed-btn"
                        icon={
                            <LogoutOutlined className="logout-uncollapsed-icon" />
                        }
                    >
                        Çıkış Yap
                    </Button>
                </Popconfirm>
            ) }
        </div>
    );
};

export default Logout;
