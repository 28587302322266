import { Tag } from "antd";
import React from "react";

const Tags = ({ user } ) => (
    <>
        <Tag color="orange">{ user?.source }</Tag>
        <Tag color={ user?.is_confirmed ? "green" : "yellow" }>
            { user?.is_confirmed ? "Onaylandı" : "Onay Bekliyor" }
        </Tag>
        <Tag color={ user?.is_online ? "blue" : "red" }>{ user?.is_online ? "Çevrimiçi" : "Çevrimdışı" }</Tag>
        { user?.is_new ? <Tag color="green">Yeni</Tag> : null }
    </>
);

export default Tags;
