import { Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { addressCountiesUrl, citiesAddressListUrl, companyListUrl } from "services";
import { addressTypeList } from "../Constant";
import { statusOptions } from "constant";
import { notificationError } from "helper";
import { get, post } from "helper/HttpHelpers";
import { formatResult } from "helper/TableHelpers";

const FilterMenu = ({ form, handleSubmit }) => {
    const [companies, setCompanies] = useState(undefined);
    const [citiesAddress, setCitiesAddress] = useState(undefined);
    const [countiesAddress, setCountiesAddress] = useState(undefined);

    const getCompanies = async () => {
        const { result, success, message } = await post(companyListUrl, {
            pageSize: 1000,
            filter: { active: 1 }
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCompanies(formatResult(result));
    };

    const getCitiesAddress = async () => {
        const { result, success, message } = await get(citiesAddressListUrl, {
            pageSize: 1000,
            filter: { active:1 }
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCitiesAddress(formatResult(result));
    };

    const getCountiesAddress = async (e) => {
        if(!e)return;
        const { result, success, message } = await get(`${addressCountiesUrl}${e}`, {
            pageSize: 1000,
            filter: { active:1 }
        });
        if (!success) {
            notificationError(message);
            return;
        }
        const counties=formatResult(result);
        setCountiesAddress(counties);
        form.setFieldsValue({ "county_id":counties?.at(0)?.label });
    };

    useEffect(() => {
        getCompanies();
        getCitiesAddress();
    }, []);
    
    return (
        <Form
            form={ form }
            layout="vertical"
            requiredMark={ "Required" }
            initialValues={ { active:statusOptions[1].value } }
            onFinish={ handleSubmit }
        >
            <Form.Item
                label="Durum"
                name="active"
                required
                tooltip="Veri durumu"
            >
                <Select options={ statusOptions } />
            </Form.Item>
            <Form.Item
                label="Firma"
                name="company_id"
                className="w-100"
            >
                <Select
                    placeholder="Lütfen firma seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                > { companies?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            </Form.Item>
            <Form.Item
                label="Adres Türü"
                name="type"
                className="w-100"
            >
                <Select
                    placeholder="Lütfen adres türünü seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                > { addressTypeList?.map((option) => 
                        (
                            <Select.Option key={ option.value }>{ option.label }
                            </Select.Option>
                        )) }
                </Select>
            </Form.Item>
            <Row gutter={ 24 }>
                <Col span={ 12 }>
                    <Form.Item
                        label="İl"
                        name="city_id"
                        className="phone-Input"
                    >
                        <Select
                            placeholder="Lütfen İl seçiniz"
                            allowClear
                            showSearch
                            filterOption={ (inputValue, option) =>
                                option.children?.toLowerCase().includes(inputValue.toLowerCase())
                            }
                            onChange={ getCountiesAddress }
                        > { citiesAddress?.map((option) =>
                                (<Select.Option key={ option.value }>
                                    { option.label }
                                </Select.Option>)) }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={ 12 }>
                    <Form.Item
                        label="İlçe"
                        name="county_id"
                        className="phone-Input"
                    >
                        <Select
                            placeholder="Lütfen İlçe seçiniz"
                            allowClear
                            showSearch
                            filterOption={ (inputValue, option) =>
                                option.children?.toLowerCase().includes(inputValue.toLowerCase())
                            }
                        >
                            { countiesAddress?.map((option) => (
                                <Select.Option key={ option.value }>{ option.label }
                                </Select.Option>
                            )) }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
         
        </Form>
    );
};
export default FilterMenu;
