import { Navigate, Route, Routes } from "react-router-dom";
import { AccountPage } from ".";
import { AddressPage } from "./address";
import { CompanyPage } from "./company";
import { CompanyDetailRouter } from "./company/detail";
import { ProtectedRoute } from "components";

const Router = () => (
    <Routes>
        <Route index element={ <AccountPage /> } />   
        <Route path="accountsum"    element={ <Navigate to="/account" replace /> } /> 
        <Route element={ <ProtectedRoute permissionKey={ "company.view" } /> }>   
            <Route path="company/*" element={ <CompanyPage /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "address.view" } /> }>  
            <Route path="address/*" element={ <AddressPage /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "company.edit" } /> }>  
            <Route path="company/:id/overview/*" element={ <CompanyDetailRouter /> } />
        </Route>
    </Routes>  
);

export default Router;
