import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Statistic } from "antd";
import React from "react";

const ProductDetails = () =>  (
    <>
        <Col >
            <Card className="rounded">
                <Statistic 
                    title="Kar"
                    value={ 11.28 }
                    precision={ 2 }
                    valueStyle={ {
                        color: "#3f8600"
                    } }
                    prefix={ <ArrowUpOutlined /> }
                    suffix="%"
                />
            </Card>
        </Col>
        <Col >
            <Card className="rounded">
                <Statistic
                    title="Sipariş"
                    value={ 9.3 }
                    precision={ 2 }
                    valueStyle={ {
                        color: "#cf1322"
                    } }
                    prefix={ <ArrowDownOutlined /> }
                    suffix="%"
                />
            </Card>
        </Col>
        <Col >
            <Card className="rounded">
                <Statistic
                    title="Ciro"
                    value={ 9.3 }
                    precision={ 2 }
                    valueStyle={ {
                        color: "#cf1322"
                    } }
                    prefix={ <ArrowDownOutlined /> }
                    suffix="%"
                />
            </Card>
        </Col>
    </>
);

export default ProductDetails;
