import { Form, InputNumber } from "antd";

const colours = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple"
];

export const priceColumns = [
    {
        render: (_, record, i) => (
            <Form.Item
                name={ [`${record?.category_id}`, "category_id"] }
                initialValue={ record?.category_id }            >
                <div style={ { color: colours?.at(i % 11) } }>{ record?.name }</div>
            </Form.Item>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        title: "Kategori"
    },
    {
        render: (_, record) => (
            <Form.Item
                name={ [`${record?.category_id}`, "price1"] }
                initialValue={ record?.price1 }
                rules={ [
                    {
                        message: "Lütfen bu alanı boş bırakmayınız!",
                        required: true
                    }
                ] }
            >
                <InputNumber
                    type="number"
                    min={ 0 }
                    precision={ 2 }
                    max={ 10000000 }
                    controls={ false }
                    onWheel={ (e) => e.target.blur() }
                    value={ 0 }
                />
            </Form.Item>
        ),
        title: "Peşin Fiyatı"
    },
    {
        render: (_, record) => (
            <Form.Item
                name={ [`${record?.category_id}`, "price2"] }
                initialValue={ record?.price2 }
                rules={ [
                    {
                        message: "Lütfen bu alanı boş bırakmayınız!",
                        required: true
                    }
                ] }
            >
                <InputNumber
                    type="number"
                    min={ 0 }
                    precision={ 2 }
                    max={ 10000000 }
                    controls={ false }
                    onWheel={ (e) => e.target.blur() }
                />
            </Form.Item>
        ),
        title: "K. Kartı Fiyatı"
    },
    {
        render: (_, record) => (
            <Form.Item
                name={ [`${record?.category_id}`, "price3"] }
                initialValue={ record?.price3  }
                rules={ [
                    {
                        message: "Lütfen bu alanı boş bırakmayınız!",
                        required: true
                    }
                ] }
            >
                <InputNumber
                    type="number"
                    min={ 0 }
                    precision={ 2 }
                    max={ 10000000 }
                    controls={ false }
                    onWheel={ (e) => e.target.blur() }
                />
            </Form.Item>
        ),
        title: "Vadeli Fiyatı"
    },
    {
        render: (_, record) => (
            <Form.Item
                name={ [`${record?.category_id}`, "price4"] }
                initialValue={ record?.price4 }
                rules={ [
                    {
                        message: "Lütfen bu alanı boş bırakmayınız!",
                        required: true
                    }
                ] }
            >
                <InputNumber
                    type="number"
                    min={ 0 }
                    precision={ 2 }
                    max={ 10000000 }
                    controls={ false }
                    onWheel={ (e) => e.target.blur() }
                />
            </Form.Item>
        ),
        title: "Liste Fiyatı"
    },
    { render: (_, record) => (
        <Form.Item
            name={ [`${record?.category_id}`, "price5"] }
            initialValue={ record?.price5 }
            rules={ [
                {
                    message: "Lütfen bu alanı boş bırakmayınız!",
                    required: true
                }
            ] }
        >
            <InputNumber
                type="number"
                min={ 0 }
                precision={ 2 }
                max={ 10000000 }
                controls={ false }
                onWheel={ (e) => e.target.blur() }
            />
        </Form.Item>
    ),
    title: "Perakende Fiyatı"
    }
];
