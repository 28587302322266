import { notification } from "antd";

export const notificationError = (message) =>
    notification.error({
        description: message,
        message: "Bilgilendirme",
        placement: "top"
    } );
	
export const notificationSuccess= (message) =>
    notification.success({
        description: message,
        message: "Bilgilendirme",
        placement: "top"
    } );
	
export const notificationWarning = (message) =>
    notification.warning({
        description: message,
        message: "Bilgilendirme",
        placement: "top"
    } );
	
export const notificationInfo = (message) =>
    notification.info({
        description: message,
        message: "Bilgilendirme",
        placement: "top"
    } );
	
export const notificationOpen = (message) =>
    notification.open({
        description: message,
        message: "Bilgilendirme",
        placement: "top"
    } );
	
export const notificationBox = (type, message) => {
    switch (type) {
    case "success":
        notificationSuccess(message);
        break;
    case "error":
        notificationError(message);
        break;
    case "warning":
        notificationWarning(message);
        break;
    case "info":
        notificationInfo(message);
        break;
    case "open":
        notificationOpen(message);
        break;
    default:
        notificationError(message);
        break;
    }
};

