import { Button, Divider, Modal, Tooltip, Upload, notification } from "antd";
import React, { useEffect, useState } from "react";
import { uploadProductImageUrl } from "services";
import { fileToBase64, notificationWarning, post, useProduct } from "helper";
import { uploadButton } from "routes/catalog/Constant";

const PictureUpload = ({ productSKU_ID }) => {
    const { mutateData, data, error } = useProduct(productSKU_ID);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [imageCount, setImageCount] = useState(0);
    const maxImageCount = 10 - imageCount;

    const toggleImagePreview = () => setPreviewOpen((prev) => !prev);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await fileToBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        toggleImagePreview();
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const handleChange = ({ file, fileList: newFileList }) => {
        const maxFileSize = 1 * 1024 * 1024;
        if (file?.size > maxFileSize)
            notificationWarning("Resim boyutu en fazla 1Mb olabilir!");
        const imagesLt1M = newFileList
            .filter((item) => item.size < maxFileSize)
            ?.filter((_, i) => i < maxImageCount);
        setFileList( imagesLt1M );
        
        Promise.all(imagesLt1M?.map((item) => fileToBase64(item.originFileObj)))
            .then((results) => {
                setImageList(results);
            })
            .catch((error) => {
                console.error(error.toString());
            });
    };

    const handleUpload = async () => {
        setUploading(true);
        const { success, message } = await post(uploadProductImageUrl, {
            id: productSKU_ID,
            images: imageList
        });
        if (!success) {
            setUploading(false);
            notification.error({
                message: "Bilgilendirme",
                description: message,
                placement: "top"
            });
            return;
        }
        mutateData();
        setUploading(false);
        setFileList([]);
    };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList
    };

    useEffect( () => {
        if (data && !error)
            setImageCount(data?.result?.images?.length);
    }, [data, error]);

    return (
        <>
            <Divider orientation="left">Resim Yükle</Divider>      
            <Upload
                multiple={ true }
                listType="picture-card"
                fileList={ fileList }
                onPreview={ handlePreview }
                onChange={ handleChange }
                { ...props }
            >
                <Tooltip
                    title={ `En fazla ${ maxImageCount } adet resim yükleyebilirsiniz!` }>
                    { fileList.length >= 8 ? null : uploadButton }
                </Tooltip>
            </Upload>
            <Modal
                open={ previewOpen }
                title={ previewTitle }
                footer={ null }
                onCancel={ toggleImagePreview }
            >
                <img
                    alt="product_SKU"
                    style={ {
                        width: "100%"
                    } }
                    src={ previewImage }
                />
            </Modal>
            <Button
                type="primary"
                onClick={ handleUpload }
                disabled={ fileList.length === 0 }
                loading={ uploading }
                style={ { marginTop: 16 } }
            >
                { uploading ? "Yükleniyor" : "Yüklemeye Başla" }
            </Button>
        </>
    );
};
export default PictureUpload;
