import { notification } from "antd";
import { userListUrl } from "services";
import { post } from "./HttpHelpers";

export const checkIfUserExists = async (inputType, value) => {    
    const { success,result,message }= await post(userListUrl, { pageSize: 1000 , filter:{ [inputType]:value } });
    if (!success) {
        notification.error({
            message: "Bilgilendirme",
            description: message,
            placement: "top"
        });
        return true;
    } else {
        if(result.length > 0 ) {
            return true;
        } return false;
    }                                                
};
