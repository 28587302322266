export const titleValues={
    addUser: "Kullanıcı Ekle",
    addAddress: "Adres Ekle",
    edit: "Filtreleme",
    addCompany:"Firma Ekle",
    addBrand:"Marka Ekle",
    addCampaign:"Kampanya Ekle",
    addCategory:"Kategori Ekle",
    addCollection:"Koleksiyon Ekle",
    addProductAttribute:"Ürün Özellik Ekle",
    addProductAttributeValue:"Ürün Özellik Değerleri Ekle",
    addProductType:"Ürün Türü Ekle",
    addRole:"Rol Ekle"
};
