import { Checkbox, Divider, Form, Input, Select } from "antd";
import React from "react";
import { virtualPosVirtualUrl } from "services";
import { checkboxFormItemLayout, notificationMessageAdd, notificationMessageEdit, wrapperCol } from "./Constant";
import { notificationError, notificationSuccess, post, put } from "helper";

export const posAccountsRenderHelper = (posInfo) => {

    let tempTitle = "";
    let categoryHeader = null;

    const renderingData = posInfo.map((item, i) => {
        const { category, valueType, selection, value, dataType, name, required, message, htmlType }=item || {};
        let propName = {};
        let label={ label: item?.label };
        let wrapperColumn = wrapperCol;
        let htmlElement=null;
        const isCheckbox=htmlType === "checkbox";
        const isSelect=valueType === "list" ;
        const isInput=(dataType==="String" || dataType==="Integer" || !value) ;

        if (tempTitle !== category) {
            categoryHeader = (<Divider orientation="center">{ category }</Divider>);
            tempTitle = category;
        }
        else categoryHeader = null;
 
        if (isSelect) htmlElement = (
            <Select
                mode={ selection==="multiple"? "multiple": false }
                options={ value?.map((option) => ({
                    value: option,
                    label: option === true ? "Evet" : option === false ? "Hayır" : option.toString()
                })) }>
            </Select>);
        else if (isCheckbox) {
            label ={};
            propName = { valuePropName: "checked" };
            wrapperColumn = checkboxFormItemLayout;
            htmlElement = (<Checkbox>{ item.label }</Checkbox>);
        }
        else if(isInput) htmlElement = (<Input />);

        return (
            <React.Fragment key={ i+1 }>
                { categoryHeader }
                <Form.Item
                    name={ name }
                    rules={ [
                        {
                            required: required ,
                            message: message
                        }
                    ] }
                    { ...label }
                    { ...propName }
                    { ...wrapperColumn }
                >
                    { htmlElement }
                </Form.Item>
            </React.Fragment>
        );
    }
    );
    return renderingData; 
};

export const schemeFormatHelper = (schemeData) =>
    schemeData.map((item) => ({ id: item?.id, scheme: item?.pos_defaults?.pos }));

export const editPutHandler = async (posId, values) => {
    const { success, message: msg } = await put(`${virtualPosVirtualUrl}/${posId}`, values);
    if (!success) {
        notificationError(msg);
        return;
    }
    notificationSuccess(notificationMessageEdit);
};

export const addPostHandler = async (values) => {
    const { success, message: msg } = await post(virtualPosVirtualUrl, values);
    if (!success) {
        notificationError(msg);
        return;
    }
    notificationSuccess(notificationMessageAdd);
};
