import { Button, Popconfirm } from "antd";
import { cartRemoveContinueUrl } from "services";
import { Columns, ListUrl } from "./Constant";
import { delete_, getColumns, notificationError, notificationSuccess } from "helper";
import { usePost } from "helper/Hook";

const path = location.pathname.split("/");
const userId = parseInt(path?.at(-2));

export const useTable = (mutateData) => {
    const onHide = async () => {
        await mutateData();
    };

    const deleteAction = async (productId,cartKey) => {
        const { success, message:msg } = await delete_(`${cartRemoveContinueUrl}${productId}/${cartKey}/${userId}`);
        if (!success) {
            notificationError(msg);
            return;
        }
        notificationSuccess("Ürün sepetten çıkarıldı");
        onHide();
    };

    const renderAction = (_, record) => (
        <>
            <Popconfirm okText="Evet" cancelText="Hayır" 
                title="Eminmisiniz?" onConfirm={ () => deleteAction(record.id,record.key) }>
                <Button type="link" size="small" danger>
                    <i className="flaticon2-trash" />
                </Button>
            </Popconfirm>
        </>
    );

    const columns = getColumns(Columns, renderAction,null,null,true);
    return { columns, onHide };
};

export const useData = (queryParams) => {
    if ( queryParams.filter.active ) {
        const newFilter = {
            user_id : userId
        };
        delete queryParams.filter.active;
        queryParams.filter = newFilter;
    }
    return usePost(ListUrl, queryParams);
};

export const getFilterQuery = (queryParams, values) => {
    const { name } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (name) filter.name__ilike = `%${name}%`;
    if (userId) filter.user_id = userId;
    newQueryParams.filter = filter;
    return newQueryParams;
};
