export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "5", value: 5 },
    { text: "10", value: 10 }
];
export const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: { active : 1 }
};
export const statusOptions = [
    { value: "", label: "Tümü" },
    { value: "1", label: "Aktif" },
    { value: "0", label: "Pasif" }
];
export const sizes = [
    { label: "3", value: 3 },
    { label: "5", value: 5 },
    { label: "10", value: 10 }
];
export const positionTypes = [
    { value: 0, label: "Nokta" },
    { value: 1, label: "Yapı" },
    { value: 2, label: "Rota / Yol" }
];
export const infoFormItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 14
    }
};

export const comparingInitialFilter = {
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: { active : 1 }
};

export const comparingInitialSalesFilter = {
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: { }
}; 

