import {
    ArrowUpOutlined,
    ShoppingCartOutlined
} from "@ant-design/icons";
import { Tag } from "antd";
import { colorStatus } from "../../Constant";

export const addKeyHelper = (data) =>
    data?.map((item) => ({ ...item, key: item.id }));

export const billingDataHelper = ( billing_address ) => {
    const { title,name, address1, city, county, phone1 } = billing_address || {};
    const PaymentBillingTags = [
        ["Adres Başlığı", title],
        ["İsim", name],
        ["Adres", address1],
        ["Şehir", city?.name],
        ["İlçe", county?.name],
        ["Telefon", phone1]
    ];
    return PaymentBillingTags || [];
};

export const customerDataHelper = (buyer) => {
    const { company, user } = buyer || {};
    const { full_name, email, gsm } = user || {};
    const customerTags = [
        ["Firma", company?.name],
        ["Kullanıcı", full_name],
        ["E-Posta", email],
        ["Gsm", gsm]
    ];
    return customerTags || [];
};

export const orderDataHelper = (data) => {
    const { total_cost, total_count, total_quantity, status_label, note } = data || {};
    const statisticalData = [
        ["Toplam Tutar", total_cost, 2, <ArrowUpOutlined key={ 1 } />, "TL", null],
        [
            "Ürün Sayısı",
            total_count,
            null,
            <ShoppingCartOutlined key={ 2 } />,
            null,
            null
        ],
        [
            "Ürün Çeşidi",
            total_quantity,
            null,
            <ShoppingCartOutlined key={ 3 } />,
            null,
            null
        ],
        [
            "Durum",
            status_label,
            null,
            null,
            null,
            colorStatus[status_label]
        ],
        [
            "Sipariş Notu",
            note,
            null,
            null,
            null,
            null
        ]
    ];

    return statisticalData || [];
};

export const shippingDataHelper = (address) => {
    const { title, name, address1, city, county, phone1, type } = address || {};
    const addressType = { company: "Şube Adresi", shipment: "Müşteri adresi" };
    const colorStatus = { "Müşteri adresi": "cyan", "Şube Adresi": "blue" };
    const addressTitle=  ( type in addressType  )
        ?  addressType[type]
        : null; 
    const color = ( addressTitle in colorStatus )
        ? colorStatus[ addressTitle ]
        : null;
    const tagsData = {
        addressType:( <Tag color={ color } className="font-weight-light">{ addressTitle }</Tag>),
        newData: [
            ["Adres Başlığı", title],
            ["İsim", name],
            ["Adres", address1],
            ["Şehir", city?.name],
            ["İlçe", county?.name],
            ["Telefon", phone1]
        ]
    };
    return tagsData || { data:null, type:null };
};
