import { Form, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { brandListUrl, categoryListUrl, productTypeListUrl } from "services";
import { Images, onSale } from "../Constant";
import { statusOptions } from "constant";
import { post } from "helper";
import { formatResult } from "helper/TableHelpers";

export const FilterMenu = ({ form, handleSubmit }) => {

    const [brands,setBrands] = useState();
    const [category,setCategory] = useState();
    const [type,setType] = useState();

    const getBrands = async () => {
        const { result, success, message } = await post(brandListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setBrands(formatResult(result));
    };

    const getCategory = async () => {
        const { result, success, message } = await post(categoryListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setCategory(formatResult(result));
    };

    const getType = async () => {
        const { result, success, message } = await post(productTypeListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setType(formatResult(result));
    };

    useEffect(() => {
        getBrands();
        getCategory();
        getType();
    }, []);

    return (
        <Form
            form={ form }
            layout="vertical"
            requiredMark={ "Required" }
            initialValues={ {
                active: statusOptions[1].value,
                has_images: Images[0].value,
                on_sale: onSale[0].value
            } }
            onFinish={ handleSubmit }
        >
            <Form.Item
                label="Durum"
                name="active"
                required
                tooltip="Veri durumu"
            >
                <Select options={ statusOptions } />
            </Form.Item>
            <Form.Item
                label="Marka"
                name="brand"
            >
                <Select 
                    mode="multiple"
                    allowClear options={ brands } />
            </Form.Item>
            <Form.Item
                label="Kategori"
                name="category_id"
            >
                <Select 
                    mode="multiple"
                    allowClear options={ category } />
            </Form.Item>
            <Form.Item
                label="Satış Durumu"
                name="on_sale"
            >
                <Select 
                    options={ onSale } />
            </Form.Item>
            <Form.Item
                label="Tür"
                name="type_id"
            >
                <Select 
                    mode="multiple"
                    allowClear options={ type } />
            </Form.Item>
            <Form.Item
                label="Resim"
                name="has_images"
            >
                <Select options={ Images } />
            </Form.Item>
        </Form>
    );
};
