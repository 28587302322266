import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "context";

const ProtectedRoute= ({ permissionKey }) => {
    const location = useLocation();
    const { user } = useAuth();

    const { is_admin = false, permissions = [] } = user;

    const renderingRoute=is_admin || permissions?.indexOf(permissionKey)!== -1  ? (
        <Outlet />
    ) : (
        <Navigate to="/dashboard" state={ { from: location } } replace />
    );

    return (<>{ renderingRoute }</>); 
};

export default ProtectedRoute;
