import { Button, Popconfirm, notification } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";
import { deleteUrl } from "services";
import { Columns, ListUrl, Path, Url } from "./Constant";
import { ProtectedElement } from "components";
import { delete_, getColumns } from "helper";
import { usePost } from "helper/Hook";

export const useTable = (mutateData) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate(Path);
    };

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${Url}${deleteUrl}/${primaryKey}`);
        if (!success) {
            notification.error({
                message: "Bilgilendirme",
                description: message,
                placement: "top"
            });
            return;
        }
        onHide();
    };

    const renderAction = (_, record) => (
        <>
            <ProtectedElement permissionKey={ "address.edit" }>  
                <Button
                    onClick={ () => navigate(`${record.id}/edit`) }
                    type="link"
                    size="small"
                >
                    <i className="flaticon2-edit text-muted" />
                </Button>
            </ProtectedElement>
            <ProtectedElement permissionKey={ "address.delete" }>  
                <Popconfirm
                    okText="Evet"
                    cancelText="Hayır"
                    title="Eminmisiniz?"
                    onConfirm={ () => deleteAction(record.id) }
                >
                    <Button type="link" size="small" danger>
                        <i className="flaticon2-trash" />
                    </Button>
                </Popconfirm>
            </ProtectedElement>
        </>
    );

    const columns = getColumns(Columns, renderAction);
    return { columns, onHide };
};

export const useData = (queryParams) => {
    return usePost(ListUrl, queryParams);
};

export const getAddViewInitialValues = (data) => {
    const { type, name, title, city, county, address1, company } = data;
    const defaultObj = {
        address1,
        city_id: { label: city?.name, value: city?.id.toString() },
        company_id: {
            label: company?.name,
            value: company?.id.toString()
        },
        county_id: { label: county?.name, value: county?.id.toString() },
        name: name || "",
        title: title || "",
        type: type || ""
    };
    return defaultObj;
};

export const handleFormSubmitQuery = (data) => {
    const { type, name, title, city_id, county_id, address1, company_id } = data;
    const cityId = typeof city_id === "object" ? city_id.value : city_id;
    const countyId = typeof county_id === "object" ? county_id.value : county_id;
    const companyId = typeof company_id === "object" ? company_id.value : company_id;
    const query = {
        ...data,
        address1,
        city_id: cityId,
        company_id: companyId,
        county_id: countyId,
        name: name || "",
        title: title || "",
        type: type || ""
    };
    return query;
};

export const getFilterQuery = (queryParams, values) => {
    const { active, name, city_id, county_id, company_id, type } = values;
    const newQueryParams =cloneDeep(queryParams);
    const filter = {};
    if (active) filter.active = +active;
    if(company_id) filter.company_id = company_id;
    if (city_id) filter.city_id = city_id;
    if (county_id) filter.county_id = county_id;
    if (name) filter.title__ilike = `%${name}%`
    if(type)filter.type=type;
    newQueryParams.filter = filter;
    return newQueryParams;
};
