import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import DrawerComp from "./AuthInfo/Drawer";
import Email from "./AuthInfo/Email";
import Gsm from "./AuthInfo/Gsm";
import Password from "./AuthInfo/Password";
import { useUser } from "helper/Hook";

const AuthInfo = () => {
    const { id: userId } = useParams();
    const { data } = useUser(userId);
    const { result: user } = data;
    
    const [openMail, setOpenMail] = useState(false);
    const [openGsm, setOpenGsm] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    
    const toggleMailDrawer = () => setOpenMail((prev) => !prev);

    const toggleGsmDrawer = () => setOpenGsm((prev) => !prev);
    
    const togglePasswordDrawer = () => setOpenPassword((prev) => !prev); 

    return (
        <>
            <Card className="title">
                <p className="title-p">Giriş Bilgileri</p>
            </Card>
            <Card className="login-info-card-content">
                <Form
                    className="login-info-card-form"
                    name="basic"
                    labelCol={ {
                        lg: { pull:0,
                            span: 5 },
                        xl: { pull:1,
                            span: 5 },
                        xxl: { pull:2,
                            span: 5 }
                    } }
                    wrapperCol={ {
                        push:2,
                        span: 14
                    } }
                    initialValues={ {
                        ["email"]: user?.email,
                        ["gsm"]: user?.gsm,
                        ["password"]: "***************"
                    } }
                    autoComplete="off"
                >
                    <Form.Item label="Email">
                        <Row gutter={ 24 }>
                            <Col span={ 18 }>
                                <Form.Item name="email">
                                    <Input
                                        className="login-info-card-input"
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={ 6 }>
                                <Button
                                    className="login-info-card-btn"
                                    type="primary"
                                    onClick={ toggleMailDrawer }
                                >
                                    Değiştir
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label="Şifre">
                        <Row gutter={ 24 }>
                            <Col span={ 18 }>
                                <Form.Item name="password">
                                    <Input
                                        className="login-info-card-input"
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={ 6 }>
                                <Button
                                    className="login-info-card-btn"
                                    type="primary"
                                    onClick={ togglePasswordDrawer }
                                >
                                    Değiştir
                                </Button>  
                            </Col>
                        </Row>              
                    </Form.Item>
                    <Form.Item label="Telefon">
                        <Row gutter={ 24 }>
                            <Col span={ 18 }>
                                <Form.Item name="gsm">
                                    <Input
                                        className="login-info-card-input"
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={ 6 }>
                                <Button
                                    className="login-info-card-btn"
                                    type="primary"
                                    onClick={ toggleGsmDrawer }
                                >
                                    Değiştir
                                </Button>  
                            </Col>
                        </Row>          
                    </Form.Item>
                </Form>
                <DrawerComp open={ openMail } onClose={ toggleMailDrawer } title="E-Mail Değiştir">
                    <Email
                        setOpenMail={ setOpenMail }
                        openMail={ openMail }
                    />
                </DrawerComp>
                <DrawerComp open={ openPassword } onClose={ togglePasswordDrawer } title="Şifre Değiştir">
                    <Password 
                        setOpenPassword={ setOpenPassword } 
                    />
                </DrawerComp>
                <DrawerComp open={ openGsm } onClose={ toggleGsmDrawer } title="Telefon Değiştir">
                    <Gsm  
                        setOpenGsm={ setOpenGsm }
                        openGsm={ openGsm }
                    />
                </DrawerComp>
            </Card>
        </>
    );
};

export default AuthInfo;
