import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Card, Form, Input, Space, notification } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { post  } from "helper";



const RegisterPage = ({ setLoading,loading }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [ buttonText, setButtonText ] = useState( "Kayıt Ol" );
  
    const onFormSubmit = async ( data ) => {
        const phone = { phone: "+905" + data.phone };
        const newValues = {
            ...data,
            phone: "+905" + data.phone
        };    
        setLoading(true);
        setButtonText( "Bekleyiniz..." );     
        //     try {
        //         const { success, message } =
        //             await post("/authenticate/admin/register", { ...newValues });
        //         if (!success) {
        //             setLoading(false);
        //             setButtonText("Kayıt Ol");
        //             notification.error({
        //                 message: "Bilgilendirme",
        //                 description: message,
        //                 placement: "top"
        //             });
        //             return;
        //         }
        //         setButtonText("Kayıt Gerçekleşti!");     
        //         navigate("/auth/code-verification",{ state:{ ...phone } });
        //     } catch (e) {
        //         setButtonText("Kayıt Ol");
        //         setLoading(false);
        //         notification.error({
        //             message: "Bilgilendirme",
        //             description: e.toString(),
        //             placement: "top"
        //         });
        //     }   
    };

    return (
        <Card className="login_card" align="left">
            <Form
                size="small"
                form={ form }
                onFinish={ onFormSubmit }
                autoComplete="off"
            >
                <Space size="small" direction="vertical" style={ { width: "100%" } }>                  
                    <Form.Item
                        name="email"
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen e-posta adresinizi girin!"
                            },
                            {
                                type:"email",
                                message: "Lütfen geçerli e-posta adresi girin!"
                            } 
                        ] }
                    >
                        <Input 
                            bordered={ false }
                            placeholder="E-posta Adresi"
                        />
                    </Form.Item>
                    <Form.Item
                        className="phone-Input"
                        name="phone"
                        form={ form }
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen telefon numaranızı yazınız!"
                            },
                            {
                                min: 9,
                                max: 9,
                                message:"Lütfen geçerli bir telefon  yazınız!"
                            }                           
                        ] }
                    >
                        <Input 
                            bordered={ false }
                            prefix="+90 5"  
                            placeholder=" Telefon numarası" 
                            onChange={ ( e ) => form.setFieldsValue(
                                { "phone": e.target.value.replace( /\D/g, "" ).slice( 0, 9 ) } ) }
                        />                    
                    </Form.Item>     
                    <Form.Item
                        name="company"
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen  firma adını yazınız!"
                            },                   
                            {
                                min: 2,
                                max: 40,
                                message:"Firma adı en az 2 karakter olmalıdır!"
                            }
                        ] }
                    >
                        <Input
                            bordered={ false }
                            placeholder="Firma"
                        />
                    </Form.Item>   
                    <Form.Item
                        name="password"              
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen şifre yazınız!"
                            },
                    
                            {
                                min: 6,
                                message:"Lütfen en az 6 karakter yazınız!"
                            },
                            {
                                whitespace: true,
                                message:"Lütfen şifre yazınız!"
                            }
                        ] }
                    >
                        <Input.Password
                            bordered={ false }
                            placeholder="Şifre"
                            iconRender={ visible => ( visible ? <EyeTwoTone /> : <EyeInvisibleOutlined /> ) }
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen şifreyi tekrar yazınız!"
                            },                    
                            {
                                whitespace: true,
                                message:"Lütfen şifreyi tekrar yazınız!"
                            }, ( { getFieldValue } ) => ( {
                                validator ( _, value ) {
                                    if ( !value || getFieldValue( "password" ) === value )
                                        return Promise.resolve ();
                                    else return Promise.reject ("Girdiğiniz şifreler eşleşmiyor!");
                                }
                            })
                        ] }
                    >
                        <Input.Password
                            bordered={ false }
                            placeholder="Şifre Tekrar"
                            iconRender={ visible => ( visible ? <EyeTwoTone /> : <EyeInvisibleOutlined /> ) }
                        />                   
                    </Form.Item>
                    <Button block type="primary" disabled
                        htmlType="submit" loading={ loading }>
                        { buttonText }
                    </Button>
                </Space>
            </Form>  
        </Card>
    );
};

export default RegisterPage;
