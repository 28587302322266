import { presetPalettes } from "@ant-design/colors";
import { Card, Statistic } from "antd";
import React from "react";
const CustomCard = ( { cardTitle, cardValue, cardPrecision=null, cardPrefix=null, cardSuffix=null, cardColor } ) => 
{
    let style;
    if ( cardTitle === "Durum" ) {
        style={
            background: presetPalettes[ cardColor ]?.at( 0 ),
            border: `1px solid ${ presetPalettes[ cardColor ]?.at( 2 ) }`,
            color: cardColor,
            padding: "2px 5px"
        }; 
    }
    else style = {};

    return (
        <Card>
            <Statistic
                title={ cardTitle }
                value={ cardValue }
                precision={ cardPrecision }
                valueStyle={ { ...style } }
                prefix={ cardPrefix }
                suffix={ cardSuffix }
                style={ { minHeight: "90px" } }
            />
        </Card>
    );
};


export default CustomCard;
