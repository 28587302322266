import { CompassOutlined, FileMarkdownOutlined, LockOutlined, 
    PieChartOutlined, UserSwitchOutlined, UsergroupAddOutlined } from "@ant-design/icons";

import { Tag } from "antd";

export const UserMenuItems = [
    { icon: <PieChartOutlined style={ { fontSize: "16px" } }/>, key: "overview", label: "Kullanıcı Önizleme" },
    { icon: <UserSwitchOutlined style={ { fontSize: "16px" } }/>, key: "personal", label: "Kullanıcı Bilgileri" },
    { icon: <LockOutlined style={ { fontSize: "16px" } }/>, key: "password", label: "Şifre İşlemleri" },
    { icon: <UsergroupAddOutlined style={ { fontSize: "16px" } }/>, key: "permission", label: "Yetki İşlemleri" }
];
export const CompanyUserMenuItems = [
    { icon: <CompassOutlined style={ { fontSize: "16px" } }/>, key: "company-account", label: "Firma İşlemleri" },
    { icon: <FileMarkdownOutlined style={ { fontSize: "16px" } }/>, key: "company-address", label: "Adres İşlemleri" }

];

export const Breadcrumbs = [
    {
        breadcrumbName: "Üyelik İşlemleri",
        path: "/membership"
    },
    {
        breadcrumbName: "Kullanıcılar",
        path: "/membership/user"
    }
];

const getUserFields = (user) => {
    const { full_name, email, id, roles, gsm, is_confirmed, company } = user || {};
    const fields = [
        {
            title: "Ad Soyad",
            value: full_name
        },
        {
            title: "Email",
            value: email
        },
        {
            title: "Firma",
            value: company?.name
        },
        {
            title: "Telefon",
            value: (
                <>
                    <p className="gsm-input">{ gsm }</p>
                    <Tag color={ is_confirmed ? "green" : "red" }>
                        { is_confirmed ? "Onaylı" : "Onaylı Değil" }
                    </Tag>
                </>
            )
        },
        {
            title: "Kullanıcı İd",
            value: id
        },
        {
            title: "Rol",
            value: roles?.at(0)?.name
        },
        {
            title: "İletişim",
            value: "E-mail, Telefon"
        },
        {
            title: "İzinler",
            value: "Evet"
        }
    ];

    return fields;
};

export { getUserFields };
