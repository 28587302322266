import { Button, Col, Image, Row, Tag } from "antd";
import { Link } from "react-router-dom";
import { addUrl, bufipayVirtualPosAccountsUrl, listUrl, virtualPosVirtualUrl } from "services";

const Breadcrumbs = [
    {
        breadcrumbName: "Bufipay",
        path: "/bufipay"
    },
    {
        breadcrumbName: "Pos Hesapları",
        path: "/bufipay/virtual-posaccounts"
    }
];

const BreadcrumbsAdd = [
    {
        breadcrumbName: "Bufipay",
        path: "/bufipay"
    },
    {
        breadcrumbName: "Pos Hesapları",
        path: "/bufipay/virtual-posaccounts"
    },
    {
        breadcrumbName: "Yeni Pos Hesabı",
        path: "/bufipay/virtual-posaccounts/add"
    }
];

const BreadcrumbsInstallments = [
    {
        breadcrumbName: "Bufipay",
        path: "/bufipay"
    },
    {
        breadcrumbName: "Pos Hesapları",
        path: "/bufipay/virtual-posaccounts"
    },
    {
        breadcrumbName: "Taksit ve Komisyonlar",
        path: "/bufipay/virtual-posaccounts/installments"
    }
];

const Columns = [
    {
        render: (_, record) => (
            <Link to={ `${record?.id}/installments` }>
                <span className="symbol symbol-50px">
                    <Image 
                        preview={ false }
                        src={ record.image }
                    />
                </span>
            </Link>
        ),
        title: "",
        width: 80
    },
    {
        render: ( _, record ) => (
            <div>
                <div className="text-muted">{ record?.pos_name }</div>
                <div className="text-muted">Kaynak Kodu { record?.out_ref }</div>
                <div className="text-muted">Kayıt Tarihi { record?.create_date }</div>
                <div className="text-muted">Güncelleme Tarihi { record?.update_date }</div>
            </div>
        ),
        title: "Pos Bilgileri"
    },
    {
        render: ( _, record ) => (
            <Row gutter={ 24 }> 
                <Col span={ 14 }>
                    <span className="text-muted">
                        { record?.is_default === true ? <Tag color="blue">{ record?.is_default === true ? "Tanımlı Sanal Pos" : undefined }</Tag> : undefined }
                    </span>
                </Col>
                <Col span={ 10 }>
                    <span className="text-muted">
                        <Tag color="yellow">{ record?.installment_count }</Tag>
                    </span>
                </Col>
            </Row>
        ),
        title: "Özellikler"
    },
    {
        render: ( _, record ) => {
            return (
                <Link to={ `${record.id}/installments` }>
                    <Button type="button">Taksit ve Komisyonlar</Button>          
                </Link>
            );},
        title: "",
        width: 80
    }
];

const Url = virtualPosVirtualUrl;
const ListUrl = `${Url}${listUrl}`;
const Path = bufipayVirtualPosAccountsUrl;
const addPath = `${bufipayVirtualPosAccountsUrl}${addUrl}`;

export { Breadcrumbs, BreadcrumbsAdd, BreadcrumbsInstallments, ListUrl, Path, addPath, Url, Columns };
