import { Layout as AntLayout } from "antd";
import { Breadcrumb, Content } from "./";
import Footer from "./Footer";

const Layout = ({ children }) => {
    return (
        <AntLayout className="site-layout">
            <Breadcrumb />
            <Content>{ children }</Content>
            <Footer style={ { textAlign: "center" } } />
        </AntLayout>
    );
};

export default Layout;
