import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { searchHistoryListContinueUrl } from "services";
import { get } from "helper";

const Page = () => {
    const { id: userId } = useParams();

    const [data,setData] = useState([]);

    const columns = [
        {
            dataIndex: "keyword",
            title: "Kelime"
        }
    ];

    const getData = async () => {
        const { result } = await get(`${searchHistoryListContinueUrl}${parseInt(userId)}`);
        result.map(item => item.key = item.keyword );
        setData(result);
    };

    useEffect(()=>{
        getData();
    },[]);

    return (
        <Table 
            dataSource={ data }
            columns={ columns }
            pagination={ false }
        />
    );
};

export default Page;
