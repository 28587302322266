import { Form, Input, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { companyListUrl, roleListUrl } from "services";
import { emailRegex, statusOptions } from "constant";
import { post } from "helper/HttpHelpers";
import { formatResult } from "helper/TableHelpers";

export const FilterMenu = ({ form, handleSubmit }) => {
    const [roles, setRoles] = useState(undefined);
    const [email, setEmail] = useState({ value:"" } );
    const [gsm, setGsm] = useState({ value:"" });
    const [companies, setCompanies] = useState(undefined);

    const getRoles = async () => {
        const { result, success, message } = await post(roleListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setRoles(formatResult(result));
    };

    const getCompanies = async () => {
        const { result, success, message } = await post(companyListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        setCompanies(formatResult(result));
    };

    const emailHandleChange = async e => {
        const emailInput = e.target.value;
        if (!emailRegex.test(emailInput) ){
            const errorMessage = "Lütfen geçerli bir e-posta adresi giriniz";
            const errorValidateStatus = { errorMsg: errorMessage, validateStatus: "error" };
            setEmail((prevValue) => ({ ...prevValue, ...errorValidateStatus }));
            return ;
        }
    };

    const gsmHandleChange = async e => {
        const gsmInput = e.target.value.replace(/\D/g, "");
        const fullGsm = "+905"+ gsmInput;
        setGsm(prev=> ({ ...prev,fullGsm,value:gsmInput }));
    };

    useEffect(() => {
        getRoles();
        getCompanies();
    }, []);

    return (
        <Form
            form={ form }
            layout="vertical"
            requiredMark={ "Required" }
            initialValues={ {
                active: statusOptions[1].value
            } }
            onFinish={ handleSubmit }
        >
            <Form.Item
                label="Durum"
                name="active"
                required
                tooltip="Veri durumu"
            >
                <Select options={ statusOptions } />
            </Form.Item>
            <Form.Item
                label="E-Mail"
                name="email"
            >
                <Input onChange={ emailHandleChange } value={ email.value }
                    placeholder="Lütfen e-posta adresinizi yazınız" />
            </Form.Item>
            <Form.Item
                label="Gsm"
                name="gsm"
                className="phone-Input"
            >
                <Input
                    prefix="+90 5"     
                    type="text" 
                    onChange={ gsmHandleChange }
                    placeholder="  Lütfen telefon numaranızı yazınız" 
                    value={ gsm.value }
                    maxLength={ 9 }
                />
            </Form.Item> 
            <Form.Item
                label="Firma"
                name="company_id"
            >
                <Select
                    placeholder="Lütfen firma seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                > { companies?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }</Select>
            </Form.Item>
            <Form.Item
                label="Rol"
                name="roles"
            >
                <Select
                    placeholder="Lütfen rol seçiniz"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                >
                    { roles?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) } 
                </Select>
            </Form.Item>
        </Form>
    );
};

