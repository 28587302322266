import { Tag } from "antd";
import { listUrl, paymentUrl } from "services";

const Columns = [
    {
        render: (_, record) => (
            <>
                <div>{ record?.bank_name } </div>
                <div className="text-muted">{ record?.created_at }</div>
                <div className="text-muted">{ record?.company?.name }</div>
            </>
        ),
        title: "Açıklama"
    },
    {
        render: ( _, record ) => (
            <div>
                <div className="text-muted">{ record?.card_owner }</div>
                <div className="text-muted">{ record?.card_number }</div>
                <div className="text-muted">Taksit :{ record?.installment_count }</div>
                <div className="text-muted"> Tutar :{ record?.price } TL</div>
            </div>
        ),
        title: "Ödeme Bilgileri"
    },
    {
        render: (_,record) => (
            <Tag color={ record.status === "started" ? "blue" :
                record.status === "ongoing" ? "coral" :
                    record.status === "success" ? "green" : "volcano" }>
                { record.status === "started" ? "Başladı" :
                    record.status === "ongoing" ? "Gönderildi" :
                        record.status === "success" ? "Başarılı" :"Başarısız" }
            </Tag>
        ),
        title: "Sipariş Durumu"
    }
];

const Path = "/membership/user";
const Url = paymentUrl;
const ListUrl = `${Url}${listUrl}`;

export { Path, Url, ListUrl, Columns };
