import { Typography } from "antd";
import React, { useEffect } from "react";

const { Text } = Typography;
let timerID = null;

const ProgressBar = ({  progress, setProgress  }) => {

    useEffect(() => {
        timerID = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress === 0) clearInterval(timerID);
                return prevProgress === 0 ? 0 : prevProgress - 1;
            });
        }, 1000);
        return () => clearInterval(timerID);
    }, []);

    return (
        <Text bordered="false">{ progress }s</Text>
    );
};

export default ProgressBar;
