import { Tag } from "antd";
import { bufipayPaymentsContinueUrl, bufipayPaymentsUrl, listUrl, paymentUrl } from "services";

const Breadcrumbs = [
    {
        breadcrumbName: "Bufipay",
        path: "/bufipay"
    },
    {
        breadcrumbName: "Ödemeler",
        path: "/bufipay/payments"
    }
];

const BreadcrumbsAdd = [
    {
        breadcrumbName: "Bufipay",
        path: "/bufipay"
    },
    {
        breadcrumbName: "Ödemeler",
        path: "/bufipay/payments"
    },
    {
        breadcrumbName: "Yeni Ödeme",
        path: "/bufipay/payments/add"
    }
];

const BreadcrumbsEdit = [
    {
        breadcrumbName: "Bufipay",
        path: "/bufipay"
    },
    {
        breadcrumbName: "Ödemeler",
        path: "/bufipay/payments"
    },
    {
        breadcrumbName: "Ödeme Detayları",
        path: "/bufipay/payments/edit"
    }
];

const Columns = [
    {
        render: (_, record) => (
            <>
                <div>{ record?.bank_name } </div>
                <div className="text-muted">{ record?.created_at }</div>
                <div className="text-muted">{ record?.company?.name }</div>
                <div className="text-muted">{ record?.user_name }</div>
            </>
        ),
        title: "Açıklama"
    },
    {
        render: ( _, record ) => (
            <div>
                <div className="text-muted">{ record?.card_owner }</div>
                <div className="text-muted">{ record?.card_number }</div>
                <div className="text-muted">Taksit :{ record?.installment_count }</div>
                <div className="text-muted"> Tutar :{ record?.price } TL</div>
            </div>
        ),
        title: "Ödeme Bilgileri"
    },
    {
        render: (_,record) => (
            <Tag color={ record.status === "started" ? "blue" :
                record.status === "ongoing" ? "coral" :
                    record.status === "success" ? "green" : "volcano" }>
                { record.status === "started" ? "Başladı" :
                    record.status === "ongoing" ? "Gönderildi" :
                        record.status === "success" ? "Onaylandı" :"Onaylanmadı" }
            </Tag>
        ),
        title: "Ödeme Durumu"
    }
];

const Url = paymentUrl;
const ListUrl = `${Url}${listUrl}`;
const Path = bufipayPaymentsUrl;
const addPath = bufipayPaymentsContinueUrl;

export { Breadcrumbs, BreadcrumbsAdd, BreadcrumbsEdit, ListUrl, Path, addPath, Url, Columns };
