import { Tag } from "antd";
import { Route, Routes } from "react-router-dom";
import { listUrl, productTypeUrl } from "services";
import { AddView } from "./add";
import { ProtectedRoute } from "components";

const StockTypeTitles = ["Seçilmemiş", "Adet", "Metrekare"];
const StockTypeClasses = ["magenta", "green", "orange"];

const Breadcrumbs = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog"
    },
    {
        breadcrumbName: "Ürün Türleri",
        path: "/product-type"
    }
];

const Columns = [
    {
        dataIndex: "name",
        title: "Ürün Türü"
    },
    {
        dataIndex: "stock_type",
        render: (stok_type) => <Tag color={ StockTypeClasses[stok_type] }>{ StockTypeTitles[stok_type] }</Tag>,
        title: "Stok Türü"
    },
    {
        dataIndex: "attribute_labels",
        title: "Özellikler"
    },
    {
        dataIndex: "variant_labels",
        title: "Varyantlar"
    }
];
const Path = "/catalog/product-type";
const Url = productTypeUrl;
const ListUrl = `${Url}${listUrl}`;

const Router = ({ onHide }) => (
    <Routes>
        <Route element={ <ProtectedRoute permissionKey={ "product.add" } /> }>
            <Route path="add" element={ <AddView onHide={ onHide } /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "product.edit" } /> }>
            <Route path=":id/edit" element={ <AddView onHide={ onHide } /> } />
        </Route>
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

