import {  ArrowLeftOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Image, Row, Space, Tabs, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { catalogProductstockContinureUrl, updateProductskuContinueUrl } from "services";
import { tableItems, tableItemsLots } from "./EditView/Constant";
import LotsDetailsPage from "./EditView/LotsDetailsPage";
import OnSaleSwitcher from "./EditView/OnSaleSwitcher";
import OrderPage from "./EditView/OrderPage";
import PicturesTab from "./EditView/Pictures/PicturesTab";
import PricePage from "./EditView/PricePage";
import ProductDetails from "./EditView/ProductDetails";
import SumPage from "./EditView/SumPage";
import VariantsPage from "./EditView/VariantsPage";
import { Breadcrumbs } from "../Constant";
import { Spinner } from "components";
import { PageTitle } from "context";
import { post, useProduct } from "helper";
import { notificationError } from "helper/NotificationHelpers";

const { Title } = Typography;

const EditView = () => {
    const { id: primaryKey } = useParams();
    const { data, error, mutateData } = useProduct(primaryKey);
    const navigate = useNavigate();

    const [breadcrumbs, setBreadcrumbs] = useState();
    const [activeTab, setActiveTab] = useState("item-1");
    const [isLots,setIsLots] = useState(true);
    const [disabledBtn,setDisabledBtn] = useState(false);
    
    const onChangeTab = ( activeKey ) => setActiveTab( activeKey );
    
    const handleBreadcrumbs = () => {
        if (data) {
            const { result: productSku } = data;
            const breadcrumbs = [
                ...Breadcrumbs,
                {
                    path: `${catalogProductstockContinureUrl}${productSku.id}`,
                    breadcrumbName: data?.result?.name
                }
            ];
            setBreadcrumbs(breadcrumbs);
        }
    };

    let count = 0;

    const onClickUpdate = async () => {
        setDisabledBtn(true);
        const { success } = await post(`${updateProductskuContinueUrl}${data.result.out_ref}`,{});
        if (count >= 5) setDisabledBtn(true);
        if (!success) {
            notificationError("Güncelleme işlemi başarısız oldu");
            setDisabledBtn(false);
            count++;
        }
        else setDisabledBtn(false);
    };

    useEffect(() => {
        handleBreadcrumbs();
    }, [data]);

    useEffect(() => {
        if ((data?.result?.stock?.detail_list)?.length === 0) setIsLots(false);
        else setIsLots(true);
    });

    if (!data && !error) {
        return <Spinner />;
    }
    else if ( !data && error ) return ( <Row justify="center"><p>Hata: Bir hata meydana geldi!</p></Row> );
    const { result } = data;

    const activateItemsWithoutLots = {
        "item-1": ( <SumPage data={ result } /> ),
        "item-2": ( <p>Düzenle</p> ),
        "item-3": ( <PicturesTab /> ),
        "item-4": ( <PricePage data={ result.category.id } /> ),
        "item-5": ( <VariantsPage data={ result?.variants } /> ),
        "item-6": ( <OrderPage data={ primaryKey } /> )
    };

    const activateItemsWithLots =  {
        "item-1": ( <SumPage data={ result } /> ),
        "item-2": ( <p>Düzenle</p> ),
        "item-3": ( <PicturesTab /> ),
        "item-4": ( <PricePage data={ result.category.id } /> ),
        "item-5": ( <VariantsPage data={ result?.variants } /> ),
        "item-6": ( <OrderPage data={ primaryKey } /> ),
        "item-7": ( <LotsDetailsPage data={ result.stock.detail_list } /> )
    };

    return (
        <>
            <PageTitle breadcrumbs={ breadcrumbs }>{ data?.result?.name }</PageTitle>
            <Space className="flex-btw" value={ "large" }>
                <Typography.Title strong level={ 4 }>
                    Ürün Bilgileri
                </Typography.Title>
                <Space>
                    <Button
                        icon={ <ArrowLeftOutlined /> }
                        onClick={ () => navigate("/catalog/product-stock") }
                    >
                        Geri
                    </Button>
                </Space>
            </Space>
            { data && !error && (
                <>
                    <Card >
                        <Row align="space-between">
                            <Col>
                                <Row>
                                    <Col>
                                        <Title level={ 5 }>{ result?.name }</Title>
                                    </Col>
                                    <Col push={ 2 }>
                                        { <OnSaleSwitcher 
                                            productId={ primaryKey } 
                                            onSale={ result?.on_sale } 
                                            mutate={ mutateData }/> }
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Space>
                                    { result.out_ref !== null ?  
                                        <Button
                                            shape="round"
                                            type="dashed"
                                            onClick={ onClickUpdate }
                                            block
                                            icon={ <CloudDownloadOutlined /> }
                                            disabled={ disabledBtn }
                                        >
                                            Güncelle
                                        </Button> 
                                        : undefined 
                                    }
                                    <Tag color={ result.stock.in_stock === false ? "red" : "green" }>
                                        { result.stock.in_stock === false ? "Stokta Yok" : "Stokta" }
                                    </Tag>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ [24,24] } align={ "middle" }>
                            <Col>
                                <Image
                                    src={ result?.image }                                    
                                    alt="product_sku"
                                    preview={ false }
                                    height={ 112 }
                                    style={ { maxWidth: "148px" } }
                                />                            
                            </Col>          
                            <ProductDetails />
                        </Row>
                        <Divider></Divider>
                        <Row style={ { marginBottom:-42 } }>
                            <Tabs
                                items={ isLots ? tableItemsLots : tableItems }
                                activeKey={ activeTab }
                                onChange={ onChangeTab }
                                tabPosition={ "top" }
                            />
                        </Row>
                    </Card>
                    <br />      
                    { isLots ? activateItemsWithLots[ activeTab ] : activateItemsWithoutLots[ activeTab ] }
                </>
            ) }
        </>
    );
};
export default EditView;
