import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsAdd } from "../Constant";
import { PageTitle } from "context";

const TopSection = ( { form, posId } ) => {
    const navigate = useNavigate();
    const [ disable, setDisable ] = useState( false );

    const onClickHandler= () => {
        form.submit();
        setDisable();
        setTimeout(()=>{
            setDisable(false);
        },1500);
    };
    return (
        <>
            <PageTitle breadcrumbs={ BreadcrumbsAdd }>
                { BreadcrumbsAdd[BreadcrumbsAdd.length - 1].breadcrumbName }
            </PageTitle>
            <Row align="space-between">
                <Col>
                    <Typography.Title strong level={ 4 }>
                        { !posId 
                            ? "Yeni Pos Hesabı Ekle"
                            : "Pos Hesabı Düzenle" }
                    </Typography.Title>
                </Col>
                <Col>
                    <Space className="flex-btw" value={ "large" }>
                        <Space>
                            <Button
                                icon={ <ArrowLeftOutlined /> }
                                onClick={ () => navigate("/bufipay/virtual-posaccounts") }
                            >
                                Geri
                            </Button>
                            <Button type="primary" 
                                disabled={ disable } 
                                onClick={ onClickHandler }>
                                Kaydet
                            </Button>
                        </Space>
                    </Space>
                </Col>
            </Row>
        </>
    );
};

export default TopSection;
