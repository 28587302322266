export const dataFormatter = (data, product_id) =>
    data
        ?.filter((product) => product.id !== product_id)
        .map((item) => ({
            name: item?.name,
            id: item?.id,
            key: item?.id.toString()
        } ) );

export const dataSelectHelper = (parameter, selectedData, allData) => {
    if (parameter === "selected")
        return { selectedData, dataMessage: "Seçili varyantlara uygulandı!" };
    if (parameter === "all")
        return {
            selectedData: allData.map((item) => item.id),
            dataMessage: "Tüm varyantlara uygulandı!"
        };
    return [];
};
