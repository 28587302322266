import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Filter from "./Filter";
import { Breadcrumbs, Router } from "../Constant";
import { useData, useTable  } from "../Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const Page = () => {
    const { queryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns, onHide } = useTable(mutate);
    const navigate = useNavigate();

    return (
        <>
            <Row align="space-between">
                <Col />
                <Col>
                    <Button 
                        icon={ <ArrowLeftOutlined /> } 
                        onClick={ () => navigate("/catalog/product-attribute") } 
                        className="mb-20">
                        Geri
                    </Button>
                </Col>
            </Row>
            <PageContent
                breadcrumbs={ Breadcrumbs }
                tableData={ useData }
                tableColumns={ columns }
                dataFilter={ <Filter /> } 
                pageRoutes={ <Router onHide={ onHide } /> }
            />
        </>
    );
};

export default Page;
