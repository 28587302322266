import { EditOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Upload, notification } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addBrandUrl, brandUrl, imagePlaceHolderUrl } from "services";
import { DrawerComp } from "components/common/Drawer";
import { fileToBase64, get, notificationWarning, post, put } from "helper";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id: primaryKey } = useParams();
    
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [ fileList, setFileList ] = useState( [] );
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [ previewTitle, setPreviewTitle ] = useState( "" );
    const [ imageList, setImageList ] = useState( undefined );
    const [open,setOpen] = useState(true);
    
    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${brandUrl}/${primaryKey}`);
            if(!success){
                notification.error({
                    message: "Bilgilendirme",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setData( result );
            setFileList([{
                uid: "-1",
                name: "image.png",
                status: "done",
                url: result?.image
            }]);
        }
    };

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSubmit = async ( values ) => {
        const newValues = { ...values, image: imageList };
        const { success, message } = !primaryKey ? await post(addBrandUrl, newValues) : 
            await put(`${brandUrl}/${primaryKey}`, newValues);
        if(!success){
            notification.error({
                message: "Bilgilendirme",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    const handlePreview = async ( file ) => {
        if (!file.url && !file.preview) {
            file.preview = await fileToBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        toggleImagePreview();
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const handleChange = async ( { fileList: newFileList } ) => {
        let tempFileList = [];
        const maxFileSize = 5 * 1024 * 1024;
        if ( newFileList?.at( -1 )?.size > maxFileSize ) {
            tempFileList = newFileList.slice( 0, -1 );
            setFileList( tempFileList );
            notificationWarning( "Resim boyutu en fazla 5 mb olabilir!" );
            return;
        }
        tempFileList.push( newFileList?.at( -1 ) );
        setFileList( tempFileList );
        if ( tempFileList?.length > 0 ) {
            const base64 = await fileToBase64( tempFileList?.at( 0 )?.originFileObj );
            setImageList( base64 );
        }
    };
    const toggleImagePreview = () => setPreviewOpen( prev => !prev );

    const props = {
        onRemove: () => 
            setFileList([])
        ,
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        if(!primaryKey)        setFileList([{
            uid: "-1",
            name: "image.png",
            status: "done",
            url: imagePlaceHolderUrl
        }]);
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    return (
        <DrawerComp
            handleSubmit={ handleSubmit }
            setOpen={ setOpen }
            open={ open }
            form={ form }
            type={ "addBrand" }
        >
            <Upload className="catalog_upload"
                listType="picture-card"
                onPreview={ handlePreview }
                onChange={ handleChange }
                fileList={ fileList }
                { ...props }
            >
                <EditOutlined />
            </Upload>
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSubmit }
            >
                <Form.Item
                    label="Marka"
                    name="name"
                    rules={ [{ required: true, message: "Lütfen marka adı giriniz!" }] }
                >
                    <Input />
                </Form.Item>
            </Form>
            <Modal
                open={ previewOpen }
                title={ previewTitle }
                footer={ null }
                onCancel={ toggleImagePreview }
            >
                <img
                    alt="product_SKU"
                    style={ {
                        width: "100%"
                    } }
                    src={ previewImage }
                />
            </Modal>
        </DrawerComp>
    );
};
export default AddView;
