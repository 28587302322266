import { Button, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { membershipUserContinueUrl } from "services";
import { Columns, ListUrl, Url } from "./Constant";
import { delete_, getColumns } from "helper";
import { usePost } from "helper/Hook";
import { notificationError } from "helper/NotificationHelpers";

export const useTable = (mutateData) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
    };

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${Url}/${primaryKey}`);
        if (!success) {
            notificationError(message);
            return;
        }
        onHide();
    };

    const renderAction = (_, record) => (
        <>
            <Button onClick={ () => navigate(`${membershipUserContinueUrl}${record.id}/overview`) } type="link" size="small">
                <i className="flaticon2-edit text-muted" />
            </Button>
            <Popconfirm okText="Evet" cancelText="Hayır" 
                title="Eminmisiniz?" onConfirm={ () => deleteAction(record.id) }>
                <Button type="link" size="small" danger>
                    <i className="flaticon2-trash" />
                </Button>
            </Popconfirm>
        </>
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns, onHide };
};

export const useData = (queryParams) => {
    return usePost(ListUrl, queryParams);
};

export const getFilterQuery = (queryParams, values) => {
    const path = location.pathname.split("/");
    const companyId = parseInt(path?.at(-2));
    const { name } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (name) filter.full_name__ilike = `%${name}%`;
    if (companyId) filter.company_id = companyId;
    newQueryParams.filter = filter;
    return newQueryParams;
};
