import { CheckOutlined, CloseSquareOutlined, CopyOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Drawer, Image, Modal, Popconfirm, Row, Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { changeDefaultImageUrl, deleteProductImageUrl } from "services";
import { dataFormatter } from "./Helper";
import VariantList from "./VariantList";
import { Spinner } from "components";
import { delete_, notificationError, notificationInfo, notificationSuccess, put, useProduct } from "helper";

const ExistingPictures = ({ productSKU_ID }) => {
    const { data ,mutateData, error } = useProduct( productSKU_ID );
    const [ previewOpen, setPreviewOpen ] = useState( false );
    const [ selectedImage, setSelectedImage ] = useState( "" );
    const [visible, setVisible] = useState(false);
    const [ selectedRows, setSelectedRows ] = useState( [] );
    let hasVariant = true;

    const handlePreviewCancel = () => setPreviewOpen( prev=>!prev );
    
    const confirmDeleteImage = async ( imageId ) => {
        const { success, message } = await delete_(`${deleteProductImageUrl}${imageId}`);
        if (!success) {
            notificationError(message);
            return;
        }
        mutateData();
        notificationSuccess( "Resim başarıyla silindi!" );
    };

    const confirmDefaultImage = async ( imageId ) => {   
        const { success, message } = await put(`${changeDefaultImageUrl}${imageId}`);
        if (!success) {
            notificationError(message);
            return;
        }
        mutateData();
        notificationSuccess("Varsayılan resim değiştirildi!");
    };

    const onDrawerClose = async () => {
        await setVisible( false );
        setSelectedRows([]);
    }; 

    const applyVariantsButtonHandler = ( item ) => {
        setVisible( true );
        setSelectedImage( { ...item } );
        !hasVariant && notificationInfo( "Ürün varyantı bulunamadı." );
    };

    const renderVariantList = useMemo( () => {
        const variantData = dataFormatter( data.result.variants, productSKU_ID );
        hasVariant = variantData?.length > 0;
        return <VariantList
            data={ variantData }
            imageId={ selectedImage?.id }
            mutate={ mutateData }
            onSelectedRowsHandler={ ( rows ) => setSelectedRows( rows ) }
            selectedRows={ selectedRows }
            onDrawerClose={ onDrawerClose }
        />;
    }, [ data.result.variants, selectedImage.id,  productSKU_ID, selectedRows ] );

    if ( !data && !error ) {
        return <Spinner />;
    }
    else if ( !data && error ) return ( <Row justify="center"><p>Hata: Bir hata meydana geldi!</p></Row> );

    return (
        <>
            <Divider orientation="left">Ürün Resimleri</Divider>
            <Row
                gutter={ [
                    { xs: 8, sm: 16, lg:32 },
                    { xs: 8, sm: 16, lg: 32 }
                ] }
            >
                { data?.result?.images?.map((item, i) => (
                    <Col xs={ 24 } sm={ 12 } md={ 8 } lg={ 6 } xl={ 6 } xxl={ 4 } key={ i } >
                        <div className={ i===0 ? "mock-container defaultimage":"mock-container" }>
                            <Image
                                key={ item.id }
                                className="img"
                                src={ item?.url }
                                alt="ÜrünSkuResmi"
                                preview={ false }
                            >
                            </Image>
                            <div className="preview">
                                <Popconfirm
                                    title="Varsayılan resim yapılsın mı?"
                                    onConfirm={ ()=>confirmDefaultImage(item.id) }
                                    okText="Evet"
                                    cancelText="Hayır"
                                >
                                    <Button
                                        type="text"
                                        icon={  <Tooltip title="Ana resim yap"><CheckOutlined /></Tooltip> }
                                        onClick={ () => setSelectedImage({ ...item } ) }
                                    />
                                </Popconfirm>                  
                                <Button
                                    type="text"
                                    icon={ <EyeOutlined /> }
                                    onClick={ () => { setSelectedImage({ ...item } ); setPreviewOpen(prev=>!prev); } }
                                />
                                <Popconfirm
                                    title="Silmek istediğinizden emin misiniz?"
                                    onConfirm={ ()=>confirmDeleteImage(item.id) }
                                    okText="Evet"
                                    cancelText="Hayır"
                                >
                                    <Button
                                        type="text"
                                        icon={ <DeleteOutlined /> }
                                        onClick={ () => setSelectedImage({ ...item } ) }
                                    />
                                </Popconfirm>
                                <Button
                                    type="text"
                                    icon={ <Tooltip title="Diğer varyantlara uygula"><CopyOutlined /></Tooltip> }
                                    onClick={ () => applyVariantsButtonHandler(item) }
                                />
                            </div>
                        </div>
                    </Col>
                )) }
            </Row>
            <Modal open={ previewOpen } title={ `Resim ID: ${ selectedImage?.id }` }
                footer={ null } onCancel={ handlePreviewCancel }
                closeIcon= {  <CloseSquareOutlined />  }>
                <img      
                    alt="ürün_sku resmi"
                    style={ {
                        width: "100%"
                    } }
                    src={ selectedImage?.url }
                />
            </Modal>
            <br />
            <Drawer
                width={ 500 }
                title= { "Varyant Resimlerini Düzenle" }
                open={ visible && hasVariant }
                onClose={ onDrawerClose }
                maskClosable={ false }        
            >
                { renderVariantList }
            </Drawer>  
        </>
    );
};

export default ExistingPictures;
