import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { virtualPosBankListUrl, virtualposSchemaUrl } from "services";
import { wrapperCol } from "./Constant";
import { posAccountsRenderHelper, schemeFormatHelper } from "./Helper";
import { formatResult, get, post } from "helper";

const BankAccounts = ({ posId, form, setLoading, setError, posName, setErrorMessage,  setRenderedScheme }) => {
    const  selectedBankId = Form.useWatch("bank_id", form);

    const [ bankList, setBankList ] = useState();
    const [ schemeList, setSchemeList ] = useState( undefined );
    const [ selectedScheme, setSelectedScheme ] = useState(undefined);

    const posAccountSelectHandler = ( id ) => {
        const filteredBankInfo = bankList.find( ( item ) => item.value === +id );
        if ( filteredBankInfo ) form.setFieldsValue( { pos_name: filteredBankInfo.label + " Pos" } );
    };
	
    const getBankInfo = async () => {
        setLoading(true);
        try {   
            const { result, success, message: msg } = await post( virtualPosBankListUrl, {
                pageSize: 1000,
                filter: { is_pos: true }
            });     
            if (!success) {
                setLoading( false );
                setError(true);
                setErrorMessage(msg);
                return;
            }
            setLoading( false );
            setError(false);
            const schemeList =  schemeFormatHelper(result);
           
            setSchemeList( schemeList );
            setBankList( formatResult( result ) );
        } catch (e) {
            setLoading( false );
            setError(true);
            console.error(e.toString());
        }
    };

    const getVirtualPosInfo = async () => {
        setLoading(true);
        try {
            const { result, success, message: msg } = await get( `${virtualposSchemaUrl}/${ posId }` );
            if ( !success ) {
                setLoading( false );
                setError(true);
                setErrorMessage(msg);
                return;
            }
            setError(false);
            setLoading( false );
            setSelectedScheme(result);
            setRenderedScheme(posAccountsRenderHelper( result) );
            form.setFieldsValue( { pos_name: posName } );
        } catch ( e ) {
            setError(true);
            setLoading( false );
            console.error(e.toString());
        }
    };
	
    useEffect(() => {
        if (posId) {
            getVirtualPosInfo (posId);
        }
        else getBankInfo();
    }, [] );


	
    useEffect(() => {  
        if ( !posId && schemeList?.length>0) {
            const selectedSch = schemeList?.find( ( item ) => item.id === +selectedBankId )?.scheme;  
            if ( selectedSch?.length>0 ) {
                setSelectedScheme(selectedSch);
                setRenderedScheme( posAccountsRenderHelper( selectedSch ) );
            }
        }    
    }, [ selectedBankId ] );
	
    useEffect(() => {
        let newScheme={};
        selectedScheme?.map((item)=> {
            if(Array.isArray(item?.name) && item?.name?.length===2){
                const newObj=item?.name?.at(0) in newScheme ?  newScheme[item?.name?.at(0)]:{};
                newScheme={ ...newScheme,
                    [ item?.name?.at(0)]: { ...newObj, [item?.name?.at(1)]:item.default } };
            }
            else newScheme={ ...newScheme,[item?.name]:item?.default };
        });
        form.setFieldsValue({ ...newScheme });
    }, [selectedScheme]);
    
    return (
        <>
            {  !posId && <Form.Item
                label="Banka Hesabı"
                name="bank_id"
                { ...wrapperCol }>
                <Select
                    onChange={ posAccountSelectHandler }
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children
                            ?.toLowerCase()
                            .includes(inputValue.toLowerCase())
                    }
                >
                    { bankList?.map((option) => (
                        <Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            </Form.Item> 
            }
        </>
    );
};

export default BankAccounts;
