import { Navigate, Route, Routes } from "react-router-dom";
import { SalesPage } from ".";
import { OrderPage } from "./order";
import { EditOrderRouter } from "./order/edit";
import { ProtectedRoute } from "components";

const Router = () => (
    <Routes>
        <Route index element={ <SalesPage /> } />
        <Route element={ <ProtectedRoute permissionKey={ "order.view" } /> }>  
            <Route path="salessum"    element={ <Navigate to="/sales" replace /> } />    
            <Route path="order/*">
                <Route  index element={ <OrderPage /> } />
                <Route path=":id/edit/*" element={ <EditOrderRouter/>  } />
            </Route>
        </Route>
    </Routes>  
);

export default Router;
