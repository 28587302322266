import { Button, Popconfirm, notification } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";
import { deleteUrl } from "services";
import { Columns, ListUrl, Path, Url } from "./Constant";
import { ProtectedElement } from "components";
import { delete_, getColumns } from "helper";
import { usePost } from "helper/Hook";

export const useTable = (mutateData) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate(Path);
    };

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${Url}${deleteUrl}/${primaryKey}`);
        if(!success){
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        onHide();
    };

    const renderAction = (_, record) => (
        <>
            <ProtectedElement permissionKey={ "collection.edit" }>
                <Button onClick={ () => navigate(`${record.id}/edit`) } type="link" size="small">
                    <i className="flaticon2-edit text-muted" />
                </Button>
            </ProtectedElement>
            <ProtectedElement permissionKey={ "collection.delete" }>
                <Popconfirm okText="Evet" cancelText="Hayır" 
                    title="Eminmisiniz?" onConfirm={ () => deleteAction(record.id) }>
                    <Button type="link" size="small" danger>
                        <i className="flaticon2-trash" />
                    </Button>
                </Popconfirm>
            </ProtectedElement>
        </>
        
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns, onHide };
};

export const useData = (queryParams) => {
    const newParams=cloneDeep(queryParams);
    return usePost(ListUrl, newParams);
};

export const getFilterQuery = (queryParams, values) => {
    const { active, name } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (active) filter.active = +active;
    if (name) newQueryParams.keyword=name;
    else delete newQueryParams?.keyword;
    newQueryParams.filter = filter;
    return newQueryParams;
};
