import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Row, Space, Tag, Typography, message } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { paymentContinueUrl, paymentResendPaymentEmailContinueUrl } from "services";
import { BreadcrumbsEdit } from "../Constant";
import { Spinner } from "components";
import { PageTitle } from "context";
import { get } from "helper";

const EditView = () => {
    const { id: primaryKey } = useParams();
    const navigate = useNavigate();

    const [detail, setDetail] = useState();

    const getDetail = async () => {
        const { result } = await get(`${paymentContinueUrl}${primaryKey}`);
        setDetail(result);
    };

    const onClickResendMail = async () => {
        const { success } = await get(`${paymentResendPaymentEmailContinueUrl}${primaryKey}`);
        if (success) message.success("Mail Gönderildi");
        else message.error("Mail Gönderilemedi");
    };

    const createPDF = async () => {

        const doc = new jsPDF("p", "mm", "a5");

        const leftSide = document.getElementById("my-pdf1");
        const canvas1 = await html2canvas(leftSide);
        const imageData1 = canvas1.toDataURL("image/png");
        doc.text("Kredi Karti Ödeme Makbuzu", 50, 20);
        doc.addImage(imageData1, "PNG", 10, 30, 130, 0);

        const rightSide = document.getElementById("my-pdf2");
        const canvas2 = await html2canvas(rightSide);
        const imageData2 = canvas2.toDataURL("image/png");
        doc.addImage(imageData2, "PNG", 10, 70, 130, 0);
        doc.save("payment.pdf");

    };

    useEffect(() => {
        getDetail();
    }, []);

    //payment/admin/{paymentId} servisi şu an yok. Bu yüzden sabit BreadcrumbsEdit kullanıldı.
    //Servis oluşturulduktan sonra breadcrumbs name  dinamik olarak oluşturulabilir
    const data = {};
    const error = null;

    if (!data && !error) {
        return <Spinner/>;
    }

    return (
        <>
            <PageTitle breadcrumbs={ BreadcrumbsEdit }>{ BreadcrumbsEdit[BreadcrumbsEdit.length - 1].breadcrumbName }
            </PageTitle>
            <Space className="flex-btw" value={ "large" }>
                <Typography.Title strong level={ 4 }>
                    Ödeme Bilgileri
                </Typography.Title>
                <Space>
                    <Button
                        icon={ <ArrowLeftOutlined/> }
                        onClick={ () => navigate("/bufipay/payments") }
                    >
                        Geri
                    </Button>
                </Space>
            </Space>
            <Card className="mt-30">
                <Row align="space-between">
                    <Col>
                        <Tag color={ detail?.status === "success" ? "green" : "red" }>
                            { detail?.status === "success" ? "Başarılı" : "Başarısız" }
                        </Tag>
                        <Tag color={ detail?.source === "success" ? "green" : "blue" }>
                            { detail?.status === "success" ? "Web" : "Mobile" }
                        </Tag>
                    </Col>

                    <Col>
                        <Space>
                            <Button onClick={ createPDF }>PDF Oluştur</Button>
                            <Button type="primary" onClick={ onClickResendMail }>Tekrar Mail Gönder</Button>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={ 24 } className="mt-15">
                    <Col span={ 12 } id="my-pdf1">
                        <Descriptions
                            title="Detay Bilgiler"
                            bordered
                            column={ 1 }
                            size={ "small" }
                        >
                            <Descriptions.Item label="Firma">
                                { detail?.company?.name }
                            </Descriptions.Item>

                            <Descriptions.Item label="Kullanıcı">
                                { detail?.user_name }
                            </Descriptions.Item>
                            <Descriptions.Item label="Tarih">
                                { detail?.created_at }
                            </Descriptions.Item>

                            { detail?.status === "success" ?
                                <Descriptions.Item label="Onay Kodu">
                                    {
                                        (detail?.extra_data?.auth_code != undefined && decodeURI(detail?.extra_data?.auth_code)?.replace(/\+/g, "%20")) ||
                                        (detail?.extra_data?.extra_data?.AuthCode != undefined && decodeURI(detail?.extra_data?.extra_data?.AuthCode)?.replace(/\+/g, "%20"))
                                    }
                                </Descriptions.Item>
                                : undefined
                            }

                            { detail?.status !== "success" ?
                                <>
                                    <Descriptions.Item label="Hata Mesajı">
                                        { (detail?.extra_data?.response?.errorMsg != undefined && decodeURI(detail?.extra_data?.response?.errorMsg)?.replace(/\+/g, "%20")) ||
                                            (detail?.extra_data?.response?.SysErrMsg != undefined && decodeURI(detail?.extra_data?.response?.SysErrMsg)?.replace(/\+/g, "%20")) ||
                                            (detail?.extra_data?.response?.ErrMsg != undefined && decodeURI(detail?.extra_data?.response?.ErrMsg)?.replace(/\+/g, "%20")) ||
                                            (detail?.extra_data?.response?.CC5Response?.ErrMsg != undefined && decodeURI(detail?.extra_data?.response?.CC5Response?.ErrMsg)?.replace(/\+/g, "%20")) ||
                                            detail?.extra_data?.result_message_title }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Hata Mesajı">
                                        { detail?.extra_data?.result_message }
                                    </Descriptions.Item>
                                </>
                                : undefined
                            }
                        </Descriptions>
                    </Col>
                    <Col span={ 12 } id="my-pdf2">
                        <Descriptions
                            title="Ödeme Bilgileri"
                            bordered
                            column={ 1 }
                            size={ "small" }
                        >
                            <Descriptions.Item label="Kart Sahibi">
                                { detail?.card_owner }
                            </Descriptions.Item>
                            <Descriptions.Item label="Kart No">
                                { detail?.card_number }
                            </Descriptions.Item>
                            <Descriptions.Item label="İşlem Tutarı">
                                { detail?.price }
                            </Descriptions.Item>
                            <Descriptions.Item label="Taksit Adedi">
                                { detail?.installment_count } Taksit
                            </Descriptions.Item>
                            <Descriptions.Item label="Kart Bankası">
                                { detail?.extra_data?.credit_card_bank }
                            </Descriptions.Item>
                            <Descriptions.Item label="Ödeme Sistemi">
                                { detail?.bank_name }
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </Card>
        </>
    );
};
export default EditView;
