export const checkboxFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 16,
            offset: 8
        }
    }
};

export const wrapperCol = {
    wrapperCol: {
        span: 14
    }
};

export const notificationMessageEdit = "Pos Hesabı Başarıyla Güncellenmiştir!";
export const notificationMessageAdd = "Pos Hesabı Başarıyla Eklenmiştir!";
