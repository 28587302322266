/* eslint-disable no-restricted-imports */
import { CloudDownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Progress, Row, Space } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { syncAddressUrl } from "services";
import FilterMenu from "./FilterMenu";
import { getFilterQuery } from "../Helper";
import { ProtectedElement } from "components";
import { DrawerComp } from "components/common/Drawer";
import { FilterButton } from "components/common/FilterButton";
import { useFilter } from "context";
import { notificationError, post, useSyncProgress } from "helper";

const Filter = ({ setType }) => {
    const push = useNavigate();
    const { queryParams, isDefaultParams, setQueryParams,searchText, setSearchText } = useFilter();
    const [ form ] = Form.useForm();
    const { progressError, progressItem1, delayItem1, syncProgress }=useSyncProgress( "address" );

    const [open, setOpen] = useState(false);
    const [ buttonStyle, setButtonStyle ] = useState( "no-filter" );

    const syncAddressList = async () => {
        const { success, message } = await post(syncAddressUrl);
        if (!success) {
            notificationError(message);
            return;
        }
        //syncProgress();
    };

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleAdd = () => push("add");

    const handleSubmit = (values) =>{ 
        if (searchText) values.name = searchText;
        applyFilter({ ...queryParams.filter, ...values });
    };

    const handleInputChange = ({ target }) => {
        const name= target.value;
        setSearchText(name);
        applyFilter({ ...queryParams.filter, address1:name, address2 :name, name });
    };

    useEffect(() => {
        isDefaultParams
            ? setButtonStyle("no-filter-btn-style")
            : setButtonStyle("filter-btn-style");
    }, [isDefaultParams]);

    return (
        <>
            <Row align="space-between">
                <Col span={ 6 }>
                    <div className="mb-10">
                        <Input
                            onChange={ handleInputChange }
                            placeholder="Firma arayın"
                            value={ searchText }
                        />
                    </div>
                </Col>
                <Col>
                    <Space>
                        <ProtectedElement permissionKey={ "address.add" }>  
                            <Button
                                shape="round"
                                type="dashed"
                                onClick={ () => setType("add") }
                                block
                                icon={ <PlusOutlined /> }
                            >
                                Yeni
                            </Button>
                        </ProtectedElement>
                        <FilterButton
                            setOpen={ setOpen }
                            buttonStyle={ buttonStyle }
                        />
                        <DrawerComp
                            handleSubmit={ handleSubmit }
                            setOpen={ setOpen }
                            open={ open }
                            form={ form }
                            type={ "edit" }
                        >
                            <FilterMenu form={ form } handleSubmit={ handleSubmit } />
                        </DrawerComp>
                    </Space>
                </Col>
            </Row>
        </>
    );
};
export default Filter;
