const { Result, Button } = require( "antd" );
const { useNavigate, useLocation } = require( "react-router-dom" );

const ErrorFallback =( { error, resetErrorBoundary } )=> {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split("/");

    const onClickHandler=()=> {
        if(path?.length===3) navigate(`/${path[1] }`);
        else if(path?.length===5) navigate(`/${path[1] }/${path[2]}`);
        else navigate("/");

        setTimeout( () => {
            if(typeof window !== "undefined") window.location.reload();
        }, 100);
    };

    return (
        <Result
            status="500"
            title="Hata"
            subTitle="Bir Hata Meydana Geldi!"
            extra={ <>
                <pre>{ `Hata: ${ error.message }` }</pre>
                <Button onClick={ resetErrorBoundary }>Tekrar dene</Button>
                <Button onClick={ onClickHandler }>Geri Dön</Button>
            </> }
        />
    );
};

export default ErrorFallback;
