import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Image, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import image from "../../../assets/media/passwordchanged.svg";
import CardHeader from "../common/CardHeader";
const { Paragraph } = Typography;

const ResetSuccessfull = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state?.where !== "reset") navigate("/auth/login");
    }, [state]);

    if (state?.where !== "reset") return <div>Yönlendiriliyorsunuz!</div>;
    return (
        <div className="forgotpassword_container">
            <Card >              
                <Space size="small" direction="vertical" style={ { width: "100%" } }>
                    <CardHeader headContent="Parola sıfırlama" link= "/auth/forgot-password"/>                     
                    <Col  align="center">
                        <Image preview={ false } src={ image }     placeholder={
                            <LoadingOutlined />
                        }/>                 
                    </Col>         
                    <Button block type="primary" 
                        className="special_green_button"
                        htmlType="button" 
                        onClick={ ()=> navigate( "/auth/login", { state: { activeTab: "1" } } ) }>
                        Giriş Yap
                    </Button>
                    <Paragraph>Şifreniz başarıyla yenilendi. Yeni şifre ile panelimize giriş yapabilirsiniz.</Paragraph>
                </Space> 
            </Card>
        </div>
    );
};

export default ResetSuccessfull;
