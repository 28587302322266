import {
    ApartmentOutlined,
    ApiOutlined,
    AppstoreOutlined,
    BankOutlined,
    BarChartOutlined,
    BookOutlined,
    BulbOutlined,
    ContainerOutlined,
    CreditCardOutlined,
    FileExcelOutlined,
    FileExclamationOutlined,
    GiftOutlined,
    GoldOutlined,
    HomeOutlined,
    InboxOutlined,
    LinkOutlined,
    OrderedListOutlined,
    SettingOutlined,
    ShoppingOutlined,
    SnippetsOutlined,
    TeamOutlined,
    UserOutlined,
    UsergroupAddOutlined
} from "@ant-design/icons";

export const SideBarItems = [
    // {
    //     children: [
    //         {
    //             icon: <OrderedListOutlined />,
    //             key: "",
    //             label: "Özet",
    //             permissionkey: "dashboard.view"
    //         }
    //     ],
    //     icon: <PieChartOutlined style={ { color: "#fff", fontSize: "16px" } } />,
    //     key: "dashboard",
    //     label: "Dashboard"
    // },
    {
        children: [
            // {
            //     icon: <OrderedListOutlined />,
            //     key: "salessum",
            //     label: "Özet",
            //     permissionkey: "order.view"
            // },
            {
                icon: <OrderedListOutlined />,
                key: "order",
                label: "Siparişler",
                permissionkey: "order.view"
            }
        ],
        icon: <OrderedListOutlined style={ { color: "#fff", fontSize: "20px" } } />,
        key: "sales",
        label: "Satış İşlemleri"
    },
    {
        children: [
            // {
            //     icon: <i className="flaticon2-infographic" />,
            //     key: "catalogsum",
            //     label: "Özet",
            //     permissionkey: "product.view"
            // },
            {
                icon: <SnippetsOutlined />,
                key: "category",
                label: "Kategoriler",
                permissionkey: "category.view"
            },
            {
                icon: <GoldOutlined />,
                key: "brand",
                label: "Markalar",
                permissionkey: "brand.view"
            },
            {
                icon: <InboxOutlined />,
                key: "product-type",
                label: "Ürün Türleri",
                permissionkey: "product.view"
            },
            {
                icon: <BulbOutlined />,
                key: "product-attribute",
                label: "Özellikler",
                permissionkey: "product.view"
            },
            {
                icon: <i className="flaticon-price-tag" />,
                key: "product-price",
                label: "Ürün Fiyatları",
                permissionkey: "product.view"
            },
            // {
            //     icon: <BookOutlined />,
            //     key: "product",
            //     label: "Ürün Kartları",
            //     permissionkey: "product_attribute.view"
            // },
            {
                icon: <BookOutlined />,
                key: "product-stock",
                label: "Stok Kartları",
                permissionkey: "product.view"
            },
            {
                icon: <GiftOutlined />,
                key: "campaign",
                label: "Kampanyalar",
                permissionkey: "campaign.view"
            },
            {
                icon: <AppstoreOutlined />,
                key: "collection",
                label: "Koleksiyonlar",
                permissionkey: "collection.view"
            }
        ],
        icon: <ContainerOutlined style={ { color: "#fff", fontSize: "16px" } } />,
        key: "catalog",
        label: "Ürün İşlemleri"
    },
    {
        children: [
            // {
            //     icon: <i className="flaticon2-infographic" />,
            //     key: "accountsum",
            //     label: "Özet",
            //     permissionkey: "company.view"
            // },
            {
                icon: <UserOutlined />,
                key: "company",
                label: "Hesaplar",
                permissionkey: "company.view"
            },
            {
                icon: <HomeOutlined />,
                key: "address",
                label: "Adresler",
                permissionkey: "address.view"
            }
        ],
        icon: <InboxOutlined style={ { color: "#fff", fontSize: "20px" } } />,
        key: "account",
        label: "Cari İşlemleri"
    },
    {
        children: [
            // {
            //     icon: <i className="flaticon2-infographic" />,
            //     key: "bufipaysum",
            //     label: "Özet",
            //     permissionkey: "payment.view"
            // },
            {
                icon: <ShoppingOutlined />,
                key: "payments",
                label: "Ödemeler",
                permissionkey: "payment.view"
            },
            // {
            //     icon: <FileExcelOutlined />,
            //     key: "cancellations",
            //     label: "İptaller",
            //     permissionkey: "payment.view"
            // },
            // {
            //     icon: <FileExclamationOutlined />,
            //     key: "returns",
            //     label: "İadeler",
            //     permissionkey: "payment.view"
            // },
            // {
            //     icon: <LinkOutlined />,
            //     key: "paybylink",
            //     label: "Link ile Ödeme",
            //     permissionkey: "payment.view"
            // },
            // {
            //     icon: <BarChartOutlined />,
            //     key: "reports",
            //     label: "Raporlar",
            //     permissionkey: "payment.view"
            // },
            {
                icon: <SettingOutlined />,
                key: "merchantsettings",
                label: "Üye İşyeri Ayarları",
                permissionkey: "virtual.view"
            },
            {
                icon: <BankOutlined />,
                key: "virtual-posaccounts",
                label: "Pos Hesapları",
                permissionkey: "virtual.view"
            }
            // {
            //     icon: <ApiOutlined />,
            //     key: "apimanagement",
            //     label: "Api Yönetimi",
            //     permissionkey: "virtual.view"
            // }
        ],
        icon: <CreditCardOutlined style={ { color: "#fff", fontSize: "20px" } } />,
        key: "bufipay",
        label: "BufiPay"
    },
    {
        children: [
            // {
            //     icon: <i className="flaticon2-infographic" />,
            //     key: "membershipsum",
            //     label: "Özet",
            //     permissionkey: "user.view"
            // },
            {
                icon: <UsergroupAddOutlined />,
                key: "user",
                label: "Kullanıcılar",
                permissionkey: "user.view"
            },
            {
                icon: <ApartmentOutlined />,
                key: "role",
                label: "Roller",
                permissionkey: "role.view"
            }
        ],
        icon: <TeamOutlined style={ { color: "#fff", fontSize: "20px" } } />,
        key: "membership",
        label: "Üyelik İşlemleri"
    },
    // Geçici olarak kaldırıldı.
    // {
    //     label: "Entegrasyonlar",
    //     key: "integration",
    //     icon: <FileAddOutlined style={ { color:"#fff", fontSize: "20px" } }/>,
    //     children: [
    //         { label: "Netgsm", key: "netgsm", icon: <AppstoreAddOutlined /> }
    //     ]
    // },
    {
        children: [
            // {
            //     icon: <i className="flaticon2-infographic" />,
            //     key: "settingssum",
            //     label: "Özet",
            //     permissionkey: "settings.view"
            // },
            {
                icon: <SettingOutlined />,
                key: "general-settings",
                label: "Ayarlar",
                permissionkey: "settings.view"
            }
        ],
        icon: <SettingOutlined style={ { color: "#fff", fontSize: "20px" } } />,
        key: "settings",
        label: "Ayarlar"
    }
];
