import { Card, Descriptions } from "antd";
import React from "react";
import { documentTags } from "../Constant";

const Documents = (  ) =>(
    <Card  style={ { height: "100%" } }>
        <Descriptions
            title="Belgeler"
            bordered
            column={ 1 }
            size={ "small" }
        >
            { documentTags.map( ( tag, i ) =>
                <Descriptions.Item key={ i } label={ tag[ 0 ] }>{ [ tag[ 1 ] ] || null }
                </Descriptions.Item>               
            ) }
        </Descriptions>
    </Card>
);
    
export default Documents;
