import { Card } from "antd";
import React, { useEffect } from "react";
import { Columns } from "./Price/Constant";
import { useData } from "./Price/Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const PricePage = ( { data } ) => {
    const { setQueryParams } = useFilter();

    useEffect(() => {
        setQueryParams({
            pageNumber: 1,
            pageSize: 10,
            sortField: "id",
            sortOrder: "desc",
            filter: { category_id : data }
        });
    }, []);
    
    return (
        <PageContent
            tableData={ useData } 
            tableColumns ={ Columns }
            selection = { false }
        />
    );
};

export default PricePage;
