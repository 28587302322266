import { Image, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { listUrl, productSkuUrl } from "services";
import { ProtectedLink } from "components";

export const Images = [
    { label: "Tümü", value: "" },
    { label: "Var", value: "1" },
    { label: "Yok", value: "0" }
];

export const onSale = [
    { label: "Tümü", value: "" },
    { label: "Satışta" , value: "1" },
    { label: "Satışta Değil" , value: "0" }
];

const Breadcrumbs = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog"
    },
    {
        breadcrumbName: "Stok Kartları",
        path: "/catalog/product-stock"
    }
];

const BreadcrumbsAdd = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog"
    },
    {
        breadcrumbName: "Stok Kartları",
        path: "/product-stock"
    },
    {
        breadcrumbName: "Yeni Ürün",
        path: "/add"
    }
];

const handleStock = (record) => {
    const { name, attributes_map, stock, update_date, create_date, id  } = record;
    return (
        <>
            <ProtectedLink permissionKey={ "product.edit" } path={ `${id}/edit` }>
                <Typography.Text className="text-dark-75 font-weight-bold text-hover-info mb-1">
                    { name }
                </Typography.Text>
            </ProtectedLink>
            <div className={ "font-weight-light text-muted" }>
                {
                    Object.keys(attributes_map).map((key, i) =>
                        <span key={ i }>
                            <span>{ key } : </span><span>{ attributes_map[key] } </span>
                        </span>
                    )
                }
            </div>
            <div className="font-weight-light font-size-xs">
                Stok { stock.quantity } { stock.type } ( Birim: { stock.unit } Metrekare )
            </div>
            <div className="font-weight-light font-size-xs">
                Oluşturma Tarihi: { create_date }
            </div>
            <div className="font-weight-light font-size-xs">
                Güncelleme Tarihi: { update_date }
            </div>
        </>
    );
};

const Columns = [
    {
        render: (_, record) => (
            <Link to={ record?.id }>
                <span className="symbol symbol-50px">
                    <Image
                        className="product img-thumbnail"
                        src={ record.image }
                    />
                </span>
            </Link>
        ),
        width: 80
    },
    {
        render: (_, record) => handleStock(record),
        title: "Ürün"
    },
    {
        render: (_,record) => {
            const { brand,type }=record;
            return (<>
                <div>{ brand.name } </div>
                <div className="font-weight-light font-size-xs">{ type?.name }</div>
            </>);
        },
        title: "Marka/Tür"
    },
    {
        dataIndex: "stock",
        render: (stock,record) => ( <>
            <Tag color={ stock?.in_stock ? "green" :"volcano" } className="mb-10">
                { stock.in_stock ? "Stokta":"Tükendi" }
            </Tag>
            <Tag color={ record?.on_sale  ? "green" : "red" }>
                { record?.on_sale  ? "Satışta" : "Satışta Değil" }
            </Tag>

        </>
        ),
        title: "Stok/Sat.Durumu"
    }
];
const Path = "/catalog/product-stock";
const Url = productSkuUrl;
const ListUrl = `${Url}${listUrl}`;

export { Breadcrumbs, BreadcrumbsAdd, Columns, Path, Url, ListUrl };

