export const BreadcrumbsMerchantSettings = [
    {
        breadcrumbName: "Bufipay",
        path: "/bufipay"
    },
    {
        breadcrumbName: "Üye İşyeri Ayarları",
        path: "/bufipay/merchantsettings"
    }
];
