import { Col, Form, Input, Row, Table } from "antd";
import { priceColumns } from "./Constant";

const PriceTable = ({ tableData, loading, name, trans_code }) => (
    <>
        <Row gutter={ 24 }>             
            <Col span={ 12 }>
                <Form.Item 
                    label="Fiyat Şablonu Kodu"
                    name="trans_code"
                    initialValue={ trans_code || "" }
                    rules={ [
                        {
                            message: "Lütfen kod giriniz!",
                            required: true
                        },
                        {
                            message:"Lütfen en az 6 karakter giriniz!",
                            min:6
                        }
                    ] }                           
                >
                    <Input placeholder="Lütfen şablon kodu giriniz"/>
                </Form.Item>
            </Col>
            <Col span={ 12 }>                  
                <Form.Item 
                    label="Şablon İsmi"
                    name="name"
                    initialValue={ name || "" }
                    rules={ [
                        {
                            message: "Lütfen isim giriniz!",
                            required: true
                        },
                        {
                            message:"Lütfen en az 6 karakter giriniz!",
                            min:6
                        }
                    ] }         
                >
                    <Input placeholder="Lütfen isim giriniz"/>
                </Form.Item></Col>           
        </Row>
        <Table
            showSorterTooltip={ { title: "Sıralamayı değiştir" } }
            columns={ priceColumns } 
            dataSource={ tableData || [] }
            loading={ loading }
            pagination={ false }
        />

    </>
);

export default PriceTable;
