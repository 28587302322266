/* eslint-disable no-restricted-imports */
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FilterMenu } from "./FilterMenu";
import { getFilterQuery } from "../Helper";
import { ProtectedElement } from "components";
import { DrawerComp } from "components/common/Drawer";
import { FilterButton } from "components/common/FilterButton";
import { useFilter } from "context";

const Filter = () => {
    const { queryParams, isDefaultParams, setQueryParams, searchText, setSearchText } = useFilter();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    
    const [open, setOpen] = useState(false);
    const [buttonStyle, setButtonStyle] = useState("no-filter");
    const [date,setDate] = useState([]);

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleAdd = () => navigate("add");

    const handleSubmit = (values) => {
        const newValues = {
            ...values,
            bank_name : values.bank_name,
            ordered_at:date
        };
        delete newValues.date;
        if ( !values.date ) newValues.ordered_at = undefined;
        if(searchText) newValues.name = searchText;
        applyFilter({ ...queryParams.filter, ...newValues });
    };

    const handleInputChange = ({ target }) => {
        const name = target?.value;
        setSearchText(name);
        applyFilter({ ...queryParams.filter, name });
    }; 

    useEffect(() => {
        isDefaultParams
            ? setButtonStyle("no-filter-btn-style")
            : setButtonStyle("filter-btn-style");
    }, [isDefaultParams]);

    return (
        <Row align="space-between">
            <Col span={ 6 }>
                <div className="mb-10">
                    <Input
                        onChange={ handleInputChange }
                        placeholder="Kart sahibi"
                        value={ searchText }
                    />
                </div>
            </Col>
            <Col>
                <Space>
                    <ProtectedElement permissionKey={ "payment.add" }>
                        <Button
                            shape="round"
                            type="dashed"
                            onClick={ handleAdd }
                            block
                            icon={ <PlusOutlined /> }
                        >
                            Yeni
                        </Button>
                    </ProtectedElement>
                    <FilterButton
                        setOpen={ setOpen }
                        buttonStyle={ buttonStyle }
                    />
                    <DrawerComp
                        handleSubmit={ handleSubmit }
                        setOpen={ setOpen }
                        open={ open }
                        form={ form }
                        type={ "edit" }
                    >
                        <FilterMenu form={ form } handleSubmit={ handleSubmit } setDate={ setDate } />
                    </DrawerComp>
                </Space>
            </Col>
        </Row>
    );
};
export default Filter;

