import { Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Columns } from "./Constant";

const VariantsPage = ( { data } ) => {
    const [tableData,setTableData] = useState();
    
    useEffect(() => {
        const newResult = data?.map( ( item ) => ( { ...item, key: item.id } ) );
        setTableData(newResult); 
    }, [data]);
    
    return (
        <Card >
            <Table
                columns={ Columns }
                dataSource={ tableData }
                showSorterTooltip={ { title: "Sıralamayı değiştir" } }
                rowKey={ (record) => record.id }
                pagination={ {
                    pageSize: 10,
                    showLessItems: true,
                    showTotal: (total, range) => (
                        <span className="text-muted">
                            <b>{ total }</b> kayıttan <b>{ range[0] }</b> - <b>{ range[1] }</b> arası
                        </span>
                    ),
                    total: data?.total_count
                } }
            /> 
        </Card>
    );
};

export default VariantsPage;
