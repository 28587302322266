export const cardNumberFormatter = ( cardNumber="" ) => {
    const rawText = [...cardNumber.split(" ").join("")]; 
    const tempCreditCard = [];
    rawText.forEach((number, index) => {
        if (index % 4 === 0) tempCreditCard.push(" "); 
        tempCreditCard.push(number);
    } );
    return tempCreditCard.join(""); 
};

export const formattedOutput = ( price ) => {
    const newPrice = new Intl.NumberFormat( "en-Us",
        {  
            currency: "TRY",
            minimumFractionDigits: 2
        } ).format( price);
    return newPrice;
};
