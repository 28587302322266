export const rolChangeHelper = (compId,e) => {
    if (compId) return {  "company_id" : compId };
    else return { "addresses": undefined , "company_id": undefined , "default_address": undefined , "roles": [e] };
};

export const getAddAddressInitialFormValues = (data) => {
    const { email, tax_office, tax_number, phone1, phone2, is_corporate } = data;
    return {
        email,
        is_corporate,
        phone1: phone1?.slice(3, 12),
        phone2: phone2?.slice(3, 12),
        tax_number,
        tax_office
    };
};
