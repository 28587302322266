
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic, notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { categoryListUrl } from "services";
import { Breadcrumbs } from "./Helper";
import { PageTitle } from "context";
import { post } from "helper";

const Page = () => {
    const navigate = useNavigate();
    const [categoriesOk, setCategoriesOk] = useState(false);
    const [categories, setCategories] = useState(undefined);
    const getCategories = async () => {
        const { success, result, message } = await post(categoryListUrl, { });
        setCategoriesOk(success);
        if(!success){
            notification.error({
                message: "Bilgilendirme",
                description: message,
                placement: "top"
            });
            return;
        }
        setCategories(result);

    };
    useEffect(() => {
        getCategories();
    }, []);
    return (
        <>
            <PageTitle breadcrumbs={ Breadcrumbs }>Katalog</PageTitle>
            <div className="site-card-wrapper">
                <Row gutter={ 16 }>
                    <Col span={ 8 }>
                        <Card title="Kategoriler" bordered={ false } actions={ [
                            <PlusOutlined key="add" onClick={ () => navigate("/catalog/category/add") }/>,
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/catalog/category") }/>
                        ] } loading={ !categoriesOk }>
                            <Statistic title="Active Users" value={ 112893 } />
                        </Card>
                    </Col>
                    <Col span={ 8 }>
                        <Card title="Alt Kategoriler" bordered={ false } actions={ [
                            <PlusOutlined key="add" onClick={ () => navigate("/catalog/subcategory/add") }/>,
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/catalog/subcategory") }/>
                        ] } loading={ !categoriesOk }>
                            <Statistic title="Active Users" value={ 112893 } />
                        </Card>
                    </Col>
                    <Col span={ 8 }>
                        <Card title="Markalar" bordered={ false } actions={ [
                            <PlusOutlined key="add" onClick={ () => navigate("/catalog/brand/add") }/>,
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/catalog/brand") }/>
                        ] } loading={ !categoriesOk }>
                            <Statistic title="Active Users" value={ 112893 } />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
        
    );
};

export default Page;
