import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { syncProgressUrl } from "services";
import { get, notificationError } from "helper";

let firstTime1 = true;
let firstTime2 = true;

export const useSyncProgress = ( parameter1, parameter2 ) => {
    const location = useLocation();

    const [ progressError, setProgressError ] = useState( false );
    const [ progressItem1, setProgressItem1 ] = useState( 100 );
    const [ progressItem2, setProgressItem2 ] = useState( 100 );
    const [ delayItem1, setDelayItem1 ] = useState( true );
    const [ delayItem2, setDelayItem2 ] = useState( true );

    const syncProgress = async () => {		
        const { success, message, result } = await get(syncProgressUrl);
        if (!success) {
            notificationError(message);
            setProgressError(true);
            return;
        }
        setProgressError( false );
        if (parameter1 in result === true) {
            setProgressItem1(parseInt(result[parameter1]));
        }     
        if (parameter2 in result === true) {
            setProgressItem2(parseInt(result[parameter2]));
        }   
    };

    useEffect(() => {
        const timerID = setInterval(() => {
            if (progressItem1 === 100 || progressError) {
                clearInterval(timerID);
            }
            //syncProgress();
        }, 1000 );
		
        if (firstTime1) {
            firstTime1=false;
            return;
        }		
        else if (progressItem1 === 100 && !firstTime1) {
            setDelayItem1(false);
            setTimeout(() => {
                setDelayItem1(true);
            }, 1000);
        }
        return () => clearInterval(timerID);
    }, [ progressItem1 ] );
	
    useEffect(() => {
        const timerID2 = setInterval(() => {
            if (progressItem2 === 100 || progressError) {
                clearInterval(timerID2);
            }
            //syncProgress();
        }, 1000 );
		
        if (firstTime2) {
            firstTime2=false;
            return;
        }		
        else if (progressItem2 === 100 && !firstTime2) {
            setDelayItem2(false);
            setTimeout(() => {
                setDelayItem2(true);
            }, 1000);
        }
        return () => clearInterval(timerID2);
    }, [ progressItem2] );
	
    useEffect( () => {
		
        return () => {
            firstTime1=true;
            firstTime2=true;
        };
    }, [ location?.pathname ] );
	
    useEffect(() => {
        //syncProgress();
    }, [] );

    const value=parameter2? { progressItem2, delayItem2 }:{};
    return { progressError,progressItem1, delayItem1, syncProgress,...value };
};

