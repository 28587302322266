export const sources = [
    {
        value:"web" , 
        label:"Web"
    },
    {
        value:"mobile" , 
        label:"Mobil"
    },
    // {
    //     value:"cms", 
    //     label:"Cms"
    // },
    // {
    //     value:"cli" , 
    //     label:"Cli"
    // },
    // {
    //     value:"out" , 
    //     label:"Out"
    // },
    // {
    //     value:"Winka" , 
    //     label:"Winka"
    // },
    // {
    //     value:"Logo" , 
    //     label:"Logo"
    // },
    // {
    //     value:"Nebim" , 
    //     label:"Nebim"
    // },
    {
        value:"erp" , 
        label:"Erp"
    }
];
