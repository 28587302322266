import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { FilterMenu } from "./FilterMenu";
import { getFilterQuery } from "../Helper";
import { ProtectedElement } from "components";
import { DrawerComp } from "components/common/Drawer";
import { FilterButton } from "components/common/FilterButton";
import { useFilter } from "context";

const Filter = () => {
    const { queryParams, isDefaultParams, setQueryParams, searchText, setSearchText } = useFilter();
    const push = useNavigate();
    const [form] = Form.useForm();

    const [open, setOpen] = useState(false);
    const [buttonStyle, setButtonStyle] = useState("no-filter");

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleAdd = () => push("add");

    const handleSubmit = (values) => {
        if (searchText) values.name = searchText;
        applyFilter({ ...queryParams.filter, ...values });
    };

    const handleInputChange = ({ target }) => {
        const name = target?.value;
        setSearchText(name);
        applyFilter({ ...queryParams.filter, name });
    };

    useEffect(() => {
        isDefaultParams
            ? setButtonStyle("no-filter-btn-style")
            : setButtonStyle("filter-btn-style");
    }, [isDefaultParams]);

    return (
        <>
            <Row gutter={ 24 }>
                <Col span={ 6 }>
                    <div className="mb-10">
                        <Input
                            onChange={ handleInputChange }
                            placeholder="Ürün ismi"
                            value={ searchText }
                        />
                    </div>
                </Col>
                <Col className="ml-auto">
                    <Space>
                        <ProtectedElement permissionKey={ "product.add" }>
                            <Button
                                shape="round"
                                type="dashed"
                                onClick={ handleAdd }
                                block
                                icon={ <PlusOutlined /> }
                            >
                                Yeni
                            </Button>
                        </ProtectedElement>
                        <FilterButton setOpen={ setOpen } buttonStyle={ buttonStyle } />
                        <DrawerComp
                            handleSubmit={ handleSubmit }
                            setOpen={ setOpen }
                            open={ open }
                            form={ form }
                            type={ "edit" }
                        >
                            <FilterMenu form={ form } handleSubmit={ handleSubmit } />
                        </DrawerComp>
                    </Space>
                </Col>
            </Row>
        </>
    );
};
export default Filter;
