import { Button, Popconfirm, notification } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";
import { Columns, ListUrl, Path, Url } from "./Constant";
import { ProtectedElement } from "components";
import { delete_, getColumns } from "helper";
import { usePost } from "helper/Hook";

export const useTable = (mutateData) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate(Path);
    };

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${Url}/${primaryKey}`);
        if(!success){
            notification.error({
                description: message,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        onHide();
    };
    
    const renderAction = (_, record) => (
        <>
            <ProtectedElement permissionKey={ "user.edit" }>  
                <Button onClick={ () => navigate(`${record.id}/overview`) } type="link" size="small">
                    <i className="flaticon2-edit text-muted" />
                </Button>
            </ProtectedElement>
            <ProtectedElement permissionKey={ "user.delete" }>  
                <Popconfirm okText="Evet" cancelText="Hayır" 
                    title="Eminmisiniz?" onConfirm={ () => deleteAction(record.id) }>
                    <Button type="link" size="small" danger>
                        <i className="flaticon2-trash" />
                    </Button>
                </Popconfirm>
            </ProtectedElement>
        </>
        
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns };
};

export const useData = (queryParams) => {
    return usePost(ListUrl, queryParams);
};

export const getFilterQuery = (queryParams, values) => {
    const { active, name, email, gsm, company_id, roles } = values;
    const newQueryParams = cloneDeep(queryParams);
    const filter = {};
    if (active) filter.active = +active;
    if (email) filter.email__ilike = `%${email}%`;
    if(Number.isInteger(+gsm) && gsm !== "+905" ) filter.gsm__ilike = `%${gsm}%`;
    if(company_id) filter.company_id = company_id;
    if(roles) filter.roles___id = roles;
    if (name) filter.full_name__ilike = `%${name}%`;
    newQueryParams.filter = filter;
    return newQueryParams;
};
