import React, { createContext, useContext, useState } from "react";

const LayoutContext = createContext();
const collapseButtonFirstState =
    window && JSON.parse(localStorage.getItem("collapsedButtonState"));

const LayoutProvider = ({ children }) => {
    const [collapsed, setCollapsed] = useState(
        collapseButtonFirstState === "open" ? false : true
    );
    const [ loading, setLoading ] = useState( false );
    const [ tableDataError, setTableDataError ] = useState( false );
    
    const toggleCollapsed = () => setCollapsed( ( prev ) => !prev );

    const tableDataErrorHandle = ( error ) => setTableDataError( error );

    const value = {
        collapsed,
        loading,
        tableDataError,
        setLoading,
        toggleCollapsed,
        tableDataErrorHandle
    };
    return (
        <LayoutContext.Provider value={ value }>
            { children }
        </LayoutContext.Provider>
    );
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
    return useContext(LayoutContext);
}
