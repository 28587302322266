import { Form } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";
import { getAddViewInitialValues, handleFormSubmitQuery, useData  } from "../Helper";
import AddAddress from "components/common/AddAddress";
import { DrawerComp } from "components/common/Drawer";
import { useFilter } from "context";
import { get, post, put } from "helper";
import { notificationError } from "helper/NotificationHelpers";

const AddView = ({ onHide, from }) => {
    const [form] = Form.useForm();
    const { id: primaryKey } = useParams();
    const { queryParams } = useFilter();
    const { mutate } = useData(queryParams);

    let condition = true;
    if ( from === "company" ) condition = false;

    const [data, setData] = useState(undefined);
    const [open,setOpen] = useState(condition);
    const [visible, setVisible] = useState(false);

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notificationError(message);
                onClose();
                return;
            }
            setData(result);
        } else {
            form.setFieldsValue({ type: "company" });
        }
    };

    const handleSubmit = async (values) => {
        const query = handleFormSubmitQuery(values);
        const { success, message } = !primaryKey
            ? await post(`${Url}/add`, query)
            : await put(`${Url}/${primaryKey}`, query);
        if (!success) {
            notificationError(message);
            onClose();
            return;
        }
        setData(undefined);
        onClose();
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
    }, []);

    useEffect(() => {
        if (visible) {
            if (data) {
                const initialValues = getAddViewInitialValues(data);
                form.setFieldsValue(initialValues);
            }
        } else return;
    }, [data, visible]);

    useEffect(()=>{
        mutate();
    },[queryParams]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    return (
        <DrawerComp
            handleSubmit={ handleSubmit }
            setOpen={ setOpen }
            open={ open }
            form={ form }
            type={ "addAddress" }
        >
            <AddAddress form={ form } handleSubmit={ handleSubmit } />
        </DrawerComp>
    );
};
export default AddView;
