import { Button } from "antd";
import { collapseIcon, unCollapseIcon } from "assets/media/index";
import React from "react";

const CollapseButton = ({
    collapsed,
    toggleCollapsed,
    onClickCollapseButton
}) => {
    return (
        <div className="collapse">
            { collapsed ? (
                <>
                    <span className="collapse-btn">
                        <Button
                            className="collapse-btn"
                            onClick={ onClickCollapseButton }
                        >
                            <img src={ unCollapseIcon } alt="" />
                        </Button>
                    </span>
                </>
            ) : (
                <Button
                    className="uncollapse-btn"
                    onClick={ onClickCollapseButton }
                >
                    <img src={ collapseIcon } alt="" />
                </Button>
            ) }
        </div>
    );
};

export default CollapseButton;
