import { Card, Descriptions } from "antd";

const BillingAddress = ( { data } ) => (
    <Card style={ { height: "100%" } }>
        <Descriptions
            title="Fatura Adres Bilgileri"
            bordered
            column={ 1 }
            size={ "small" }
        >
            { data?.map((tag, i) => (
                <Descriptions.Item label={ tag[0] } key={ i }>
                    { tag[1] || null }
                </Descriptions.Item>
            )) }
        </Descriptions>
    </Card>
);

export default BillingAddress;
