import Filter from "./Filter";
import { Breadcrumbs, Router } from "../Constant";
import { useData, useTable  } from "../Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const Page = () => {
    const { queryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns, onHide } = useTable( mutate );

    return (
        <PageContent 
            breadcrumbs={ Breadcrumbs  } 
            tableData={ useData } 
            tableColumns={ columns } 
            dataFilter={ <Filter /> } 
            pageRoutes={ <Router onHide={ onHide } /> }
        />
    );
};

export default Page;
