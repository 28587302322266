import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { companyListUrl } from "services";
import { formatResult, notificationError, post } from "helper";

const CompanyList = () => {
    const [companyList, setCompanyList] = useState([]);

    const getCompanyList = async () => {
        const { result, success, message } = await post(companyListUrl,{ pageSize:1000 });
        if (!success) {
            notificationError( message );
            return;
        }
        setCompanyList(formatResult(result));
    };

    useEffect(() => {
        getCompanyList();
    }, []);

    return (
        <Form.Item
            label="Firma Listesi"
            name="company_id"
        >
            <Select
                allowClear
                showSearch
                filterOption={ (inputValue, option) =>
                    option.children?.toLowerCase().includes(inputValue.toLowerCase())
                }
            >
                {  companyList ?.map((option) => (
                    <Select.Option key={ option.value }>{ option.label }</Select.Option>
                )) } 
            </Select>
        </Form.Item>
    );
};

export default CompanyList;
