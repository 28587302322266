import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popover } from "antd";
import React from "react";

const SearchButton = ({ collapsed, content }) => {
    return (
        <div className="search-btn">
            { collapsed ? (
                <Popover
                    mode="dark"
                    placement="right"
                    content={ content }
                    trigger="click"
                >
                    <Button className="search-collapsed-btn" type="link">
                        <SearchOutlined className="search-collapsed-icon"></SearchOutlined>
                    </Button>
                </Popover>
            ) : (
                <Input
                    className="search-uncollapsed-btn"
                    type="text"
                    prefix={
                        <SearchOutlined className="search-uncollapsed-icon" />
                    }
                    size="large"
                    placeholder="  Ara"
                />
            ) }
        </div>
    );
};

export default SearchButton;
