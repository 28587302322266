import { Button, Card, Col, Result, Row } from "antd";
import React from "react";

const ErrorPage = ({ setType }) => {

    return (
        <Card>
            <Row gutter={ 24 }>
                <Col span={ 12 } push={ 6 }>
                    <Result
                        status="warning"
                        title="Bu Ayar Şemaya Sahip Değil!"
                        extra={
                            <Button type="primary" onClick={ ()=> setType("list") }>
                                Mail ve Sms Ayarları Sayfasına Dön
                            </Button>
                        }
                    />
                </Col>
            </Row> 
        </Card>
    );
};

export default ErrorPage;
