/* eslint-disable indent */
import { Button, Card, Col, Form, Row, Select, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { company, companyContinueUrl, paymentMethodsList } from "services";
import { setFormValue, setPaymentMethods } from "./Helper";
import { PaymentRow } from "./paymentRow";
import { formatResult, get, post, put } from "helper";
import { notificationError } from "helper/NotificationHelpers";

const Page = () => {
    const [form] = Form.useForm();
    const { id: Id } = useParams();

    const [options,setOptions] = useState();
    const [firmMethods,setFirmMethods] = useState();
    const [allMethods,setallMethods] = useState();
    const [external,setExternal] = useState(false);

    const methods = Form.useWatch("payment_method", form);

    const getMethods = [];

    const handleSubmit = async (values) => {
        const FirmMethods =setPaymentMethods(values, allMethods, external);
        const newValues = {
            id: Id,
            methods : FirmMethods
        };
        const { success, message:msg } = await put(`${companyContinueUrl}${parseInt(Id)}`, newValues);
        if (!success) {
            notificationError(msg);
            return;
        }
        message.success("Ödeme Yöntemi Güncellendi!");
    };
    const getData = async () => {
        const { result, success, message } = await post(paymentMethodsList, {
            pageSize: 1000,
            filter: { active: 1 }
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setallMethods(result);
        setOptions(formatResult(result));
    };

    const getCompanyMethod = async (id) => {
        const { result, success, message } = await get(`${company}${id}`, {
            pageSize: 1000,
            filter: { active: 1 }
        });
        if (!success) {
            notificationError(message);
            return;
        }
        const getExternalValue = result.payment_methods.find(item => item.slug === "kredi-karti" )?.is_external;
        { getExternalValue !== undefined ? setExternal(getExternalValue) : undefined;}
        setFirmMethods(result?.payment_methods);
        result.payment_methods.map(item => getMethods.push(item.id));
        form.setFieldsValue({ payment_method:getMethods });
    };

    useEffect(() => {
        getData();
        getCompanyMethod(parseInt(Id));
    }, []);

    useEffect(()=>{
        const formArray = setFormValue(firmMethods);
        formArray.map(item => form.setFieldsValue(item));
    },[firmMethods]);
    
    return (
        <>
            <Card className="overview-card">
                <Row gutter={ 24 }>
                    <Col
                        span={ 20 }
                        className="title"
                        style={ { display: "flex", alignItems: "center" } }
                    >
                        <p style={ { margin: 0 } }>Sipariş Ödeme Yöntemleri</p>
                    </Col>
                </Row>
            </Card>
            <Card className="company-info-card-content">
                <Form
                    className="company-info-card-form"
                    form={ form }
                    name="basic"
                    labelCol={ {
                        lg: { span: 5, pull: 0 },
                        xl: { span: 5, pull: 1 },
                        xxl: { span: 5, pull: 2 }
                    } }
                    wrapperCol={ {
                        push: 2,
                        span: 14
                    } }
                    onFinish={ handleSubmit }
                    autoComplete="off"
                >
                    <Row gutter={ 24 }>
                        <Col span={ 20 } push={ 1 }>
                            <Form.Item label="Ödeme Yöntemi" name="payment_method">
                                <Select
                                    placeholder="Lütfen rol seçiniz"
                                    allowClear
                                    showSearch
                                    mode="multiple"
                                    filterOption={ (inputValue, option) =>
                                option.children
                                    ?.toLowerCase()
                                    .includes(inputValue.toLowerCase())
                            }
                                    options={ options }
                        >
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    { methods?.map(item => 
                        <PaymentRow 
                            key={ item } 
                            item={ item } 
                            firmMethods={ firmMethods } 
                            allMethods={ allMethods } 
                            setExternal={ setExternal }
                            external={ external }
                        />
                    ) }
                </Form>
            </Card>
            <Card>
                <Row align="space-between">
                    <Col>
                    </Col>
                    <Col>
                        <Space>
                            <Button
                                type="primary"
                                className="footer-btn"
                                onClick={ () => {
                                    form.submit();
                                } }
                            >
                                Değişiklikleri Kaydet
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </>
    );
};
export default Page;
