import { Button, Popconfirm } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { deleteConfigContinueUrl } from "services";
import { Columns, ListUrl } from "./Constant";
import { ProtectedElement } from "components";
import { delete_, getColumns, notificationError, usePost } from "helper";

export const useTable = ( setType, setId ) => {

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${deleteConfigContinueUrl}${primaryKey}`);
        if (!success) {
            notificationError(message);
            return;
        }
    };

    const renderAction = (_, record) => (
        <>
            <ProtectedElement permissionKey={ "setting.edit" }>  
                <Button
                    onClick={ () =>  [setType("edit"), setId(record.id)] }
                    type="link"
                    size="small"
                >
                    <i className="flaticon2-edit text-muted" />
                </Button>
            </ProtectedElement>
            <ProtectedElement permissionKey={ "setting.delete" }>  
                <Popconfirm
                    okText="Evet"
                    cancelText="Hayır"
                    title="Eminmisiniz?"
                    onConfirm={ () => deleteAction(record.id) }
                >
                    <Button type="link" size="small" danger>
                        <i className="flaticon2-trash" />
                    </Button>
                </Popconfirm>
            </ProtectedElement>
        </>
    );

    const columns = getColumns(Columns, renderAction);
    return { columns };
};

export const useData = (queryParams) => {
    return usePost(ListUrl, queryParams);
};

export const getFilterQuery = (queryParams, values) => {
    const { active, name } = values;
    const newQueryParams =  cloneDeep(queryParams);
    const filter = {};
    if (active) filter.active = +active;
    if (name) filter.firm__ilike = `%${name}%`;
    newQueryParams.filter = filter;
    return newQueryParams;
};
