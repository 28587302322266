import { Checkbox, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";

const PropsRowPage = ({ data, form, type, setCheckboxValue, setFormObject, editType }) => {
    const { label, required, name, value, dataType, selection, htmlType, valueType, placeholder  } = data;

    const [condition,setCondition] = useState();
    const [optionsArray,setOptionsArray] = useState(undefined);

    const onChangeCheckbox = (e) => setCheckboxValue(e.target.checked);

    useEffect(()=>{
        if (htmlType === "checkbox" && valueType === "list") setCondition("checkbox");
        else if (valueType === "list") {
            if(selection==="multiple") setCondition("multipleDropdown");
            else setCondition("singleDropdown"); 
        }
        else if (dataType==="String" || dataType==="Integer" || !value) setCondition("textbox");
        else return null;
    },[]);

    useEffect(()=>{
        if (editType) form.setFieldsValue({ [name]:undefined });
        if ( type === "Edit" && !editType ) form.setFieldsValue({ [name]:data.default });
        if ( valueType === "list" ) setOptionsArray(value?.map(item=>{ return { label:item,value:item };}));
    },[]);

    return (
        <Form.Item
            label={ condition !== "checkbox" ? label : undefined }
            name={ name }
            required={ required }
            valuePropName={ condition === "checkbox" ? "checked" : undefined }
            rules={ condition !== "checkbox" ? [
                {
                    message:"Lütfen değer giriniz", required: true
                },
                () => ({
                    validator(_,value) {
                        if (value !== undefined && value !== "") {
                            setFormObject(prev => ({ ...prev,[name]:true }));
                            return Promise.resolve("");
                        }
                        else {
                            setFormObject(prev => ({ ...prev,[name]:false }));
                            return  Promise.reject("");
                        } 
                    }
                })
            ] : undefined }
        >
            { condition === "textbox" && label === "Parola" ? 
                <Input.Password placeholder={ placeholder } /> 
                : condition === "textbox" ?
                    <Input placeholder={ placeholder } /> 
                    : condition === "checkbox" ? 
                        <Checkbox checked={ type==="Edit" ? data.default : false } onChange={ onChangeCheckbox }>{ label }</Checkbox> 
                        : condition === "singleDropdown" ? 
                            <Select
                                placeholder={ placeholder }
                                allowClear
                                filterOption={ (inputValue, option) =>
                                    option.children?.toLowerCase().includes(inputValue.toLowerCase())
                                }
                                mode={ condition === "multipleDropdown" ? "multiple" : undefined }
                            >
                                { optionsArray?.map((option) => (<Select.Option key={ option.value }>{ option.label }</Select.Option>
                                )) }
                            </Select>
                            : undefined
            }
        </Form.Item>
    );
};

export default PropsRowPage;
