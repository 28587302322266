import { Navigate, Route, Routes } from "react-router-dom";
import { MemberShipPage } from "./";
import { RolePage } from "./role";
import { EditRoleRouter } from "./role/edit";
import  { UserPage } from "./user";
import { UserDetailRouter } from "./user/detail";
import { ProtectedRoute } from "components";

const Router = () => (
    <Routes>
        <Route index element={ <MemberShipPage /> } />
        <Route path="membershipsum"    element={ <Navigate to="/membership" replace /> } /> 
        <Route element={ <ProtectedRoute permissionKey={ "user.view" } /> }>      
            <Route path="user/*" element={ <UserPage /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "user.edit" } /> }>     
            <Route path="/user/:id/overview/*" element={ <UserDetailRouter/> } ></Route>
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "role.view" } /> }>  
            <Route path="role/*" element={ <RolePage /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "role.edit" } /> }>  
            <Route path="role/:id/edit/*" element={ <EditRoleRouter /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "role.add" } /> }>  
            <Route path="role/add/*" element={  <EditRoleRouter />  } />
        </Route>
    </Routes>  
);

export default Router;
