import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { roleUrl } from "services";
import { get, notificationError } from "helper";

const RoleInfo = ({ roleId, form, setRoleType, roleList, isEdit, editRolName, setEditRolName }) => {

    const getRoleInfo = async () => {
        const { result, success, message } = await get(`${roleUrl}/${roleId}`);
        if (!success) {
            notificationError(message);
            return;
        }
        const { name, description, origins, company_id, type } = result || {};
        form.setFieldsValue({
            company_id: company_id?.toString(),
            description,
            name: name,
            origins: origins || []
        });
        if (isEdit) setEditRolName(name); 
        setRoleType(type);
    };

    useEffect(() => {
        getRoleInfo();
    }, [roleId]);

    return (
        <>
            <Form.Item
                label="Rol İsmi"
                name="name"
                rules={ [
                    { message: "Lütfen rol ismi giriniz", required: true },
                    {
                        validator: (_, value) => {
                            let isExist;
                            if(isEdit){
                                isExist= roleList?.filter(
                                    (item) => item?.label !== editRolName
                                ).find(
                                    (item) => item?.label === value
                                );
                            }
                            else isExist = roleList?.find(
                                (item) => item?.label === value
                            );
                            if (isExist)
                                return Promise.reject(
                                    new Error("Lütfen farklı bir isim seçiniz!")
                                );
                            else return Promise.resolve();
                        }
                    }
                ] }
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Açıklama"
                name="description"
                rules={ [{ message: "Lütfen açıklama giriniz", required: true }] }
            >
                <Input />
            </Form.Item>
        </>
    );
};

export default RoleInfo;
