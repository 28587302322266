import { Card, Row, Tabs } from "antd";
import React, { useState } from "react";
import { activateItems, tableItems } from "./Constant";

const MerchantSettingsPage = () => {
    const [activeTab, setActiveTab] = useState("item-1");

    const onChangeTab = ( activeKey ) => setActiveTab( activeKey );

    return (
        <>
            <Card>
                <Row style={ { marginBottom:-42 } }>
                    <Tabs
                        items={ tableItems }
                        activeKey={ activeTab }
                        onChange={ onChangeTab }
                        tabPosition={ "top" }
                    />
                </Row>
            </Card>
            <br />      
            { activateItems[ activeTab ] }
        </>
    );
};

export default MerchantSettingsPage;
