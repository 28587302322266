import { Image } from "antd";
import { Route, Routes } from "react-router-dom";
import { collectionUrl, listUrl } from "services";
import { AddView } from "./add";
import { ProtectedRoute } from "components";

const Breadcrumbs = [
    {
        breadcrumbName: "Katalog",
        path: "/catalog"
    },
    {
        breadcrumbName: "Koleksiyonlar",
        path: "/collection"
    }
];

const Columns = [
    {
        render: (_, record) => (
            <span className="symbol symbol-50px">
                <Image 
                    className="product img-thumbnail"
                    src={ record.image }
                />
            </span>
        ),
        title: "",
        width: 80
    },
    {
        render: (_, record) => {
            const { name,  update_date, create_date } = record;
            return(
                <>
                    <div className="mb-5">  { name }</div>
                    <div className="font-weight-light font-size-xs">
                        Kayıt Tarihi: { create_date }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Güncelleme Tarihi: { update_date }
                    </div>
                </>
            );},
        title: "Kolleksiyon"
    }
];
const Path = "/catalog/collection";
const Url = collectionUrl;
const ListUrl = `${Url}${listUrl}`;

const Router = ({ onHide }) => (
    <Routes>
        <Route element={ <ProtectedRoute permissionKey={ "collection.add" } /> }>
            <Route path="add" element={ <AddView onHide={ onHide } /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "collection.edit" } /> }>
            <Route path=":id/edit" element={ <AddView onHide={ onHide } /> } />
        </Route>
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

