import { Button, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { salesOrderUrl } from "services";
import { Columns, ListUrl, Path, Url } from "./Constant";
import { delete_, getColumns, notificationError } from "helper";
import { usePost } from "helper/Hook";

export const useTable = (mutateData) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate(Path);
    };

    // const deleteAction = async (primaryKey) => {
    //     const { success, message } = await delete_(`${Url}/${primaryKey}`);
    //     if (!success) {
    //         notificationError(message);
    //         return;
    //     }
    //     onHide();
    // };

    const renderAction = (_, record) => (
        <>
            <Button 
                onClick={ () => navigate(`${salesOrderUrl}${record?.id}/edit`) } 
                type="link" 
                size="small"
            >
                <i className="flaticon2-file text-muted" />
            </Button>
            { /* <Popconfirm okText="Evet" cancelText="Hayır" 
                title="Eminmisiniz?" onConfirm={ () => deleteAction(record.id) }>
                <Button type="link" size="small" danger>
                    <i className="flaticon2-trash" />
                </Button>
            </Popconfirm> */ }
        </>
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns, onHide };
};

export const useData = (queryParams) => {
    const newParams={ ...queryParams };
    newParams.sortField = "ordered_at";
    return usePost(ListUrl,newParams);
};

export const getFilterQuery = (queryParams, values) => {
    const path = location?.pathname.split("/");
    const companyId = parseInt(path?.at(-2));
    const { name } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (name) filter.order_key__ilike = `%${name}%`;
    if (companyId) filter.company_id = companyId;
    newQueryParams.filter = filter;
    return newQueryParams;
};
