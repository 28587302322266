import { Col, Row } from "antd";
import React, { useMemo } from "react";
import { billingDataHelper, customerDataHelper, shippingDataHelper } from "./Helper";
import BillingAddress from "./OrderSummary/BillingAddress";
import CustomerDetails from "./OrderSummary/CustomerDetails";
import Documents from "./OrderSummary/Documents";
import ShippingAddress from "./OrderSummary/ShippingAddress";

const OrderSummary = ( { data } ) => {
    const renderCustomerDetails = useMemo( () => {
        return (<CustomerDetails data={ customerDataHelper(data.buyer) } />);
    }, [ data.buyer ] );

    const renderDocuments = useMemo(() => {
        return <Documents data={ data.documents } />;
    }, [data.documents]);

    const renderBillingAddress = useMemo(() => {
        return <BillingAddress data={ billingDataHelper( data.billing_address ) }  />;
    }, [ data.billing_address ] );
    
    const renderShippingAddress = useMemo(() => {
        return <ShippingAddress data={ shippingDataHelper( data.address ) }/>;
    }, [data.address]);
    
    return (
        <>
            <Row gutter={ 24 }>
                <Col span={ 12 } display="flex">
                    { renderCustomerDetails }
                </Col>
                <Col span={ 12 } display="flex">
                    { renderDocuments }
                </Col>
            </Row>
            <br />
            <Row gutter={ 24 }>
                <Col span={ 12 }>
                    { renderBillingAddress }
                </Col>
                <Col span={ 12 }>
                    { renderShippingAddress }
                </Col>
            </Row>
        </>
    );
};
export default OrderSummary;
