import {  Card, Tabs, Typography } from "antd";
import React, { useState } from "react";
import GetCodeWithEmail from "./GetCodeWithEmail";
import GetCodeWithPhone from "./GetCodeWithPhone";
import CardHeader from "../common/CardHeader";
import CodeVerificationPage from "../common/CodeVerificationPage";

const { Paragraph } = Typography;

const ForgotPasswordPage= () => 
{
    const [ stepData, setStepData ] = useState( undefined );
    const items = [
        { label: "E-Posta ile", key: "1", children: <GetCodeWithEmail setStepData={ setStepData } /> },
        { label: "Telefon ile", key: "2", children:  <GetCodeWithPhone setStepData={ setStepData }/> }
    ];
    
    return (   
        <div className="forgotpassword_container">
            { !stepData?.sent ? 
                (<Card>
                    <CardHeader headContent="Parola Sıfırlama" link="/auth/login"/>         
                    <Paragraph>Şifre sıfırlama işlemi için size kod göndereceğiz.
                        Kod için E-Posta veya Telefon Seçeneklerini kullanabilirsiniz.</Paragraph>
                    <Tabs centered items={ items } />
                </Card>) :
                (<CodeVerificationPage stepData={ stepData } setStepData={ setStepData } />) }
        </div>
    );
};


export default ForgotPasswordPage;
