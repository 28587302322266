import { Col, Form, InputNumber, Switch } from "antd";
import { CustomNumberInput } from "components";

export const columns = [
    {  
        title: "Taksit Sayısı",
        dataIndex: "installment",
        render: (_, record) => (
            <Form.Item
                initialValue={ record?.installment }
                name={ [`${record?.id}`, "installment"] }
                rules={ [
                    {
                        required: true,
                        message: "Lütfen boş bırakmayınız!"
                    }
                ] }
            >
                <p>{ record?.installment === 1 ? "Tek Çekim" : `${record?.installment} Taksit` }</p>
            </Form.Item>
        )
    },
    {
        title: "Aktif/Pasif",
        dataIndex: "active",
        key: "active",
        render: (_, record) =>
            (
                <>
                    <Form.Item
                        name={ [`${record?.id}`, "active"] }
                        valuePropName="checked"
                        initialValue={ record?.active }
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen boş bırakmayınız!"
                            }
                        ] }
                    >
                        <Switch  />
                    </Form.Item>
                    <Form.Item
                        name={ [`${record?.id}`, "additiveInstallment"] }
                        label="Ekstra Taksit"
                        initialValue={ record?.additiveInstallment }
                    >
                        <InputNumber
                            type="number"
                            min={ 0 }
                            precision={ 0 }
                            max={ 9 }
                            controls={ false }
                        />
                    </Form.Item>
                </>
            )       
    },
    {
        title: "Yurtiçi Komisyon(%)",
        dataIndex: "comissions",
        key: "comissions",

        render: (_, record) =>
            (
                <Col>      
                    <Form.Item
                        name={ [`${record?.id}`, "bankOnUsDebitCardCommisionRate"] }
                        label="Kendi Banka Kartı"
                        className="product-stock_input_border"
                        initialValue={ record?.bankOnUsDebitCardCommisionRate }
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen boş bırakmayınız!"
                            }
                        ] }
                    >
                        <InputNumber
                            type="number"
                            min={ 0 }
                            precision={ 2 }
                            max={ 100 }
                            controls={ false }
                            onWheel={ (e) => e.target.blur() } 
                        />
                    </Form.Item>
                    { record?.installment === 1 && (
                        <Form.Item
                            name={ [`${record?.id}`, "bankNotOnUsDebitCardCommisionRate"] }
                            label="Diğer Banka Kartı"
                            className="product-stock_input_border"
                            initialValue={ record?.bankNotOnUsDebitCardCommisionRate }
                            rules={ [
                                {
                                    required: true,
                                    message: "Lütfen boş bırakmayınız!"
                                }
                            ] }
                        >
                            <InputNumber
                                type="number"
                                min={ 0 }
                                precision={ 2 }
                                max={ 100 }
                                controls={ false }
                                onWheel={ (e) => e.target.blur() } 
                            />
                        </Form.Item>
                    ) }         
                    <Form.Item
                        name={ [`${record?.id}`, "bankOnUsCreditCardCommisionRate"] }
                        label={ "Kendi Kredi Kartı" }
                        initialValue={ record?.bankOnUsCreditCardCommisionRate }
                        className="product-stock_input_border"
                        rules={ [
                            {
                                required: true,
                                message: "Lütfen boş bırakmayınız!"
                            }
                        ] }
                    >
                        <InputNumber
                            type="number"
                            min={ 0 }
                            precision={ 2 }
                            max={ 100 }
                            controls={ false }
                            onWheel={ (e) => e.target.blur() } 
                        />
                    </Form.Item>
                    { record?.installment === 1 && (
                        <Form.Item
                            name={ [`${record?.id}`, "bankNotOnUsCreditCardCommisionRate"] }
                            label="Diğer Kredi Kartı"
                            className="product-stock_input_border"
                            initialValue={ record?.bankNotOnUsCreditCardCommisionRate }
                            rules={ [
                                {
                                    required: true,
                                    message: "Lütfen boş bırakmayınız!"
                                }
                            ] }
                        >
                            <InputNumber
                                type="number"
                                min={ 0 }
                                precision={ 2 }
                                max={ 100 }
                                controls={ false }
                                onWheel={ (e) => e.target.blur() } 
                            />
                        </Form.Item>
                    ) }
            
                </Col>
            )
    },
    {
        title: "Yabancı Kart Komisyon (%)",
        dataIndex: "address",
        key: "address",
        render: (_, record) =>
            (
                <Form.Item
                    name={ [`${record?.id}`, "bankForiegnCardCommisionRate"] }
                    className="product-stock_input_border"
                    initialValue={ record?.bankForiegnCardCommisionRate }
                    rules={ [
                        {
                            required: true,
                            message: "Lütfen boş bırakmayın!"
                        }
                    ] }
                >
                    <InputNumber
                        type="number"
                        min={ 0 }
                        precision={ 2 }
                        max={ 100 }
                        controls={ false }
                        onWheel={ (e) => e.target.blur() } 
                    />
                </Form.Item>
            )
    },
    {
        title: "Bloke Gün Sayısı (gün)",
        dataIndex: "blockageDay",
        render: ( _, record ) =>(
            <Form.Item
                name={ [`${record?.id}`, "blockageDay"] }
                className="product-stock_input_border"
                initialValue={ record?.blockageDay }
                rules={ [
                    {
                        required: true,
                        message: "Lütfen boş bırakmayın!"
                    }
                ] }
            >
                <InputNumber
                    type="number"
                    min={ 0 }
                    max={ 999 }
                    precision={ 0 }
                    controls={ false }
                    onWheel={ (e) => e.target.blur() } 
                />
            </Form.Item>
        )
    },
    {
        title: "Vade Fark Oranı (%)",
        dataIndex: "merchantCommissionRate",
        render: (_, record) =>
            (
                <Form.Item
                    name={ [`${record?.id}`, "merchantCommissionRate"] }
                    className="product-stock_input_border"
                    initialValue={ record?.merchantCommissionRate }
                    rules={ [
                        {
                            required: true,
                            message: "Lütfen boş bırakmayın!"
                        }
                    ] }
                >
                    <InputNumber
                        type="number"
                        min={ 0 }
                        precision={ 2 }
                        max={ 900 }
                        controls={ false }
                        onWheel={ (e) => e.target.blur() } 
                    />
                </Form.Item>
            )
    }
];

