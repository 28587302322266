import { Tabs } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import CodeVerificationPage from "./common/CodeVerificationPage";
import LoginWithEmail from "./login/LoginWithEmail";
import LoginWithPhone from "./login/LoginWithPhone";
import RegisterPage from "./login/RegisterPage";

const LoginPage = () => {
    const { state } = useLocation();

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(
        state?.activeTab.toString() || "1"
    );
    const [smsLogin, setSmsLogin] = useState(false);
    const [stepData, setStepData] = useState(undefined);

    const items = [
        {
            label: "Giriş Yap",
            key: "1",
            children: !smsLogin ? (
                <LoginWithEmail
                    setLoading={ setLoading }
                    loading={ loading }
                    setSmsLogin={ setSmsLogin }
                />
            ) : (
                <LoginWithPhone
                    setLoading={ setLoading }
                    loading={ loading }
                    setSmsLogin={ setSmsLogin }
                    setStepData={ setStepData }
                />
            )
        },
        {
            label: "Kayıt Ol",
            key: "2",
            children: (
                <RegisterPage setLoading={ setLoading } loading={ loading }></RegisterPage>
            )
        }
    ];

    const changeTab = (activeKey) => setActiveTab(activeKey);

    return (
        <div className="loginTabContainer">
            { !stepData?.sent ? (
                <Tabs
                    type="card"
                    activeKey={ activeTab }
                    onChange={ changeTab }
                    items={ items }
                ></Tabs>
            ) : (
                <CodeVerificationPage stepData={ stepData } setStepData={ setStepData } />
            ) }
        </div>
    );
};

export default LoginPage;
