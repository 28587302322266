import Filter from "./Filter";
import { Breadcrumbs } from "../Constant";
import { useData, useTable  } from "../Helper";
import { PageContent } from "components";
import { useFilter } from "context";

const Page = () => {
    const { queryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns } = useTable(mutate);

    return (
        <PageContent 
            breadcrumbs={ Breadcrumbs } 
            tableData={ useData } 
            tableColumns={ columns } 
            dataFilter={ <Filter /> } 
        />
    );
};

export default Page;
