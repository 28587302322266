import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tag, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { ordersSendToServerUrl, updateAnOrderUrl } from "services";
import { notificationError, notificationSuccess, post } from "helper";

const { Text } = Typography;

const TopTitle = ({ orderKey, orderDate, mutate, orderOutref, data }) => {
    const [loading, setLoading] = useState(false);

    const updateOrderAction = async (outRef) => {
        setLoading(true);
        const { success, message } = await post(`${updateAnOrderUrl}${outRef}`);
        if (!success) {
            setLoading(false);
            notificationError(message);
            return;
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
        notificationSuccess("Gönderim isteğiniz alındı");
        mutate();
    };

    const sendToServerAction = async (primaryKey) => {
        setLoading(true);
        const { success, message } = await post(`${ordersSendToServerUrl}`, {
            "order_ids": [primaryKey]
        });
        setLoading(false);
        if (!success) {
            notificationError(message);
            return;
        }
        notificationSuccess("Sipariş başarıyla gönderildi");
    };

    return (
        <>
            <Col>
                <Row>
                    <Col>
                        <Text level={4}>
                            <b>{orderKey}</b>
                        </Text>
                        &nbsp;&nbsp;
                        {orderDate}
                    </Col>
                    <Col push={2}>
                        <Tag color="blue">{(data.source).toUpperCase()}</Tag>
                    </Col>
                </Row>
            </Col>
            <Col>
                {(data.is_sent === false && data.out_ref === null) ?
                    <Tooltip title="Sunucuya gönder">
                        <Button
                            type="link"
                            size="default"
                            onClick={() => sendToServerAction(data.id)}
                            loading={loading.one}
                        >
                            <i className="flaticon2-send-1 mr-5" /> Gönder
                        </Button>
                    </Tooltip> :
                    <Tag color="green">
                        Gönderildi
                    </Tag>
                }
                <Button
                    type="link"
                    onClick={() => updateOrderAction(orderOutref)}
                    loading={loading.two}>
                    <CloudDownloadOutlined />
                    Güncelle
                </Button>
            </Col>
        </>
    );
};

export default TopTitle;
