import { Button, Card, Col, Form, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addressListUrl, companyListUrl, roleListUrl, userAddressesUrl } from "services";
import { notificationError, post, put } from "helper";
import { useUser } from "helper/Hook";
import { formatResult } from "helper/TableHelpers";

const CompanyInfo = () => {
    const { id: userId } = useParams();
    const { data } = useUser(userId);
    const { result: user } = data;
    const [form] = Form.useForm();

    const [roles, setRoles] = useState(undefined);
    const [roleType, setRoleType] = useState(user?.roles[0]?.type);
    const [roleId, setRoleId] = useState(user?.roles[0]?.id.toString());
    const [address, setAddress] = useState(undefined);
    const [officialAddress, setOfficialAddress] = useState(undefined);
    const [companies, setCompanies] = useState(undefined);

    const companyId = user?.company_id;

    const completeInfo = () => {
        form.setFieldsValue({
            company_id: companyId?.toString(),
            roles: user?.roles[0]?.id?.toString()
        });
    };

    const getRoles = async () => {
        const { result, success, message } = await post(roleListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setRoles(formatResult(result, "name", "id" , "type"));
    };

    const onRolChange = (e,rolType) => {
        setRoleId(e);
        setRoleType(rolType.type);
        form.setFieldsValue({
            addresses: undefined,
            company_id: undefined,
            default_address: undefined
        });
    };

    const handleSubmit = async (values) => {
        if (values.roles === "companyuser" && values.addresses[0].label) {
            for (let i = 0; i < values.addresses.length; i++) {
                values.addresses[i] = values.addresses[i].value;
            }
        }
        let newValues = {};
        if (roleType === "panel" || roleType === "seller") {
            newValues = {
                company_id: undefined,
                roles: [parseInt(roleId)],
                user_id: user.id
            };
        } else {
            newValues = {
                ...values,
                roles: [parseInt(roleId)],
                user_id: user.id
            };
        }
        if (newValues.addresses && newValues.addresses[0].label) newValues.addresses[0] = newValues.addresses[0].value
        const { success, message: msg } = await put(
            `${userAddressesUrl}/${user.id}`,
            newValues
        );
        if (!success) {
            notificationError(msg);
            return;
        }
        message.success("Bilgiler güncellendi!");
    };

    const getNewUserAddress = async () => {
        const { result, success, message } = await post(addressListUrl, {
            filter: { user_id: user.id },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        for (let i = 0; i < result.length; i++) {
            if (result[i].is_default === true)
                form.setFieldsValue({ default_address: result[i].id.toString() });
        }
        const addrArr = result?.map((item) => {
            return { label: item.title, value: item.id.toString() };
        });
        form.setFieldsValue({ addresses: addrArr });
        setOfficialAddress(addrArr);
    };

    const getCompanyAddresses = async (companyId) => {
        if (!companyId) return;
        const { success, result, message } = await post(addressListUrl, {
            filter: { active: true, company_id: companyId, type: "company" },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setAddress(
            result?.map((item) => {
                return { label: item.title, value: item.id };
            })
        );
    };

    const onChangeCompanyId = (e) => {
        getCompanyAddresses(e);
        form.setFieldsValue({
            addresses: undefined,
            default_address: undefined
        });
    };

    const getUserOfficialAddress = async (e) => {
        form.setFieldsValue({ default_address: undefined });
        let selectedAddresses = [];
        for (let i = 0; i < e.length; i++) {
            const selectedAddr = address.filter((a) => a.value === +e[i]);
            selectedAddr.length > 0 && selectedAddresses.push(selectedAddr[0]);
            setOfficialAddress(selectedAddresses);
        }
    };

    const getCompanies = async () => {
        const { result, success, message } = await post(companyListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCompanies(formatResult(result));
    };

    useEffect(() => {
        getRoles();
        getCompanies();
    }, []);

    useEffect(() => {
        getCompanyAddresses(companyId);
        completeInfo();
        getNewUserAddress();
    }, [user]);

    const authorizedAddresses = roleType === "companyuser" ? officialAddress : address;

    return (
        <>
            <Card className="title">
                <p className="title-p">Şirket Bilgileri</p>
            </Card>
            <Card className="company-info-card-content">
                <Form
                    className="company-info-card-form"
                    form={ form }
                    name="basic"
                    labelCol={ {
                        lg: { pull: 0, span: 5 },
                        xl: { pull: 1, span: 5 },
                        xxl: { pull: 2, span: 5 }
                    } }
                    wrapperCol={ {
                        push: 2,
                        span: 14
                    } }
                    onFinish={ handleSubmit }
                    autoComplete="off"
                >
                    <Form.Item label="Rol" name="roles">
                        <Select
                            placeholder="Lütfen rol seçiniz"
                            allowClear
                            showSearch
                            filterOption={ (inputValue, option) =>
                                option.children
                                    ?.toLowerCase()
                                    .includes(inputValue.toLowerCase())
                            }
                            onChange={ onRolChange }
                        >
                            { roles?.map((option) => (<Select.Option key={ option.value } type={ option.type }>{ option.label }</Select.Option>
                            )) }
                        </Select>
                    </Form.Item>
                    { (roleType === "companyadmin" || roleType === "companyuser") && (
                        <Form.Item
                            label="Firma"
                            name="company_id"
                            rules={ [
                                {
                                    message: "Lütfen firma seçiniz!",
                                    required: true
                                }
                            ] }
                            className="w-100"
                        >
                            <Select
                                placeholder="Lütfen firma seçiniz"
                                allowClear
                                showSearch
                                filterOption={ (inputValue, option) =>
                                    option.children
                                        ?.toLowerCase()
                                        .includes(inputValue.toLowerCase())
                                }
                                onChange={ onChangeCompanyId }
                            >
                                { companies?.map((option) => (
                                    <Select.Option key={ option.value }>
                                        { option.label }
                                    </Select.Option>
                                )) }
                            </Select>
                        </Form.Item>
                    ) }
                    { roleType === "companyuser" && (
                        <Form.Item
                            label="Yetkili Adres"
                            name="addresses"
                            rules={ [
                                {
                                    message: "Lütfen adres seçiniz!",
                                    required: true
                                }
                            ] }
                            className="w-100"
                        >
                            <Select
                                placeholder="Lütfen yetkili adres seçiniz"
                                allowClear
                                mode="multiple"
                                showSearch
                                filterOption={ (inputValue, option) =>
                                    option.children
                                        ?.toLowerCase()
                                        .includes(inputValue.toLowerCase())
                                }
                                onChange={ getUserOfficialAddress }
                            >
                                { address?.map((option) => (
                                    <Select.Option key={ option.value }>
                                        { option.label }
                                    </Select.Option>
                                )) }
                            </Select>
                        </Form.Item>
                    ) }
                    { (roleType === "companyuser" || roleType === "companyadmin") && (
                        <Form.Item
                            label="Tanımlı Adres"
                            name="default_address"
                            rules={ [
                                {
                                    message: "Lütfen adres seçiniz!",
                                    required: true
                                }
                            ] }
                            className="w-100"
                        >
                            <Select
                                placeholder="Lütfen tanımlı adres seçiniz"
                                allowClear
                                showSearch
                                className="w-100"
                                filterOption={ (inputValue, option) =>
                                    option.children
                                        ?.toLowerCase()
                                        .includes(inputValue.toLowerCase())
                                }
                            >
                                { authorizedAddresses?.map((option) => (
                                    <Select.Option key={ option.value }>
                                        { option.label }
                                    </Select.Option>
                                )) }
                            </Select>
                        </Form.Item>
                    ) }
                </Form>
            </Card>
            <Card>
                <Row gutter={ 24 }>
                    <Col
                        xs={ {
                            push: 13,
                            span: 1
                        } }
                        sm={ {
                            push: 14,
                            span: 2
                        } }
                        md={ {
                            push: 16,
                            span: 4
                        } }
                        lg={ {
                            push: 18,
                            span: 6
                        } }
                        xl={ {
                            push: 18,
                            span: 8
                        } }
                        xxl={ {
                            push: 18,
                            span: 10
                        } }
                    >
                        <Button
                            type="primary"
                            className="footer-btn"
                            onClick={ () => {
                                form.submit();
                            } }
                        >
                            Değişiklikleri Kaydet
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default CompanyInfo;
