import { Tag, Typography } from "antd";
import { Route, Routes } from "react-router-dom";
import { addressUrl, listUrl } from "services";
import { AddView } from "./add";
import { ProtectedRoute } from "components";

const Breadcrumbs = [
    {
        breadcrumbName: "Cari İşlemleri",
        path: "/account"
    },
    {
        breadcrumbName: "Adresler",
        path: "/address"
    }
];

const Columns = [
    {
        render: ( _, record ) => {
            const { name, title, address1, address2, out_ref, code, create_date, update_date }=record || {};
            const titleStyle=!record?.name && record.title 
                ? "text-dark-75 font-weight-bold text-hover-info mb-1":"text-muted";        
            return(
                <>   
                    <Typography.Text className="text-dark-75 font-weight-bold text-hover-info mb-1">
                        { name }
                    </Typography.Text>
                    <div className={ titleStyle }>{ title }</div>
                    { address1 && (<div className="text-muted">{ address1 }</div>) }
                    { address2 && (<div className="text-muted">İkinci Adres: { address2 }</div>) }
                    { code && (<div className="text-muted">Kod: { code }</div>) }
                    { out_ref && (<div className="text-muted">Kaynak Kodu: { out_ref }</div>) }
                    { create_date && (<div className="text-muted">Kayıt Tarihi: { create_date }</div>) }
                    { update_date && (<div className="text-muted">Güncelleme Tarihi: { update_date }</div>) }
                </>
            );},
        sorter:true,
        title: "Adres"
    },
    {
        render: ( _,record ) => <>
            <span>{ record?.county?.name }</span><br />
            <span>{ record?.city?.name }</span>
        </>,
        title: "İlçe/İl"
    },
    {
        render: ( _,record ) =>{
            const addressTitle=  ( record?.type in addressType  )
                ?  addressType[record.type]
                : null; 
            const color = ( addressTitle in colorStatus )
                ? colorStatus[ addressTitle ]
                : null;
            return (<Tag color={ color }>
                { addressTitle }
            </Tag> );
        },
        title: "Adres Türü"
    }
];

const addressType = { company: "Şube Adresi", shipment: "Müşteri adresi" };
export const addressTypeList = [{ label:"Şube Adresi", value:"company" },{ label:"Müşteri Adresi", value:"shipment" }];
const colorStatus = { "Müşteri adresi": "cyan", "Şube Adresi": "blue" };

const Path = "/account/address";
const Url = addressUrl;
const ListUrl = `${ Url }${listUrl}`;

const Router = ( { onHide } ) => (
    <Routes>
        <Route element={ <ProtectedRoute permissionKey={ "address.add" } /> }>   
            <Route path="add" element={ <AddView onHide={ onHide } /> } />
        </Route>
        <Route element={ <ProtectedRoute permissionKey={ "address.edit" } /> }>   
            <Route path=":id/edit" element={ <AddView onHide={ onHide } /> } />
        </Route>
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

