import { FilterOutlined } from "@ant-design/icons";
import { Button } from "antd/lib/radio";
import React from "react";

export const FilterButton = ({ setOpen, buttonStyle }) => {
    return (
        <>
            <Button
                shape="round"
                className={ `filter-btn ${buttonStyle}` }
                onClick={ () => setOpen(true) }
            >
                Filtrele
                <FilterOutlined className="filter-icon" />
            </Button>
        </>
    );
};
