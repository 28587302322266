import { Button, Card, Form, Input, Row, Space, notification } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authenticateLoginUrl } from "services";
import { useAuth } from "context";
import { post } from "helper";

const LoginWithEmail = ({ setLoading, loading, setSmsLogin }) => {
    const { createSession } = useAuth();
    const navigate = useNavigate();
    const [buttonText, setButtonText] = useState("Giriş Yap");

    const onFormSubmit = async (data) => {
        setLoading(true);
        setButtonText("Bekleyin ...");
        try {
            const { user, tokens, success, message } = await post(
                authenticateLoginUrl,
                {
                    ...data
                }
            );
            if (!success) {
                setLoading(false);
                setButtonText("Giriş Yap");
                notification.error({
                    description: message,
                    message: "Bilgilendirme",
                    placement: "top"
                });
                return;
            }
            setButtonText("Giriş başarılı!");
            await createSession({ tokens, user });
            navigate("/dashboard");
        } catch (e) {
            setButtonText("Giriş Yap");
            setLoading(false);
            console.error(e.toString());
        }
    };

    return (
        <Card className="login_card" align="center">
            <Form onFinish={ onFormSubmit } autoComplete="off" size="small">
                <Space size="small" direction="vertical" style={ { width: "100%" } }>
                    <Form.Item
                        name="email"
                        rules={ [
                            {
                                message: "Lütfen e-posta adresinizi girin!",
                                required: true
                            },
                            {
                                message: "Lütfen geçerli e-posta adresi girin!",
                                type: "email"
                            }
                        ] }
                    >
                        <Input bordered={ false } placeholder="Eposta Adresi" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={ [{ message: "Şifre giriniz!", required: true }] }
                    >
                        <Input.Password bordered={ false } placeholder="Şifre" />
                    </Form.Item>
                    <Row align="end">
                        <Button
                            type="link"
                            className="link_button"
                            size={ "small" }
                            onClick={ () =>
                                navigate("/auth/forgot-password", { replace: true })
                            }
                        >
                            Şifremi unuttum
                        </Button>
                    </Row>
                    <Button block type="primary" htmlType="submit" loading={ loading }>
                        { buttonText }
                    </Button>
                    <Button
                        block
                        htmlType="button"
                        onClick={ () => setSmsLogin((prev) => !prev) }
                    >
                        SMS ile Giriş Yap
                    </Button>
                </Space>
            </Form>
        </Card>
    );
};

export default LoginWithEmail;
