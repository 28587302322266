import { Button, Popconfirm, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Columns, ListUrl, Path, Url } from "./Constant";
import { ProtectedElement } from "components";
import { delete_, getColumns } from "helper";
import { usePost } from "helper/Hook";

export const useTable = (mutateData, data) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate(Path);
    };

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${Url}/${primaryKey}`);
        if(!success){
            notification.error({
                message: "Bilgilendirme",
                description: message,
                placement: "top"
            });
            return;
        }
        onHide();
    };

    const editHandler = (id) => {
        const selectedPosName = data?.find( ( item ) => item.id === id )?.pos_name;
        navigate( `${ id }/edit`, { state: selectedPosName });
    };
    
    const renderAction = (_, record) => (
        <>
            <ProtectedElement permissionKey={ "virtual.edit" }>
                <Button onClick={ ()=>editHandler(record?.id) } type="link" size="small">
                    <i className="flaticon2-edit text-muted" />
                </Button>
            </ProtectedElement>
            <ProtectedElement permissionKey={ "virtual.delete" }>
                <Popconfirm okText="Evet" cancelText="Hayır" 
                    title="Eminmisiniz?" onConfirm={ () => deleteAction(record.id) }>
                    <Button type="link" size="small" danger>
                        <i className="flaticon2-trash" />
                    </Button>
                </Popconfirm>
            </ProtectedElement>
        </>
        
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns };
};

export const useData = (queryParams) => usePost(ListUrl, queryParams);

export const getFilterQuery = (queryParams, values) => {
    const { name,active } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (active) filter.active = +active;
    if (name) filter.pos_name__ilike = `%${name}%`;
    newQueryParams.filter = filter;
    return newQueryParams;
};
