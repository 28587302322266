import { Layout } from "antd";
import { darkIcon, lightIcon } from "assets/media/index";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CollapseButton from "./SideBar/CollapseButton";
import Logout from "./SideBar/Logout";
import MenuSection from "./SideBar/MenuSection";
import Profile from "./SideBar/Profile";
import SearchButton from "./SideBar/SearchButton";
import ThemeSelection from "./SideBar/ThemeSelection";
import { SideBarItems } from "constant/SideBar";
import { useAuth, useLayout } from "context";
import { useAllowedSidebarItems } from "helper";

const { Sider } = Layout;

const SideBar = () => {
    const location = useLocation();
    const allowedSideBarItems = useAllowedSidebarItems();
    
    const [selectedKey, setSelectedKey] = useState(["dashboard"]);
    const [openKey, setOpenKey] = useState([]);
    const navigate = useNavigate();
    const [theme, setTheme] = useState("light");
    const [bgLight, setBgLight] = useState("white");
    const [bgDark, setBgDark] = useState("#F0F0F0");
    const [colorLight, setColorLight] = useState();
    const [colorDark, setColorDark] = useState();
    const { removeSession } = useAuth();
    const { collapsed, toggleCollapsed } = useLayout();
    const [icon, setIcon] = useState(lightIcon);

    const onClickCollapseButton = () => {
        localStorage.setItem(
            "collapsedButtonState",
            JSON.stringify(collapsed ? "open" : "close")
        );
        toggleCollapsed();
    };

    const handlePath = () => {
        const { pathname } = location;
        const paths = pathname.substring(1).split("/");
        const prefix = paths[0];
        setOpenKey( [ prefix ] );
        const path = paths[1];
        const keySelected = path ? path : prefix;
        setSelectedKey([keySelected]);
    };

    const onChangeLight = () => {
        setTheme("light");
        setBgLight("white");
        setBgDark("#F0F0F0");
        setColorLight("#535763");
        setColorDark("#535763");
        setIcon(lightIcon);
    };

    const onChangeDark = () => {
        setTheme("dark");
        setBgDark("white");
        setBgLight("#F0F0F0");
        setColorLight("#535763");
        setColorDark("#535763");
        setIcon(darkIcon);
    };

    useEffect(() => {
        handlePath();
    }, [location.pathname]);

    const handleMenuClick = (item) => {
        const { key } = item;
        const sideBarItem = SideBarItems.find((ele) =>
            ele.children
                ? ele.children?.find((elem) => elem.key == key)
                : ele.key == key
        );
        const elem = sideBarItem.children
            ? sideBarItem.children.find((ele) => ele.key == key)
            : sideBarItem;
        const path = sideBarItem.children
            ? `/${sideBarItem.key}/${elem.key}`
            : `/${sideBarItem.key}`;
        navigate(path);
    };

    const logout = async () => {
        await removeSession();
        // window.location.reload();
        navigate("/auth/login");
    };

    return (
        <Sider
            trigger={ null }
            collapsible
            collapsed={ collapsed }
            onCollapse={ toggleCollapsed }
            className="sidebar-menu mt-64"
        >
            <CollapseButton
                collapsed={ collapsed }
                toggleCollapsed={ toggleCollapsed }
                onClickCollapseButton={ onClickCollapseButton }
            />
            <Profile collapsed={ collapsed } />
            <SearchButton collapsed={ collapsed } />
            <MenuSection
                collapsed={ collapsed }
                theme={ theme }
                selectedKey={ selectedKey }
                openKey={ openKey }
                SideBarItems={ allowedSideBarItems }
                handleMenuClick={ handleMenuClick }
            />
            <Logout collapsed={ collapsed } logout={ logout } />
            <ThemeSelection
                collapsed={ collapsed }
                onChangeDark={ onChangeDark }
                onChangeLight={ onChangeLight }
                bgLight={ bgLight }
                colorLight={ colorLight }
                colorDark={ colorDark }
                bgDark={ bgDark }
                icon={ icon }
            />
        </Sider>
    );
};

export default SideBar;
