import React from "react";
import { Route, Routes } from "react-router-dom";
import { InstallmentsView } from ".";

const Router = () => (
    <Routes>
        <Route path="/" element={ <InstallmentsView /> } /> 
    </Routes>  
);

export default Router;
