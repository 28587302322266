import { Form, Select } from "antd";
import { useData } from "../Helper";
import { statusOptions } from "constant";
import { useFilter } from "context";

export const FilterMenu = ({ form, handleSubmit }) => {
    const { queryParams } = useFilter();
    const { data } = useData(queryParams);
    
    return (
        <Form
            form={ form }
            layout="vertical"
            requiredMark={ "Required" }
            initialValues={ {
                active: statusOptions[1].value
            } }
            onFinish={ handleSubmit }
        >
            <Form.Item
                label="Durum"
                name="active"
                required
                tooltip="Veri durumu"
            >
                <Select options={ statusOptions } />
            </Form.Item>
            <Form.Item
                label="Fiyat Şablon Kodu"
                name="trans_code"
            >
                <Select  options={ data?.result?.map((item)=>({ label:item?.trans_code, value:item?.trans_code })) }/>
            </Form.Item>
        </Form>
    );
};
