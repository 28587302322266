import { SettingsListPage } from "./mailSms/list";

export const tableItems = [
    { key: "item-1", label: "Mail Ve Sms Ayarları" },
    { key: "item-2", label: "Firma Ayarları" }
];

export const activateItems =
{
    "item-1":<SettingsListPage />,
    "item-2":<p>Firma Ayarları</p>
};
