import { Card, Row } from "antd";
import React from "react";

export const Overview = (props) => {
    return (
        <Card>
            <Row gutter={ 24 }>{ props.children }</Row>
        </Card>
    );
};
