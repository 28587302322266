import { Cascader, Form, notification } from "antd";
import React, { useEffect, useState } from "react";
import { categoryListUrl, categorySubListUrl } from "services";
import { post } from "helper";

const formatResult = (data,isLeaf) => {
    return data?.map(item => ({ label: item.name, value: item.id ,  isLeaf }));
};

const Categories = () => {
    const [ categoryList, setCategoryList ] = useState( undefined );

    const getCategoryList = async  ( ) => {
        try {
            const { result, success, message } = await post(categoryListUrl, {
                pageSize: 1000,
                filter: { active: 1 }
            });
            if (!success) {
                notification.error({
                    message: "Bilgilendirme",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setCategoryList( formatResult( result,false ) );
        } catch (e) {
            console.log(e.toString());    }
    };

    const onChange = async ( value, selectedOptions ) => {
        const subCategory= await getSubCategory( selectedOptions[ 0 ]?.value );
        const existingCategory = categoryList.find( ( item ) => item.value === selectedOptions[ 0 ]?.value );		
        if ( existingCategory) { 
            setCategoryList(prevData => prevData.map( ( item ) => {	
                if ( item.value === selectedOptions[ 0 ]?.value ) return { ...item, children: subCategory };
                else return item;
            } )) ;      
        }	
    };

    const getSubCategory = async ( value ) => {   
        try {
            const { result, success, message } = await post(categorySubListUrl, {
                pageSize: 1000,
                filter: { active: 1, category_id: value }
            });
            if ( !success ) {
                notification.error({
                    message: "Bilgilendirme",
                    description: message,
                    placement: "top"
                });
                return;
            }
            return formatResult(result,true);	
        } catch ( e ) {
            console.log(e.toString());   
        }
    };
	
    useEffect( () => {
        getCategoryList();
    }, [] );

    return (
        <Form.Item
            label="Kategori"
            name="category_id"
            rules={ [ { required: true, message: "Lütfen kategori seçiniz!" } ] }>
            <Cascader options={ categoryList } onChange={ onChange } changeOnSelect placeholder="Lütfen kategori seçiniz!"/>
        </Form.Item> );
};
export default Categories;
