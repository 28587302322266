import { Button, Form, Input, Typography, message, notification } from "antd";
const { Text } = Typography;
import React from "react";
import { useParams } from "react-router-dom";
import { userPasswordContinueUrl } from "services";
import { put } from "helper";
import { useUser } from "helper/Hook";

const Password = ({ setOpenPassword }) => {
    const { id: userId } = useParams();
    const { data } = useUser(userId);
    const { result: user } = data;
    const [form] = Form.useForm();

    const handleSubmitPassword = async () => {
        const password =(form.getFieldValue("password"));
        const newValues = {
            password: password,
            user_id: user.id
        };
        const { success, message: msg } = await put(
            `${userPasswordContinueUrl}${user.id}`,
            newValues
        );
        if (!success) {
            notification.error({
                description: msg,
                message: "Bilgilendirme",
                placement: "top"
            });
            return;
        }
        message.success("Şifreniz Başarıyla Güncellendi!");
        setOpenPassword(false);
    };

    return (
        <div>
            <Text>Yeni şifre giriniz</Text>
            <Form 
                form={ form } 
            >
                <Form.Item
                    name="password"
                    rules={ [{ message: "Şifre giriniz", required: true }] }
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <p style={ { marginTop: "10px" } }>Yeni şifrenizi doğrulayınız</p>
                <Form.Item
                    name="confirmPassword"
                    dependencies={ ["password"] }
                    rules={ [
                        {
                            message: "Şifreyi doğrulayın",
                            required: true
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("Şifre Eşleşmedi");
                            }
                        })
                    ] }
                    hasFeedback
                >
                    <Input.Password className="login-info-card-drawer-input" />
                </Form.Item>
                <Button
                    type="primary"
                    style={ { marginTop: "10px" } }
                    onClick={ handleSubmitPassword }
                >
                    Şifre Değiştir
                </Button>
            </Form>
        </div>
    );
};

export default Password;
