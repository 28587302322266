import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const TopSection = ({ isDisabled }) => {
    const navigate = useNavigate();

    return (
        <Space className="flex-btw" value={ "large" }>
            <Typography.Title strong level={ 5 }>
                Ödeme Bilgileri
            </Typography.Title>
            <Space>
                <Button
                    icon={ <ArrowLeftOutlined /> }
                    onClick={ () => navigate( "/bufipay/payments" ) }
                >
                    Geri
                </Button>
                <Button type="primary" htmlType="submit" disabled={ isDisabled }>
                    Öde
                </Button>
            </Space>
        </Space>
    );
};

export default TopSection;
