import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Space, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductInformation from "./AddView/ProductInformation";
import { BreadcrumbsAdd } from "../Constant";
import { PageTitle } from "context";

const AddView = () => {
    const [ productDetails, setProductDetails ] = useState( {} );
    const navigate = useNavigate();
    
    const handleSave = async ( values ) => {
        console.log( values );
        console.log(productDetails);

        try {			
            // const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            //     await put(`${Url}/${primaryKey}`, values);
            // if(!success){
            //     notification.error({
            //         message: "Bilgilendirme",
            //         description: message,
            //         placement: "top"
            //     });
            //     return;
            // }
	
        } catch (error) {
            console.log(error.toString());
        }
    };

    return (
        <>
            <PageTitle breadcrumbs={ BreadcrumbsAdd }>
                { BreadcrumbsAdd[ BreadcrumbsAdd.length - 1 ].breadcrumbName }</PageTitle>
            <Form
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Space className="flex-btw" value={ "large" }>
                    <Typography.Title strong level={ 4 }>
                        Ürün Bilgileri
                    </Typography.Title>
                    <Space>
                        <Button icon={ <ArrowLeftOutlined /> } onClick={ () => navigate("/catalog/product-stock") }>
                            Geri
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Kaydet
                        </Button>
                    </Space>
                </Space>
                <ProductInformation
                    setProductDetails={ setProductDetails }
                    productDetails={ productDetails }/>     
            </Form>
        </>
 
    );
};
export default AddView;
