export const roleListUrl = "/role/list";
export const addressListUrl = "/address/list";
export const companyListUrl = "/company/list";
export const userAddressesUrl = "/user/address";
export const virtualPosBankListUrl = "/bank/bank/list";
export const virtualListUrl = "/virtual-pos/virtual/list";
export const virtualPosConfigListUrl = "/virtual-pos/config/list";
export const virtualPosConfigChangeUrl = "/virtual-pos/config/change";
export const citiesAddressListUrl = "/address/cities/list";
export const addressCountiesUrl = "/address/counties/";
export const companyContinueUrl = "/company/";
export const installmentsUpdateUrl = "/virtual-pos/installment/update";
export const installmentsListUrl = "/virtual-pos/installment/list";
export const userAddUrl = "/user/add";
export const paymentContinueUrl = "/payment/";
export const paymentMethodsList = "/payment/list/payment_methods";
export const newPaymentUrl = "/payment/payment/";
export const bin_checkUrl = "/payment/bin_check/";
export const categoryListUrl = "/category/list";
export const categoryUrl ="/category";
export const addCategoryUrl = "/category/add";
export const productAttributeListUrl = "/product_attribute/list";
export const brandUrl = "/brand";
export const brandListUrl = "/brand/list";
export const addBrandUrl = "/brand/add";
export const imagePlaceHolderUrl="https://api-dev.buficore.com/placeholder.png";
export const accountCompany = "/account/company/";
export const settingConfigUrl = "/setting/config";
export const settingConfigListUrl = "/setting/config/list/";
export const emailTestUrl = "/setting/email_test";
export const smsTestUrl = "/setting/sms_test";
export const syncCompanyUrl = "/sync/company";
export const syncProgressUrl="/setting/syncProgress";
export const membershipUserContinueUrl = "/membership/user/";
export const membershipUserUrl = "/membership/user";
export const accountCompanyUrl = "/account/company";
export const syncProductUrl = "/sync/product";
export const syncProductAttributesUrl ="/sync/attributeValues";
export const syncBrandUrl = "/sync/brand";
export const syncCategoryUrl = "/sync/category";
export const syncAddressUrl="/sync/address";
export const syncOrderUrl="/sync/order";
export const company = "/company/";
export const assignImageUrl = "/product/sku/assignImage";
export const deleteProductImageUrl = "/product/sku/delete_image/";
export const changeDefaultImageUrl="/product/sku/default_image/";
export const uploadProductImageUrl="/product/sku/add_image";
export const campaignDetailsUrl = "/campaign/";
export const campaignAddUrl = "/campaign/add";
export const productSkuListUrl="/product/sku/list";
export const ordersSendToServerUrl="/order/sendToServer/";
export const updateAnOrderUrl = "/update/order/";
export const salesOrderUrl = "/sales/order/";
export const companyOrderUpdateUrl = "/update/order_company/";
export const productFeeListUrl = "/product/fee/list";
export const productFeeUpdateUrl = "/update/product_fee_company/";
export const authenticateMeUrl = "/authenticate/me";
export const authenticateLoginUrl = "/authenticate/login";
export const orderUrl = "/order";
export const listUrl = "/list";
export const addUrl = "/add";
export const editUrl = "/edit";
export const deleteUrl = "/delete";
export const overviewUrl = "/overview";
export const categorySubListUrl = "/category/sub/list";
export const productTypeListUrl = "/product_type/list";
export const productTypeUrl = "/product_type";
export const productAttributeUrl = "/product_attribute";
export const productAttributeValueUrl = "/product_attribute/value";
export const productSkuUrl = "/product/sku";
export const productSkuDetailUrl = "/product/sku/detail/";
export const campaignUrl = "/campaign";
export const collectionUrl = "/collection";
export const companyUrl = "/company";
export const addressUrl = "/address";
export const paymentUrl = "/payment";
export const bufipayPaymentsUrl = "/bufipay/payments";
export const bufipayPaymentsContinueUrl = "/bufipay/payments/";
export const virtualPosVirtualUrl = "/virtual-pos/virtual";
export const bufipayVirtualPosAccountsUrl = "/bufipay/virtual-posaccounts";
export const userListUrl = "/user/list";
export const userUrl = "/user";
export const userContinueUrl = "/user/";
export const roleUrl = "/role";
export const productFeeUrl = "/product/fee";
export const virtualPosVirtualListUrl = "virtual-pos/virtual/list";
export const productUrl = "/product";
export const catalogProductUrl = "/catalog/product";
export const userPasswordContinueUrl = "user/password/";
export const userCreateConfirmCodeUrl = "user/createConfirmCode";
export const userValidateConfirmCodeContinueUrl = "user/validate-confirm-code/";
export const productSkuDeactiveContinueUrl = "product/sku/deactivate/";
export const searchHistoryListContinueUrl = "/search/history/list/";
export const favoriteUrl = "/favorite";
export const productToggleSaleUrl = "product/sku/toggle_sale/";
export const cartUrl = "/cart";
export const cartRemoveContinueUrl = "/cart/remove/";
export const cartDeleteContinueUrl = "cart/delete/";
export const notificationUrl = "/notification";
export const originListUrl="/origins/list";
export const templateListUrl="/role/role_templates";
export const roleAddUrl="/role/add";
export const paymentResendPaymentEmailContinueUrl = "/payment/resend_payment_email/";
export const virtualposSchemaUrl="virtual-pos/get_schema";
export const syncProductFeeUrl = "/sync/product_fee";
export const syncProductLotUrl = "/sync/product_lot";
export const syncProductStockUrl = "/sync/product_stock";
export const syncProductSkuUrl = "/sync/product_sku";
export const userActivationContinueUrl = "/user/activation/";
export const userActivationSMSUrl = "/user/activation_sms/";
export const userConfirmContinueUrl = "/user/confirm/";
export const catalogProductstockContinureUrl = "/catalog/product-stock/";
export const productOrdersContinueUrl = "/product/orders/";
export const productOrdersUrl = "/product/orders";
export const updateProductskuContinueUrl = "/update/product_sku/";
export const settingConfigFirmsAndCategoriesUrl = "/setting/config/firms_and_categories";
export const settingAddConfigUrl = "/setting/add/config";
export const settingEditConfigContinueUrl = "/setting/edit/config/";
export const settingsMailSmsSettingsUrl = "/settings/mail-sms-settings";
export const settingConfigTableListUrl= "/setting/config/table/list";
export const deleteConfigContinueUrl = "/delete/config/";
export const productPriceUrl = "/product/price";
