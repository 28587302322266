import { Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { columns } from "./Constant";

const Installments = ({ installments, onSelectedInstallmentHandler }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([1]);

    const onSelectChange = (newSelectedRowKeys) => 
        setSelectedRowKeys(newSelectedRowKeys);

    useEffect(() => {
        const selectedKeyData = installments?.find((item) => {
            return item.key === selectedRowKeys[0];
        });
        if ( !selectedKeyData ) return;
        const { install, total_amount } = selectedKeyData;
        onSelectedInstallmentHandler({
            installment_cnt: install.toString(),
            amount: total_amount?.toString()
        });
    }, [ selectedRowKeys, installments ] );
    
    return (
        <>
            <Typography.Title strong level={ 5 }>
                Taksit Seçenekleri
            </Typography.Title>
            <div className="installments_table">
                <Table
                    columns={ columns }
                    dataSource={ installments }
                    pagination={ false }
                    rowSelection={ {
                        selectedRowKeys,
                        onChange: onSelectChange,
                        type: "radio"
                    } }
                />
            </div>
        </>
    );
};
export default Installments;
