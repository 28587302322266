import { Navigate, Route, Routes } from "react-router-dom";
import { SettingsPage } from ".";
import GeneralPage from "./generalSettings/GeneralPage";
import { ProtectedRoute } from "components";

const Router = () => (
    <Routes>
        <Route index element={ <SettingsPage /> } />  
        <Route path="settingssum"    element={ <Navigate to="/settings" replace /> }/>
        <Route element={ <ProtectedRoute permissionKey={ "settings.view" } /> }>
            <Route path="general-settings" element={ < GeneralPage/> } />
        </Route>
    </Routes>  
);

export default Router;
