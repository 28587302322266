/* eslint-disable no-restricted-imports */
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space } from "antd";
import isEqual from "lodash/isEqual";
import { useState } from "react";
import { companyOrderUpdateUrl } from "services";
import { getFilterQuery } from "./Helper";
import { useFilter } from "context";
import { notificationError, notificationSuccess, post } from "helper";

const Filter = ({ outRef }) => {
    const { queryParams, setQueryParams } = useFilter();

    const [loading,setLoading] = useState(false);

    const syncOrderList = async () => {
        setLoading(true);
        const { success, message } = await post(`${companyOrderUpdateUrl}${outRef}`,{
            out_ref:outRef
        });
        if (!success) {
            setLoading(false);
            notificationError(message);
            return;
        }
        setLoading(false);
        notificationSuccess("Güncelleme başarıyla tamamlandı");
    };

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleInputChange = ({ target }) => {
        const name = target?.value;
        applyFilter({ ...queryParams.filter, name });
    };

    return (
        <Form layout="vertical" requiredMark={ "Required" }>
            <Row align="space-between" className="mb-20">
                <Col>
                    <Input
                        onChange={ handleInputChange }
                        placeholder="Sipariş Numarası Giriniz"
                    />
                </Col>
                <Col>
                    <Space>
                        { outRef ? <Button
                            shape="round"
                            type="dashed"
                            onClick={ () => syncOrderList() }
                            block
                            icon={ <CloudDownloadOutlined /> }
                            loading={ loading }
                        >
                            Güncelle
                        </Button> : undefined 
                        }
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
export default Filter;
