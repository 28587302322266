import React from "react";
import AuthInfo from "./AuthInfo";
import CompanyInfo from "./CompanyInfo";
import PreferenceInfo from "./PreferenceInfo";
import UserInfo from "./UserInfo";

const Page = () => {
    return (
        <div className="settings-card">
            <UserInfo />
            <AuthInfo />
            <CompanyInfo />
            <PreferenceInfo />
        </div>
    );
};

export default Page;
