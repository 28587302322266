import { Image } from "antd";
import { Link, Route, Routes } from "react-router-dom";
import { accountCompany, addUrl, companyUrl, listUrl } from "services";
import { AddView } from "./add";
import { ProtectedRoute } from "components";

const Breadcrumbs = [
    {
        breadcrumbName: "Cari İşlemleri",
        path: "/account"
    },
    {
        breadcrumbName: "Firmalar",
        path: "/company"
    }
];

const BreadcrumbsAdd = [
    {
        breadcrumbName: "Cari İşlemleri",
        path: "/account"
    },
    {
        breadcrumbName: "Firmalar",
        path: "/account/company"
    },
    {
        breadcrumbName: "Yeni Firma",
        path: "/add"
    }
];

const Columns = [
    {
        render: (_, record) => (
            <Link to={ `${accountCompany}${record?.id}/overview` }>
                <span className="symbol symbol-50px">
                    <Image 
                        className="product img-thumbnail"
                        src={ record.image }
                    />
                </span>
            </Link>
        ),
        title: "",
        width: 80
    },
    {
        render: (_, record) => {
            const { id, code, name, out_ref }=record || {};
            return (
                <div>
                    <Link to={ `${accountCompany}${id}/overview` } 
                        className="text-dark-75 font-weight-bold text-hover-info mb-1">{ name }</Link>
                    { out_ref && (<div className="text-muted">Kaynak kodu: { out_ref }</div>) }
                    { code && (<div className="text-muted">Kod: { code }</div>) }

                    <div className="font-weight-light font-size-xs">
                        Kayıt Tarihi: { record?.create_date }
                    </div>
                    <div className="font-weight-light font-size-xs">
                        Güncelleme Tarihi: { record?.update_date }
                    </div>
                </div>
            );},
        sorter:true,
        title: "Firma"
    }
];
const Path = "/account/company";
const Url = companyUrl;
const ListUrl = `${Url}${listUrl}`;
const addPath = `${Path}${addUrl}`;

const Router = ({ onHide }) => (
    <Routes>
        <Route element={ <ProtectedRoute permissionKey={ "company.add" } /> }>  
            <Route path="add" element={ <AddView onHide={ onHide }/> } />
        </Route>
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router, addPath, BreadcrumbsAdd };

