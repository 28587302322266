import { Card, Row } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import ExistingPictures from "./ExistingPictures";
import PictureUpload from "./PictureUpload";
import { Spinner } from "components";
import { useProduct } from "helper";

const PicturesTab = () => {
    const { id: primaryKey } = useParams();
    const { data, error } = useProduct( primaryKey );
	
    if (!data && !error) {
        return <Spinner />;
    }
    else if ( !data && error ) return ( <Row justify="center"><p>Hata: Bir hata meydana geldi!</p></Row> );
    
    return (
        <Card>
            <ExistingPictures productSKU_ID={ data?.result?.id } />
            <PictureUpload productSKU_ID={ data?.result?.id } />
        </Card>
    );
};

export default PicturesTab;
