import { Button, Row, Table, Typography } from "antd";
import React, { useState } from "react";
import { assignImageUrl } from "services";
import { variantColumns } from "./Constant";
import { dataSelectHelper } from "./Helper";
import { notificationError, notificationSuccess, post } from "helper";

const VariantList = ({ data, imageId:image_id, mutate, selectedRows, onSelectedRowsHandler, onDrawerClose }) => {
    const [page, setPage] = useState(1);   
    const hasSelected = selectedRows?.length > 0;

    const applyVariants = async ( parameter ) => {
        const { selectedData, dataMessage }=dataSelectHelper(parameter,selectedRows,data);
        const product_sku_ids = selectedData;
        const { success, message } = await post(assignImageUrl, {
            product_sku_ids,
            image_id
        });
        if (!success) {
            notificationError(message);
            return;
        }
        mutate();  
        notificationSuccess( dataMessage, "topRight" );
        onDrawerClose();
    };

    return (
        <>
            <Row justify={ "space-between" }>
                <>
                    <Button
                        type="primary"
                        disabled={ !hasSelected }
                        onClick={ () => applyVariants("selected") }
                    >
                        Uygula
                    </Button>
                    <Typography.Text className="mt-3">{ `${selectedRows.length} ürün seçildi` }</Typography.Text>
                </>
                <Button type="primary" onClick={ () => applyVariants("all") }>
                    Hepsine Uygula
                </Button>
            </Row>
            <Table
                columns={ variantColumns }
                dataSource={ data }
                rowKey={ (record) => record.id }
                pagination={ {
                    current: page,
                    pageSize: 20,
                    onChange: (page) => setPage(page)
                } }
                rowSelection={ {
                    columnWidth: "2%",
                    type: "checkbox",
                    selectedRowKeys: selectedRows,
                    onChange: (keys) =>onSelectedRowsHandler(keys)
                } }
            />
        </>
    );
};

export default VariantList;
