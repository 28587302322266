import { Badge, Button, Card, Col, Divider, Row, Table } from "antd";
import { differenceBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { companyListUrl } from "services";
import Companies from "./company/Companies";
import { columnHelper, formatResultForTable, formatResultForTable1 } from "./company/Helper";
import { notificationError, notificationInfo, post, put } from "helper";

let totalCompanyCount=0;
let originalCompanyList=[];

const CompanyPricesPage = ({ data, primaryKey, mutate }) => {
    const memoisedFetchedData=useMemo(()=>formatResultForTable1(data?.companies),[data.companies]);

    const [loading, setLoading] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [companyList, setCompanyList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState(memoisedFetchedData);
    const [newColumns, setNewColumns] = useState(undefined);

    const hasSelected = selectedRowKeys?.length > 0;
    const hasTableData = tableData?.length > 0;

    const handleSave = async (whichData) => {
        const companies = whichData ==="table" ? tableData?.map((company) => company?.value):whichData==="all"?
            originalCompanyList.map((company) => company?.value) :[];

        const { success, message } = await put(`/product/price/${primaryKey}`, {
            ...data,
            companies
        });
        if (!success) {
            notificationError(message);
            return;
        }
        if(whichData==="all")setCompanyList([]);
        notificationInfo("Firmalar fiyat şablonuna başarıyla eklendi.");
        mutate();
    };

    const getCompanyList = async () => {
        setLoading(true);
        setError(false);
        const { result, success, message, total_count } = await post(`${companyListUrl}`, {
            filter: { active: 1 },
            pageSize:1000
        });
        totalCompanyCount=total_count;
        setLoading(false);
        if (!success) {
            setError(true);
            notificationError(message);
            return;
        }
        const companyList = formatResultForTable(result);
        originalCompanyList=[...companyList];
        const filteredExistingCompanyList = differenceBy(companyList,memoisedFetchedData, "value");
        setCompanyList(filteredExistingCompanyList);
    };

    const rowDeleteHandler = (record) =>{
        setSelectedRowKeys(prev=>prev.filter((row) => row !== record.key));
        setTableData((prev) =>
            prev
                .filter((item) => item.key !== record.key)
                .sort((a, b) => a.label.localeCompare(b.label))
        );
        setCompanyList((prev) => [...prev, record].sort((a, b) =>a.label.localeCompare(b.label)));
    };

    const deleteAllRows = () => {
        let existingTableData=[];
        setTableData(prev=>{
            existingTableData=[...prev];
            return [];
        });
        setCompanyList(prev=>{
            const deletedExistList = differenceBy(prev, existingTableData, "value");
            return ([...deletedExistList,...existingTableData].sort((a, b) => a.label.localeCompare(b.label)));
        });
    };

    const deleteSelectedRows = () => {
        const newTableData = tableData.filter(
            (price) => selectedRowKeys.indexOf(price.key) > -1
        );
        setSelectedRowKeys([]);
        setCompanyList((prev) => [...newTableData, ...prev]);
        setTableData((prev) =>
            prev.filter((company) => selectedRowKeys.indexOf(company.key) === -1)
        );
    };

    const pagination = {
        current: pageNumber,
        onChange: (page) => setPageNumber(page),
        pageSize: pageSize,
        showLessItems: false,
        showTotal: (total, range) => (
            <span className="text-muted">
                <b>{ total }</b> kayıttan <b>{ range[0] }</b> - <b>{ range[1] }</b> arası
            </span>
        ),
        total: tableData?.length
    };

    const handleTableChange = (pagination, filters, sorter, data) => setPageSize(pagination?.pageSize);

    const handleRowChange=(keys) => setSelectedRowKeys(keys);

    useEffect(() => {
        getCompanyList();
        setNewColumns(columnHelper(rowDeleteHandler));
    }, []);

    useEffect(() => {   
        setTableData(memoisedFetchedData);
    }, [memoisedFetchedData]);

    return (
        <Card>
            <Row gutter={ 24 } align="middle" justify="center">
                <Col span={ 14 }>
                    <Row justify="space-between" style={ { marginBottom:"30px" } }>
                        <Button
                            type="secondary"
                            onClick={ ()=>handleSave("all") }
                            disabled={ companyList?.length<1 || totalCompanyCount===tableData?.length }
                        >
                            Tüm  Firmalara Uygula
                        </Button>                
                        <Button
                            type="primary"
                            onClick={ ()=>handleSave("table") }
                            disabled={ !hasTableData }
                        >
                            Kaydet
                        </Button>

                    </Row>
                    { !error && (
                        <Companies
                            className="mb-20"
                            companyList={ companyList }
                            setCompanyList={ (list) => setCompanyList(list) }
                            tableData={ tableData }
                            setTableData={ (data) => setTableData(data) }
                            loading={ loading }
                            totalCompanyCount={ totalCompanyCount }
                        />
                    ) }
                    <Divider orientation="left">
                        Firma Tablosu
                    </Divider>
                    <Row justify="space-between" className="mb-10">
                        <Badge 
                            count={ selectedRowKeys.length }
                            overflowCount={ 1000 }
                        >                  
                            <Button
                                type="secondary"
                                disabled={ !hasSelected }
                                onClick={ deleteSelectedRows }
                                danger
                            >
                                Sil
                            </Button>
                        </Badge>
                        <Badge 
                            count={ tableData.length }
                            overflowCount={ 1000 }
                        >
                            <Button
                                type="secondary"
                                onClick={ deleteAllRows }
                                disabled={ !hasTableData }
                                danger
                            >
                                Hepsini Sil
                            </Button>
                        </Badge>
                    </Row>
                    { tableData && (
                        <Table
                            showSorterTooltip={ { title: "Sıralamayı değiştir" } }
                            columns={ newColumns }
                            dataSource={ tableData }
                            rowKey={ (record) => record.key }
                            pagination={ pagination }
                            onChange={ handleTableChange }
                            rowSelection={ {
                                columnWidth: "2%",
                                onChange: handleRowChange,
                                selectedRowKeys,
                                type: "checkbox"
                            } }
                        />
                    ) }
                </Col>
            </Row>
        </Card>
    );
};

export default CompanyPricesPage;
