/* eslint-disable no-restricted-imports */
import { Button, Col, Form, Input, Popconfirm, Row, Space, Tag } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { cartDeleteContinueUrl, cartUrl, listUrl } from "services";
import { getFilterQuery, useData } from "./Helper";
import { useFilter } from "context";
import { delete_, notificationError, notificationSuccess, post } from "helper";

const Filter = ({ userId }) => {
    const { queryParams, setQueryParams } = useFilter();
    const { mutate } = useData(queryParams);

    const [totalCount,setTotalCount] = useState();
    const [totalQuantity,setTotalQuantity] = useState();

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleInputChange = (event) => applyFilter({ ...queryParams.filter, name:event.target.value });

    useEffect(()=>{
        mutate();
    },[queryParams]);

    const getTotalCount = async () => {
        const { message, success, total_cost, total_quantity } = await post(`${cartUrl}${listUrl}`,
            {
                filter: { user_id : parseInt(userId) },
                pageNumber: 1,
                pageSize: 10,
                sortField: "id",
                sortOrder: "desc"
            }
        );
        if (!success) {
            notificationError(message);
            return;
        }
        setTotalCount(total_cost);
        setTotalQuantity(total_quantity);
    };

    const onClearClick = async () => {
        const { message, success } = await delete_(`${cartDeleteContinueUrl}${userId}`);
        if (!success) {
            notificationError(message);
            return;
        }
        mutate();
        notificationSuccess("Sepet temizlendi");
    };

    useEffect(()=>{
        getTotalCount();
    });

    return (
        <Row align="space-between">
            <Col>
                <Form>
                    <Form.Item name="searchText" required>
                        <Input
                            onChange={ handleInputChange }
                            placeholder="Kelime girin"
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col>
                <Space>
                    <Tag color="green" >
                        Toplam Tutar : { totalCount } ₺
                    </Tag>
                    <Tag color="blue" >
                        Toplam Adet : { totalQuantity }
                    </Tag>
                    <Popconfirm
                        className="logout-collapsed-confirm"
                        title="Emin misiniz？"
                        okText="Evet"
                        cancelText="Hayır"
                        onConfirm={ onClearClick }
                    >
                        <Button
                            type="primary"
                        >Sepeti Temizle</Button>
                    </Popconfirm>
                </Space>
            </Col>
        </Row>
    );
};
export default Filter;
