import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Result } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbsAdd } from "./Constant";
import { PageTitle } from "context";

const SuccessPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (!state?.paid) navigate("/bufipay/payments");
    }, [ state ] );
    
    return (
        <Card>
            <PageTitle breadcrumbs={ BreadcrumbsAdd }>
                { BreadcrumbsAdd[BreadcrumbsAdd.length - 1].breadcrumbName }
            </PageTitle>
            <Result
                status="success"
                title="Ödemeniz başarılı bir şekilde gerçekleşti!"
                subTitle="İlginizden dolayı teşekkür ederiz."
                extra={ [
                    <Button
                        key="back" icon={ <ArrowLeftOutlined /> }
                        onClick={ () => navigate( "/bufipay/payments" ) }
                    >
                        Geri
                    </Button>
                ] }
            />
        </Card>
    );
};

export default SuccessPage;
