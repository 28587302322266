import { listUrl, roleUrl } from "services";
import { ProtectedLink } from "components";

const Breadcrumbs = [
    {
        path: "/membership",
        breadcrumbName: "Üyelik İşlemleri"
    },
    {
        path: "/membership/role",
        breadcrumbName: "Roller"
    }
];

const Columns = [
    {
        title: "Rol",
        render: (_, record) => (
            <>
     
                <ProtectedLink permissionKey={ "role.edit" } path={ `${record?.id}/edit` }>
                    <div>{ record?.name }</div>
                </ProtectedLink>
                <div className="text-muted">{ record?.description }</div>
            </>
        )
    }
];
const Path = "/membership/role";
const Url = roleUrl;
const ListUrl = `${Url}${listUrl}`;


export { Breadcrumbs, Columns, Path, Url, ListUrl };

