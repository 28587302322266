import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { categoryListUrl } from "services";
import { priceDataFormatter } from "../common/Helper";
import PriceTable from "../common/PriceTable";
import { BreadcrumbsAdd } from "../Constant";
import ErrorMessage from "components/common/ErrorMessage";
import { PageTitle } from "context";
import { notificationError, notificationSuccess, post } from "helper";

const AddView = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [tableData, setTableData] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSave = async (values) => {
        const { trans_code, name,...rest } = values;
        const newValues={
            categories:Object.values(rest),
            companies:[],
            name,
            trans_code
        };
        const { success, message } = await post("/product/price/add", newValues);
        if (!success) {
            notificationError(message);
            return;
        }
        notificationSuccess("Fiyat Şablonu başarıyla eklendi.");
    };

    const getCategories = useCallback(async () => {
        setLoading(true);
        setError(false);
        const { success, result, message } = await post(categoryListUrl, {
            pageSize: 1000
        });
        setLoading(false);
        if (!success) {
            message && notificationError({ message: message });
            setError(true);
            return;
        }
        setError(false);
        const newResult = priceDataFormatter(result);
        setTableData(newResult);
    }, []);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    return (
        <>
            <PageTitle breadcrumbs={ BreadcrumbsAdd }>
                { BreadcrumbsAdd[BreadcrumbsAdd.length - 1].breadcrumbName }
            </PageTitle>
            <Space className="flex-btw" value={ "large" }>
                <Typography.Title strong level={ 4 }>
                    Ürün Fiyat Bilgileri
                </Typography.Title>
                <Space>
                    <Button
                        icon={ <ArrowLeftOutlined /> }
                        onClick={ () => navigate("/catalog/product-price") }
                    >
                        Geri
                    </Button>
                    <Button type="primary" onClick={ () => form.submit() }>
                        Kaydet
                    </Button>
                </Space>
            </Space>
            <Card>
                { error ? (       
                    <ErrorMessage />          
                ) :  (<Form requiredMark={ "required" } onFinish={ handleSave } form={ form }>
                    <PriceTable tableData={ tableData } loading={ loading } />
                </Form>) }
            </Card>
        </>
    );
};

export default AddView;
