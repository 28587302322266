import { Card, Form, Input, Select, notification } from "antd";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { productTypeListUrl } from "services";
import Brands from "./Brands";
import Categories from "./Categories";
import ProductVariants from "./ProductVariants";
import { post } from "helper";

const { TextArea } = Input;

const formatResult = (data) => {
    return data?.map((item) => ({ ...item, label: item.name, value: item.id }));
};

const ProductInformation = ({ productDetails, setProductDetails }) => {
    const [productTypes, setProductTypes] = useState(undefined);
    const [selectedProductType, setSelectedProductType] = useState(undefined);
    const [hasVariant, setHasVariant] = useState(undefined);
    const [variants, setVariants] = useState({});
    const [showVariantTable, setShowVariantTable] = useState(false);
     
    const getTypeList = async () => {
        try {
            const { result, success, message } = await post(productTypeListUrl, {
                pageSize: 1000,
                filter: { active: 1 }
            });
            if ( !success ) {
                notification.error({
                    message: "Bilgilendirme",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setProductTypes(formatResult(result));
        } catch (e) {
            console.log(e.toString());
        }
    };

    const onProductTypeChange = ( value ) => {
        const existingCategory = productTypes?.find(
            ( item ) => item.value === +value
        );
        setHasVariant( existingCategory?.has_variants );
        setSelectedProductType( { ...existingCategory } );
    };

    const onVariantsChange = ( input ) => ( event ) => {
        const variantTitle = event?.map( ( item ) => ( { [ input ]: item } ) );
        let variantNames=[];
        selectedProductType?.variants.map( item => variantNames.push( item.name ) );

        setVariants((prev) => {
            let variantData = Object.assign(prev);
            if (event.length === 0) {
                delete variantData[input];
            } else variantData = Object.assign( { ...prev, [ input ]: variantTitle } );

            if ( isEqual(variantNames, Object.keys(variantData))) {
                setShowVariantTable(true);
            } else setShowVariantTable(false);
            return variantData;
        });
    };

    useEffect(() => {
        getTypeList();
    }, []);

    return (
        <Card>         
            <Form.Item
                label="Ürün Adı"
                name="product_name"
                rules={ [ { required: true, message: "Lütfen ürün adını giriniz!" } ] }
                hasFeedback
            >
                <Input placeholder="Lütfen ürün adını giriniz!"/>
            </Form.Item>
            <Form.Item
                label="Ürün Türü"
                name="product_type"
                rules={ [{ required: true, message: "Lütfen ürün türünü seçiniz!" }] }
            >
                <Select
                    placeholder="Lütfen ürün türünü seçiniz!"
                    allowClear
                    showSearch
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    onChange={ onProductTypeChange }
                >
                    { productTypes?.map((option) => (
                        <Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            </Form.Item>
            <Brands />       
            <Categories />       
            <Form.Item
                label="Açıklamalar"
                name="product_definition"
                className="product-stock_input_border"
                rules={ [ { required: true, message: "Lütfen ürün açıklamasını yazınız!" },
                    { min: 12, max: 120, message:"Ürün açıklaması en fazla 120 karakter olmalıdır!" } ] } >
                <TextArea rows={ 4 } placeholder="Lütfen ürün açıklaması giriniz!"/>
            </Form.Item>
            { hasVariant && selectedProductType?.variants?.map((item) => (
                <Form.Item
                    key={ item?.id?.toString() }
                    label={ item?.name }
                    name={ item?.name }
                    rules={ [{ required: true, message: "Lütfen en az bir değer seçiniz!" }] }
                >
                    <Select
                        mode="multiple"
                        onChange={ onVariantsChange( item.name ) }>
                        { item?.values?.map((option) => (
                            <Select.Option key={ option.name }>{ option.name }</Select.Option>
                        )) }
                    </Select>
                </Form.Item>
            )) }
            { hasVariant && showVariantTable && (
                <ProductVariants
                    variants={ variants }
                    setVariants={ setVariants }
                    setProductDetails={ setProductDetails }
                    productDetails={ productDetails }/>
            ) }
        </Card>
    );
};

export default ProductInformation;
