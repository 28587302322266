import { Input } from "antd";
import React from "react";

const CustomNumberInput = ({ value = "", onChange, digitsOfNumber, placeholder, prefix }) => {
    const triggerChange = (changedValue) => onChange?.( changedValue );

    const onNumberChange = ({ target }) => {
        const numberInput = target?.value.replace(/\D/g, "").slice(0, digitsOfNumber);
        triggerChange( numberInput);		
    };

    return (
        <Input
            type="text"
            value={ value }
            onChange={ onNumberChange }
            placeholder={ placeholder }
            prefix={ prefix }
        />
    );
};
export default CustomNumberInput;
