import { Badge, Button, Card, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { columnHelper } from "./Helper";

const CampaignData = ({
    campaignData,
    onCampaignDataHandler,
    onFilteredOptionsHandler
}) => {
    const [page, setPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [newColumns, setNewColumns] = useState(undefined);
    const hasSelected = selectedRows?.length > 0;

    const deleteAllRows = () => onCampaignDataHandler([]);
    
    const deleteSelectedRows = () => {
        const newCampaignData = campaignData.filter(
            (product) => selectedRows.indexOf(product.id) > -1
        );
        const addingCampaignData = campaignData.filter(
            (product) => selectedRows.indexOf(product.id) === -1
        );
        onCampaignDataHandler(addingCampaignData);
        onFilteredOptionsHandler((prev) => [...prev, ...newCampaignData]);
    };

    const rowDeleteHandler = (record) => onCampaignDataHandler((prev) =>prev.filter((item) => item.key !== record.key));

    useEffect(() => {
        const columns = columnHelper(rowDeleteHandler);
        setNewColumns(columns);
    }, []);

    return (
        <Card>
            <Row justify="space-between" className="mb-10">
                <Badge 
                    count={ selectedRows.length }
                    overflowCount={ 1000 }
                >
                    <Button
                        type="secondary"
                        disabled={ !hasSelected }
                        onClick={ deleteSelectedRows }
                        danger
                    >
                        Sil
                    </Button>
                </Badge>
                <Badge 
                    count={ campaignData.length }
                    overflowCount={ 1000 }
                >
                    <Button type="secondary" onClick={ deleteAllRows } danger>
                        Hepsini Sil
                    </Button>
                </Badge>
            </Row>
            <Table
                columns={ newColumns }
                dataSource={ campaignData }
                rowKey={ (record) => record.id }
                pagination={ {
                    current: page,
                    onChange: (page) => setPage(page),
                    pageSize: 20
                } }
                rowSelection={ {
                    columnWidth: "2%",
                    onChange: (keys) => setSelectedRows(keys),
                    selectedRowKeys: selectedRows,
                    type: "checkbox"
                } }
            />
        </Card>
    );
};

export default CampaignData;
