import { Button, Result } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ErrorMessage = ({ error }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split("/");

    const onClickHandler=()=> {
        if(path?.length===3) navigate(`/${path[1] }`);
        else if(path?.length===5) navigate(`/${path[1] }/${path[2]}`);
        else navigate("/");
    };

    return (
        <Result
            status="500"
            title={ error?.response?.status }
            subTitle="Özür Dileriz! Bir Hata Meydana geldi!"
            extra={
                <>             
                    <Button type="primary" onClick={ () => location.reload() }>
                        Yenile
                    </Button>
                    <Button onClick={ onClickHandler }>Geri Dön</Button>
                </>
            }
        />
    );
};

export default ErrorMessage;
